import React from "react";
import CommonStyle from "common/styles/common.css";
import { Container, Row, Col, Nav } from "react-bootstrap";

import SubscriptionAndBillingOverview from "./overview";
import BillingReport from "./billing-report";
import PaymentHistory from "./payment-history";
import { UserInfoContext } from "../../_newapp/common/contexts/contexts";

const sidebarLinks = {
  "/subscription-and-billing/overview": {
    title: "Overview",
    component: () => {
      return <SubscriptionAndBillingOverview />;
    }
  },
  "/subscription-and-billing/billing-report": {
    title: "Billing Report",
    component: () => {
      return <BillingReport />;
    }
  },
  "/subscription-and-billing/payment-history": {
    title: "Payment History",
    component: () => {
      return <PaymentHistory />;
    }
  }
};

function SubscriptionAndBilling(props) {
  function handleTabSelected(path) {
    if (path === props.location.pathname) {
      return;
    }
    props.history.push(path);
  }

  function isNavLinkActive(path) {
    return props.location.pathname === path;
  }

  return (
    // 1. if in free trial
    // 2. if free trial expired
    // 3. if active

    // 4. if inactive (canceled subscription)
    // 5. if payment failed (suspend subscription)
    <React.Fragment>
      <UserInfoContext.Consumer>
        {({ userInfo }) => {
          const shouldShowBillingReport = _.get(
            userInfo,
            "publisher.config.dashboardConfig.billingReportDashboard.enabled"
          );

          return (
            <div className={CommonStyle.wrapper}>
              <Container>
                <Row>
                  <Col lg="2">
                    <div>
                      <Nav
                        variant="pills"
                        defaultActiveKey={props.location.pathname}
                        className="flex-column"
                        onSelect={handleTabSelected}
                      >
                        <Nav.Link
                          eventKey={"/subscription-and-billing/overview"}
                          active={isNavLinkActive(
                            "/subscription-and-billing/overview"
                          )}
                        >
                          Overview
                        </Nav.Link>
                        {shouldShowBillingReport && (
                          <Nav.Link
                            eventKey={
                              "/subscription-and-billing/billing-report"
                            }
                            active={isNavLinkActive(
                              "/subscription-and-billing/billing-report"
                            )}
                          >
                            Billing Report
                          </Nav.Link>
                        )}
                        <Nav.Link
                          eventKey={"/subscription-and-billing/payment-history"}
                          active={isNavLinkActive(
                            "/subscription-and-billing/payment-history"
                          )}
                        >
                          Payment History
                        </Nav.Link>
                      </Nav>
                    </div>
                  </Col>
                  <Col lg="10">
                    <div>
                      <small className="text-muted">{`SUBSCRIPTION & BILLING`}</small>
                      <h1 className="display-4">
                        {sidebarLinks[props.location.pathname].title}
                      </h1>
                      <hr />
                    </div>
                    {sidebarLinks[props.location.pathname].component()}
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }}
      </UserInfoContext.Consumer>
    </React.Fragment>
  );
}

module.exports = SubscriptionAndBilling;
