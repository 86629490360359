import React from 'react'

import GeneralStyles from '../styles/common.css'
import LoadingImage from '../images/ajax-loader.gif'

class YbLoadingView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={GeneralStyles.viewPlaceholder}>
        <img src={LoadingImage} />
      </div>
    );
  }
};

module.exports = YbLoadingView;
