import gql from "graphql-tag";

export const ONBOARD_AD_UNITS = gql`
  mutation ONBOARD_AD_UNITS($input: onboardGamAdUnitsV2Input!, $pubId: Int) {
    create: onboardGamAdUnitsV2(input: $input, assumePubId: $pubId) {
      gamAdUnits {
        id
        itemId: adUnitId
      }
    }
  }
`;

export const CREATE_AD_UNIT = gql`
  mutation CREATE_AD_UNIT($input: onboardGamAdUnitsInput!, $pubId: Int) {
    create: onboardGamAdUnits(input: $input, assumePubId: $pubId) {
      gamAdUnits {
        id
        itemId: adUnitId
      }
    }
  }
`;

export const SAVE_AD_UNIT = gql`
  mutation SAVE_AD_UNIT($input: updateGamAdUnitInput!, $pubId: Int) {
    update: updateGamAdUnit(input: $input, assumePubId: $pubId) {
      gamAdUnit {
        id
      }
    }
  }
`;
