import React from "react";

function InvitationInvalid() {
  return (
    <div>
      <h3>
        Oops! This link is no longer working, please contact your reseller for
        more information.
      </h3>

      <hr />
      <p>
        Do you meant to login? <a href="#/login">You can login here</a>
      </p>
    </div>
  );
}

export default InvitationInvalid;
