import React from "react";
import { Query, withApollo, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import Styles from "../../summary/styles/summary.css";

import YbLoading from "common/components/yb-loading";
import YbModal from "common/components/yb-modal";
import YbTooltip from "common/components/yb-tooltip";
import YbHighlight from "common/components/yb-highlight";

import OAuth from "../components/oauth";
import { mutation as adxMutation } from "../query/adx-account";

import UserStyles from "../../user/styles/signup.css";
import { GET_GAM_ADX_ACCOUNTS } from "../query/gam-adx-accounts";

class YbAddAdxBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      adxAccount: {},
      submitting: false,
      reloadExternalSource: false
    };
  }

  renderAddAdxAccountModal(
    networkId,
    adxAccount,
    submitting,
    addAdxAccount,
    mutationProps,
    stateError,
    callback,
    hideCallback
  ) {
    var showAdxModal = this.props.showAdxModal;
    var reloadExternalSource = this.state.reloadExternalSource;
    var variables = {
      pcFilter: {
        networkId: networkId
      },
      adxFilter: {
        networkId: networkId
      },
      pubId: this.props.publisherId
    };

    return (
      <Query
        query={GET_GAM_ADX_ACCOUNTS}
        variables={variables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          let existingAccounts = _.get(data, "adxAccounts.edges", []).map(
            edge => {
              const code = _.get(edge, "node.clientCode");
              return code;
            }
          );

          let extAccounts = _.get(data, "extPropertyCodes", []).map(edge => {
            const code = _.get(edge, "code");
            return code;
          });

          let candidates = extAccounts.filter(extAccount => {
            return existingAccounts.indexOf(extAccount) === -1;
          });

          candidates = _.map(candidates, c => {
            return {
              name: c,
              value: c
            };
          });

          if (candidates.length > 0) {
            return (
              <YbModal
                error={stateError}
                onHide={() => {
                  var hideCallback = this.props.hideCallback;

                  if (hideCallback) {
                    hideCallback();
                  }

                  this.setState({
                    error: null
                  });
                }}
                show={this.props.showAdxModal}
                fields={[
                  {
                    key: "name",
                    placeholder: `${i18n`EX_DOT`} ${i18n`ACCOUNT_DISPLAY_NAME_ELLIPSIS`}`,
                    title: i18n`DISPLAY_NAME`,
                    extra: (
                      <span>
                        <YbTooltip
                          big={true}
                          position="auto"
                          message={
                            <div>
                              <YbHighlight
                                text={i18n`On your Ad Manager Console, look up the Display name of your account under Admin > Linked accounts > Ad Exchange`}
                                highlight={[
                                  i18n`Display name`,
                                  i18n`Admin`,
                                  i18n`Linked accounts`,
                                  i18n`Ad Exchange`,
                                  i18n`Ad Manager Console`
                                ]}
                              />
                            </div>
                          }
                        >
                          <span
                            className={`${CommonStyle.buttonLinkLight} ${
                              UserStyles.infoTooltip
                            }`}
                          >
                            <i className="fa fa-info-circle" />
                          </span>
                        </YbTooltip>
                      </span>
                    )
                  },
                  {
                    key: "clientCode",
                    title: i18n`WEB_PROPERTY_CODE`,
                    placeholder: `${i18n`EX_DOT`} 12345678999999...`,
                    type: "select",
                    selectOptions: candidates,
                    extra: (
                      <span>
                        <YbTooltip
                          big={true}
                          position="auto"
                          message={
                            <div>
                              <YbHighlight
                                text={i18n`On your Ad Manager Console, look up the Web Property code of your account under Admin > Linked accounts > Ad Exchange`}
                                highlight={[
                                  i18n`Web Property code`,
                                  i18n`Admin`,
                                  i18n`Linked accounts`,
                                  i18n`Ad Exchange`,
                                  i18n`Ad Manager Console`
                                ]}
                              />
                            </div>
                          }
                        >
                          <span
                            className={`${CommonStyle.buttonLinkLight} ${
                              UserStyles.infoTooltip
                            }`}
                          >
                            <i className="fa fa-info-circle" />
                          </span>
                        </YbTooltip>
                      </span>
                    )
                  }
                ]}
                disabled={mutationProps.loading}
                onSubmit={({ name, clientCode }) =>
                  addAdxAccount({
                    variables: {
                      networkId,
                      clientCode,
                      name,
                      pubId: this.props.publisherId
                    }
                  })
                    .then(({ data }) => {
                      if (callback) {
                        callback(data);
                      }
                      refetch();
                    })
                    .catch(error => {
                      this.setState({
                        error: error
                      });
                    })
                }
                title={i18n`NEW_ADX_ACCOUNT`}
              />
            );
          } else {
            return (
              <YbModal
                error={mutationProps.error}
                onHide={hideCallback}
                show={showAdxModal}
                disabled={mutationProps.loading}
                title={i18n`NEW_ADX_ACCOUNT`}
                successText={i18n`ADD_ADX_ACCOUNT`}
                disableSubmit={submitting || !adxAccount.webPropertyCode}
                disabled={submitting || reloadExternalSource}
              >
                <span className={CommonStyle.textView}>
                  <div
                    className={`${CommonStyle.textAlignCenter} ${
                      CommonStyle.textAlignCenter
                    } ${Styles.inModal}`}
                  >
                    {loading && <YbLoading isWhiteText={true} />}

                    {error && (
                      <div className={Styles.networkCandidate}>
                        <div
                          className={`${Styles.titleWrapper} ${
                            Styles.errorMsg
                          } text-danger`}
                        >
                          Error loading your AdX web property codes. Please make
                          sure your account is linked to a AdX account, or
                          contact us for more info.
                        </div>
                      </div>
                    )}

                    {data && candidates.length === 0 && (
                      <div
                        style={{ paddingTop: "60px", paddingBottom: "200px" }}
                      >
                        No linked AdX accounts were found in your Ad Manager.
                        <span>
                          <YbTooltip
                            position="bottom"
                            message={
                              <div>
                                <YbHighlight
                                  text={i18n`On your Ad Manager Console, look up the Web Property code of your account under Admin > Linked accounts > Ad Exchange`}
                                  highlight={[
                                    i18n`Web Property code`,
                                    i18n`Admin`,
                                    i18n`Linked accounts`,
                                    i18n`Ad Exchange`,
                                    i18n`Ad Manager Console`
                                  ]}
                                />
                              </div>
                            }
                          >
                            <span
                              className={`${CommonStyle.buttonLinkLight} ${
                                UserStyles.infoTooltip
                              }`}
                            >
                              <i className="fa fa-info-circle" />
                            </span>
                          </YbTooltip>
                        </span>
                      </div>
                    )}
                  </div>
                </span>
              </YbModal>
            );
          }
        }}
      </Query>
    );
  }

  render() {
    var adxAccount = this.state.adxAccount;
    var networkId = this.props.networkId;
    var callback = this.props.callback;
    var hideCallback = this.props.hideCallback;
    var stateError = this.state.error;
    var submitting = this.state.submitting;

    return (
      <Mutation mutation={adxMutation}>
        {(addAdxAccount, { data, loading, called, error }) => {
          let mutationProps = { data, loading, called, error };
          return this.renderAddAdxAccountModal(
            networkId,
            adxAccount,
            submitting,
            addAdxAccount,
            mutationProps,
            stateError,
            callback,
            hideCallback
          );
        }}
      </Mutation>
    );
  }
}

module.exports = YbAddAdxBtn;
