import gql from "graphql-tag";

export const GET_DASHBOARD_URL = gql`
  query {
    dashboard {
      summaryUrl
      analyticsURL
    }
  }
`;
