import gql from "graphql-tag";

export const typenames = {
  edge: "AdxAccountEdge",
  connection: "AdxAccountConnection",
  node: "AdxAccount"
};

export const query = gql`
  query($first: Int, $after: ID, $networkId: Int!, $pubId: Int) {
    adxAccounts(
      first: $first
      after: $after
      filter: { networkId: $networkId }
      assumePubId: $pubId
    ) @connection(key: "adxAccounts", filter: ["filter"]) {
      edges {
        node {
          id
          accountId: adxAccountId
          name
          clientCode
          problems
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const mutation = gql`
  mutation CREATE_ADX_ACCOUNT(
    $networkId: Int!
    $clientCode: String!
    $name: String!
    $pubId: Int
  ) {
    createAdxAccount(
      input: { networkId: $networkId, clientCode: $clientCode, name: $name }
      assumePubId: $pubId
    ) {
      adxAccount {
        id
        adxAccountId
        clientCode
        name
        problems
      }
    }
  }
`;

// export const mutation = gql`
//   mutation ADD_ADSENSE_ACCOUNT($accountId: Int!, $authorizationCode: String!) {
//     authorizeAdsenseAccount(input: {
//       accountId: $accountId,
//       authorizationCode: $authorizationCode
//     }) {
//       adsenseAccount {
//         id,
//         accountId,
//         clientCode,
//         name,
//         problems
//       }
//     }
//   }
// `
