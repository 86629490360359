import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FadeInWrapper from "./fade-in-wrapper";
import MetricTooltip from "./metric-tooltip";
import NumberFormat from "./number-format";
import InfoDescriptionTooltip from "./info-desc-tooltip";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

function AlgoCard(props) {
  const { title, tooltipMetricKey, value, footerElement } = props;
  return (
    <div className="p-3 border rounded">
      <div className="d-flex align-items-center justify-content-around">
        <div style={styles.statBlock}>
          <div style={styles.statTitle}>
            {title}
            <InfoDescriptionTooltip
              metricKey={tooltipMetricKey}
              place="top"
            ></InfoDescriptionTooltip>
          </div>
          <div style={styles.statText}>
            <FadeInWrapper key={value}>
              <NumberFormat value={value} format="0,0"></NumberFormat>
            </FadeInWrapper>
          </div>
        </div>
      </div>
      <div style={styles.statFooter}>{footerElement}</div>
    </div>
  );
}

class AlgorithmSection extends React.PureComponent {
  render() {
    const { inventoryData } = this.props;
    return (
      <React.Fragment>
        <div style={styles.sectionTitle}>Protection Activities</div>

        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "32.5%" }}>
            <AlgoCard
              title="Current Segments"
              tooltipMetricKey="CURRENT_SEGMENTS"
              value={inventoryData.currentSegments}
              footerElement={
                <div>
                  Evolved over{" "}
                  <span style={{ fontWeight: "600" }}>
                    <NumberFormat
                      value={inventoryData.totalSegmentBatches}
                      format="0,0"
                    ></NumberFormat>
                  </span>{" "}
                  generations
                </div>
              }
            ></AlgoCard>
          </div>
          <div style={{ width: "32.5%" }}>
            <AlgoCard
              title="Floor Strategy Simulations"
              tooltipMetricKey="FLOOR_STRATEGY_SIMULATIONS"
              value={inventoryData.totalExperiments}
              footerElement={
                <div>
                  Over{" "}
                  <span style={{ fontWeight: "600" }}>
                    <NumberFormat
                      value={inventoryData.averageExperimentsPerDay}
                      format="0,0"
                    ></NumberFormat>
                  </span>{" "}
                  simulations per day
                </div>
              }
            ></AlgoCard>
          </div>
          <div style={{ width: "32.5%" }}>
            <AlgoCard
              title="Pricing Detections"
              tooltipMetricKey="PRICING_DETECTIONS"
              value={inventoryData.totalExperimentGroups}
              footerElement={
                <div>
                  Over{" "}
                  <span style={{ fontWeight: "600" }}>
                    <NumberFormat
                      value={inventoryData.averageExperimentGroupsPerDay}
                      format="0,0"
                    ></NumberFormat>
                  </span>{" "}
                  price detections per day
                </div>
              }
            ></AlgoCard>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  sectionTitle: {
    fontSize: "28px",
    marginTop: "16px",
    marginBottom: "16px",
    color: "#374151",
    fontWeight: "600",
  },
  statText: {
    fontSize: "36px",
    paddingBottom: "8px",
  },
  statTitle: {
    color: "#556377",
    fontWeight: "500",
    fontSize: "1rem",
  },
  statBlock: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  statFooter: {
    color: "#4a5568",
    textAlign: "center",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    marginBottom: "-1rem",
    marginLeft: "-1rem",
    marginRight: "-1rem",
    fontSize: "0.875rem",
    backgroundColor: "#edf2f7",
  },
};

export default AlgorithmSection;
