import React from "react";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";

import CommonStyles from "common/styles/common.css";
import YbLoadingView from "common/components/yb-loading-view";

import EmbeddedDashboard from "../components/embedded-dashboard";
import { GET_DASHBOARD_URL } from "../query/dashboard";

/**
 *  /dashboard/overview
 */
class DashboardOverview extends React.Component {
  constructor(props) {
    super(props);
  }

  backToHome() {
    this.props.history.push("/");
  }

  render() {
    return (
      <Query query={GET_DASHBOARD_URL} fetchPolicy={"network-only"}>
        {({ loading, error, data, refetch }) => {
          var content = <span></span>;

          if (loading) {
            content = <YbLoadingView />;
          }

          if (data && data.dashboard) {
            if (!data.dashboard.analyticsURL) {
              content = (
                <div
                  className={CommonStyles.textView}
                >{i18n`NOT_AVAILABLE`}</div>
              );
            } else {
              content = (
                <EmbeddedDashboard dashboard={data.dashboard.analyticsURL} />
              );
            }
          }

          if (error) {
            content = (
              <div
                className={`${CommonStyles.textView} ${CommonStyles.textDanger}`}
              >{i18n`ERROR_LOADING_DASHBOARD_ELLIPSIS`}</div>
            );
          }

          return (
            <div>
              <div className={CommonStyles.wrapper}>
                <div className={CommonStyles.pageTitle}>{i18n`Analytics`}</div>
                {content}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

module.exports = DashboardOverview;
