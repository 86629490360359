import React, { useState, useEffect } from "react";

import MainRouter from "./main-router";
import LANGUAGES from "../common/i18n/langs/index";
import _ from "lodash";

// Most of our website is using i18n for language support, but should deprecate this feature soon
window.i18n = function i18n(key) {
  var lang = localStorage.getItem("lang") || "en";

  if (!LANGUAGES[lang]) {
    lang = "en";
  }

  return LANGUAGES[lang].data[key] || key.toString();
};

window._ = _;

function MainApp(props) {
  return (
    <React.Fragment>
      <MainRouter />
    </React.Fragment>
  );
}

export default MainApp;
