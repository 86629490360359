import React, { useState } from "react";
import { Card, Table, Button, Form } from "react-bootstrap";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import * as Yup from "yup";

const BasicInfoView = (props) => {
  const publisher = props.publisher;
  const canShowEditButton = props.canShowEditButton;

  return (
    <React.Fragment>
      <Table borderless>
        <tbody>
          <tr>
            <td className="pl-0 text-secondary">Publisher Name:</td>
            <td width="70%">
              <div className="text-dark font-weight-bold">{publisher.name}</div>
            </td>
          </tr>
        </tbody>
      </Table>
      {canShowEditButton && (
        <Button
          className="text-dark"
          variant="light"
          size="sm"
          style={{ backgroundColor: "#e6e6e6" }}
          onClick={props.handleIsEditing}
        >
          Edit basic information
        </Button>
      )}
    </React.Fragment>
  );
};

const BasicInfoForm = (props) => {
  const publisher = props.publisher;
  const handleCancel = props.handleCancel;
  const defaultPublisher = {
    publisherName: publisher.name,
  };

  const PublisherFormSchema = Yup.object().shape({
    publisherName: Yup.string().required(
      "Please fill in your publisher name (Usually it's the company name)"
    ),
  });

  function handlePublisherFormBasicSubmit(values) {
    console.log("Submitttt", values);
    const pub = {
      name: values.publisherName,
      publisherId: publisher.publisherId,
    };
    props.handleUpdatePublisher(pub);
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={defaultPublisher}
        validationSchema={PublisherFormSchema}
        onSubmit={handlePublisherFormBasicSubmit}
        render={(props) => {
          const { errors, touched, values } = props;

          return (
            <FormikForm onSubmit={props.handleSubmit}>
              <Table borderless>
                <tbody>
                  <tr>
                    <td className="pl-0 text-secondary">Publisher Name:</td>
                    <td width="70%">
                      <Form.Group controlId="publisherName">
                        <Form.Control
                          type="text"
                          name="publisherName"
                          onChange={props.handleChange}
                          value={values.publisherName}
                          isValid={
                            touched.publisherName && !errors.publisherName
                          }
                          isInvalid={
                            errors.publisherName && touched.publisherName
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.publisherName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div className="d-flex justify-content-start">
                <Button
                  className="text-dark pl-4 pr-4"
                  variant="light"
                  size="sm"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  variant="primary"
                  type="submit"
                  className="ml-2 pl-5 pr-5"
                  size="sm"
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  Save
                </Button>
              </div>
            </FormikForm>
          );
        }}
      />
    </React.Fragment>
  );
};

function PublisherBasicInfo(props) {
  const [isEditing, setIsEditing] = useState(false);
  const publisher = props.publisher;
  const handleSubmit = props.handleSubmit;
  const canShowEditButton = props.canShowEditButton;

  function handleEditBasicInfo() {
    setIsEditing(!isEditing);
  }

  function handleUpdatePublisher(pub) {
    handleSubmit(pub).then(() => {
      setTimeout(() => {
        setIsEditing(false);
      }, 500);
    });
  }

  return (
    <Card className="shadow-sm">
      <Card.Body className="p-4">
        <div className="text-muted mb-4" style={{ letterSpacing: "0.04em" }}>
          <b>BASIC INFORMATION</b>
          <hr />
        </div>

        {isEditing ? (
          <React.Fragment>
            <BasicInfoForm
              publisher={publisher}
              handleUpdatePublisher={handleUpdatePublisher}
              handleCancel={handleEditBasicInfo}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <BasicInfoView
              publisher={publisher}
              handleIsEditing={handleEditBasicInfo}
              canShowEditButton={canShowEditButton}
            />
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
}

export default PublisherBasicInfo;
