import gql from "graphql-tag";

export const typenames = {
  connection: "GamAccountConnection",
  edge: "GamAccountEdge",
  node: "GamAccount"
};

export const OAuthConfig = {
  url: "https://accounts.google.com/o/oauth2/v2/auth",
  params: {
    scope: "openid email https://www.googleapis.com/auth/dfp",
    include_granted_scopes: true,
    redirect_uri: window.location.origin,
    response_type: "code",
    client_id: process.env.GOOGLE_SERVICE_CLIENT_ID,
    prompt: "consent",
    access_type: "offline"
  }
};

export const query = gql`
  query($first: Int, $after: ID, $pubId: Int) {
    gamAccounts(first: $first, after: $after, assumePubId: $pubId)
      @connection(key: "gamAccounts") {
      edges {
        node {
          id
          accountId
          name
          problems
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const mutation = gql`
  mutation ADD_GAM_ACCOUNT($code: String!, $name: String!, $pubId: Int) {
    createGamAccount(
      input: { name: $name, authorizationCode: $code }
      assumePubId: $pubId
    ) {
      gamAccount {
        id
        accountId
        name
        problems
      }
    }
  }
`;
