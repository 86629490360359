import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import YbLoading from "../common/components/yb-loading";
import Terms from "../terms";
import SignupRegistration from "../user/states/signup";
// import Home from "./base/states/home";
import Login from "./base/states/login";
// import Signup from "./base/states/signup";
import Invite from "./base/states/invite";
// import ScrollToTop from "../common/components/scroll-to-top";

import { API_ENDPOINT } from "./common/constants/common";
import CheckLoginAs from "./base/states/check-login-as";

function MainRouter(props) {
  let ignore = false;
  const [isLoading, setIsLoading] = useState(false);
  const [isRecognizedUser, setIsRecognizedUser] = useState(false);
  const [isValidUser, setIsValidUser] = useState();

  const abortController = new AbortController();
  const signal = abortController.signal;

  async function checkIsRecognizedUser() {
    const registerApi = `${API_ENDPOINT}/register`;
    try {
      const response = await fetch(registerApi, {
        credentials: "include",
        signal: signal,
      });
      console.log("Check is recognized user: ", response);
      const isRecognized = await response.ok;
      return isRecognized;
    } catch (err) {
      console.log("Error recognizing user!", err);
      return false;
    }
  }

  useEffect(() => {
    async function onUpdate() {
      if (!ignore) {
        setIsLoading(true);
        const isRecognized = await checkIsRecognizedUser();
        setIsRecognizedUser(isRecognized);
        setIsValidUser("YES");
        setIsLoading(false);
      } else {
        abortController.abort();
      }
    }

    // TODO: check on every route change!
    onUpdate();

    return function cleanUp() {
      ignore = true;
    };
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <YbLoading />
      ) : (
        <Router>
          {isValidUser && (
            <React.Fragment>
              {isRecognizedUser ? (
                <Switch>
                  <CheckLoginAs></CheckLoginAs>

                  {/* <ScrollToTop>
                    <Home />
                  </ScrollToTop> */}
                </Switch>
              ) : (
                <Switch>
                  <Route path="/login" component={Login} />
                  {/* /newuser: no longer allow new user to signup */}
                  {/* <Route path="/newuser" component={Signup} /> */}
                  <Route
                    path="/login-unrecognized-user"
                    component={() => <Login isInvalidUser={true}></Login>}
                  ></Route>

                  {/* This signup is the old publisher registration */}
                  {/* /signup: no longer allowed, replace with "Please contact your admin" */}
                  <Route
                    path="/signup"
                    render={(props) => (
                      <SignupRegistration
                        {...props}
                        onSignup={(_) => {
                          setIsRecognizedUser(true);
                        }}
                      />
                    )}
                  />

                  <Route
                    path="/invite"
                    render={(props) => (
                      <Invite
                        {...props}
                        onSignup={(_) => {
                          // make sure to handle this path in base-router
                          setIsRecognizedUser(true);
                        }}
                      />
                    )}
                  />

                  <Route path="/terms" component={Terms} />

                  <Route
                    path="/"
                    render={(props) => {
                      // console.log("Match path /", props);
                      return <Redirect to="/login" />;
                    }}
                  />

                  <Route
                    render={(props) => {
                      return (
                        <div>
                          <h1>404 - Cannot find this page</h1>
                          <div>{JSON.stringify(props, null, 4)}</div>
                        </div>
                      );
                    }}
                  />
                </Switch>
              )}
            </React.Fragment>
          )}
        </Router>
      )}
    </React.Fragment>
  );
}

export default MainRouter;
