import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import HomeWrapper from "./home-wrapper";
import LoginAsWrapper from "./login-as-wrapper";
import ScrollToTop from "common/components/scroll-to-top";

const COOKIE_NAME = "loginAsPubId";

function CheckLoginAs(props) {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);

  const [currentPubId, setCurrentPubId] = useState(_checkLoginAsCookie());

  useEffect(() => {
    const currentPubId = _checkLoginAsCookie();
    // console.log("check login as", currentPubId);
    if (currentPubId) {
      setCurrentPubId(currentPubId);
    }

    return () => {
      //
    };
  }, []);

  function handleSwitchLoginAs() {
    // remove pubId from cookie so that the user can select another publisher to login as
    removeCookie(COOKIE_NAME);
    setCurrentPubId();

    // replace url to home path
    window.history.replaceState(null, "", "/");
  }

  function _checkLoginAsCookie() {
    const loginAsPubId = cookies.loginAsPubId;
    return loginAsPubId;
  }

  return currentPubId ? (
    <ScrollToTop>
      <HomeWrapper
        loginAsPubId={currentPubId}
        handleSwitchLoginAs={handleSwitchLoginAs}
      ></HomeWrapper>
    </ScrollToTop>
  ) : (
    // <Redirect to="/login-as"></Redirect>
    <LoginAsWrapper></LoginAsWrapper>
  );
}

export default CheckLoginAs;
