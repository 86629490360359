import React from "react";
import { Mutation } from "react-apollo";
import { Redirect } from "react-router-dom";
import { Container, Card, Row, Col, Alert } from "react-bootstrap";

import CommonStyle from "common/styles/common.css";
import YbLoading from "common/components/yb-loading";

import SubscriptionDescription from "./subscription-description";
import BillingInfo from "./billing-info-form";
import BillingProfileInfo from "./billing-profile-info";

import {
  SET_BILLING_SETTING,
  SUBSCRIBE_PLAN
} from "../query/subscription-and-billing";

import { UserInfoContext } from "../../_newapp/common/contexts/contexts";
import { USER_ROLE } from "../../_newapp/common/constants/common";

class BillingSettingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profileId: this.props.activeProfileId,
      isPaymentFailed: this.props.isPaymentFailed,
      isSubscribe: this.props.isSubscribe,
      thirdPartyRedirectPath: this.props.thirdPartyRedirectPath,

      // if isCreditCardAuthorized is true, show billing info
      // it can only be authorized if:
      // 1. redirected and success with profile id
      // 2. query billing setting and has active billing profile
      isCreditCardAuthorized: false,
      // Billing Info
      billingSetting: {
        billingContact: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          phoneCountryCode: ""
        },
        billingRecipients: [],
        billingProfileId: "",
        geoCountryId: 45, // default TAIWAN
        currency: "TWD",
        invoiceSetting: {
          type: "TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE" // default
        }
      }
    };

    this.handleSetBillingSetting = this.handleSetBillingSetting.bind(this);
    this.handleUpdateActiveProfileId = this.handleUpdateActiveProfileId.bind(
      this
    );
    this.handleUpdateIsCreditCardAuthorized = this.handleUpdateIsCreditCardAuthorized.bind(
      this
    );
  }

  transformInvoiceSettingToBackendApiWantedFormat(
    isBillingTaiwan,
    payerEmail,
    invoiceSetting
  ) {
    if (!isBillingTaiwan) {
      return {
        category: "B2C",
        carrierType: "2",
        carrierNum: payerEmail,
        printFlag: "N",
        taxType: "1"
      };
    }

    switch (invoiceSetting.type) {
      case "TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE": {
        return {
          category: "B2B",
          ubn: invoiceSetting.taxCode,
          printFlag: "Y",
          taxType: "1"
        };
      }

      case "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__CITIZEN_DIGITAL_CERTIFICATE": {
        return {
          category: "B2C",
          carrierType: "1",
          carrierNum: invoiceSetting.citizenDigitalCertificate,
          printFlag: "N",
          taxType: "1"
        };
      }

      case "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__MOBILE_BARCODE_CARRIER": {
        return {
          category: "B2C",
          carrierType: "0",
          carrierNum: `/${invoiceSetting.mobileBarcodeCarrier}`,
          printFlag: "N",
          taxType: "1"
        };
      }

      case "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__EZPAY_CARRIER": {
        return {
          category: "B2C",
          carrierType: "2",
          carrierNum: payerEmail,
          printFlag: "N",
          taxType: "1"
        };
      }

      case "DONATE_INVOICE": {
        return {
          category: "B2C",
          loveCode: parseInt(invoiceSetting.loveCode),
          printFlag: "N",
          taxType: "1"
        };
      }
    }
  }

  handleSetBillingSetting(
    finalBillingSetting,
    setBillingSetting,
    handleUpdateUserInfo
  ) {
    // transform goddamn invoice setting
    let invoiceSettingBackendApiWantedFormat = null;
    const isBillingTaiwan = finalBillingSetting.geoCountryId == 45;
    const payerEmail = finalBillingSetting.billingContact.email;
    invoiceSettingBackendApiWantedFormat = this.transformInvoiceSettingToBackendApiWantedFormat(
      isBillingTaiwan,
      payerEmail,
      finalBillingSetting.invoiceSetting
    );
    const companyName = finalBillingSetting.companyName
      ? finalBillingSetting.companyName
      : "";

    let params = {
      variables: {
        input: {
          billingContact: finalBillingSetting.billingContact,
          billingRecipients: finalBillingSetting.billingRecipients,
          billingProfileId: finalBillingSetting.billingProfileId,
          currency: finalBillingSetting.currency,
          geoCountryId: parseInt(finalBillingSetting.geoCountryId),
          invoiceSetting: invoiceSettingBackendApiWantedFormat,
          companyName
        }
      }
    };

    return setBillingSetting(params).then(() => {
      handleUpdateUserInfo();
    });
  }

  handleUpdateActiveProfileId(profileId) {
    this.setState({
      billingSetting: {
        ...this.state.billingSetting,
        billingProfileId: profileId
      }
    });
  }

  handleUpdateIsCreditCardAuthorized(isCreditCardAuthorized) {
    this.setState({
      isCreditCardAuthorized: isCreditCardAuthorized
    });
  }

  render() {
    let paymentFailedJsx = "";

    if (this.state.isPaymentFailed) {
      paymentFailedJsx = (
        <Alert variant="danger">
          <Alert.Heading>Your credit card authorization failed.</Alert.Heading>
          <p>Please try another card or try again later.</p>
        </Alert>
      );
    }

    return (
      <div>
        <UserInfoContext.Consumer>
          {({ userInfo, handleUpdateUserInfo }) => {
            return (
              <div className={CommonStyle.wrapper}>
                {userInfo && (
                  <Card>
                    <Card.Body>
                      <Container>
                        <Row>
                          <Col>
                            <SubscriptionDescription isSubscribing={true} />

                            <br />

                            <div>
                              <h4>Payment</h4>
                              <hr />

                              {paymentFailedJsx}

                              <BillingProfileInfo
                                profileId={this.props.activeProfileId}
                                activeProfileId={
                                  this.state.billingSetting.billingProfileId
                                }
                                thirdPartyRedirectPath={
                                  this.props.thirdPartyRedirectPath
                                }
                                setActiveProfileId={
                                  this.handleUpdateActiveProfileId
                                }
                                setIsCreditCardAuthorized={
                                  this.handleUpdateIsCreditCardAuthorized
                                }
                              />
                            </div>

                            <br />

                            <div>
                              {this.state.isCreditCardAuthorized && (
                                <div>
                                  <h4>Billing</h4>
                                  <hr />
                                  <Mutation mutation={SET_BILLING_SETTING}>
                                    {(
                                      setBillingSetting,
                                      { loading, error, data }
                                    ) => {
                                      if (loading) {
                                        return <YbLoading />;
                                      }

                                      if (error) {
                                        return (
                                          <div>
                                            ERROR{" "}
                                            {JSON.stringify(
                                              `${error}`,
                                              null,
                                              4
                                            )}
                                            <BillingInfo
                                              isSubscribe={
                                                this.state.isSubscribe
                                              }
                                              billingSetting={
                                                this.state.billingSetting
                                              }
                                              handleSubmit={bs =>
                                                this.handleSetBillingSetting(
                                                  bs,
                                                  setBillingSetting,
                                                  handleUpdateUserInfo
                                                )
                                              }
                                            />
                                          </div>
                                        );
                                      }

                                      if (data) {
                                        if (this.state.isSubscribe) {
                                          return (
                                            <Mutation mutation={SUBSCRIBE_PLAN}>
                                              {(
                                                subscribePlan,
                                                { loading, error, data }
                                              ) => {
                                                if (loading) {
                                                  return <YbLoading />;
                                                }

                                                if (error) {
                                                  console.log(
                                                    "Subscribe failed",
                                                    error
                                                  );
                                                }

                                                if (
                                                  data &&
                                                  data.subscribePlan
                                                ) {
                                                  if (
                                                    userInfo.role ===
                                                    USER_ROLE.RESELLER_ADMIN
                                                  ) {
                                                    return (
                                                      <Redirect to="/billing/details" />
                                                    );
                                                  } else {
                                                    return (
                                                      <Redirect to="/subscription-and-billing/overview" />
                                                    );
                                                  }
                                                }

                                                subscribePlan({
                                                  variables: {
                                                    input: {
                                                      plan: "WHAT_IS_THE_PLAN"
                                                    }
                                                  }
                                                });

                                                return "";
                                              }}
                                            </Mutation>
                                          );
                                        } else {
                                          if (
                                            userInfo.role ===
                                            USER_ROLE.RESELLER_ADMIN
                                          ) {
                                            return (
                                              <Redirect to="/billing/details" />
                                            );
                                          } else {
                                            return (
                                              <Redirect to="/subscription-and-billing/overview" />
                                            );
                                          }
                                        }
                                      }

                                      return (
                                        <BillingInfo
                                          isSubscribe={this.state.isSubscribe}
                                          billingSetting={
                                            this.state.billingSetting
                                          }
                                          handleSubmit={bs =>
                                            this.handleSetBillingSetting(
                                              bs,
                                              setBillingSetting,
                                              handleUpdateUserInfo
                                            )
                                          }
                                        />
                                      );
                                    }}
                                  </Mutation>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>
                )}
              </div>
            );
          }}
        </UserInfoContext.Consumer>
      </div>
    );
  }
}

module.exports = BillingSettingForm;
