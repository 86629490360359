import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import FilterDateRangePopover from "./filter-date-range-popover";

class FilterDateRange extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.onOpenPopover = this.onOpenPopover.bind(this);
  }

  onOpenPopover() {
    this.setState({ show: !this.state.show });
  }

  handleClosePopover() {
    this.setState({ show: false });
  }

  render() {
    const { startDate, endDate, handleDateRangeFilterChanged } = this.props;

    return (
      <React.Fragment>
        <OverlayTrigger
          rootClose={true} // close when clicked outside overlay
          show={this.state.show}
          onToggle={this.onOpenPopover}
          trigger="click"
          placement="bottom-start"
          overlay={
            <Popover
              id="daterange-selector-popover"
              className="shadow-lg p-2"
              style={{
                maxWidth: "800px",
                width: "800px",
                backgroundColor: "#f7f7f7",
              }}
            >
              <FilterDateRangePopover
                startDate={startDate}
                endDate={endDate}
                handleClosePopover={this.handleClosePopover}
                handleDateRangeFilterChanged={handleDateRangeFilterChanged}
              ></FilterDateRangePopover>
            </Popover>
          }
        >
          <Button
            variant="light"
            style={styles.filterBtn}
            className="text-dark px-3"
          >
            From <b className="text-gray-700">{startDate}</b> to{" "}
            <b className="text-gray-700">{endDate}</b>
            <span className="fa fa-angle-down ml-2"></span>
          </Button>
        </OverlayTrigger>
      </React.Fragment>
    );
  }
}

const styles = {
  header: {
    padding: "12px 84px",
  },
  filterBtn: {
    border: "1px solid lightgray",
  },
  currencySelector: {
    width: "120px",
  },
};

export default FilterDateRange;
