import React from "react";
import _ from "lodash";

import YbMCMUnitTooltip from "../../../ad-units/components/yb-mcm-unit-tooltip";

import ItemStyle from "common/styles/inventory-item-latest.css";

function MCMLabel({ siteNetworkCodes, id }) {
  return (
    <YbMCMUnitTooltip
      key={siteNetworkCodes}
      siteNetworkCodes={siteNetworkCodes}
      tooltipKey={id + "-mcm-tooltip"}
    >
      <span className="rounded" className={ItemStyle.mcmLabel}>
        MCM
      </span>
    </YbMCMUnitTooltip>
  );
}

module.exports = MCMLabel;
