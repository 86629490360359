import React from "react";
import { Redirect } from "react-router-dom";
import { Query, Mutation } from "react-apollo";

import FormStyle from "common/styles/forms.css";
import CommonStyle from "common/styles/common.css";
import YbLoading from "common/components/yb-loading";
import YbTooltip from "common/components/yb-tooltip";
import YbLoadingView from "common/components/yb-loading-view";

import YbAddAdSenseBtn from "../../integration/components/yb-add-adsense-btn";
import YbAddAdxBtn from "../../integration/components/yb-add-adx-btn";

import {
  GET_NETWORK,
  GET_AD_SENSE_ACCOUNTS,
  GET_ADX_ACCOUNTS,
  CREATE_YIELD_SET,
  SAVE_YIELD_SET,
  GET_YIELD_SET,
} from "../query/yield-sets";

/**
 *  /yield-sets/create/:networkId
 *  /yield-sets/update/:yieldSetId
 */

class YieldSetForm extends React.Component {
  constructor(props) {
    super(props);
    this.nameChanged = this.nameChanged.bind(this);
    this.adxAccountChanged = this.adxAccountChanged.bind(this);
    this.adSenseAccountChanged = this.adSenseAccountChanged.bind(this);
    this.submitClick = this.submitClick.bind(this);
    this.backToSummary = this.backToSummary.bind(this);
    this.addAdSenseAccount = this.addAdSenseAccount.bind(this);
    this.addAdxAccount = this.addAdxAccount.bind(this);
    this.adxOnHide = this.adxOnHide.bind(this);
    this.adSenseOnHide = this.adSenseOnHide.bind(this);
    this.adxAdded = this.adxAdded.bind(this);
    this.adSenseAdded = this.adSenseAdded.bind(this);

    const pubId = _.get(this.props, "match.params.publisherId");

    let overviewRoute = "/summary/overview";
    if (pubId > 0) {
      overviewRoute = `/publisher/${pubId}/summary/overview`;
    }

    this.state = {
      name: "",
      adSenseAccount: "",
      adxAccount: "",
      submitted: false,
      addAdSense: false,
      addAdx: false,
      reloadAdsense: true,
      reloadAdx: true,
      showAdSenseComponent: false,
      publisherId: _.parseInt(pubId),
      overviewRoute,
    };
  }

  addAdSenseAccount() {
    this.setState({
      addAdSense: true,
      showAdSenseComponent: true,
    });
  }

  adSenseOnHide() {
    this.setState({
      addAdSense: false,
      showAdSenseComponent: false,
    });
  }

  addAdxAccount() {
    this.setState({
      addAdx: true,
    });
  }

  adxOnHide() {
    this.setState({
      addAdx: false,
    });
  }

  adxAdded(data) {
    this.setState({
      reloadAdx: true,
      addAdx: false,
      adxAccount: data.createAdxAccount.adxAccount.adxAccountId,
    });
  }

  adSenseAdded(data) {
    this.setState({
      reloadAdsense: true,
      addAdSense: false,
      adSenseAccount: data.createAdsenseAccount.adsenseAccount.accountId,
      showAdSenseComponent: false,
    });
  }

  backToSummary() {
    this.props.history.goBack();
  }

  adxAccountChanged(event) {
    var id = parseInt(event.target.value);

    this.setState({
      adxAccount: id === -1 ? "" : id,
    });
  }

  adSenseAccountChanged(event) {
    var id = parseInt(event.target.value);

    this.setState({
      adSenseAccount: id === -1 ? "" : id,
    });
  }

  nameChanged(event) {
    this.setState({ name: event.target.value });
  }

  submitClick() {
    if (!this.state.submitted) {
      this.setState({
        submitted: true,
      });
    }
  }

  setYieldSet(yieldSet) {
    if (!this.state.yieldSet) {
      this.state.yieldSet = yieldSet;
      this.state.name = yieldSet.name;

      if (yieldSet.adsenseAccount) {
        this.state.adSenseAccount = yieldSet.adsenseAccount.accountId;
      }

      if (yieldSet.adxAccount) {
        this.state.adxAccount = yieldSet.adxAccount.adxAccountId;
      }
    }
  }

  // renderAdSenseAccounts() {
  //   var variables = {
  //     filter: {
  //       networkId: this.network.networkId
  //     },
  //     pubId: this.state.publisherId
  //   };

  //   return (
  //     <Query
  //       query={GET_AD_SENSE_ACCOUNTS}
  //       variables={variables}
  //       fetchPolicy={"network-only"}
  //     >
  //       {({ loading, error, data, refetch }) => {
  //         if (loading) {
  //           this.state.reloadAdsense = false;
  //           return <YbLoading />;
  //         }

  //         if (error) {
  //           return (
  //             <span className="text-danger">
  //               <input className={FormStyle.hiddenInput} required />
  //               {i18n`ERROR_LOADING_ADSENSE_ACCOUNTS_ELLIPSIS`}
  //             </span>
  //           );
  //         }

  //         if (this.state.reloadAdsense) {
  //           this.state.reloadAdsense = false;
  //           refetch();
  //         }

  //         return (
  //           <div>
  //             <div className={FormStyle.inputGroupRightButton}>
  //               <select
  //                 value={this.state.adSenseAccount}
  //                 onChange={this.adSenseAccountChanged}
  //               >
  //                 <option
  //                   value=""
  //                   disabled
  //                 >{i18n`SELECT_AN_ADSENSE_ACCOUNT_ELLIPSIS`}</option>
  //                 <option value="-1">{i18n`NONE`}</option>
  //                 {data.adsenseAccounts.edges.map((account, index) => {
  //                   return (
  //                     <option key={index} value={account.node.accountId}>
  //                       {account.node.name} ({account.node.clientCode})
  //                     </option>
  //                   );
  //                 })}
  //               </select>
  //               <span className={FormStyle.rightButtonWrapper}>
  //                 <YbTooltip
  //                   message={i18n`ADD_ADSENSE_ACCOUNT`}
  //                   position="left"
  //                 >
  //                   <button
  //                     key="add"
  //                     type="button"
  //                     className={CommonStyle.buttonNoBorder}
  //                     onClick={this.addAdSenseAccount}
  //                   >
  //                     <i className="fa fa-plus" />
  //                   </button>
  //                 </YbTooltip>
  //               </span>
  //             </div>
  //             {this.renderAdxAccounts()}
  //           </div>
  //         );
  //       }}
  //     </Query>
  //   );
  // }

  renderAdxAccounts() {
    var variables = {
      filter: {
        networkId: this.network.networkId,
      },
      pubId: this.state.publisherId,
    };

    return (
      <Query
        query={GET_ADX_ACCOUNTS}
        variables={variables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            this.state.reloadAdx = false;
            return <YbLoading />;
          }

          if (error) {
            return (
              <span className="text-danger">
                <input className={FormStyle.hiddenInput} required />
                {i18n`ERROR_LOADING_ADX_ACCOUNTS_ELLIPSIS`}
              </span>
            );
          }

          if (this.state.reloadAdx) {
            this.state.reloadAdx = false;
            refetch();
          }

          return (
            <div className={FormStyle.inputGroupRightButton}>
              <select
                value={this.state.adxAccount}
                onChange={this.adxAccountChanged}
              >
                <option value="" disabled>{i18n`Select an AdX Account`}</option>
                <option value="-1">{i18n`NONE`}</option>
                {data.adxAccounts.edges.map((account, index) => {
                  return (
                    <option key={index} value={account.node.adxAccountId}>
                      {account.node.name} ({account.node.clientCode})
                    </option>
                  );
                })}
              </select>
              <span className={FormStyle.rightButtonWrapper}>
                <YbTooltip message={i18n`ADD_ADX_ACCOUNT`} position="left">
                  <button
                    key="add"
                    type="button"
                    className={CommonStyle.buttonNoBorder}
                    onClick={this.addAdxAccount}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </YbTooltip>
              </span>
            </div>
          );
        }}
      </Query>
    );
  }

  renderAccounts() {
    var selectClass = "";

    if (
      this.state.submitted &&
      !this.state.adSenseAccount &&
      !this.state.adxAccount
    ) {
      selectClass = FormStyle.invalid;
    }

    return (
      <div className={FormStyle.row}>
        <div className={FormStyle.label}>
          {i18n`ACCOUNTS`} <span className={FormStyle.requiredStar}>*</span>
        </div>
        <div className={selectClass}>
          {/* {this.renderAdSenseAccounts()} */}

          {this.renderAdxAccounts()}
        </div>
      </div>
    );
  }

  renderForm(isEdit) {
    var formClass = FormStyle.form;
    var mutation = isEdit ? SAVE_YIELD_SET : CREATE_YIELD_SET;

    if (this.state.submitted) {
      formClass = FormStyle.submitted + " " + FormStyle.form;
    }

    return (
      <div>
        <div className={CommonStyle.wrapper}>
          <div className={CommonStyle.pageTitle}>
            {isEdit ? i18n`UPDATE` : i18n`NEW`} {i18n`YIELD_SET_FOR`}{" "}
            {this.network.name}
          </div>
          <Mutation mutation={mutation}>
            {(saveYieldSet, { loading, error, data }) => {
              var submitBtn = (
                <button
                  className={FormStyle.submitBtn}
                  type="submit"
                  onClick={this.submitClick}
                >
                  <i className={`fa ${isEdit ? "fa-save" : "fa-plus"}`} />{" "}
                  {isEdit ? i18n`SAVE` : i18n`CREATE`}
                </button>
              );
              var errorMessage = "";

              if (loading) {
                submitBtn = (
                  <span className={FormStyle.submitLoading}>
                    <YbLoading />
                  </span>
                );
              }

              if (error) {
                let err = "";
                if (error.message) {
                  err = error.message.replace("GraphQL error: ", "");
                } else {
                  err = isEdit
                    ? i18n`ERROR_SAVING_YIELD_SET_ELLIPSIS`
                    : i18n`ERROR_CREATING_YIELD_SET_ELLIPSIS`;
                }
                errorMessage = (
                  <div
                    style={{
                      backgroundColor: "#ffdddd",
                      margin: "10px",
                      padding: "8px 16px",
                    }}
                  >
                    <span className="text-danger">{err}</span>
                  </div>
                );
              }

              if (data) {
                return <Redirect to={this.state.overviewRoute} />;
              }

              return (
                <div>
                  <form
                    onKeyPress={(event) => {
                      if (
                        event.key === "Enter" &&
                        event.target.type !== "submit"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onSubmit={(event) => {
                      var mutationParams = {
                        variables: {
                          input: {
                            name: this.state.name,
                          },
                          pubId: this.state.publisherId,
                        },
                      };

                      if (this.state.adSenseAccount) {
                        mutationParams.variables.input.adSenseAccountId =
                          this.state.adSenseAccount;
                      }

                      if (this.state.adxAccount) {
                        mutationParams.variables.input.adxAccountId =
                          this.state.adxAccount;
                      }

                      if (isEdit) {
                        mutationParams.variables.input.yieldSetId =
                          this.state.yieldSet.yieldSetId;
                      } else {
                        mutationParams.variables.input.networkId =
                          this.network.networkId;
                      }

                      if (this.state.adSenseAccount || this.state.adxAccount) {
                        saveYieldSet(mutationParams);
                      }

                      event.preventDefault();
                    }}
                    className={formClass}
                  >
                    <div className={FormStyle.row}>
                      <div className={FormStyle.label}>
                        {i18n`NAME`}{" "}
                        <span className={FormStyle.requiredStar}>*</span>
                      </div>
                      <div>
                        <input
                          required
                          type="text"
                          value={this.state.name}
                          onChange={this.nameChanged}
                          placeholder={`${i18n`EX_DOT`} ${
                            this.network.name
                          } - ${i18n`YIELD_SET`}`}
                        />
                      </div>
                    </div>
                    {this.renderAccounts()}
                    {errorMessage}
                    <div>{submitBtn}</div>
                  </form>
                  {!loading && (
                    <button
                      className={FormStyle.cancelBtn}
                      onClick={this.backToSummary}
                    >
                      <i className="fa fa-times" /> {i18n`CANCEL`}
                    </button>
                  )}
                </div>
              );
            }}
          </Mutation>
        </div>
      </div>
    );
  }

  renderNetwork(isEdit, networkId) {
    var addAdSense = this.state.addAdSense;
    var addAdx = this.state.addAdx;
    var showAdSenseComponent = this.state.showAdSenseComponent;

    if (addAdSense) {
      setTimeout(() => {
        this.setState({
          addAdSense: false,
        });
      }, 0);
    }

    return (
      <div>
        <Query
          query={GET_NETWORK}
          variables={{ pubId: this.state.publisherId }}
          fetchPolicy={"network-only"}
        >
          {({ loading, error, data }) => {
            var seletedNetwork = null;

            if (loading) {
              return <YbLoadingView />;
            }

            if (error) {
              return <Redirect to={this.state.overviewRoute} />;
            }

            seletedNetwork = data.gamNetworks.edges.find((network) => {
              return network.node.networkId === networkId;
            });

            if (!seletedNetwork) {
              return <Redirect to={this.state.overviewRoute} />;
            }

            this.network = seletedNetwork.node;
            return this.renderForm(isEdit);
          }}
        </Query>
        {showAdSenseComponent && (
          <YbAddAdSenseBtn
            showAdSenseModal={addAdSense}
            hideCallback={this.adSenseOnHide}
            callback={this.adSenseAdded}
            networkId={networkId}
            publisherId={this.state.publisherId}
          />
        )}
        <YbAddAdxBtn
          showAdxModal={addAdx}
          hideCallback={this.adxOnHide}
          callback={this.adxAdded}
          networkId={networkId}
          publisherId={this.state.publisherId}
        />
      </div>
    );
  }

  render() {
    var networkId = parseInt(this.props.match.params.networkId);
    var yieldSetId = parseInt(this.props.match.params.yieldSetId);
    var variables = {
      filter: {
        yieldSetIds: [yieldSetId],
      },
      pubId: this.state.publisherId,
    };

    if (yieldSetId) {
      return (
        <div>
          <Query
            query={GET_YIELD_SET}
            variables={variables}
            fetchPolicy={"network-only"}
          >
            {({ loading, error, data }) => {
              var yieldSet = null;

              if (loading) {
                return <YbLoadingView />;
              }

              if (error) {
                return <Redirect to={this.state.overviewRoute} />;
              }

              yieldSet = data.yieldSets.edges[0];

              if (!yieldSet) {
                return <Redirect to={this.state.overviewRoute} />;
              }

              yieldSet = yieldSet.node;

              this.setYieldSet(yieldSet);

              return this.renderNetwork(true, yieldSet.gamNetwork.networkId);
            }}
          </Query>
        </div>
      );
    }

    return this.renderNetwork(false, networkId);
  }
}

module.exports = YieldSetForm;
