import gql from "graphql-tag";

export const GET_NETWORK_INFO = gql`
  query {
    gamNetworks {
      edges {
        node {
          networkId
          name
          currency
          code
          status
          product
        }
      }
    }
  }
`;
