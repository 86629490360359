import React from "react";
import { Card } from "react-bootstrap";

class PaymentInfoView extends React.Component {
  constructor(props) {
    super(props);

    const billingSetting = this.props.billingSetting;
    const activeBillingProfile = billingSetting.activeBillingProfile;
    const isPaymentMethodWired =
      activeBillingProfile && activeBillingProfile.method === "WIRED";
    let creditCardNumber = "";
    let payerEmail = "";

    if (activeBillingProfile && activeBillingProfile.method === "CREDIT_CARD") {
      const card6No = activeBillingProfile.profile.card6No;
      const card4No = activeBillingProfile.profile.card4No;
      creditCardNumber = `${card6No.substr(0, 4)}-${card6No.substr(
        4,
        2
      )}**-****-${card4No}`;

      payerEmail = activeBillingProfile.profile.payerEmail;
    }

    this.state = {
      billingSetting,
      activeBillingProfile,
      isPaymentMethodWired,
      creditCardNumber,
      payerEmail
    };
  }

  render() {
    return (
      <div>
        <div>
          {this.state.isPaymentMethodWired ? (
            <div>
              <label>
                <i className="fa fa-exchange fa-fw text-secondary" /> &nbsp;
                Wire Transfer
              </label>
              <div className="text-muted">
                <small>
                  Please contact us directly through our designated contact
                  person if you would like to update your payment method.
                </small>
              </div>
            </div>
          ) : (
            <div>
              <label>
                <i className="fa fa-credit-card fa-fw text-secondary" /> &nbsp;
                Credit Card
              </label>
              <Card>
                <Card.Body>
                  <div
                    style={{
                      color: "#414a52",
                      fontSize: "18px",
                      fontWeight: "bold",
                      letterSpacing: "0.06em"
                    }}
                  >
                    {this.state.creditCardNumber}
                  </div>
                  <span className="text-muted">
                    <span>{this.state.payerEmail}</span>
                  </span>
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>
    );
  }
}

module.exports = PaymentInfoView;
