import React, { useState } from "react";
import _ from "lodash";

import ItemStyle from "common/styles/inventory-item-latest.css";

import YbTooltip from "common/components/yb-tooltip";
import YbReqStatWrapper from "../../../ad-units/components/yb-req-stat-wrapper";
import MCMLabel from "./mcm-label";

import { BiCheckDouble, BiCheck, BiX } from "react-icons/bi";
// import { VscTypeHierarchySub } from "react-icons/vsc";

function _onClickableAreaClicked(item, layerNum, onDrillDown, onSelectUnit) {
  if (item.queryError) return;
  if (!item.hasChildren && item.isOnboarded) return;
  if (!item.hasChildren && !item.isCompatible) return;

  if (item.hasChildren) {
    return onDrillDown(item, layerNum);
  }

  if (item.isCompatible && !item.isOnboarded) {
    return onSelectUnit(item);
  }
}

/**
 * Leaf Unit:
 * 1. Selectable
 * 2. Not Selectable
 *    - Not compatible
 *    - Onboarded
 *    - Onboarded and not compatible
 *
 * Parent Unit:
 * 1. Selectable
 * 2. Not Selectable
 *    - Not compatible
 *    - Onboarded
 *    - Onboarded and not compatible
 */
function OnboardItemComponent(props) {
  const { item, layerNum, onSelectUnit, onSelectAllChildUnits, onDrillDown } =
    props;
  // console.log(props.layerNum, props.item);

  if (item.hasChildren) {
    return ParentUnitItemComponent(props);
  } else {
    return LeafUnitItemComponent(props);
  }
}

/**
 * Leaf Unit:
 * 1. Selectable
 * 2. Not Selectable
 *    - Not compatible
 *    - Onboarded
 *    - Onboarded and not compatible
 */
function LeafUnitItemComponent(props) {
  const { item, layerNum, onSelectUnit, onSelectAllChildUnits, onDrillDown } =
    props;

  let unitItemClass = ItemStyle.unitItem;
  let checkboxStatus = item.checkboxStatus;
  let checkboxClass = ItemStyle.checkbox;
  let checkboxElement = "";

  const isSelectable = item.isCompatible && !item.isOnboarded;
  if (isSelectable) {
    if (checkboxStatus === "SELECTED") {
      checkboxClass += " " + ItemStyle.selected;
    }

    checkboxElement = (
      <button
        type="button"
        className={checkboxClass}
        onClick={(event) => {
          onSelectUnit(item);
          event.stopPropagation();
        }}
      >
        <i className="fa fa-check"></i>
      </button>
    );
  } else {
    // Already onboarded
    if (item.isOnboarded) {
      unitItemClass += " " + ItemStyle.onboarded;
      checkboxClass += " " + ItemStyle.isOnboarded;
      checkboxElement = (
        <YbTooltip message="Already onboarded" position="right">
          <button type="button" className={checkboxClass}>
            <i className="fa fa-check"></i>
          </button>
        </YbTooltip>
      );
    }
    // Not Compatible
    else if (!item.isCompatible) {
      unitItemClass += " " + ItemStyle.notCompatible;
    }
  }

  if (item.queryError) {
    unitItemClass = ItemStyle.unitItemHasError;
    checkboxElement = (
      <YbTooltip message={item.queryError.message} position="right">
        <span style={{ color: "red" }}>
          <i className="fa fa-exclamation-triangle"></i>
        </span>
      </YbTooltip>
    );
  }

  return (
    <div
      className={unitItemClass}
      onClick={() =>
        _onClickableAreaClicked(item, layerNum, onDrillDown, onSelectUnit)
      }
    >
      <div className={ItemStyle.checkboxWrapper}>{checkboxElement}</div>

      <div className={ItemStyle.clickableArea}>
        <div className={ItemStyle.itemName}>
          {item.name}
          <div>
            {item.siteNetworkCodes && (
              <MCMLabel
                id={item.extId}
                siteNetworkCodes={item.siteNetworkCodes}
              ></MCMLabel>
            )}
          </div>
        </div>

        <div>
          {!item.hasChildren && !item.isCompatible && (
            <div className={ItemStyle.notCompatibleText}>Not compatible</div>
          )}
          <YbReqStatWrapper item={item}></YbReqStatWrapper>
        </div>
        {/* <div className={ItemStyle.itemStat}>
          <YbReqStatTooltip
            item={item}
            tooltipKey={item.extId + "-req-tooltip"}
          >
            <YbReqStatDiagram item={item}></YbReqStatDiagram>
          </YbReqStatTooltip>
        </div> */}

        <div style={{ width: "16px" }}></div>
        {/* <div className={ItemStyle.arrowWrapper}> */}
        {/* keep this spacing so that it is aligned with parent unit */}
        {/* </div> */}
      </div>
    </div>
  );
}

/**
 * Parent Unit:
 * 1. Selectable
 * 2. Not Selectable
 *    - All Onboarded
 *    - All Not Compatible
 *    - Special Case: Onboarded Parent Unit
 */
const CHILDREN_ONBOARD_STATUS = {
  ALL_NOT_COMPATIBLE: "ALL_NOT_COMPATIBLE",
  ALL_ONBOARDED: "ALL_ONBOARDED",
  SOME_ONBOARDED: "SOME_ONBOARDED",
};
const CHILDREN_SELECTION_STATUS = {
  SOME_SELECTED: "SOME_SELECTED",
  ALL_SELECTED: "ALL_SELECTED",
  NONE_SELECTED: "NONE_SELECTED",
};
const CHECKBOX_STATUS = {
  ONBOARDED: "ONBOARDED",
  NOT_COMPATIBLE: "NOT_COMPATIBLE",
  SELECTED: "SELECTED",
  NOT_SELECTED: "NOT_SELECTED",
};
function ParentUnitItemComponent(props) {
  const { item, layerNum, onSelectUnit, onSelectAllChildUnits, onDrillDown } =
    props;

  let unitItemClass =
    ItemStyle.unitItem +
    " " +
    (item.isDrilledDown && ItemStyle.unitItemIsDrilledDown);

  let checkboxClass = ItemStyle.checkbox;
  let checkboxElement = "";

  if (item.checkboxStatus === CHECKBOX_STATUS.NOT_COMPATIBLE) {
    // has no checkbox
  } else if (item.checkboxStatus === CHECKBOX_STATUS.ONBOARDED) {
    checkboxClass += " " + ItemStyle.isOnboarded;
    checkboxElement = (
      <YbTooltip message="Already onboarded" position="right">
        <button type="button" className={checkboxClass}>
          <i className="fa fa-check"></i>
        </button>
      </YbTooltip>
    );
  } else {
    if (item.checkboxStatus === CHECKBOX_STATUS.SELECTED) {
      checkboxClass += " " + ItemStyle.selected;
    }

    checkboxElement = (
      <button
        type="button"
        className={checkboxClass}
        onClick={(event) => {
          onSelectUnit(item);
          event.stopPropagation();
        }}
      >
        <i className="fa fa-check"></i>
      </button>
    );
  }

  if (item.queryError) {
    unitItemClass = ItemStyle.unitItemHasError;
    checkboxElement = (
      <YbTooltip message={item.queryError.message} position="right">
        <span style={{ color: "red" }}>
          <i className="fa fa-exclamation-triangle"></i>
        </span>
      </YbTooltip>
    );
  }

  const [isHovered, setIsHovered] = useState(false);

  const shouldShowActionArea =
    item.childrenOnboardStatus !== CHILDREN_ONBOARD_STATUS.ALL_NOT_COMPATIBLE &&
    item.childrenOnboardStatus !== CHILDREN_ONBOARD_STATUS.ALL_ONBOARDED;

  const shouldShowSelectAllChildBtn =
    item.childrenSelectionStatus !== CHILDREN_SELECTION_STATUS.ALL_SELECTED;
  const shouldShowUnselectAllChildBtn =
    item.childrenSelectionStatus !== CHILDREN_SELECTION_STATUS.NONE_SELECTED;

  return (
    <div
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={unitItemClass}
        onClick={() =>
          _onClickableAreaClicked(item, layerNum, onDrillDown, onSelectUnit)
        }
      >
        <div className={ItemStyle.checkboxWrapper}>{checkboxElement}</div>

        <div className={ItemStyle.clickableArea}>
          <div className={ItemStyle.itemName}>
            <div>
              {item.name}
              {/* <div>
                {item.isCompatible ? "_Compatible" : "_NotCompat"}
                {item.isOnboarded ? "_Onboarded" : "_NotOnboard"}_
                {item.childrenOnboardStatus}_{item.dailyCompatibleAvgReq}
              </div> */}
              {/* <div style={{ fontSize: "12px" }}>
                <div>
                  selectableDirectChildUnitCount:{" "}
                  {item.selectableDirectChildUnitCount}
                </div>
                <div>selectedDirectCount: {item.selectedDirectCount}</div>
                <div>
                  selectedPartialDirectCount: {item.selectedPartialDirectCount}
                </div>
                <div>{item.childrenSelectionStatus}</div>

                <div style={{ color: "red" }}>
                  numOnboardableChildren: {item.numOnboardableChildren}
                </div>
                <div style={{ color: "red" }}>
                  numOnboardedChildren: {item.numOnboardedChildren}
                </div>
              </div> */}
            </div>
            <div style={{ display: "flex", gap: "4px" }}>
              {item.siteNetworkCodes && (
                <MCMLabel
                  id={item.extId}
                  siteNetworkCodes={item.siteNetworkCodes}
                ></MCMLabel>
              )}
            </div>
          </div>

          <div>
            {!item.hasChildren && !item.isCompatible && (
              <div className={ItemStyle.notCompatibleText}>Not compatible</div>
            )}
            <YbReqStatWrapper item={item}></YbReqStatWrapper>
          </div>
          {/* <div className={ItemStyle.itemStat}>
            <YbReqStatTooltip
              item={item}
              tooltipKey={item.extId + "-req-tooltip"}
            >
              <YbReqStatDiagram item={item}></YbReqStatDiagram>
            </YbReqStatTooltip>
          </div> */}

          <div className={ItemStyle.arrowWrapper}>
            <div className={ItemStyle.arrowIcon}>
              <i className="fa fa-angle-right fa-lg text-secondary"></i>
            </div>
            <ChildrenOnboardStatus item={item}></ChildrenOnboardStatus>
          </div>
        </div>
      </div>
      {shouldShowActionArea && (
        <div className={ItemStyle.actionArea}>
          {isHovered && (
            <>
              {shouldShowSelectAllChildBtn && (
                <div
                  className={ItemStyle.selectAllChildren}
                  onClick={() =>
                    onSelectAllChildUnits(item, { isSelectAll: true })
                  }
                >
                  {/* + All <VscTypeHierarchySub></VscTypeHierarchySub> */}+ All
                  child units
                </div>
              )}

              {shouldShowUnselectAllChildBtn && (
                <div
                  className={ItemStyle.unselectAllChildren}
                  onClick={() =>
                    onSelectAllChildUnits(item, { isSelectAll: false })
                  }
                >
                  {/* - All <VscTypeHierarchySub></VscTypeHierarchySub> */}- All
                  child units
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

function ChildrenOnboardStatus({ item }) {
  // child units selection status
  // let childrenSelectionStatus = CHILDREN_SELECTION_STATUS.NONE_SELECTED;
  // // all selected
  // if (
  //   item.selectableDirectChildUnitCount > 0 &&
  //   item.selectableDirectChildUnitCount === item.selectedDirectCount
  // ) {
  //   childrenSelectionStatus = CHILDREN_SELECTION_STATUS.ALL_SELECTED;
  // }
  // else if ()
  // some selected

  // none selected

  // selection status > onboard status
  let hasCircle = true;
  let circleColorClass = "";
  let iconColorClass = "";
  let iconElement = "";
  let tooltipMessage = null;
  switch (item.childrenOnboardStatus) {
    case CHILDREN_ONBOARD_STATUS.ALL_NOT_COMPATIBLE: {
      hasCircle = false;
      iconColorClass = ItemStyle.iconColorLightGray;
      iconElement = <BiX></BiX>;
      tooltipMessage = "All child units are not compatible";
      break;
    }
    case CHILDREN_ONBOARD_STATUS.ALL_ONBOARDED: {
      hasCircle = false;
      iconColorClass = ItemStyle.iconColorLightOrange;
      iconElement = <BiCheckDouble></BiCheckDouble>;
      tooltipMessage = "All compatible child units have been onboarded";
      break;
    }
    case CHILDREN_ONBOARD_STATUS.SOME_ONBOARDED: {
      circleColorClass = ItemStyle.circleColorLight;
      iconColorClass = ItemStyle.iconColorLightOrange;
      iconElement = <BiCheck></BiCheck>;
      tooltipMessage = "Some compatible child units have been onboarded";
      break;
    }
    default: {
      // nah
    }
  }

  switch (item.childrenSelectionStatus) {
    case CHILDREN_SELECTION_STATUS.ALL_SELECTED: {
      circleColorClass = ItemStyle.circleColorSolid;
      iconColorClass = ItemStyle.iconColorWhite;
      iconElement = <BiCheckDouble></BiCheckDouble>;
      tooltipMessage = "All compatible child units have been selected";
      break;
    }
    case CHILDREN_SELECTION_STATUS.SOME_SELECTED: {
      circleColorClass = ItemStyle.circleColorSolid;
      iconColorClass = ItemStyle.iconColorWhite;
      iconElement = <BiCheck></BiCheck>;
      tooltipMessage = "Some compatible child units have been selected";
      break;
    }
    default: {
      //nah
    }
  }

  if (item.isDrilledDown) {
    circleColorClass += " " + ItemStyle.isDrilledDown;
  }

  let circleClass = hasCircle ? ItemStyle.childStatusIconCircle : "";
  circleClass += " " + circleColorClass;

  return (
    <div className={ItemStyle.childStatusWrapper}>
      <div className={circleClass}>
        <div className={ItemStyle.childStatusIcon + " " + iconColorClass}>
          <YbTooltip message={tooltipMessage} position="left">
            {iconElement}
          </YbTooltip>
        </div>
      </div>
    </div>
  );
}

module.exports = OnboardItemComponent;
