import React from "react";
import { Button } from "react-bootstrap";
import { ApolloConsumer } from "react-apollo";
import { GET_INVOICE_DOWNLOAD_URL } from "../query/payment-history";
import cogoToast from "cogo-toast";

function onLinkFetched(url) {
  window.open(url.downloadUrl);
}

function InvoiceDownloadButton(props) {
  const filter = {
    type: props.type,
    invoiceNo: props.uniqueNo
  };

  return (
    <React.Fragment>
      {props.uniqueNo ? (
        <ApolloConsumer>
          {client => (
            <Button
              className="align-text-top"
              type="button"
              variant="link"
              onClick={() => {
                return client
                  .query({
                    query: GET_INVOICE_DOWNLOAD_URL,
                    variables: {
                      filter
                    }
                  })
                  .then(response => {
                    const data = response.data;
                    onLinkFetched(data.invoiceDownloadUrl);
                  })
                  .catch(err => {
                    console.log("Failed to fetch donwload link", err);
                    cogoToast.error(
                      "Failed to fetch download link, please contact us or try again later",
                      {
                        position: "bottom-center"
                      }
                    );
                  });
              }}
            >
              <i className="fa fa-download" />
            </Button>
          )}
        </ApolloConsumer>
      ) : (
        "-"
      )}
    </React.Fragment>
  );
}

module.exports = InvoiceDownloadButton;
