import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";

function PublisherInvitationLinkCopy(props) {
  let ignore = false;
  const { invitationLink } = props;
  const [isCopiedMessageShown, setIsCopiedMessageShown] = useState(false);

  useEffect(() => {
    return function cleanup() {
      ignore = true;
    };
  });

  function handleCopyLink() {
    if (isCopiedMessageShown) return;
    let timeout = null;
    const copyText = document.getElementById("publisher-invitation-link");
    copyText.select();
    document.execCommand("copy");

    if (!ignore) {
      setIsCopiedMessageShown(true);
      timeout = setTimeout(() => {
        setIsCopiedMessageShown(false);
      }, 3000);
    } else {
      clearTimeout(timeout);
    }
  }

  return (
    <Form>
      <Form.Control
        id="publisher-invitation-link"
        type="text"
        name="invitationLink"
        defaultValue={invitationLink}
        readOnly
        style={{ background: "#FFFFFF" }}
      />

      <div className="d-flex justify-content-center mt-2">
        <Button variant="info" onClick={handleCopyLink}>
          {isCopiedMessageShown ? <b>Copied!</b> : "Copy invitation link"}
        </Button>
      </div>
    </Form>
  );
}

export default PublisherInvitationLinkCopy;
