import gql from "graphql-tag";

export const GET_BILLING_PROFILE = gql`
  query getBillingProfile($filter: billingProfilesFilter!) {
    billingProfiles(filter: $filter) {
      edges {
        node {
          profileId
          method
          profile
        }
      }
    }
  }
`;

export const GET_BILLING_SETTING = gql`
  query {
    billingSetting {
      billingContact {
        firstName
        lastName
        email
        phoneNumber
        phoneCountryCode
      }
      billingRecipients
      activeBillingProfile {
        profileId
        method
        profile
      }
      geoCountry {
        countryId
        name
      }
      companyName
      currency
      invoiceSetting
    }
  }
`;
