import React, { useState } from "react";
import { Card, Button, Form, Col, ListGroup } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import _ from "lodash";

import {
  GOOGLE_ACCOUNT_TYPE,
  GAM_LOGO_TYPE,
} from "../../common/constants/common";
import AdManagerImage from "../../../common/images/ad_manager_header_raw.png";
import AdManager360Image from "../../../common/images/ad_manager360_header.png";
import BillingSectionImage from "../../../common/images/gam_billing_section_focus.png";
import AdSensePaymentSetting from "../../../common/images/adsense_payment_setting.png";
import AdSensePaymentProfileCountry from "../../../common/images/adsense_payment_profile_country.png";
import CountrySelector from "../../../subscription-and-billing/components/country-selector";

const PublisherFormSchema = Yup.object().shape({
  geoCountryId: Yup.number().required("Please select your GAM country"),
});

function PublisherFormGAMProfile(props) {
  const {
    defaultPublisher,
    handlePreviousStep,
    handleCancel,
    handlePublisherFormSubmit,
  } = props;

  const [isCountrySettingHelpOpen, setIsCountrySettingHelpOpen] =
    useState(false);

  function handlePublisherGAMProfileFormSubmit(values) {
    // if validation passes
    console.log("publihser gam profile form submit", values);
    handlePublisherFormSubmit(values);
  }

  function handleToggleCountrySettingHelp() {
    setIsCountrySettingHelpOpen(!isCountrySettingHelpOpen);
  }

  function handleCountrySelect(geoCountryId, setValues, values) {
    setValues({ ...values, geoCountryId: geoCountryId });
  }

  return (
    <React.Fragment>
      <div className="text-muted mb-4" style={{ letterSpacing: "0.04em" }}>
        <b>INVOICE SETTING</b>
      </div>

      <Formik
        initialValues={defaultPublisher}
        validationSchema={PublisherFormSchema}
        onSubmit={handlePublisherGAMProfileFormSubmit}
        render={(props) => {
          const { errors, touched, values } = props;

          return (
            <FormikForm onSubmit={props.handleSubmit}>
              {
                <React.Fragment>
                  <Form.Row className="mb-4">
                    <Form.Group
                      as={Col}
                      lg="8"
                      controlId="validationFormikGoogleAccountCountry"
                    >
                      <Form.Label
                        className="text-dark"
                        style={{ fontSize: "18px", fontWeight: "bolder" }}
                      >
                        What is your invoicing country?
                      </Form.Label>
                      <Form.Control
                        style={{ display: "none" }}
                        type="number"
                        name="geoCountryId"
                        onChange={props.handleChange}
                        value={values.geoCountryId}
                        isValid={touched.geoCountryId && !errors.geoCountryId}
                        isInvalid={errors.geoCountryId && touched.geoCountryId}
                      />
                      <CountrySelector
                        geoCountryId={values.geoCountryId}
                        handleChange={(geoCountryId) =>
                          handleCountrySelect(
                            geoCountryId,
                            props.setValues,
                            values
                          )
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.geoCountryId}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </React.Fragment>
              }
              <div className="d-flex justify-content-end mt-4">
                <Button variant="link" type="button" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  className="ml-2"
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                  onClick={() => handlePreviousStep(values)}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="ml-2"
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  Create Publisher
                </Button>
              </div>
            </FormikForm>
          );
        }}
      />
    </React.Fragment>
  );
}

export default PublisherFormGAMProfile;
