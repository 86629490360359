import React from "react";
import { Query } from "react-apollo";
import { Alert } from "react-bootstrap";

import YbLoadingView from "common/components/yb-loading-view";

import CreditCardInfo from "./credit-card-info";

import {
  GET_BILLING_PROFILE,
  GET_BILLING_SETTING,
  GET_ALL_BILLING_PROFILES
} from "../query/subscription-and-billing";

class BillingProfileInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profileId: this.props.profileId,
      thirdPartyRedirectPath: this.props.thirdPartyRedirectPath,
      setActiveProfileId: this.props.setActiveProfileId,
      setIsCreditCardAuthorized: this.props.setIsCreditCardAuthorized
    };

    this.handleSetActiveProfileId = this.handleSetActiveProfileId.bind(this);
    this.handleSetIsCreditCardAuthorized = this.handleSetIsCreditCardAuthorized.bind(
      this
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("should component update", nextProps, nextState);
    // There is no need for this component to be rerendered
    // console.log(this.props.activeProfileId, nextProps.activeProfileId);
    // console.log(this.props.activeProfileId === nextProps.activeProfileId);
    if (this.props.activeProfileId === nextProps.activeProfileId) {
      return false;
    }

    return true;
  }

  handleSetActiveProfileId(profileId) {
    return this.state.setActiveProfileId(profileId);
  }

  handleSetIsCreditCardAuthorized(isCreditCardAuthorized) {
    return this.state.setIsCreditCardAuthorized(isCreditCardAuthorized);
  }

  render() {
    let paymentInfoJsx = "";
    let paymentFailedJsx = (
      <Alert variant="danger">
        <Alert.Heading>Oh snap! Something went wrong.</Alert.Heading>
        <p>
          We have failed to get your credit card registered to our system,
          please try another card or contact us for support.
        </p>
      </Alert>
    );

    if (this.state.profileId) {
      paymentInfoJsx = (
        <Query
          query={GET_BILLING_PROFILE}
          variables={{
            filter: { profileIds: [this.state.profileId] }
          }}
          fetchPolicy={"network-only"}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <YbLoadingView />;
            }

            if (error) {
              console.log("GET_BILLING_PROFILE", error);
              return (
                <div>
                  {paymentFailedJsx}
                  <CreditCardInfo
                    thirdPartyRedirectPath={this.state.thirdPartyRedirectPath}
                  />
                </div>
              );
            }

            const billingProfiles = data.billingProfiles;
            const billingProfile = billingProfiles.edges[0];

            if (!billingProfile) {
              console.log("no billing profile");
              return (
                <div>
                  {paymentFailedJsx}
                  <CreditCardInfo
                    thirdPartyRedirectPath={this.state.thirdPartyRedirectPath}
                  />
                </div>
              );
            } else {
              const profile = billingProfile.node;

              this.handleSetActiveProfileId(profile.profileId);
              this.handleSetIsCreditCardAuthorized(true);

              return (
                <div>
                  {profile.method === "CREDIT_CARD" ? (
                    <CreditCardInfo
                      thirdPartyRedirectPath={this.state.thirdPartyRedirectPath}
                      activeProfile={profile}
                    />
                  ) : (
                    <div>
                      <label>
                        <i className="fa fa-exchange fa-fw text-secondary" />{" "}
                        &nbsp; Wire Transfer
                      </label>
                    </div>
                  )}
                </div>
              );
            }
          }}
        </Query>
      );
    } else {
      // 1. Get billingSetting.activeProfile, if nothing
      // 2. Try billingProfiles, if nothing
      // 3. A clean user
      paymentInfoJsx = (
        <Query query={GET_BILLING_SETTING} fetchPolicy={"network-only"}>
          {({ loading, error, data }) => {
            if (loading) {
              return <YbLoadingView />;
            }

            if (error) {
              console.log("GET_BILLING_SETTING", error);
              return "";
            }

            const billingSetting = data.billingSetting;

            if (billingSetting) {
              const activeBillingProfile = billingSetting.activeBillingProfile;
              if (activeBillingProfile) {
                this.handleSetActiveProfileId(activeBillingProfile.profileId);
                this.handleSetIsCreditCardAuthorized(true);

                return (
                  <div>
                    {activeBillingProfile.method === "CREDIT_CARD" ? (
                      <CreditCardInfo
                        thirdPartyRedirectPath={
                          this.state.thirdPartyRedirectPath
                        }
                        activeProfile={activeBillingProfile}
                      />
                    ) : (
                      <div>
                        <label>
                          <i className="fa fa-exchange fa-fw text-secondary" />{" "}
                          &nbsp; Wire Transfer
                        </label>
                      </div>
                    )}
                  </div>
                );
              }
            } else {
              // Check if user has multiple billing profiles
              return (
                <Query
                  query={GET_ALL_BILLING_PROFILES}
                  fetchPolicy={"network-only"}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <YbLoadingView />;
                    }

                    if (error) {
                      console.log("GET_ALL_BILLING_PROFILES", error);
                      return "";
                    }

                    const billingProfiles = data.billingProfiles;
                    const profiles = billingProfiles.edges.map(edge => {
                      return edge.node;
                    });

                    // Only has one credit card to choose
                    if (profiles && profiles.length === 1) {
                      const activeBillingProfile = profiles[0];

                      if (activeBillingProfile) {
                        this.handleSetActiveProfileId(
                          activeBillingProfile.profileId
                        );
                        this.handleSetIsCreditCardAuthorized(true);

                        return (
                          <CreditCardInfo
                            thirdPartyRedirectPath={
                              this.state.thirdPartyRedirectPath
                            }
                            activeProfile={activeBillingProfile}
                          />
                        );
                      }
                      // should be impossible for a user to have multiple cards for now
                    } else if (profiles && profiles.length > 1) {
                      const defaultProfile = profiles[0].profileId;

                      this.handleSetActiveProfileId(defaultProfile.profileId);
                      this.handleSetIsCreditCardAuthorized(true);

                      return (
                        <CreditCardInfo
                          thirdPartyRedirectPath={
                            this.state.thirdPartyRedirectPath
                          }
                          activeProfile={defaultProfile}
                        />
                        // TODO: this is broken
                        // <CreditCardInfo
                        //   thirdPartyRedirectPath={
                        //     this.state.thirdPartyRedirectPath
                        //   }
                        //   profileOptions={fakeProfiles}
                        //   setActiveProfileId={this.handleSetActiveProfileId}
                        //   // defaultProfileId={fakeProfiles[0].profileId}
                        // />
                      );
                    } else {
                      return (
                        <CreditCardInfo
                          thirdPartyRedirectPath={
                            this.state.thirdPartyRedirectPath
                          }
                        />
                      );
                    }
                  }}
                </Query>
              );
            }
          }}
        </Query>
      );
    }

    return paymentInfoJsx;
  }
}

module.exports = BillingProfileInfo;
