export async function fetchRespJson(url) {
  try {
    const response = await fetch(url);
    const resultJson = await response.json();

    if (response.ok) {
      return resultJson; // parses JSON response into native JavaScript objects
    } else {
      throw JSON.stringify(resultJson);
    }
  } catch (err) {
    console.log("error get data", err);
    throw err;
  }
}
