import React from 'react'

import GeneralStyles from '../styles/common.css'
import LoadingImage from '../images/ajax-loader-small.gif'
import LoadingImageText from '../images/ajax-loader-inline.gif'
import LoadingImageWhiteText from '../images/ajax-loader-inline-white.gif'

class YbLoading extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var isText = this.props.isText;
    var isWhiteText = this.props.isWhiteText;
    var darkBackground = this.props.darkBackground;

    return (
      <span className={GeneralStyles.textAlignCenter}>
        <img src={isText ? LoadingImageText : ( isWhiteText ? LoadingImageWhiteText : LoadingImage)} />
      </span>
    );
  }
};

module.exports = YbLoading;
