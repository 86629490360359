import React from 'react'

import Styles from 'common/styles/common.css'
import YbDivider from 'common/components/yb-divider'

class Terms extends React.Component {
  constructor(props) {
    super(props);
  }

  backToHome () {
    this.props.history.push("/");
  }

  render() {
    return (
      <div>
        <div className={Styles.notFoundWrapper}>
          <div className={Styles.mainTitle}>
            {i18n`SERVICE AGREEMENT`}
          </div>
          <YbDivider></YbDivider>
          <div className={Styles.textAlignJustify}>
            <p>Welcome and thank you for your interest in Yieldbooster&trade;! This document will layout the framework of our working relationship and make clear such elements of our agreement as Intowow service offerings, revenue uplift calculations, and payment details.</p>
            <p>This agreement (&ldquo;<strong>Agreement</strong>&rdquo;) is entered into by and between Intowow Innovation Limited, a company duly incorporated under the laws of the British Virgin Islands with registered offices at Sertus Chambers, P.O. Box 905, Quastisky Building, Road Town, Tortola, British Virgin Islands (&ldquo;<strong>Intowow</strong>&rdquo;) and the website publisher (&ldquo;<strong>Publisher</strong>&rdquo;).&nbsp; (Intowow and Publisher shall be referred to each as a &ldquo;<strong>Party</strong>&rdquo; and collectively as the &ldquo;<strong>Parties</strong>&rdquo;.)</p>
            <p>With respect to our joint Agreement, the Parties agree to the terms and conditions of Intowow Yieldbooster&trade; Service (&ldquo;<strong>Service</strong>&rdquo;) as described in the subsequent. Prior to formalizing and finalizing this Agreement, please feel free to consult (or have consulted) with counsel before the execution of this Agreement.</p>
            <p><strong>&nbsp;</strong></p>
            <ol>
            <li><strong>Definition</strong>
            <ol>
            <li><strong>"Publishers" </strong>means persons or organizations with owned or curated media who sell inventory on their websites and/or apps.<br /><br /></li>
            <li><strong>"Intowow Services" </strong>means Intowow will offer Yieldbooster&trade;, a Google Adx and AdSense yield optimization service operated by Intowow that provides Publisher with access to the automated process of setting floor prices for the ad units of Publisher&rsquo;s choosing.<br /><br /></li>
            <li><strong>"Intowow UI" </strong>means the Publisher online user interface through which Publisher can login and manage its ad units and check the optimization performance operated by Intowow Yieldbooster&trade;.<br /><br /></li>
            <li><strong>"Term" </strong>means the effective term of this Agreement.<br /><br /></li>
            <li><strong>"Ad(s)"</strong> means advertisements or any material that promotes a brand or products or services, including but not limited to texts, badges, videos, banners, interstitial ads, and buttons.<br /><br /></li>
            <li><strong>"Impression" </strong>means an advertisement renders on a user's screen.<br /><br /></li>
            <li><strong>"CPM" </strong>means the cost per thousand impressions.<br /><br /></li>
            <li><strong>"Clicks"</strong> means an instance of a user pressing down (clicking) on a mouse button in an ad space.<br /><br /></li>
            <li><strong>"Click-through Rate" </strong>means the percentage of ad impressions that were clicked on as compared to the entire number of clicks [CTR% = (Clicks &divide; Imps) x 100]<br /><br /></li>
            <li><strong>"Sell-through Rate" </strong>means percentage of available impressions sold to internet advertisers during a specific period.<br /><br /></li>
            <li><strong>"Ad Request"</strong> means the number of times a publisher&rsquo;s site requests ads to be displayed.<br /><br /></li>
            <li><strong>&ldquo;</strong><strong>Yieldbooster&trade; </strong>Ad Serving Fee&rdquo; means when Yieldbooster&trade; takes effect, it sends ad requests to Google Ad Manager(GAM) and therefore results in a GAM ad serving fee. The fee is based on the commercial agreement between Google and the Publisher. In most cases, Google will charge the Publisher by CPM for the impressions exceeding the Publisher&rsquo;s monthly free quota.<br /><br /></li>
            <li><strong>"Request RPM" </strong>means ad request revenue per thousand (also called revenue per mille, RPM) which is calculated by dividing the Publisher&rsquo;s estimated earnings by the number of ad requests the Publisher made, then multiplying by 1000.<br /><br /></li>
            <li><strong>"Ad Unit" </strong>means a set of ads displayed as a result of one piece of the ad code.<br /><br /></li>
            <li><strong>"Placement" </strong>means a tool for grouping ad units. It's a collection of one or more ad units where an advertiser's ad can be displayed.<br /><br /></li>
            <li><strong>"Creative"</strong> means the actual ad, which could be the actual image bytes, flash files, or custom HTML tags.<br /><br /></li>
            <li><strong>"Line Item" </strong>means a set of requirements for an ad to show, including how and when the ads should be shown, the sizes of the creatives it should show and the order in which to show them.<br /><br /></li>
            <li><strong>"Order" </strong>means an agreement between Publisher and an advertiser that includes an invoice number (optional), start and end dates, contact information., and contains one or more Line Item instances.<br /><br /></li>
            <li><strong>"Key-Value" </strong>means a tool of defining custom targeting criteria, such as search terms or specific pages.<br /><br /></li>
            <li><strong>"Intellectual Property Rights" </strong>means copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right.<br /><br /></li>
            <li><strong>"Confidential Information"</strong> means any and all information, including but not limited to the features and functionality of the Intowow Services (as defined below), that is disclosed by Intowow to Publisher, either directly or indirectly, in writing, orally or by inspection of tangible objects, which if disclosed in writing or tangible form is marked as &ldquo;Confidential,&rdquo; or with some similar designation, or if disclosed orally, is identified as being proprietary and/or confidential at the time of disclosure, or under the circumstances and nature of the information would be reasonably deemed to be confidential.<br /><br /></li>
            <li><strong>"Intowow Software"</strong> means any APIs or other software for integrating the Publisher Network with the Intowow Services.<br /><br /></li>
            <li><strong>"Service Data" </strong>means any data that Intowow collects during delivery of Service or performance of its obligations under this Agreement, including the End User&rsquo;s device information, End User's session-based browsing behavior, number of impressions, http header information, any other data that Publisher provides to Intowow.<strong><br /><br /> </strong></li>
            </ol>
            </li>
            <li><strong>Term</strong>
            <ol>
            <li>Agreement Effective Date: January 14th , 2019<br /><br /></li>
            <li>The Term of this Agreement shall be 1 year from the Effective Date.<br /><br /></li>
            <li>As Yieldbooster&trade; provides a continuous service, the Term shall automatically renew for an additional 1 year and same thereafter unless either Party otherwise provides written notice 10 days prior to the expiration hereof.<br /><br /></li>
            </ol>
            </li>
            <li><strong>Service Fees and Payment<br /></strong>The service that Publisher purchases and the service fees that Publisher will pay Intowow under this Agreement are as follows:
            <ol>
            <li>Service Fee: Publisher shall pay Intowow on a monthly sliding scale, based on Net Uplift Revenue, which is the Uplift Revenue (as set forth under Exhibit A) generated from the onboarded ad units that use Intowow Yieldbooster&trade; less the Yieldbooster&trade; Ad Serving Fee if applicable &nbsp;(as set forth under Exhibit B).&nbsp;<br /><br /></li>
            <li>Payment:
            <ol style={{listStyleType: 'upper-alpha'}}>
            <li>Payment of all amounts hereunder will be in U.S. Dollars.</li>
            <li>Intowow shall provide Publisher with reasonable access to its online publisher dashboard (&ldquo;Dashboard&rdquo;) where the Publisher can find monthly calculations of the payment to be made by Publisher to Intowow. The Dashboard shall, on the 6th of every calendar month, reflect the amounts payable by Publisher to Intowow for the preceding calendar month and Publisher shall make the undisputed payment reflected on such date within thirty (30) days (e.g., the amounts payable by Publisher to Intowow for the month of June shall be reflected in the Dashboard on 6 July and the payment shall be made on or before 6 Aug). The data obtained from the Dashboard shall be relied upon unless more than a 10% error or problem is detected by Intowow and Publisher.<br /><br /></li>
            </ol>
            </li>
            <li>Method of Payment: Publisher shall remit the payment to
            <ol style={{listStyleType: 'upper-alpha'}}>
            <li>Account Name: Intowow Innovation Limited</li>
            <li>Account Number (IBAN): 909 441 106 925</li>
            <li>Bank Name: E.SUN Commercial Bank, Hong Kong Branch</li>
            <li>SWIFT Code: ESUNHKHH</li>
            <li>Bank Address: SUITES 2805, 28/F, TOWER 6, THE GATEWAY, 9 CANTON ROAD, TSIMSHATSUI, KOWLOON, HONG KONG&nbsp;&nbsp;&nbsp;&nbsp;<br /><br /></li>
            </ol>
            </li>
            <li>Publisher shall pay the amount due on each invoice without deducting any taxes that may be applicable to such payments. Publisher is responsible for paying any and all withholding, sales, value added or other taxes, duties or charges applicable to this Agreement, other than taxes based on Intowow&rsquo;s income.<br /><br /></li>
            <li>Intowow reserves the right to pause the Service if any payments are more than thirty (30) days overdue.</li>
            </ol>
            </li>
            </ol>
            <ol start="4">
            <li><strong>Accessibility and Authorization</strong>
            <ol>
            <li>According to the terms and conditions of this Agreement, Publisher may, during the Term, access and use the Intowow Yieldbooster&trade; UI solely for purposes of checking optimization performance of the onboarded ad units, editing onboarded ad units, and uploading new ad units for optimization. Publisher hereby grants Intowow the non-exclusive authorization to view, edit, and activate the creatives, line items, orders, placements and key-values of Publisher&rsquo;s onboarded ad units through Intowow Yieldbooster&trade;<br /><br /></li>
            </ol>
            </li>
            <li><strong>Implementation</strong>
            <ol>
            <li>Intowow shall have the discretion to measure and determine the number of impressions, clicks, click-through rate (CTR), sell-through rate, revenue, CPM, request RPM, YieldLift (set forth under Exhibit A), or other actions in connection with yield optimization in accordance with customary industry standards, and all payments will be based on such measurements. Should Publisher find discrepancies in the measurement of said number(s) with evidence, and such discrepancy is greater than 10%, Publisher may notify Intowow with written notice within 30 days from the date of payment in relation to the dispute. Otherwise Publisher shall be deemed to waive its rights to dispute relevant payment. The Parties agree to discuss in good faith resolution of said discrepancy.<br /><br /></li>
            <li>Intowow reserves the right to modify or suspend the Intowow Services in whole or in part at any time if Intowow believes such modification is reasonably necessary in order to comply with applicable laws or industry regulations, including but not limited to the requirements of any self-regulatory program or framework, to avoid or limit liability, to prevent errors or any other harm with respect to the Intowow Services or other properties, services, web sites and applications serviced by the Intowow Services, or to respond to Publisher&rsquo;s material breach of this Agreement as determined by Intowow.<strong><br /><br /> </strong></li>
            </ol>
            </li>
            <li><strong>Service Data</strong>
            <ol>
            <li>Intowow may collect and receive Service Data, the collection, utilization and access to Service Data are solely to the extent and for the purpose of performing its obligations under the Agreement. Intowow shall safeguard any Service Data against unauthorized access or use.<br /><br /></li>
            <li>Intowow shall not, without Publisher&rsquo;s prior written consent, use, sell, license, lease or otherwise transfer Service Data provided by Publisher to any third party.<br /><br /></li>
            </ol>
            </li>
            <li><strong>Intellectual Property and Ownership</strong>
            <ol>
            <li>Intowow retains all rights, titles and interest in and to the Services and any materials created, developed or provided by Intowow in connection with this Agreement, including but not limited to all Intellectual Property Rights related to each of the foregoing.<br /><br /></li>
            </ol>
            </li>
            <li><strong>Representation and Warranty</strong>
            <ol>
            <li>The Parties represent and warrant that the Parties have the full power and authority to enter into this Agreement.<br /><br /></li>
            <li>The Parties covenant that the execution of this Agreement and performance of The Parties&rsquo; obligations under this Agreement do not violate any other agreement to which either is a party.<br /><br /></li>
            <li>The Parties covenant that this Agreement constitutes a legal, valid and binding obligation upon execution.<strong><br /><br /></strong></li>
            </ol>
            </li>
            <li><strong>Publisher&rsquo;s Obligations</strong>
            <ol>
            <li>Publisher shall not have or allow any third party to:
            <ol style={{listStyleType: 'upper-alpha'}}>
            <li>create derivative works based on the Intowow Services;</li>
            <li>modify, remove, or obscure any proprietary notices or legends that appear on the Intowow Services or during the use and operation thereof;</li>
            <li>copy, distribute, rent, lease, lend, sublicense, transfer or make the Intowow Services available to any third party;</li>
            <li>decompile, reverse engineer, or disassemble the Intowow Software;</li>
            <li>create or attempt to create a substitute or similar service or product through use of or access to any of the Intowow Services or proprietary information related thereto.<br /><br /></li>
            </ol>
            </li>
            <li>Publisher shall register an account to manage its ad units and view all available performance metrics through the Intowow UI, provided that Publisher acknowledges that such metrics are provided by a third party and may change at any time. Publisher is solely responsible for the security of its account and shall be responsible for any activities of any person authorized by Publisher.<br /><br /></li>
            <li>Intowow shall not be liable for Publisher&rsquo;s non-performance of Publisher&rsquo;s obligation set forth in this Section.<strong><br /><br /></strong></li>
            </ol>
            </li>
            <li><strong>Confidentiality</strong>
            <ol>
            <li>The Parties shall not use Confidential Information except as necessary to exercise its rights or perform its obligations under this Agreement. The Parties shall not disclose Confidential Information to any third party except to those of its representatives, officers, employees, or consultants that need to know such Confidential Information for the purposes of this Agreement, provided that each of such representatives, officers, employees, or consultants is subject to a written agreement that includes binding use and disclosure restrictions that are at least as protective of Confidential Information as those set forth herein. The Parties shall be jointly and severally liable with their representatives, officers, employees, or consultants that breach of the confidential obligation in this Section.<br /><br /></li>
            <li>Confidential Information shall not include information that: (a) is or becomes generally known to the public through no fault of or breach of this Agreement by Publisher; (b) is rightfully known by Publisher at the time of disclosure without an obligation of confidentiality; (c) is independently developed by Publisher without use of the Intowow&rsquo;s Confidential Information; or (d) is rightfully obtained by Publisher from a third party without restriction on use or disclosure.<br /><br /></li>
            <li>Publisher shall use its reasonable efforts to maintain the confidentiality of all Confidential Information in its possession or control, where such reasonable effort shall in no event less than the efforts that Publisher ordinarily uses with respect to its own proprietary information of similar nature and importance. Notwithstanding the foregoing, the Confidential Information may be disclosed by Publisher only pursuant to the order or requirement of a court, administrative agency, or other governmental body, provided that the Party required to make such a disclosure gives reasonable notice to the other Party to contest such order or requirement.<br /><br /></li>
            </ol>
            </li>
            <li><strong>Termination</strong>
            <ol>
            <li>Either Party may terminate this Agreement during the Term if the other Party is in material breach 30 days prior to the expected termination date. Publisher shall pay the remaining Service Fees as set forth in Section 3 herein.<br /><br /></li>
            <li>Either Party may terminate this Agreement if the other Party is in material breach of any obligation, covenants, representation or warranty hereunder and fails to amend such material breach (if capable of cure) within 14 days after receiving notice of the breach from the non-breaching Party. Publisher acknowledges that Publisher&rsquo;s default payment under this Agreement is deemed as a material breach hereof.<br /><br /></li>
            <li>Either Party may terminate immediately upon notice at any time if:
            <ol style={{listStyleType: 'upper-alpha'}}>
            <li>the other Party files a petition for bankruptcy or is adjudicated as bankrupt;</li>
            <li>the other Party makes an assignment for the benefit of its creditors or an arrangement for its creditors;</li>
            <li>a receiver is appointed over all or substantially all of the other Party&rsquo;s assets or business;</li>
            <li>the other Party is dissolved or liquidated; or</li>
            <li>the other Party discontinues its business.</li>
            <li>upon termination or expiration of this Agreement, all licenses granted herein shall be terminated and Publisher shall discontinue all use of the Intowow Services.<br /><br /></li>
            </ol>
            </li>
            <li>Section 7, 9, 10, 11, 12, 13 and 14 shall survive the termination or expiration of this Agreement.<br /><br /></li>
            </ol>
            </li>
            <li><strong>NO PUNITIVE DAMAGE<br /></strong>INTOWOW SHALL NOT BE LIABLE TO PUBLISHER OR ANY THIRD PARTY FOR ANY PUNITIVE, INCIDENTAL, INDIRECT, SPECIAL, RELIANCE OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO THE LOSS OF BUSINESS, REVENUE, OR ANTICIPATED PROFITS, WHETHER OR NOT INTOWOW WAS ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES</li>
            </ol>
            <ol start="13">
            <li><strong>LIMITATION OF LIABILITY<br /></strong>INTOWOW SHALL IN NO EVENT BE LIABLE FOR
            <ol>
            <li>ANY INFRINGEMENT CLAIMS ARISING OUT OF THE COMBINATION OF THE INTOWOW SERVICE OR USE WITH OTHER HARDWARE, SOFTWARE OR OTHER ITEMS NOT PROVIDED BY INTOWOW TO THE EXTENT SUCH INFRINGEMENT WOULD NOT HAVE OCCURRED ABSENT SUCH COMBINATION OR USE; OR<br /><br /></li>
            <li>ANY UNAUTHORIZED MODIFICATION OF THE INTOWOW SERVICES.<br /><br /></li>
            </ol>
            </li>
            </ol>
            <ol start="14">
            <li><strong>DISCLAIMER<br /></strong>EXCEPT AS EXPRESSLY SET FORTH HEREIN, INTOWOW MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE SUBJECT MATTER OF THIS AGREEMENT, AND INTOWOW EXPRESSLY DISCLAIMS THE IMPLIED WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, AND IMPLIED WARRANTIES DEALING OR PERFORMANCE HEREOF.&nbsp; EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, INTOWOW AND ITS DIRECTORS, SUPPLIERS, LICENSORS, AND PARTNERS DO NOT WARRANT THAT THE RESULTS OF USING INTOWOW SERVICE, THE FUNCTIONS CONTAINED IN THE INTOWOW SERVICES OR INTOWOW SOFTWARE WILL BE CORRECT, UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THEY ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</li>
            </ol>
            <ol start="15">
            <li><strong>Miscellaneous</strong>
            <ol>
            <li>Section headings are for reference purposes only, and should not be used in the interpretation hereof. No provision of this Agreement shall be construed against either Party as the drafter thereof.<br /><br /></li>
            <li>This Agreement shall be fairly interpreted and construed in accordance with its terms and without strict interpretation or construction in favor of or against either Party.<br /><br /></li>
            <li>Any delay in or failure of performance by either Party under this Agreement will not be considered a breach of this Agreement and will be excused to the extent caused by any occurrence beyond the reasonable control of such party including, but not limited to, acts of God, power outages and governmental restrictions.<br /><br /></li>
            <li>If any provision of this Agreement is determined by a court of competent jurisdiction to be invalid, illegal or unenforceable, such determination will not impair or affect the validity, legality, or enforceability of the remaining provisions of this Agreement, and each provision is hereby declared to be separate, severable, and distinct.<br /><br /></li>
            <li>The Parties are independent contractors with respect to each other. This Agreement does not constitute and shall not be construed as constituting a partnership or joint venture among the Parties hereto, or an employee-employer relationship. Neither Party shall have the right to obligate or bind the other Party in any manner whatsoever, nor nothing herein contained shall give, or is intended to give, any rights of any kind to any third party.<br /><br /></li>
            <li>All notices under the terms of this Agreement shall be given in writing and sent by registered mail, internationally recognized carrier, or confirmed fax transmission, or delivered by hand to the address set forth on the first page herein. All notices are deemed to have been received when they are hand delivered, or after five business days of their mailing.<br /><br /></li>
            <li>A waiver of any provision of this Agreement will only be valid if provided in writing and will only be applicable to the specific incident and occurrence so waived. The failure of a Party to insist upon strict adherence to any term of this Agreement on any occasion shall not be considered a waiver of such Party&rsquo;s rights or deprive such Party of the right thereafter to insist upon strict adherence to that term or any other term of this Agreement.<br /><br /></li>
            <li>This Agreement constitutes the entire agreement between the Parties. This Agreement is the complete and exclusive statement of the terms of the understanding of the Parties with respect to the subject matter hereof, and supersedes any prior or contemporaneous agreements, commitments, proposals, representations, or communications, oral or written. The Parties shall not modify, amend or otherwise change this Agreement in any manner except by a written instrument executed by the Parties.<br /><br /></li>
            <li>This Agreement shall be construed in accordance with and governed by the laws of the Taiwan and shall be interpreted thereunder. Any dispute, controversy, difference or claim arising out of, relating to or in connection with this Agreement, or the breach, termination or invalidity thereof, shall be finally settled by arbitration referred to the Chinese Arbitration Association, Taipei (the &ldquo;CCA&rdquo;) in Taipei in accordance with the CCA Arbitration Rules. The place of arbitration shall be in Taipei, Taiwan. The language of arbitration shall be Chinese. The arbitral award shall be final and binding upon both Parties.</li>
            </ol>
            </li>
            </ol>
          </div>
          <YbDivider></YbDivider>
          <span className={Styles.roundButton} onClick={this.backToHome.bind(this)}>
            <i className="fa fa-home"></i> {i18n`BACK_TO_HOME`}
          </span>
        </div>
      </div>
    );
  }
}

module.exports = Terms;
