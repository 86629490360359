import PATHNAME from "./routes-pathnames";
// import YbOptimizationIndicator from "common/components/yb-optimization-indicator";

// Order Matters!
const directPublisherLinks = [
  {
    title: "Home",
    path: PATHNAME.home,
  },
  {
    title: "Inventory",
    path: PATHNAME.inventorySummary,
    aliases: ["/yield-sets", "/inventory", "/network", "/ad-units"], // nav still active in these paths
  },
  // {
  //   title: "AdSense Ad Unit Allocation",
  //   path: PATHNAME.adSizeOptimization,

  //   // TODO: if this indicator is necessary, rewrite it in /_newapp
  //   // and use PublisherInfoContext to get publisherId in order to query under a specific publisher

  //   // renderChildren: () => {
  //   //   return <YbOptimizationIndicator />;
  //   // }
  // },
  {
    title: "Inventory Onboard Wizard",
    path: PATHNAME.onBoardWizard,
  },
  {
    title: "Analytics",
    path: PATHNAME.dashboardOverview,
  },
  {
    title: "Subscription & Billing",
    path: PATHNAME.subscriptionAndBilling.overview,
  },
  {
    title: "Users",
    path: PATHNAME.users,
  },
  // {
  //   title: "Settings",
  //   path: PATHNAME.settings
  // }
];

const managedPublisherLinks = [
  {
    title: "Home",
    path: PATHNAME.home,
  },
  {
    title: "Inventory",
    path: PATHNAME.inventorySummary,
  },
  // {
  //   title: "AdSense Ad Unit Allocation",
  //   path: PATHNAME.adSizeOptimization,
  // },
  {
    title: "Inventory Onboard Wizard",
    path: PATHNAME.onBoardWizard,
  },
  {
    title: "Analytics",
    path: PATHNAME.dashboardOverview,
  },
  {
    title: "Users",
    path: PATHNAME.users,
  },
  // {
  //   title: "Settings",
  //   path: PATHNAME.settings
  // }
];

const resellerLinks = [
  {
    title: "Home",
    path: PATHNAME.home,
  },
  {
    title: "Publishers",
    path: PATHNAME.publishers,
  },
  {
    title: "Analytics",
    path: PATHNAME.resellerDashboard,
  },
  {
    title: "Billing",
    path: PATHNAME.resellerBilling,
  },
  {
    title: "Users",
    path: PATHNAME.users,
  },
  {
    title: "Settings",
    path: PATHNAME.settings,
  },
];

const resellerControllingPublisherLinks = [
  {
    title: "Inventory",
    path: PATHNAME.publisherBase.inventorySummary,
    matchPath: PATHNAME.publisherBase.inventorySummary.substr(
      PATHNAME.publisherBase.basePath.length
    ),
    aliases: ["/yield-sets", "/inventory", "/network", "/ad-units"],
  },
  // {
  //   title: "AdSense Ad Unit Allocation",
  //   path: PATHNAME.publisherBase.adSizeOptimization,
  //   matchPath: PATHNAME.publisherBase.adSizeOptimization.substr(
  //     PATHNAME.publisherBase.basePath.length
  //   ),
  // },
  {
    title: "Inventory Onboard Wizard",
    path: PATHNAME.publisherBase.onBoardWizard,
    matchPath: PATHNAME.publisherBase.onBoardWizard.substr(
      PATHNAME.publisherBase.basePath.length
    ),
  },
  // {
  //   title: "Settings",
  //   path: PATHNAME.publisherBase.settings,
  //   matchPath: PATHNAME.publisherBase.settings.substr(
  //     PATHNAME.publisherBase.basePath.length
  //   )
  // }
];

export const DIRECT_PUBLISHER_LINKS = directPublisherLinks;
export const MANAGED_PUBLISHER_LINKS = managedPublisherLinks;
export const RESELER_LINKS = resellerLinks;
export const RESELLER_CONTROLLING_PUBLISHER_LINKS =
  resellerControllingPublisherLinks;
