import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import { GET_PUBLISHER_INFO } from "../../base/query/publisher-info";
import { TRIAL_STATUS, SUBSCRIPTION_STATUS } from "../constants/common";
import { PublisherInfoContext } from "./contexts";
import YBLoadingView from "common/components/yb-loading-view";

function PublisherInfoContextProvider(props) {
  let ignore = false;
  const pubId = props.publisherId;

  const [publisherInfo, setPublisherInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!ignore) {
      if (pubId > 0) {
        getPublisherInfo(pubId);
      } else {
        setPublisherInfo({ publisherId: -1 });
      }
    }

    return function cleanUp() {
      ignore = true;
    };
  }, [props.publisherId]);

  async function getPublisherInfo(pubId) {
    setIsLoading(true);
    const { client } = props;
    try {
      const tempPublisherInfo = await queryPublisherInfo(client, pubId);

      const properPublisherInfo = transformPublisherInfo(tempPublisherInfo);

      setPublisherInfo(properPublisherInfo);
    } catch (err) {
      console.log("getPublisherInfo Error", err);
    }
    setIsLoading(false);
  }

  function handlePublisherChange(publisherInfo, redirectPath) {
    console.log(
      "CHANGED PUBLISHER IN PUBLISHER INFO CONTEXT",
      publisherInfo,
      redirectPath
    );

    getPublisherInfo(publisherInfo.publisherId);

    if (redirectPath) {
      window.location = redirectPath;
    }
  }

  return (
    <PublisherInfoContext.Provider
      value={{ pubId, publisherInfo, handlePublisherChange }}
    >
      {isLoading ? (
        <YBLoadingView />
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </PublisherInfoContext.Provider>
  );
}

async function queryPublisherInfo(client, pubId) {
  try {
    const response = await client.query({
      query: GET_PUBLISHER_INFO,
      variables: {
        input: {
          publisherIds: [pubId]
        },
        pubId: pubId
      }
    });
    const data = await response.data;
    return data;
  } catch (err) {
    console.log("Error querying publisher info", err);
    throw "Unable to identify publisher";
  }
}

function transformPublisherInfo(tempPublisherInfo) {
  const pub = _.get(tempPublisherInfo, "publishers.edges[0].node");
  const accountConfig = _.get(tempPublisherInfo, "accountConfig.config");

  const publisherType = _.get(pub, "type");
  const publisherId = _.get(pub, "publisherId");
  const pubName = _.get(pub, "name");
  const isRegisteredPublisher = _.get(pub, "registered") === "REGISTERED";
  const accountType = _.get(pub, "accountType");
  // const isBillablePublisher = _.has(
  //   pub,
  //   "billingSetting.activeBillingProfile.profileId"
  // );
  const trialRemainingDays = _.get(
    pub,
    "accountStatus.trialStatus.remainingDays"
  );
  const isInFreeTrial = trialRemainingDays > 0;

  const trialStatus = getTrialStatus(accountType, trialRemainingDays);
  const subscriptionStatus = getSubscriptionStatus(accountType);

  let properPublisherInfo = {
    publisherId,
    name: pubName,
    type: publisherType,
    isRegistered: isRegisteredPublisher,
    accountType,
    isInFreeTrial,
    trialStatus,
    subscriptionStatus,
    config: accountConfig
  };

  return properPublisherInfo;
}

function getSubscriptionStatus(accountType) {
  // SUBSCRIBED, NOT_SUBSCRIBED
  if (accountType === "ACTIVE") {
    return SUBSCRIPTION_STATUS.SUBSCRIBED;
  }

  return SUBSCRIPTION_STATUS.NOT_SUBSCRIBED;
}

function getTrialStatus(accountType, remainingDays) {
  // TRIAL, TRIAL_EXPIRED
  if (accountType === "TRIAL_EXPIRED") {
    return TRIAL_STATUS.TRIAL_EXPIRED;
  }

  if (remainingDays > 0) {
    return TRIAL_STATUS.TRIAL;
  }
}

export default withApollo(PublisherInfoContextProvider);
