import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";

import { GET_ASSOCIATED_PUBLISHERS } from "../../base/query/user-info";
import YBLoadingView from "common/components/yb-loading-view";
import LoginAsPublisher from "./login-as-publisher";

function getOldApolloClient() {
  let headers = {
    "content-type": "text/plain",
  };
  const client = new ApolloClient({
    uri: process.env.INTOWOW_GRAPHQL_ENDPOINT + "/graphql",
    headers,
    credentials: "include",
    fetchOptions: {
      useGETForQueries: true,
    },
  });
  return client;
}
const COOKIE_NAME = "loginAsPubId";

function LoginAsWrapper(props) {
  let ignore = false;
  const client = getOldApolloClient();
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);

  const [userInfo, setUserInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!ignore) {
      getUserInfo(client);
    }

    return function cleanUp() {
      ignore = true;
    };
  }, []);

  async function getUserInfo(client) {
    setIsLoading(true);

    try {
      const tempUserInfo = await queryUserInfo(client);
      setUserInfo(tempUserInfo.me);

      // if user has only 1 associatedPublishers:
      const associatedPublishers = _.get(
        tempUserInfo,
        "me.associatedPublishers",
        []
      );
      if (associatedPublishers.length === 1) {
        const pubId = _.get(associatedPublishers, [0, "publisherId"]);
        handleLoginAsChange(pubId);
      }
    } catch (err) {
      console.log("getUserInfo Error", err);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function handleLoginAsChange(pubId) {
    console.log("handleLoginAsChange", pubId);

    setCookie(COOKIE_NAME, pubId);
    window.location.reload(false);
  }

  return (
    <div>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          {isLoading ? (
            <YBLoadingView />
          ) : (
            <LoginAsPublisher
              // loginAsPubId={loginAsPubId}
              userInfo={userInfo}
              handleLoginAsChange={handleLoginAsChange}
            ></LoginAsPublisher>
          )}
        </ApolloHooksProvider>
      </ApolloProvider>
    </div>
  );
}

async function queryUserInfo(client) {
  try {
    const response = await client.query({
      query: GET_ASSOCIATED_PUBLISHERS,
      fetchPolicy: "network-only",
    });
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("Error querying associated publishers", err);
    throw "Unable to identify user";
  }
}

export default LoginAsWrapper;
