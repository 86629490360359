import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import { withRouter } from "react-router-dom";
import intowowLogo from "../../../common/images/intowow-logo-180x180.png";
import { USER_ROLE } from "../../common/constants/common";
import YbPublisherAccountStatus from "../../../common/components/yb-publisher-account-status";
import PublisherSelectorInHeader from "./publisher-selector-in-header";

import Styles from "../styles/header.css";

const Header = (props) => {
  const [isResellerHovered, setIsResellerHovered] = useState(false);

  const userInfo = props.userInfo;
  const isResellerControllingPublisher =
    _.get(props, "publisherInfo.publisherId") > 0;
  const controlledPublisher = props.publisherInfo;

  function handleResellerNameClicked() {
    props.handleSwitchPublisher({ publisherId: -1 }, "/publishers");
    props.history.push("/publishers");
  }

  function handleLogoClicked() {
    if (isResellerControllingPublisher) {
      props.history.push("/publishers");
    } else {
      props.history.push("/overview");
    }
  }

  return (
    <React.Fragment>
      <Navbar
        style={{ backgroundColor: "#0f182c", height: "64px" }}
        variant="dark"
      >
        <Navbar.Brand
          style={{ marginLeft: "8px", cursor: "pointer" }}
          className="pb-2"
          onClick={handleLogoClicked}
        >
          {/* TODO: Add hover effect or sth - breathing effect would be cool */}
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "16px",
              border: "2px solid #324d69",
            }}
          >
            <img src={intowowLogo} width="36px" />
          </div>
        </Navbar.Brand>

        <Navbar.Text className="ml-2">
          {userInfo.role === USER_ROLE.RESELLER_ADMIN && (
            <React.Fragment>
              {userInfo.reseller && isResellerControllingPublisher ? (
                // Reseller controlling publisher

                <div className="d-flex align-items-center">
                  <div
                    onClick={handleResellerNameClicked}
                    style={{
                      padding: "6px 8px",
                      borderRadius: "6px",
                      cursor: "pointer",
                      color: `${isResellerHovered ? "#D7D7D7" : "#AEAEAE"}`,
                      backgroundColor: `${
                        isResellerHovered ? "#1d2b46" : "#0D1525"
                      }`,
                    }}
                    onMouseEnter={() => {
                      setIsResellerHovered(true);
                    }}
                    onMouseLeave={() => {
                      setIsResellerHovered(false);
                    }}
                  >
                    <div
                      style={{
                        lineHeight: "0.8",
                        fontSize: "smaller",
                        marginLeft: "8px",
                        color: "#AEAEAE",
                      }}
                    >
                      <small>RESELLER</small>
                    </div>
                    <strong
                      style={{
                        fontSize: "larger",
                        letterSpacing: "0.05em",
                        marginLeft: "8px",
                      }}
                    >
                      {userInfo.reseller.name}
                    </strong>

                    <i
                      className="fa fa-angle-right"
                      style={{ marginLeft: "16px", marginRight: "8px" }}
                    />
                  </div>

                  <div>
                    <PublisherSelectorInHeader
                      publisher={controlledPublisher}
                      reseller={userInfo.reseller}
                    >
                      <div
                        className="text-left"
                        style={{
                          lineHeight: "0.8",
                          fontSize: "smaller",
                        }}
                      >
                        <small>PUBLISHER</small>
                      </div>
                      <strong
                        className="text-light"
                        style={{
                          fontSize: "larger",
                          letterSpacing: "0.05em",
                        }}
                      >
                        {controlledPublisher.name}
                        <i className="fa fa-caret-down ml-2 text-dark" />
                      </strong>
                    </PublisherSelectorInHeader>
                    <span
                      className="ml-2"
                      style={{ verticalAlign: "-webkit-baseline-middle" }}
                    >
                      <YbPublisherAccountStatus
                        publisherId={controlledPublisher.publisherId}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="align-self-center">
                  {userInfo.reseller && (
                    <div>
                      <div style={{ lineHeight: "0.8", fontSize: "smaller" }}>
                        <small>RESELLER</small>
                      </div>
                      <strong
                        className="text-light"
                        style={{
                          fontSize: "larger",
                          letterSpacing: "0.05em",
                        }}
                      >
                        {userInfo.reseller.name}
                      </strong>
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          )}

          {userInfo.role === USER_ROLE.MANAGED_PUBLISHER_ADMIN && (
            <React.Fragment>
              {userInfo.reseller ? (
                <div className="d-flex align-items-center">
                  <div>
                    <div style={{ lineHeight: "0.8", fontSize: "smaller" }}>
                      <small>RESELLER</small>
                    </div>
                    <strong
                      className="text-light"
                      style={{
                        fontSize: "larger",
                        letterSpacing: "0.05em",
                      }}
                    >
                      {userInfo.reseller.name}
                    </strong>
                  </div>

                  {userInfo.publisher && (
                    <React.Fragment>
                      <i
                        className="fa fa-angle-right"
                        style={{ marginLeft: "16px", marginRight: "16px" }}
                      />
                      <div>
                        <div
                          style={{
                            lineHeight: "0.8",
                            fontSize: "smaller",
                          }}
                        >
                          <small>PUBLISHER</small>
                        </div>
                        <strong
                          className="text-light"
                          style={{
                            fontSize: "larger",
                            letterSpacing: "0.05em",
                          }}
                        >
                          {userInfo.publisher.name}
                        </strong>
                        <span className="ml-2">
                          <YbPublisherAccountStatus />
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <React.Fragment>
                  {userInfo.publisher && (
                    <div className="align-self-center">
                      <div style={{ lineHeight: "0.8", fontSize: "smaller" }}>
                        <small>PUBLISHER</small>
                      </div>
                      <strong
                        className="text-light"
                        style={{
                          fontSize: "larger",
                          letterSpacing: "0.05em",
                        }}
                      >
                        {userInfo.publisher.name}
                      </strong>
                      <span className="ml-2">
                        <YbPublisherAccountStatus />
                      </span>
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {userInfo.role === USER_ROLE.DIRECT_PUBLISHER_ADMIN && (
            <React.Fragment>
              {userInfo.publisher && (
                <div className="align-self-center">
                  <div style={{ lineHeight: "0.8", fontSize: "smaller" }}>
                    <small>PUBLISHER</small>
                  </div>
                  <strong
                    className="text-light"
                    style={{
                      fontSize: "larger",
                      letterSpacing: "0.05em",
                    }}
                  >
                    {userInfo.publisher.name}
                  </strong>
                  <span className="ml-2">
                    <YbPublisherAccountStatus />
                  </span>
                </div>
              )}
            </React.Fragment>
          )}
        </Navbar.Text>

        <Nav className="ml-auto" style={{ marginRight: "8px" }}>
          <Navbar.Text>
            <span>{userInfo && userInfo.user.email}</span>
            <div
              className="d-flex justify-content-end mt-0"
              style={{ lineHeight: "0.8" }}
            >
              {userInfo.user.associatedPublishers.length > 1 && (
                // <a
                //   className="mr-4"
                //   href=""
                //   onClick={(e) => {
                //     e.preventDefault(); // so it doesn't refresh page!
                //     props.handleSwitchLoginAs();
                //   }}
                // >
                //   <small className="text-primary">Switch publisher</small>
                // </a>
                <button
                  className={Styles.headerSwitchBtn}
                  onClick={() => {
                    props.handleSwitchLoginAs();
                  }}
                >
                  <small className="text-primary">Switch publisher</small>
                </button>
              )}
              {/* <a
                href=""
                onClick={(e) => {
                  props.handleSwitchLoginAs();
                  handleLogout(e);
                }}
              >
                <small className="text-primary">Sign out</small>
              </a> */}
              <button
                className={Styles.headerSignoutBtn}
                onClick={handleLogout}
              >
                <small className="text-primary">Sign out</small>
              </button>
            </div>
          </Navbar.Text>
        </Nav>
      </Navbar>
    </React.Fragment>
  );
};

function handleLogout() {
  const redirectUrl = encodeURIComponent(window.location.origin);
  const url = `${process.env.INTOWOW_GRAPHQL_ENDPOINT}/logout?logout_uri=${redirectUrl}`;

  window.location.href = url;
}

export default withRouter(Header);
