import React, { useEffect, useState } from "react";
import intowowLogo from "../../../common/images/intowow-logo-180x180.png";
import { Card } from "react-bootstrap";
import Styles from "../styles/login-as.css";

function LoginAsPublisher(props) {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    document.title = "Login as | Intowow";
    document.body.style.backgroundColor = "#161A30";

    const userInfo = props.userInfo;
    setUserInfo(userInfo);

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);

  function handlePublisherClicked(pubId) {
    props.handleLoginAsChange(pubId);
  }

  return (
    <React.Fragment>
      <div
        style={{
          paddingTop: "80px",
          paddingBottom: "120px",
        }}
      >
        <div className="d-flex justify-content-center align-self-center">
          <div style={{ width: "60%" }}>
            <div className="d-flex justify-content-center pb-4">
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "30px",
                  border: "4px solid black",
                }}
              >
                <img src={intowowLogo} width="52px" />
              </div>
            </div>

            <Card
              className="text-center shadow-lg"
              style={{
                borderRadius: "8px",
                padding: "24px",
                background: "#fbfeff",
              }}
            >
              <Card.Body>
                <h1 className="mt-2 mb-4">Choose a publisher:</h1>

                <div className="d-flex justify-content-center align-self-center">
                  <div className={Styles["login-as-list-wrapper"]}>
                    <div className={Styles["login-as-list-header"]}>
                      Login as
                    </div>
                    <div className={Styles["login-as-list"]}>
                      {userInfo &&
                        userInfo.associatedPublishers.map((pub) => {
                          return (
                            <div
                              key={pub.publisherId}
                              className={Styles["login-as-list-item"]}
                              onClick={() =>
                                handlePublisherClicked(pub.publisherId)
                              }
                            >
                              <div className="d-flex">
                                {/* <div
                                  className="text-center"
                                  style={{
                                    width: "54px",
                                    paddingRight: "4px",
                                    flex: "none", // will not grow or shrink based on size of pub name
                                  }}
                                >
                                  {pub.publisherId}
                                </div> */}
                                <div>
                                  <div className="d-flex align-items-center">
                                    <b>{pub.name}</b>
                                    {pub.type === "RESELLER" && (
                                      <span
                                        className={Styles["reseller-badge"]}
                                      >
                                        RESELLER
                                      </span>
                                    )}
                                  </div>
                                  {pub.reseller ? (
                                    <div className={Styles["reseller-text"]}>
                                      <span>Reseller: </span>
                                      {pub.reseller.name}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoginAsPublisher;
