import React from "react";
import { Query, withApollo, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";

import YbTooltip from "common/components/yb-tooltip";
import YbHighlight from "common/components/yb-highlight";

import OAuth from "../components/oauth";
import {
  mutation as adsenseMutation,
  OAuthConfig
} from "../query/adsense-account";

import UserStyles from "../../user/styles/signup.css";

class YbAddAdSenseBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      authorized: false
    };
  }

  render() {
    var modalClosed = this.state.modalClosed;
    var showAdSenseModal = this.props.showAdSenseModal;
    var networkId = this.props.networkId;
    var authorized = this.state.authorized;
    var callback = this.props.callback;

    return (
      <Mutation mutation={adsenseMutation}>
        {(addAdsenseAccount, { data, loading, called, error }) => {
          var mismatched = false;

          if (error) {
            if (error.message.match(/Error\:\s403/)) {
              error.message =
                "Error: Unable to retrieve AdSense information, please retry adding an AdSense account";
            }

            if (error.message.match(/Client\scode\smismatched/)) {
              error.message =
                "Error: Please retry adding the AdSense account with the correct Web property code";
            }
          }

          return (
            <OAuth
              {...OAuthConfig}
              authorizeNow={showAdSenseModal && !authorized}
              callback={() => {
                this.setState({
                  authorized: true
                });
              }}
              modal={{
                extraContent: mismatched ? (
                  <div
                    className={`${CommonStyle.textAlignLeft} ${
                      CommonStyle.textDanger
                    }`}
                  >
                    Client code mismatched
                  </div>
                ) : null,
                error: error,
                disabled: loading,
                title: i18n`NEW_ADSENSE_ACCOUNT`,
                fields: [
                  {
                    key: "name",
                    placeholder: `${i18n`EX_DOT`} ${i18n`ACCOUNT_DISPLAY_NAME_ELLIPSIS`}`,
                    title: i18n`DISPLAY_NAME`,
                    extra: (
                      <span>
                        <YbTooltip
                          big={true}
                          position="auto"
                          message={
                            <div>
                              <YbHighlight
                                text={i18n`On your Ad Manager Console, look up the Display name of your account under Admin > Linked accounts > AdSense`}
                                highlight={[
                                  i18n`Display name`,
                                  i18n`Admin`,
                                  i18n`Linked accounts`,
                                  i18n`AdSense`,
                                  i18n`Ad Manager Console`
                                ]}
                              />
                            </div>
                          }
                        >
                          <span
                            className={`${CommonStyle.buttonLinkLight} ${
                              UserStyles.infoTooltip
                            }`}
                          >
                            <i className="fa fa-info-circle" />
                          </span>
                        </YbTooltip>
                      </span>
                    )
                  },
                  {
                    key: "clientCode",
                    title: i18n`WEB_PROPERTY_CODE`,
                    placeholder: `${i18n`EX_DOT`} 12345678999999...`,
                    withPrefix: "ca-pub-",
                    type: "number",
                    extra: (
                      <span>
                        <YbTooltip
                          big={true}
                          position="auto"
                          message={
                            <div>
                              <YbHighlight
                                text={i18n`On your Ad Manager Console, look up the Web Property code of your account under Admin > Linked accounts > AdSense`}
                                highlight={[
                                  i18n`Web Property code`,
                                  i18n`Admin`,
                                  i18n`Linked accounts`,
                                  i18n`AdSense`,
                                  i18n`Ad Manager Console`
                                ]}
                              />
                            </div>
                          }
                        >
                          <span
                            className={`${CommonStyle.buttonLinkLight} ${
                              UserStyles.infoTooltip
                            }`}
                          >
                            <i className="fa fa-info-circle" />
                          </span>
                        </YbTooltip>
                      </span>
                    )
                  }
                ],
                closeCallback: () => {
                  var hideCallback = this.props.hideCallback;

                  if (hideCallback) {
                    hideCallback();
                  }

                  this.setState({
                    error: null,
                    authorized: false
                  });
                },
                onSubmit: ({ name, clientCode, code }) => {
                  return addAdsenseAccount({
                    variables: {
                      networkId,
                      clientCode: `pub-${clientCode}`,
                      name,
                      authorizationCode: code,
                      pubId: this.props.publisherId
                    }
                  }).then(({ data }) => {
                    if (callback) {
                      callback(data);
                    }
                  });
                }
              }}
            />
          );
        }}
      </Mutation>
    );
  }
}

module.exports = YbAddAdSenseBtn;
