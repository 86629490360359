import gql from "graphql-tag";

export const GET_ALL_COUNTRIES = gql`
  query {
    geoCountrys(first: 1000) {
      edges {
        node {
          countryId
          name
        }
      }
    }
  }
`;
