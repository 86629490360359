import React from 'react'
import { Query, Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'

import CommonStyle from 'common/styles/common.css'
import YbTooltip from 'common/components/yb-tooltip'
import YbLoading from 'common/components/yb-loading'
import YbIndicator from 'common/components/yb-indicator'

import Styles from '../styles/summary.css'
import YbYieldSet from '../components/yb-yield-set'
import YbStatus from '../components/yb-status'
import YbHider from '../components/yb-hider'
import YbInventoryItem from '../components/yb-inventory-item'
import { SYNC_AD_UNIT, TOGGLE_INVENTORY, NETWORK_PROBLEMS } from '../query/summary'

class YbAccountPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.state.yieldSetsRef = this.refs.yieldSets;
    this.state.adUnitsRef = this.refs.adUnits;
    this.state.networksRef = this.refs.networks;

    setTimeout(() => {this.forceUpdate();}, 0);
  }

  toggleNetwork() {

  }

  toggleYieldSet() {

  }

  renderAdUnits(network, yieldSet, adUnits) {
    var accountConfig = this.props.accountConfig;

    return adUnits.map((adUnit, adUnitIndex) => {
      return (
        <YbInventoryItem
          displayOnly={true}
          isPlacement={false}
          accountConfig={accountConfig}
          key={adUnitIndex}
          network={network}
          yieldSet={yieldSet}
          item={adUnit}
          itemIndex={adUnitIndex}
          wrapperClass={Styles.adUnits}
          />
      );
    });
  }

  render() {
    var account = this.props.account;
    var network = this.props.network;
    var yieldSet = this.props.yieldSet
    var adUnits = this.props.adUnits;
    var yieldSetsRef = this.state.yieldSetsRef;
    var adUnitsRef = this.state.adUnitsRef;
    var networksRef = this.state.networksRef;

    return (
      <div className={Styles.displayOnly}>
        <div className={`row ${CommonStyle.textAlignLeft} ${CommonStyle.textView}`}>
          <b>{i18n`Google Ad Manager Account: `}</b> {account.name}
        </div>
        <div
          className={Styles.network + ' ' + Styles.showYieldSets}
          >
          <div className={`row ${Styles.titleWrapper} ${CommonStyle.textAlignLeft}`}>
            <div className="col-md-12">
              {network.name}
            </div>
          </div>
          <div className={`${Styles.yieldSets}`} ref="yieldSets">
            <div
              className={Styles.yieldSet + ' ' + Styles.showContent + ' ' + Styles.showAdUnits}
              >
              <span className={Styles.connector}></span>
              <div className={`row ${Styles.titleWrapper} ${CommonStyle.textAlignLeft}`}>
                <div className="col-md-12">
                  {yieldSet.name}
                </div>
              </div>
              <div className={`${Styles.content}`}>
                <div ref="adUnits">
                  <div className={`row ${Styles.titleWrapper} ${Styles.inventoryLabel} ${CommonStyle.textAlignLeft}`}>
                    <span className={Styles.connector}></span>
                    <div className="col-md-12">
                      <span>{i18n`AD_UNITS`}</span>
                    </div>
                  </div>
                  {this.renderAdUnits(network, yieldSet, adUnits)}
                </div>
              </div>
              <YbHider direct={true} collectionRef={adUnitsRef} lineHeight={17}/>
            </div>
          </div>
          <YbHider collectionRef={yieldSetsRef} lineHeight={31}/>
        </div>
      </div>
    );
  }
};

module.exports = YbAccountPreview;
