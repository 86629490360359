import React from "react";
import {
  Container,
  Card,
  Form,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "react-bootstrap";

import EmbeddedNewebpayForm from "./embedded-newebpay-form";

class CreditCardInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newProfile: {
        payerEmail: ""
      },
      validated: false,
      shouldRedirectToNewebpay: false,
      // props
      activeProfile: this.props.activeProfile,
      profileOptions: this.props.profileOptions,
      thirdPartyRedirectPath: this.props.thirdPartyRedirectPath,
      setActiveProfileId: this.props.setActiveProfileId

      // defaultProfileId: this.props.defaultProfileId,
      // selectedProfileId: ""
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAddNewCard = this.handleAddNewCard.bind(this);
    this.handleCreditCardChange = this.handleCreditCardChange.bind(this);
    this.handleSetActiveProfileId = this.handleSetActiveProfileId.bind(this);
  }

  handleEmailChange(e) {
    this.setState({
      newProfile: {
        payerEmail: e.target.value
      }
    });
  }

  handleAddNewCard(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // pass html validation
      this.setState({ shouldRedirectToNewebpay: true });
    }
    this.setState({ validated: true });
  }

  handleCreditCardChange(selectedOption) {
    console.log(`Option selected:`, selectedOption);
    this.setState({
      defaultProfileId: selectedOption
    });
  }

  handleSetActiveProfileId(profileId) {
    this.state.setActiveProfileId(profileId);
  }

  // There are 3 Cases:
  // 1. Has a activeProfile
  // 2. Has one or more profileOptions
  // 3. Has no activeProfile and no profileOptions
  render() {
    const { activeProfile, profileOptions } = this.state;
    let cardInfoJsx = null;

    if (activeProfile) {
      const profile = activeProfile.profile;
      const creditCardNumber = `${profile.card6No.substr(
        0,
        4
      )}-${profile.card6No.substr(4, 2)}**-****-${profile.card4No}`;

      cardInfoJsx = (
        <div>
          <Form.Label>Credit Card</Form.Label>
          <Row>
            <Col lg="10">
              <ListGroup className="list-group">
                <ListGroupItem>
                  <Container>
                    <Row>
                      <Col sm="2">
                        <i
                          className="fa fa-lg fa-credit-card text-secondary"
                          style={{ paddingTop: "16px" }}
                        />
                      </Col>
                      <Col sm="9">
                        <Card.Title style={{ letterSpacing: "0.06em" }}>
                          {creditCardNumber}
                        </Card.Title>
                        <Card.Subtitle className="text-muted">
                          <span>{profile.payerEmail}</span>
                        </Card.Subtitle>
                      </Col>
                      <Col sm="1">
                        <i
                          className="fa fa-lg fa-check text-success"
                          style={{ paddingTop: "16px" }}
                        />
                      </Col>
                    </Row>
                  </Container>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </div>
      );
    } else if (profileOptions && profileOptions.length > 1) {
      // TODO: should not be possible for a user to have multiple cards for now
      cardInfoJsx = (
        <div>
          we do not support this yet
          {/* <Form.Group controlId="creditCard">
            <Form.Label>Choose a credit card</Form.Label>

            <ListGroup className="list-group">
              {profileOptions.map(profile => {
                let creditCardNumber = `${profile.profile.card6No.substr(
                  0,
                  4
                )}-${profile.profile.card6No.substr(4, 2)}**-****-${
                  profile.profile.card4No
                }`;

                return (
                  <ListGroupItem
                    action
                    key={profile.profileId}
                    onClick={() =>
                      this.handleCreditCardChange(profile.profileId)
                    }
                    value={profile.profileId}
                    required
                    style={{
                      border:
                        this.state.defaultProfileId === profile.profileId
                          ? "1.5px solid #29A645"
                          : ""
                    }}
                  >
                    <Container>
                      <Row>
                        <Col sm="2">
                          <i
                            className="fa fa-lg fa-credit-card text-secondary"
                            style={{ paddingTop: "16px" }}
                          />
                        </Col>
                        <Col sm="9">
                          <Card.Title style={{ letterSpacing: "0.06em" }}>
                            {creditCardNumber}
                          </Card.Title>
                          <Card.Subtitle className="text-muted">
                            <span>{profile.profile.payerEmail}</span>
                          </Card.Subtitle>
                        </Col>
                        <Col sm="1">
                          {this.state.defaultProfileId ===
                            profile.profileId && (
                            <i
                              className="fa fa-lg fa-check text-success"
                              style={{ paddingTop: "16px" }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </Form.Group>
          <div className="d-flex justify-content-center">
            <Button
              type="button"
              variant="primary"
              disabled={!this.state.defaultProfileId}
              onClick={() =>
                this.handleSetActiveProfileId(this.state.defaultProfileId)
              }
            >
              Use this card
            </Button>
          </div> */}
        </div>
      );
    } else {
      cardInfoJsx = (
        <div>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleAddNewCard}
          >
            <Form.Group controlId="payerEmail">
              <Form.Label>Email</Form.Label>
              <Row>
                <Col lg="7">
                  <Form.Control
                    type="text"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}"
                    value={this.state.newProfile.payerEmail}
                    onChange={this.handleEmailChange}
                    required
                  />
                  <Form.Text className="text-muted">
                    This is the email to receive your credit card payment
                    notifications.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <div>
              <div>
                <Button variant="outline-primary" type="submit" size="lg">
                  <i className="fa fa-credit-card fa-fw" />
                  &nbsp; Add a new card
                </Button>
                <Form.Text className="text-muted">
                  We will redirect you to our payment partner (
                  <a href="https://www.newebpay.com/" target="_blank">
                    Newebpay
                  </a>
                  ) to insert your credit card info.
                </Form.Text>
              </div>
            </div>
          </Form>
          <div style={{ height: "20px" }}>
            {this.state.shouldRedirectToNewebpay && (
              <div>
                <EmbeddedNewebpayForm
                  thirdPartyRedirectPath={this.state.thirdPartyRedirectPath}
                  payerEmail={this.state.newProfile.payerEmail}
                />
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        {cardInfoJsx}
        <br />
      </div>
    );
  }
}

module.exports = CreditCardInfo;
