import React from "react";
import _ from "lodash";
import { Query } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import YbLoadingView from "common/components/yb-loading-view";
import FormStyle from "common/styles/forms.css";
import YbTooltip from "common/components/yb-tooltip";

import ReqStatStyle from "common/styles/req-stat-tooltip.css";

import { GET_UNIT_CANDIDATES } from "../query/queries";
import AdUnitsOnboardHandler from "./ad-units-onboard-handler-latest";
import { formatCandidates } from "../helpers/ad-unit-candidate-formatter-latest";

class RootLayerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reloadExternalSource: false,
    };

    this.onSyncWithGAM = this.onSyncWithGAM.bind(this);
  }

  onSyncWithGAM() {
    this.setState({
      reloadExternalSource: true,
    });
  }

  render() {
    const {
      yieldSetId,
      gamNetworkId,
      publisherId,

      wizard, // wizard action buttons
    } = this.props;

    const queryVariables = {
      filter: {
        parentAdUnitIds: ["root"],
        gamNetworkId: gamNetworkId,
        reload: this.state.reloadExternalSource,
      },
      pubId: publisherId,
    };

    return (
      <React.Fragment>
        <div>
          <div
            className={FormStyle.label}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              {i18n`Ad Units`} <span className={FormStyle.requiredStar}>*</span>{" "}
              <YbTooltip
                message={i18n`SYNCHRONIZE_YOUR_INVENTORY_WITH_GOOGLE_AD_MANAGER`}
              >
                <button
                  className={CommonStyle.button}
                  onClick={this.onSyncWithGAM}
                >
                  <i className="fa fa-refresh" />
                </button>
              </YbTooltip>
            </div>
            <div style={{ display: "flex", gap: "24px" }}>
              <div style={{ fontSize: "12px", color: "#595959" }}>
                <div style={{ fontWeight: "600" }}>Daily Avg Reqeusts</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    className={ReqStatStyle.stat}
                    style={{ marginRight: "8px" }}
                  >
                    <div className={ReqStatStyle.gptIndicator}></div>
                    GPT
                  </div>

                  <div className={ReqStatStyle.stat}>
                    <div className={ReqStatStyle.ampIndicator}></div>
                    AMP
                  </div>
                </div>
              </div>
              <div style={{ fontSize: "12px", color: "#595959" }}>
                <div style={{ fontWeight: "600" }}>MCM Manage Inventory</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <span
                    className="rounded"
                    style={{
                      backgroundColor: "#BFDBFE",
                      color: "#1E40AF",
                      padding: "2px 4px",
                      fontSize: "11px",
                      fontWeight: "400",
                      textDecoration: "none",
                    }}
                  >
                    MCM
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Query
            query={GET_UNIT_CANDIDATES}
            variables={queryVariables}
            fetchPolicy={"network-only"}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <YbLoadingView />;
              }

              if (error) {
                console.log(error);
                return (
                  <div style={{ color: "red" }}>
                    Error Loading Ad Units...
                    <div style={{ fontSize: "14px" }}>
                      {error.message && JSON.stringify(error.message, null, 4)}
                    </div>
                  </div>
                );
              }

              if (data) {
                if (data.extItems.length > 0) {
                  const candidates = formatCandidates(
                    _.cloneDeep(data.extItems)
                  );
                  return (
                    <AdUnitsOnboardHandler
                      yieldSetId={yieldSetId}
                      gamNetworkId={gamNetworkId}
                      publisherId={publisherId}
                      rootLayerCandidates={candidates}
                      wizard={wizard}
                    ></AdUnitsOnboardHandler>
                  );
                } else {
                  return "No ad units to onboard.";
                }
              }

              return "";
            }}
          </Query>
        </div>
      </React.Fragment>
    );
  }
}

module.exports = RootLayerWrapper;
