import React from "react";
import { Query } from "react-apollo";

import { Redirect } from "react-router-dom";
import YbLoadingView from "common/components/yb-loading-view";

import NewebpayForm from "./newebpay-form";
import { GET_NEWEBPAY_FORM } from "../query/subscription-and-billing";

class EmbeddedNewebpayForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thirdPartyRedirectPath: this.props.thirdPartyRedirectPath,
      redirectUrl: `${window.location.origin}/#${
        this.props.thirdPartyRedirectPath
      }`
    };
  }

  render() {
    var variables = {
      filter: {
        payerEmail: this.props.payerEmail,
        redirectUrl: this.state.redirectUrl
      }
    };

    return (
      <div>
        <Query
          query={GET_NEWEBPAY_FORM}
          variables={variables}
          fetchPolicy={"network-only"}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <YbLoadingView />;
            }

            if (error) {
              console.log(error);
              return <Redirect to={this.state.thirdPartyRedirectPath} />;
            }

            const formdata = data.newebPayProfile.form;
            return (
              <div>
                <NewebpayForm formdata={formdata} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

module.exports = EmbeddedNewebpayForm;
