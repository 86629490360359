export class CacheHelper {
  constructor (client, cacheParams, key, typenames) {
    this.client = client
    this.cacheParams = cacheParams
    this.key = key
    this.typenames = typenames
  }

  afterFetchMore (prev, { fetchMoreResult }) {
    if (!fetchMoreResult) {
      return prev
    }

    const { key, typenames } = this

    return {
      [key]: {
        pageInfo: fetchMoreResult[key].pageInfo,
        edges: [...prev[key].edges, ...fetchMoreResult[key].edges],
        __typename: typenames.connection
      }
    }
  }

  afterMutation ({ data }) {
    const { client, key, typenames, cacheParams }  = this

    const cached = client.readQuery(cacheParams)[key]
    const idx = cached.edges.findIndex(function ({ node }) {
      return node.id === data.id
    })

    let updated = true
    if (idx !== -1) {
      cached.edges[idx].node = data
    } else if (!cached.pageInfo.hasNextPage) {
      cached.pageInfo.endCursor = data.id
      cached.edges = [...cached.edges, {
        node: data,
        __typename: typenames.edge
      }]
    } else {
      updated = false
    }

    if (updated) {
      client.writeQuery({
        ...cacheParams,
        data: { [key]: cached }
      })
    }
  }
}
