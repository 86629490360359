import React from "react";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { Query, Mutation } from "react-apollo";

import FormStyle from "common/styles/forms.css";
import CommonStyle from "common/styles/common.css";
import ItemStyle from "common/styles/inventory-item.css";
import YbLoading from "common/components/yb-loading";
import YbTooltip from "common/components/yb-tooltip";
import YbLoadingView from "common/components/yb-loading-view";
import Constants from "common/constants/constants";

import YbTreeSelectorV2 from "common/components/yb-tree-selector-v2";
import YbReqStatTooltip from "../components/yb-req-stat-tooltip";
import YbReqStatDiagram from "../components/yb-req-stat-diagram";

import Style from "../styles/ad-units";

import {
  GET_YIELD_SET,
  GET_UNIT_CANDIDATES,
  CREATE_AD_UNIT,
} from "../query/ad-units-v2";
import { UserInfoContext } from "../../_newapp/common/contexts/contexts";

/**
 *  /ad-units/create/:yieldSetId
 *  /inventory/create/:yieldSetId
 */
// const overviewRoute = "/summary/overview";
const minFloorPrice = 0.0;
const maxFloorPrice = 99999999.99;

// V2:
// 1. Remove placements
// 2. Remove never before seen features... (unitType, platformType)
// 3. Remove ad unit edit!
// 4. Remove account config query (already got that with user info context)

class AdUnitFormV2 extends React.Component {
  constructor(props) {
    super(props);
    this.adUnitChanged = this.adUnitChanged.bind(this);
    this.removeAdUnits = this.removeAdUnits.bind(this);
    this.submitClick = this.submitClick.bind(this);
    this.backToSummary = this.backToSummary.bind(this);
    this.reloadExternalSource = this.reloadExternalSource.bind(this);

    const pubId = _.get(this.props, "match.params.publisherId");

    let overviewRoute = "/summary/overview";
    if (pubId > 0) {
      overviewRoute = `/publisher/${pubId}/summary/overview`;
    }

    this.state = {
      yieldSet: "",
      savedAdUnit: "",
      item: null,
      candidateIndex: "",
      candidates: [],
      treeSelection: [],
      submitted: false,
      adxFloorPrice: minFloorPrice.toFixed(2),
      adSenseFloorPrice: minFloorPrice.toFixed(2),
      reloadExternalSource: false,
      publisherId: _.parseInt(pubId),
      overviewRoute: overviewRoute,
    };
  }

  backToSummary() {
    this.props.history.goBack();
  }

  filterAlreadyUsed(data) {
    var alreadyUsed = data.items.edges.map((item) => {
      return item.node.extId;
    });

    var result = data.extItems.map((item) => {
      item.isOnboarded = alreadyUsed.indexOf(item.extId) !== -1;
      return item;
    });

    return result;
  }

  adxFloorPriceChanged(event) {
    this.setState({
      adxFloorPrice: event.target.value,
    });
  }

  onBlur(isAdx, event) {
    var prop = isAdx ? "adxFloorPrice" : "adSenseFloorPrice";
    var state = {};

    state[prop] = parseFloat(event.target.value);

    if (state[prop] < minFloorPrice || !state[prop]) {
      state[prop] = minFloorPrice;
    }

    if (state[prop] > maxFloorPrice) {
      state[prop] = maxFloorPrice;
    }

    state[prop] = state[prop].toFixed(2);
    this.setState(state);
  }

  submitClick() {
    if (!this.state.submitted) {
      this.setState({
        submitted: true,
      });
    }
  }

  adUnitChanged(item) {
    var items = this.state.item;

    if (!Array.isArray(item)) {
      item = [item];
    }

    if (Array.isArray(items)) {
      items.push(...item);
    } else if (items) {
      items = [items, ...item];
    } else {
      items = item;
    }

    this.setState({
      item: items,
    });
  }

  removeAdUnits(itemsToRemove) {
    var items = this.state.item;

    if (!Array.isArray(itemsToRemove)) {
      itemsToRemove = [itemsToRemove];
    }

    if (Array.isArray(items)) {
      itemsToRemove.forEach((itemToRemove) => {
        items.splice(items.indexOf(itemToRemove), 1);
      });
    } else {
      items = [];
    }

    this.setState({
      item: items,
    });
  }

  renderItemComponent(item, isSelected) {
    let unitItemClass = ItemStyle.unitItem;

    if (item.isOnboarded && !item.hasChildren) {
      unitItemClass = ItemStyle.unitItemOnboarded;
    } else if (!item.isCompatible && !item.hasChildren) {
      unitItemClass = ItemStyle.unitItemNotCompatible;
    }

    let checkboxClass = ItemStyle.checkbox;
    if (item.isOnboarded) {
      checkboxClass += " " + ItemStyle.isOnboarded;
    } else if (isSelected) {
      checkboxClass += " " + ItemStyle.selected;
    }

    let checkboxElement = "";
    if (item.isOnboarded && !item.hasChildren) {
      checkboxElement = (
        <YbTooltip message="Already onboarded" position="right">
          <button type="button" className={checkboxClass}>
            <i className="fa fa-check"></i>
          </button>
        </YbTooltip>
      );
    } else if (!item.hasChildren && item.isCompatible) {
      checkboxElement = (
        <button type="button" className={checkboxClass}>
          <i className="fa fa-check"></i>
        </button>
      );
    }

    return (
      <div className={unitItemClass}>
        <div className={ItemStyle.checkboxWrapper}>{checkboxElement}</div>

        <div className={ItemStyle.itemName} title={item.extId}>
          {item.name}
        </div>

        <div className={ItemStyle.itemStat}>
          <YbReqStatTooltip
            item={item}
            tooltipKey={item.extId + "-req-tooltip"}
          >
            <YbReqStatDiagram item={item}></YbReqStatDiagram>
          </YbReqStatTooltip>
        </div>

        <div className={ItemStyle.arrowWrapper}>
          {item.hasChildren && (
            <i className={`fa fa-angle-right ${Style.branchIcon}`}></i>
          )}
        </div>
      </div>
    );
  }

  formatCandidates(candidates) {
    // const REQ_TYPE = {
    //   0: "Unknown",
    //   1: "GPT",
    //   2: "AMP",
    //   3: "GPT Lite",
    //   4: "APP",
    //   5: "Video",
    // };

    for (const c in candidates) {
      const item = candidates[c];
      // Important! portfolio schema is fixed!
      let reqStatPortfolio = item.portfolio[0];
      let gptReq = 0;
      let ampReq = 0;
      let othersReq = 0;
      let dailyCompatibleAvgReq = 0;
      let dailyTotalAvg = 0;
      for (let i = 0; i < reqStatPortfolio.length; ++i) {
        let reqPortfolio = reqStatPortfolio[i];
        let reqType = reqPortfolio[0];
        let isCompatible = reqPortfolio[1];
        let dailyReq = reqPortfolio[2];

        dailyTotalAvg += dailyReq;
        if (isCompatible) {
          dailyCompatibleAvgReq += dailyReq;
        }

        if (reqType === 1) {
          gptReq += dailyReq;
        } else if (reqType === 2) {
          ampReq += dailyReq;
        } else {
          othersReq += dailyReq;
        }
      }
      let gptReqPercentage = (gptReq / dailyCompatibleAvgReq) * 100;
      let ampReqPercentage = (ampReq / dailyCompatibleAvgReq) * 100;
      let othersReqPercentage = (othersReq / dailyCompatibleAvgReq) * 100;

      // if (!item.isCompatible) {
      //   gptReqPercentage = 0;
      //   ampReqPercentage = 0;
      //   dailyCompatibleAvgReq = 0;
      // }

      item.gptReqPercentage = gptReqPercentage;
      item.ampReqPercentage = ampReqPercentage;
      item.dailyCompatibleAvgReq = dailyCompatibleAvgReq;

      // used in tooltip
      item.othersReqPercentage = othersReqPercentage;
      item.dailyTotalAvg = dailyTotalAvg;
      item.gptReq = gptReq;
      item.ampReq = ampReq;
      item.othersReq = othersReq;
    }

    // Sort candidates!
    // has children first
    // isCompatible
    // by daily avg req
    // alphabetical
    candidates = _.orderBy(
      candidates,
      ["hasChildren", "isCompatible", "dailyCompatibleAvgReq"],
      ["desc", "desc", "desc"]
    );

    return candidates;
  }

  renderAdUnitCandidates() {
    var candidates = this.state.candidates;
    var required = <span />;
    var submitted = this.state.submitted;
    var invalid = false;

    invalid = !this.state.item || !this.state.item.length;

    if (invalid) {
      required = <input className={FormStyle.hiddenInput} required />;
    }

    candidates = this.formatCandidates(candidates);

    return (
      <div>
        <YbTreeSelectorV2
          items={[candidates]}
          value={this.state.treeSelection}
          selectedItems={this.state.item}
          onSelect={this.adUnitChanged}
          onRemove={this.removeAdUnits}
          id="extId"
          invalid={submitted && invalid}
          renderItemComponent={this.renderItemComponent}
        >
          {({ renderItems, drillTo }) => {
            var variables = {
              filter: {
                // Important! use id instead of parentPath to search child units to improve performance
                parentAdUnitIds: [drillTo.extId],
                gamNetworkId: this.state.yieldSet.gamNetwork.networkId,
                reload: this.state.reloadExternalSource,
              },
              pubId: this.state.publisherId,
            };

            return (
              <Query
                query={GET_UNIT_CANDIDATES}
                variables={variables}
                fetchPolicy={"network-only"}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <div className={CommonStyle.textAlignCenter}>
                        <YbLoading />
                      </div>
                    );
                  }

                  if (error) {
                    return (
                      <span className={CommonStyle.textDanger}>
                        Error Loading Ad Units...
                      </span>
                    );
                  }

                  var items = this.filterAlreadyUsed(data);
                  items = this.formatCandidates(items);

                  return renderItems(items);
                }}
              </Query>
            );
          }}
        </YbTreeSelectorV2>
        {required}
      </div>
    );
  }

  renderAdxFloorPrice(accountConfig) {
    var yieldSet = this.state.yieldSet;
    var currency = yieldSet.gamNetwork.currency;
    currency = Constants.currencies[currency] || currency || "$";

    if (
      !yieldSet.adxAccount ||
      !accountConfig.gamNetwork.adx.floorPriceSetting.enabled
    ) {
      return <span />;
    }

    return (
      <div className={FormStyle.row}>
        <div className={FormStyle.label}>{i18n`ADX_FLOOR_PRICE`}</div>
        <div className={FormStyle.currencyWrapper}>
          <span className={FormStyle.currency}>{currency}</span>
          <input
            required
            type="number"
            value={this.state.adxFloorPrice}
            onChange={this.adxFloorPriceChanged.bind(this)}
            onBlur={this.onBlur.bind(this, true)}
            step="0.01"
            min={minFloorPrice}
            max={maxFloorPrice}
          />
        </div>
      </div>
    );
  }

  reloadExternalSource(event) {
    this.setState({
      reloadExternalSource: true,
      item: null,
      treeSelection: [],
    });

    event.preventDefault();
  }

  renderFormContent(accountConfig) {
    var formClass = FormStyle.form;

    if (this.state.submitted) {
      formClass = `${FormStyle.submitted} ${FormStyle.form}`;
    }

    return (
      <Mutation mutation={CREATE_AD_UNIT}>
        {(saveInventory, { loading, error, data }) => {
          var submitBtn = null;
          var submitLabel = (
            <span>
              <i className="fa fa-plus" /> {i18n`CREATE`}
            </span>
          );

          submitBtn = (
            <button
              className={FormStyle.submitBtn}
              type="submit"
              onClick={this.submitClick}
            >
              {submitLabel}
            </button>
          );

          if (loading) {
            submitBtn = (
              <span className={FormStyle.submitLoading}>
                <YbLoading />
              </span>
            );
          }

          if (error) {
            submitBtn = (
              <span className={FormStyle.submitLoading}>
                <span className="text-danger">
                  <span>{i18n`ERROR_CREATING_AD_UNIT_ELLIPSIS`}</span>
                </span>
              </span>
            );
          }

          if (data) {
            return <Redirect to={this.state.overviewRoute} />;
          }

          return (
            <div>
              <form
                onKeyPress={(event) => {
                  if (event.key === "Enter" && event.target.type !== "submit") {
                    event.preventDefault();
                  }
                }}
                onSubmit={(event) => {
                  var adxFloorPrice = parseFloat(this.state.adxFloorPrice);
                  var adSenseFloorPrice = parseFloat(
                    this.state.adSenseFloorPrice
                  );
                  var item = this.state.item;
                  var items = item;
                  var yieldSet = this.state.yieldSet;
                  var input = {};
                  var params = {
                    variables: {
                      input: input,
                      pubId: this.state.publisherId,
                    },
                  };

                  input.yieldSetId = yieldSet.yieldSetId;

                  if (!Array.isArray(items)) {
                    items = [items];
                  }

                  input.gamAdUnits = items.map((item) => ({
                    extGamAdUnitId: item.extId,
                    adUnitCode: item.code,
                    name: item.name,
                    parentPath: item.parentPath,
                    adSizeIds: item.adSizes.map((size) => size.sizeId),
                    adxFloorPrice: adxFloorPrice,
                    adSenseFloorPrice: adSenseFloorPrice,
                  }));

                  saveInventory(params);
                  event.preventDefault();
                }}
                className={formClass}
              >
                <div className={FormStyle.row}>
                  <div className={FormStyle.label}>
                    {i18n`AD_UNITS`}{" "}
                    <span className={FormStyle.requiredStar}>*</span>
                    <YbTooltip
                      message={i18n`SYNCHRONIZE_YOUR_INVENTORY_WITH_GOOGLE_AD_MANAGER`}
                    >
                      <button
                        className={CommonStyle.button}
                        onClick={this.reloadExternalSource}
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </YbTooltip>
                  </div>
                  {this.renderAdUnitCandidates()}
                </div>
                {this.renderSelectedItems()}
                {this.renderAdxFloorPrice(accountConfig)}
                <div>{submitBtn}</div>
              </form>

              {!loading && (
                <button
                  className={FormStyle.cancelBtn}
                  onClick={this.backToSummary}
                >
                  <i className="fa fa-times" /> {i18n`CANCEL`}
                </button>
              )}
            </div>
          );
        }}
      </Mutation>
    );
  }

  onRemoveClick(item, event) {
    this.removeAdUnits(item);
    event.preventDefault();
  }

  onRemoveAll(event) {
    this.setState({
      item: [],
    });
    event.preventDefault();
  }

  renderSelectedItems() {
    var selectedItems = this.state.item || [];

    if (!Array.isArray(selectedItems)) {
      selectedItems = [selectedItems];
    }

    selectedItems = selectedItems.filter((item) => item);

    if (!selectedItems.length) {
      return <span />;
    }

    // Sort candidates!
    selectedItems = _.orderBy(
      selectedItems,
      ["dailyCompatibleAvgReq"],
      ["desc"]
    );

    return (
      <div className={FormStyle.row}>
        <div className={FormStyle.label}>
          {i18n`SELECTED_AD_UNITS`} ({selectedItems.length})
          <button
            className={CommonStyle.buttonLink}
            onClick={this.onRemoveAll.bind(this)}
          >
            <i className="fa fa-times" /> {i18n`REMOVE_ALL`}
          </button>
        </div>

        <div className={Style.selectedAdUnits}>
          {selectedItems
            .map((item) => {
              item.fullName = `${
                item.parentPath && item.parentPath !== "/"
                  ? item.parentPath
                  : ""
              }/${item.name.replace("/", '"#"')}`;
              return item;
            })
            .map((item, index) => {
              return (
                <div className={ItemStyle.selectedRow} key={index}>
                  <div className={ItemStyle.selectedRowStart}>
                    <button
                      className={CommonStyle.buttonLink}
                      onClick={this.onRemoveClick.bind(this, item)}
                    >
                      <i className="fa fa-times" />
                    </button>

                    <div className={ItemStyle.unitName}>{item.fullName}</div>
                  </div>

                  <div className={ItemStyle.itemStat}>
                    <YbReqStatTooltip
                      item={item}
                      tooltipKey={item.extId + "-selected-tooltip"}
                    >
                      <YbReqStatDiagram item={item}></YbReqStatDiagram>
                    </YbReqStatTooltip>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  renderForm(accountConfig) {
    var variables = {
      filter: {
        gamNetworkId: this.state.yieldSet.gamNetwork.networkId,
      },
      pubId: this.state.publisherId,
    };

    variables.filter.reload = this.state.reloadExternalSource;

    // variables.filter.parentPath = "/";
    // Important! use "root" instead of "/" to improve performance
    variables.filter.parentAdUnitIds = ["root"];

    return (
      <div>
        <div className={CommonStyle.pageTitle}>
          {i18n`NEW`} {i18n`AD_UNIT`} {i18n`FOR`} {this.state.yieldSet.name}
        </div>

        <Query
          query={GET_UNIT_CANDIDATES}
          variables={variables}
          fetchPolicy={"network-only"}
        >
          {({ loading, error, data }) => {
            var candidates = this.state.candidates;

            if (loading) {
              return <YbLoadingView />;
            }

            if (error) {
              return (
                <div className={`text-danger ${CommonStyle.textView}`}>
                  <input className={FormStyle.hiddenInput} required />
                  {i18n`ERROR_LOADING_AD_UNIT_CANDIDATES_ELLIPSIS`}
                </div>
              );
            }

            if (this.state.reloadExternalSource) {
              setTimeout(() => {
                this.setState({
                  reloadExternalSource: false,
                });
              });
            }

            while (candidates.length) {
              candidates.pop();
            }

            this.filterAlreadyUsed(data).forEach((item) => {
              candidates.push(item);
            });

            return this.renderFormContent(accountConfig);
          }}
        </Query>
      </div>
    );
  }

  renderYieldSet(yieldSetId, accountConfig) {
    return (
      <Query
        query={GET_YIELD_SET}
        variables={{ pubId: this.state.publisherId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }
          if (error) {
            return <Redirect to={this.state.overviewRoute} />;
          }

          this.state.yieldSet = data.yieldSets.edges.find((yieldSet) => {
            return yieldSet.node.yieldSetId === yieldSetId;
          });

          if (!this.state.yieldSet) {
            return `YieldSet ID ${yieldSetId} does not exist.`;
          }

          this.state.yieldSet = this.state.yieldSet.node;
          return this.renderForm(accountConfig);
        }}
      </Query>
    );
  }

  render() {
    return (
      <UserInfoContext.Consumer>
        {({ userInfo }) => {
          if (userInfo) {
            const accountConfig = userInfo.publisher.config.resourceConfig;
            var yieldSetId = parseInt(this.props.match.params.yieldSetId);

            return (
              <div className={CommonStyle.wrapper}>
                {this.renderYieldSet(yieldSetId, accountConfig)}
              </div>
            );
          }

          return "";
        }}
      </UserInfoContext.Consumer>
    );
  }
}

module.exports = AdUnitFormV2;
