import React from "react";

import CommonStyle from "common/styles/common.css";

import YbTooltip from "common/components/yb-tooltip";

import Styles from "../styles/wizard.css";

class YbTracker extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(step) {
    this.props.setStep(step);
  }

  render() {
    var currentStep = this.props.currentStep;
    var stepData = this.props.stepData;
    var showNextStep = this.props.showNextStep;
    var stepDescription = this.props.stepDescription;
    var crossedStep4 = this.props.crossedStep4;
    // var steps = [1, 2, 3, 4, 5, 6];
    var steps = [1, 2, 3, 4, 5];
    var step1 = stepData[1];
    var step2 = stepData[2];
    var step3 = stepData[3];

    return (
      <div>
        <div className={CommonStyle.textAlignCenter}>
          {step1.selectedAccount && (
            <span className={CommonStyle.highlightedText}>
              {step1.selectedAccount.name}
            </span>
          )}
          {step2.selectedNetwork && (
            <span>
              {" "}
              <i className="fa fa-angle-right"></i>{" "}
              <span className={CommonStyle.highlightedText}>
                {step2.selectedNetwork.name}
              </span>
            </span>
          )}
          {step3.selectedYieldSet && (
            <span>
              {" "}
              <i className="fa fa-angle-right"></i>{" "}
              <span className={CommonStyle.highlightedText}>
                {step3.selectedYieldSet.name}
              </span>
            </span>
          )}
        </div>
        <br />
        <div className={Styles.trackerWrapper}>
          {steps.map((step, index) => {
            return (
              <span
                key={index}
                className={`${
                  showNextStep(step) ? Styles.complete : Styles.incomplete
                } ${currentStep === step ? Styles.current : ""} ${
                  crossedStep4 && step < 5 ? Styles.crossedStep4 : ""
                }
                ${currentStep === 5 && step === 4 && Styles.complete}`}
              >
                <YbTooltip message={stepDescription(step)} position="auto">
                  <span
                    className={`${Styles.stepTracker}`}
                    onClick={this.onClick.bind(this, step)}
                  >
                    {step}
                  </span>
                </YbTooltip>
                {index !== steps.length - 1 && (
                  <span className={Styles.stepLiner}></span>
                )}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

module.exports = YbTracker;
