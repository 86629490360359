import gql from "graphql-tag";

export const GET_NETWORK = gql`
  query($pubId: Int) {
    gamNetworks(first: 1000, assumePubId: $pubId) {
      edges {
        node {
          id
          networkId
          name
        }
      }
    }
  }
`;

export const GET_YIELD_SET = gql`
  query getYieldSets($filter: yieldSetsFilter!, $pubId: Int) {
    yieldSets(filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          yieldSetId
          name
          gamNetwork {
            networkId
          }
          adxAccount {
            id
            adxAccountId
            clientCode
            name
          }
          adsenseAccount {
            accountId
            id
          }
        }
      }
    }
  }
`;

export const GET_AD_SENSE_ACCOUNTS = gql`
  query($filter: adsenseAccountsFilter!, $pubId: Int) {
    adsenseAccounts(first: 1000, filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          name
          clientCode
          accountId
        }
      }
    }
  }
`;

export const GET_ADX_ACCOUNTS = gql`
  query($filter: adxAccountsFilter!, $pubId: Int) {
    adxAccounts(first: 1000, filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          name
          clientCode
          adxAccountId
        }
      }
    }
  }
`;

export const CREATE_YIELD_SET = gql`
  mutation CreateYieldSet($input: createYieldSetInput!, $pubId: Int) {
    createYieldSet(input: $input, assumePubId: $pubId) {
      yieldSet {
        id
        yieldSetId
        name
      }
    }
  }
`;

export const SAVE_YIELD_SET = gql`
  mutation updateYieldSet($input: updateYieldSetInput!, $pubId: Int) {
    updateYieldSet(input: $input, assumePubId: $pubId) {
      yieldSet {
        id
      }
    }
  }
`;
