import React from "react";
import _ from "lodash";

import ItemStyle from "common/styles/inventory-item.css";
import YbTooltip from "common/components/yb-tooltip";

import YbReqStatWrapper from "../../../ad-units/components/yb-req-stat-wrapper";
import MCMLabel from "./mcm-label";
import ParentUnitLabel from "./parent-unit-label";

function BatchOnboardItemComponent(props) {
  const { item, onSelectUnit } = props;

  let unitItemClass = ItemStyle.unitItem;
  let checkboxStatus = item.checkboxStatus;
  let checkboxClass = ItemStyle.checkbox;
  let checkboxElement = "";

  const isSelectable = item.isCompatible && !item.isOnboarded;
  if (isSelectable) {
    if (checkboxStatus === "SELECTED") {
      checkboxClass += " " + ItemStyle.selected;
    }

    checkboxElement = (
      <button
        type="button"
        className={checkboxClass}
        onClick={(event) => {
          onSelectUnit(item);
          event.stopPropagation();
        }}
      >
        <i className="fa fa-check"></i>
      </button>
    );
  } else {
    // Already onboarded
    if (item.isOnboarded) {
      unitItemClass += " " + ItemStyle.onboarded;
      checkboxClass += " " + ItemStyle.isOnboarded;
      checkboxElement = (
        <YbTooltip message="Already onboarded" position="right">
          <button type="button" className={checkboxClass}>
            <i className="fa fa-check"></i>
          </button>
        </YbTooltip>
      );
    }
    // Not Compatible
    else if (!item.isCompatible) {
      unitItemClass += " " + ItemStyle.notCompatible;
    }
  }

  if (item.queryError) {
    unitItemClass = ItemStyle.unitItemHasError;
    checkboxElement = (
      <YbTooltip message={item.queryError.message} position="right">
        <span style={{ color: "red" }}>
          <i className="fa fa-exclamation-triangle"></i>
        </span>
      </YbTooltip>
    );
  }

  return (
    <div className={unitItemClass}>
      <div className={ItemStyle.checkboxWrapper}>{checkboxElement}</div>

      <div className={ItemStyle.clickableArea}>
        <div className={ItemStyle.itemName}>
          <div
            style={{
              color: "#718096",
              fontSize: "0.8rem",
              lineHeight: 1,
            }}
          >
            {item.extId}
          </div>
          {item.invalidLabel && (
            <span
              style={{
                padding: "2px 4px",
                backgroundColor: "#FEF2F2",
                color: "#CE2D2D",
                fontSize: "0.8rem",
                marginRight: "8px",
              }}
            >
              {item.invalidLabel}
            </span>
          )}

          <div className={ItemStyle.unitName} style={{ marginLeft: 0 }}>
            {item.fullPath}
          </div>

          <div>{item.hasChildren && <ParentUnitLabel></ParentUnitLabel>}</div>

          <div>
            {item.siteNetworkCodes && (
              <MCMLabel
                id={item.extId}
                siteNetworkCodes={item.siteNetworkCodes}
              ></MCMLabel>
            )}
          </div>
        </div>

        <YbReqStatWrapper item={item}></YbReqStatWrapper>

        <div className={ItemStyle.arrowWrapper}>
          {/* keep this spacing so that it is aligned with parent unit */}
        </div>
      </div>
    </div>
  );
}

module.exports = BatchOnboardItemComponent;
