import gql from "graphql-tag";

export const query = gql`
  query users($count: Int!, $cursor: ID, $pubId: Int) {
    users(first: $count, after: $cursor, assumePubId: $pubId)
      @connection(key: users) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;

export const mutation = gql`
  mutation invite($email: EmailAddress!, $pubId: Int) {
    inviteUsers(input: { emails: [$email] }, assumePubId: $pubId) {
      users {
        id
        email
      }
    }
  }
`;
