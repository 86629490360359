import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import YbReqStatDiagram from "./yb-req-stat-diagram";
import NumberFormat from "../../_newapp/home-dashboard/components/number-format";
import Style from "common/styles/req-stat-tooltip.css";

const YbMCMUnitTooltip = (props) => {
  let { siteNetworkCodes, tooltipKey } = props;

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top-start"
      overlay={
        <Popover
          id={tooltipKey}
          className="shadow-lg"
          style={{
            maxWidth: "400px",
            backgroundColor: "#f7f7f7",
          }}
        >
          <Popover.Content>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "6px",
              }}
            >
              Child Network Codes:
            </div>
            <div
              style={{
                backgroundColor: "#F2F2F2",
                border: "1px solid #A6A6A6",
                borderRadius: "4px",
                padding: "6px",
                marginBottom: "4px",
              }}
            >
              {typeof siteNetworkCodes === "string"
                ? siteNetworkCodes
                : siteNetworkCodes &&
                  siteNetworkCodes.map((code) => {
                    return <div key={code}>{code}</div>;
                  })}
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
};

module.exports = YbMCMUnitTooltip;
