import React, { useState, useEffect } from "react";
import { API_ENDPOINT } from "../../common/constants/common";

const abortController = new AbortController();
const signal = abortController.signal;

function useValidateInvite(queryString) {
  const [isLoading, setIsLoading] = useState(false);
  const [validateData, setValidateData] = useState();
  let ignore = false;

  function transformData(responseData) {
    let inviteData = {
      isValid: responseData.isValid,
      message: responseData.message || "",
      data: null
    };

    if (responseData.isValid) {
      const data = responseData.data;
      inviteData.data = {
        hash: data.h,
        reseller: {
          resellerId: data.reseller.id,
          name: data.reseller.name
        },
        publisher: {
          publisherId: data.publisher.id,
          name: data.publisher.name,
          googleAccountType: data.publisher.googleAccountType,
          geoCountryId: data.publisher.geoCountry.id,
          geoCountryName: i18n(data.publisher.geoCountry.name),
          websiteLinks: data.publisher.websites
        }
      };
    }

    return inviteData;
  }

  async function validateInvitation(queryString) {
    console.log("fetching");
    const url = `${API_ENDPOINT}/pubRegister/v1/validateInvitationLink?${queryString}`;
    let response = null;
    let responseData = null;
    let inviteData = null;

    try {
      response = await fetch(url, { signal });
      responseData = await response.json();
      inviteData = transformData(responseData);

      if (!ignore) {
        setValidateData(inviteData);
      }
    } catch (err) {
      if (err.name === "AbortError") {
        // do nothing
      } else {
        throw err;
      }
    }

    return inviteData;
  }

  useEffect(() => {
    async function onMount() {
      if (ignore) {
        abortController.abort();
      } else {
        setIsLoading(true);
        console.log("loading..");
        validateInvitation(queryString);
        setIsLoading(false);
        console.log("finish loading");
      }
    }

    onMount();
    return function cleanup() {
      ignore = true;
      //
    };
  }, queryString);

  return { isLoading, validateData };
}

export default useValidateInvite;
