const publisherBasePath = "/publisher/:publisherId";
const pathnames = {
  terms: "/terms",
  publishers: "/publishers",
  resellerDashboard: "/reseller-dashboard",
  resellerBilling: "/billing",
  inventorySummary: "/summary/overview",
  // adSizeOptimization: "/optimization/ad-size",
  onBoardWizard: "/wizard",
  dashboardOverview: "/dashboard/overview",
  users: "/users",
  settings: "/settings",
  supportCenter: "/support/center",
  home: "/home-dashboard",

  subscriptionAndBilling: {
    overview: "/subscription-and-billing/overview",
    billingReport: "/subscription-and-billing/billing-report",
    paymentHistory: "/subscription-and-billing/payment-history",
    billingSetting: "/subscription-and-billing/billing-setting",
    subscribe: "/subscription-and-billing/subscribe",
  },

  yieldSet: {
    create: "/yield-sets/create/:networkId",
    update: "/yield-sets/update/:yieldSetId",
  },
  adUnit: {
    create: "/ad-units/create/:yieldSetId",
    update: "/ad-units/update/:adUnitId",
  },
  placement: {
    create: "/placements/create/:yieldSetId",
    update: "/placements/update/:placementId",
  },
  inventoryCreate: "/inventory/create/:yieldSetId",
  networkAccounts: "/network/:networkId/accounts",
  integration: "/integration",

  publisherBase: {
    basePath: publisherBasePath,
    inventorySummary: `${publisherBasePath}/summary/overview`,
    // adSizeOptimization: `${publisherBasePath}/optimization/ad-size`,
    onBoardWizard: `${publisherBasePath}/wizard`,
    settings: `${publisherBasePath}/settings`,
  },
};

export default pathnames;
