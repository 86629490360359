import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";

import YbLoadingView from "common/components/yb-loading-view";
import YbHighlight from "common/components/yb-highlight";
import YbTooltip from "common/components/yb-tooltip";
import YbTreeSelector from "common/components/yb-tree-selector";

import YbAddGAMAccountBtn from "../../summary/components/yb-add-gam-account-btn";
import YbAccountPreview from "../../summary/components/yb-account-preview";

import UserStyles from "../../user/styles/signup.css";

import { GET_ACCOUNTS } from "../../summary/query/summary";

import Style from "../styles/wizard.css";

class YbWizardStep1 extends React.Component {
  constructor(props) {
    super(props);

    this.accountChanged = this.accountChanged.bind(this);
    this.accountCreated = this.accountCreated.bind(this);

    this.state = {
      publisherId: props.publisherId,
    };
  }

  accountChanged(account) {
    this.props.updateCurrentData((stepData) => {
      stepData.selectedAccount = account;
      this.props.resetStep(2);
    });
  }

  accountCreated(data) {
    this.props.updateCurrentData((stepData) => {
      stepData.refetch();
      stepData.createdAccountId = data.createGamAccount.gamAccount.accountId;
      stepData.radio = "option2";
      stepData.accounts = null;
    });
  }

  render() {
    var stepData = this.props.stepData;

    return (
      <Query
        query={GET_ACCOUNTS}
        variables={{ pubId: this.state.publisherId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return (
              <div
                className={`${CommonStyle.textView} ${CommonStyle.textDanger}`}
              >
                {i18n`ERROR_LOADING_ACCOUNTS_ELLIPSIS`}
              </div>
            );
          }

          if (data) {
            stepData.accounts = data.gamAccounts.edges.map((edge) => edge.node);
            stepData.refetch = refetch;

            if (stepData.createdAccountId) {
              setTimeout(() => {
                this.props.updateCurrentData((stepData) => {
                  stepData.selectedAccount = stepData.accounts.find(
                    (account) => {
                      return account.accountId === stepData.createdAccountId;
                    }
                  );

                  if (stepData.selectedAccount) {
                    stepData.createdAccountId = null;
                    stepData.nextStepFunc();
                  }
                });
              });

              return <YbLoadingView />;
            } else if (!stepData.radio) {
              if (!stepData.accounts.length) {
                stepData.radio = "option1";
              } else {
                stepData.radio = "option2";

                if (!stepData.selectedAccount) {
                  setTimeout(() => {
                    this.props.updateCurrentData((stepData) => {
                      stepData.selectedAccount = stepData.accounts[0];
                    });
                  });
                }
              }
            }
          }

          return (
            <div>
              <div>
                <YbHighlight
                  text={i18n`Intowow needs your authorization for us to operate GAM API on your behalf. The optimization is performed in a fully automatic manner with Intowow AI engine.`}
                  highlight={[i18n`Key-values`, i18n`API Access`]}
                />
              </div>
              <br />
              {!!stepData.accounts.length && (
                <div className={CommonStyle.textAlignLeft}>
                  <label className={`${FormStyle.inputRadio}`}>
                    <input
                      type="radio"
                      value="option1"
                      name="radio"
                      onChange={this.props.handleOptionChange}
                      checked={stepData.radio === "option1"}
                    />{" "}
                    {i18n`Create new account`}
                  </label>
                  <br />
                  <label className={`${FormStyle.inputRadio}`}>
                    <input
                      type="radio"
                      value="option2"
                      name="radio"
                      onChange={this.props.handleOptionChange}
                      checked={stepData.radio === "option2"}
                    />{" "}
                    {i18n`Use an existing account`}
                  </label>
                </div>
              )}
              {stepData.radio === "option1" && (
                <div>
                  <br />
                  <div className={CommonStyle.textAlignLeft}>
                    <b>{i18n`Before you start please make sure you have:`}</b>
                  </div>
                  <div className={CommonStyle.textAlignLeft}>
                    <i
                      className={`fa fa-check ${CommonStyle.highlightedText}`}
                    />{" "}
                    {i18n`An email account/password to access Google Ad Manager as an admin role.`}
                  </div>
                  {/* <div className={CommonStyle.textAlignLeft}>
                    <i
                      className={`fa fa-check ${CommonStyle.highlightedText}`}
                    />{" "}
                    {i18n`If you want to optimize AdSense you require an email account/password to access Google AdSense as an admin role.`}
                  </div> */}
                  <br />
                  <hr></hr>
                  <br />
                  {i18n`With your Google Ad Manager account, login using Google OAuth from the button below`}
                  <br />
                  <br />
                  <div>
                    <span className={CommonStyle.displayInlineBlock}>
                      <YbAddGAMAccountBtn
                        callback={this.accountCreated}
                        publisherId={this.state.publisherId}
                      >
                        <span
                          className={`${CommonStyle.button} ${CommonStyle.buttonPrimary} ${CommonStyle.buttonBig}`}
                        >
                          <i className="fa fa-plus" /> {i18n`NEW_GAM_ACCOUNT`}
                        </span>
                      </YbAddGAMAccountBtn>
                    </span>
                    <YbTooltip
                      big={true}
                      position="auto"
                      message={
                        <div>
                          <div className={`${CommonStyle.textAlignLeft}`}>
                            {i18n`After clicking on `}{" "}
                            <span className={Style.gamBtnImg} /> :
                          </div>
                          <br />
                          <div
                            className={`${CommonStyle.textAlignLeft}`}
                          >{i18n`1) Complete the Google OAuth with your email account and password`}</div>
                          <br />
                          <div className={CommonStyle.textAlignLeft}>
                            <YbHighlight
                              text={i18n`2) Enter a Name for your GAM account in New GAM Account dialog and click Create. Intowow supports setting up multiple GAM accounts. The name of your GAM account is an alias to help you easily recognize your account. (Example: NBC(nbc.com))`}
                              highlight={[
                                i18n`Name`,
                                i18n`New GAM Account`,
                                i18n`Create`,
                              ]}
                            />
                          </div>
                          <br />
                          <div>
                            <span className={Style.gamAccountDialog} />
                          </div>
                        </div>
                      }
                    >
                      <span
                        className={`${CommonStyle.buttonLink} ${UserStyles.infoTooltip}`}
                      >
                        <i className="fa fa-info-circle" />
                      </span>
                    </YbTooltip>
                  </div>
                  <br />
                  <br />
                  <div className="alert alert-secondary" role="alert">
                    <i className="fa fa-info-circle" />{" "}
                    {i18n`Don't worry, we have no access to your email password. The Google OAuth process goes directly to Google's server where the authentication happened only between you and Google.`}
                  </div>
                </div>
              )}
              {stepData.radio === "option2" && (
                <div className={FormStyle.row}>
                  <div className={FormStyle.label}>
                    {i18n`GAM Account`}{" "}
                    <span className={FormStyle.requiredStar}>*</span>
                  </div>
                  <YbTreeSelector
                    noSort={true}
                    short={true}
                    noHeader={true}
                    items={[stepData.accounts]}
                    value={[]}
                    selectedItems={stepData.selectedAccount}
                    onSelect={this.accountChanged}
                    onRemove={this.accountChanged}
                    id="accountId"
                  />
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

module.exports = YbWizardStep1;
