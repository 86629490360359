import _ from "lodash";
import moment from "moment";

export function formatMoneyWithCurrency({ currency, value }) {
  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency,
  });
}

// V2
export function transformHomeDashboardData(homeDashboardData) {
  console.log(_.cloneDeep(homeDashboardData));
  if (!homeDashboardData) return;
  let data = homeDashboardData;

  // Use USD if publisher has no default reporting currency
  const defaultCurrency = _.find(homeDashboardData.exchangeRates, {
    isDefault: true,
  });
  data.defaultReportingCurrency = defaultCurrency
    ? defaultCurrency.currency
    : "USD";
  data.exchangeRates = _transformExchangeRates(data.exchangeRates);

  data.gamNetworkStatusReport = _transformToJson(data.gamNetworkStatusReport);
  data.gamNetworkPerformanceReport = _transformToJson(
    data.gamNetworkPerformanceReport
  );
  data.yieldSetStatusReport = _transformToJson(data.yieldSetStatusReport);
  data.yieldSetPerformanceReport = _transformToJson(
    data.yieldSetPerformanceReport
  );

  const pubIdByNetworkId = _.reduce(
    data.gamNetworks,
    (result, n) => {
      result[n.id] = n.pubId;
      return result;
    },
    {}
  );

  _.forEach(data.gamNetworkPerformanceReport, (r) => {
    // r.date: "2021-05-25T00:00:00.000Z"
    r.date = r.date.slice(0, 10); // 2021-05-25
    // r.date = moment.utc(r.date).format("YYYY-MM-DD");
    r.dateMs = moment.utc(r.date).unix();

    // to simplify filter
    r.pubId = pubIdByNetworkId[r.gamNetworkId];

    // all metrics are string
    r.totalRequests = _.parseInt(r.totalRequests);
    r.protectedRequests = _.parseInt(r.protectedRequests);
    r.boostingRequests = _.parseInt(r.boostingRequests);
    r.notProtectedRequests = _.parseInt(r.notProtectedRequests);
    r.protectedOriginalRevenue = _.parseInt(r.protectedOriginalRevenue);
    r.originalRevenue = _.parseInt(r.originalRevenue);
    r.increasedRevenue = _.parseInt(r.increasedRevenue);
    r.cost = _.parseInt(r.cost);
  });

  _.forEach(data.yieldSetPerformanceReport, (r) => {
    // r.date: "2021-05-25T00:00:00.000Z"
    r.date = r.date.slice(0, 10); // 2021-05-25
    // r.date = moment.utc(r.date).format("YYYY-MM-DD");
    r.dateMs = moment.utc(r.date).unix();

    // to simplify filter
    r.pubId = pubIdByNetworkId[r.gamNetworkId];

    // all metrics are string
    r.totalRequests = _.parseInt(r.totalRequests);
    r.protectedRequests = _.parseInt(r.protectedRequests);
    r.boostingRequests = _.parseInt(r.boostingRequests);
    r.notProtectedRequests = _.parseInt(r.notProtectedRequests);
    r.protectedOriginalRevenue = _.parseInt(r.protectedOriginalRevenue);
    r.originalRevenue = _.parseInt(r.originalRevenue);
    r.increasedRevenue = _.parseInt(r.increasedRevenue);
    r.cost = _.parseInt(r.cost);
  });

  _.forEach(data.gamNetworkStatusReport, (r) => {
    // to simplify filter
    r.pubId = pubIdByNetworkId[r.gamNetworkId];

    r.compatibleRequests = _.parseInt(r.compatibleRequests); // network only
    r.compatibleUnitCount = _.parseInt(r.compatibleUnitCount);
    r.onboardedRequests = _.parseInt(r.onboardedRequests);
    r.onboardedUnitCount = _.parseInt(r.onboardedUnitCount);
    r.runningRequests = _.parseInt(r.runningRequests); // v2 shows "protected requests"
    r.runningUnitCount = _.parseInt(r.runningUnitCount); // v2 shows "protected unit"
    r.totalUnitCount = _.parseInt(r.totalUnitCount);

    // newly added for v2
    r.currentSegments = _.parseInt(r.currentSegments);
    r.totalSegmentBatches = _.parseInt(r.totalSegmentBatches);
    r.totalExperiments = _.parseInt(r.totalExperiments);
    r.totalExperimentGroups = _.parseInt(r.totalExperimentGroups);
    r.averageExperimentsPerDay = _.parseInt(r.averageExperimentsPerDay);
    r.averageExperimentGroupsPerDay = _.parseInt(
      r.averageExperimentGroupsPerDay
    );
  });

  _.forEach(data.yieldSetStatusReport, (r) => {
    // to simplify filter
    r.pubId = pubIdByNetworkId[r.gamNetworkId];

    r.onboardedUnitCount = _.parseInt(r.onboardedUnitCount);
    r.runningUnitCount = _.parseInt(r.runningUnitCount); // v2 shows "protected unit"
    r.onboardedRequests = _.parseInt(r.onboardedRequests); // yieldset only
    r.runningRequests = _.parseInt(r.runningRequests); // v2 shows "protected requests"

    // newly added for v2
    r.currentSegments = _.parseInt(r.currentSegments);
    r.totalSegmentBatches = _.parseInt(r.totalSegmentBatches);
    r.totalExperiments = _.parseInt(r.totalExperiments);
    r.totalExperimentGroups = _.parseInt(r.totalExperimentGroups);
    r.averageExperimentsPerDay = _.parseInt(r.averageExperimentsPerDay);
    r.averageExperimentGroupsPerDay = _.parseInt(
      r.averageExperimentGroupsPerDay
    );
  });

  const publishers = _.map(data.publishers, (pub) => {
    const pubNetworks = _.filter(data.gamNetworks, { pubId: pub.id });
    pub.networkIds = _.map(pubNetworks, "id");
    pub.hasPerfData = _.some(pubNetworks, { hasPerfData: true });
    return pub;
  });

  data.publishers = _.orderBy(
    publishers,
    ["hasPerfData", "id"],
    ["desc", "asc"]
  );

  data.gamNetworks = _.orderBy(
    data.gamNetworks,
    ["hasPerfData", "id"],
    ["desc", "asc"]
  );

  data.yieldSets = _.orderBy(
    data.yieldSets,
    ["hasPerfData", "id"],
    ["desc", "asc"]
  );

  return data;
}

export function calculateTrendData({
  homeDashboardData,
  selectedPubId,
  selectedNetworkId,
  selectedYieldSetIds,
  startDate,
  endDate,
  exchangeRates,
}) {
  // --- Revenue Trend ---
  // For "Revenue with Yieldbooster": (notProtectedRevenue + protectedOriginalRevenue + increasedRevenue)
  // For "Revenue without Yieldbooster": (notProtectedRevenue + protectedOriginalRevenue)
  // For "Extra Ad Serving Cost": (cost)
  // For "Net Increased Revenue": increasedRevenue - cost
  // For "Perceived Lift": (100 * (increasedRevenue - cost) / protectedOriginalRevenue [%])
  // For "Total Requests" : totalRequests
  // For "Original Revenue": protectedOriginalRevenue
  // For "Not-Protected Revenue": notProtectedRevenue

  // --- Request Protection Index ---
  // For "Protected Score": 100 * protectedRequests / totalRequest
  // For "Defended Score": 100 * boostingRequests / protectedRequests

  // --- Revenue Lift ---
  // For "Secured Lift": 100 * (increasedRevenue - cost) / originalRevenue
  // For "Perceived Lift": 100 * (increasedRevenue - cost) / protectedOriginalRevenue

  // --- Request Funnel ---
  // For "Compatible Requests": totalRequests
  // For "Protected Requests": protectedRequests
  // For "Defended Requests": boostingRequests

  // --- Secured Request RPM ---
  // For "Secured Request RPM": (increasedRevenue + originalRevenue) / (boostingRequests / 1000)
  // For "Benchmark Request RPM": originalRevenue / (boostingRequests / 1000)
  let reports = [];
  if (selectedPubId === -1) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return _isDateBetween({ date: r.date, startDate, endDate });
    });
  } else if (
    selectedPubId !== -1 &&
    selectedNetworkId === -1 &&
    selectedYieldSetIds === -1
  ) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        r.pubId === selectedPubId
      );
    });
  } else if (selectedNetworkId !== -1 && selectedYieldSetIds === -1) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        r.gamNetworkId === selectedNetworkId
      );
    });
  } else if (selectedYieldSetIds !== -1) {
    reports = _.filter(homeDashboardData.yieldSetPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        _.indexOf(selectedYieldSetIds, r.yieldSetId) !== -1
      );
    });
  }

  // by date
  let dataMap = {};
  _.forEach(reports, (r) => {
    if (!dataMap[r.date]) {
      dataMap[r.date] = {
        date: r.date,
        dateMs: r.dateMs,

        notProtectedRevenue: 0,
        protectedOriginalRevenue: 0,
        increasedRevenue: 0,
        cost: 0,
        originalRevenue: 0,

        totalRequests: 0,
        protectedRequests: 0,
        boostingRequests: 0,
      };
    }
    let {
      date,
      notProtectedRevenue,
      protectedOriginalRevenue,
      increasedRevenue,
      cost,
      originalRevenue,

      totalRequests,
      protectedRequests,
      boostingRequests,
    } = r;

    // currency is different per month
    const month = date.substring(0, "2020-12".length);
    const currencyRate = exchangeRates[month];

    increasedRevenue = _normalizeRevenue(increasedRevenue * currencyRate);
    originalRevenue = _normalizeRevenue(originalRevenue * currencyRate);
    notProtectedRevenue = _normalizeRevenue(notProtectedRevenue * currencyRate);
    protectedOriginalRevenue = _normalizeRevenue(
      protectedOriginalRevenue * currencyRate
    );
    cost = _normalizeRevenue(cost * currencyRate);

    dataMap[r.date].increasedRevenue += _.round(increasedRevenue, 2);
    dataMap[r.date].originalRevenue += _.round(originalRevenue, 2);
    dataMap[r.date].notProtectedRevenue += _.round(notProtectedRevenue, 2);
    dataMap[r.date].protectedOriginalRevenue += _.round(
      protectedOriginalRevenue,
      2
    );
    dataMap[r.date].cost += _.round(cost, 2);

    dataMap[r.date].totalRequests += totalRequests;
    dataMap[r.date].protectedRequests += protectedRequests;
    dataMap[r.date].boostingRequests += boostingRequests;
  });

  const rrr = _.map(dataMap, (r) => {
    const revenueWithYB =
      r.notProtectedRevenue + r.protectedOriginalRevenue + r.increasedRevenue;
    const revenueWithoutYB = r.notProtectedRevenue + r.protectedOriginalRevenue;

    const netIncreasedRevenue = r.increasedRevenue - r.cost;

    const perceivedLift =
      r.protectedOriginalRevenue > 0
        ? (netIncreasedRevenue / r.protectedOriginalRevenue) * 100
        : 0;

    r.perceivedLift = _.round(perceivedLift, 2);
    r.revenueWithYB = _.round(revenueWithYB, 2);
    r.revenueWithoutYB = _.round(revenueWithoutYB, 2);

    // // Important! filter out data from when we are not optimizing but has a tiny bit of request
    // if (r.managedScore < 0.1) {
    //   r.grossRevenueLift = 0;
    //   r.perceivedLift = 0;
    //   r.rRpmWithYB = 0;
    //   r.rRpmWithoutYB = 0;
    // }

    return r;
  });

  return _.orderBy(rrr, ["dateMs"], ["asc"]);
}

export function calculateDistributionData({
  homeDashboardData,
  // selectedNetworkId, // highlight
  // selectedYieldSetIds, // highlight
  startDate, // filter
  endDate, // filter
  exchangeRates,
}) {
  // notProtectedRevenue, protectedOriginalRevenue, increasedRevenue, cost
  // For "Ad Revenue Distribution" (notProtectedRevenue + protectedOriginalRevenue + increasedRevenue)
  // For "Net Increased Revenue Distribution" (increasedRevenue - cost)
  let publishersMap = {};
  let networksMap = {};
  let yieldSetsMap = {};

  _.forEach(homeDashboardData.yieldSetPerformanceReport, (r) => {
    if (!_isDateBetween({ date: r.date, startDate, endDate })) {
      return;
    }

    // currency is different per month
    const month = r.date.substring(0, "2020-10".length);
    const currencyRate = exchangeRates[month];

    const notProtectedRevenue = r.notProtectedRevenue * currencyRate;
    const protectedOriginalRevenue = r.protectedOriginalRevenue * currencyRate;
    const increasedRevenue = r.increasedRevenue * currencyRate;
    const cost = r.cost * currencyRate;

    if (!publishersMap[r.pubId]) {
      publishersMap[r.pubId] = {
        id: r.pubId,
        name: _.find(homeDashboardData.publishers, { id: r.pubId }).name,
        notProtectedRevenue,
        protectedOriginalRevenue,
        increasedRevenue,
        cost,
      };
    } else {
      publishersMap[r.pubId].notProtectedRevenue += notProtectedRevenue;
      publishersMap[r.pubId].protectedOriginalRevenue +=
        protectedOriginalRevenue;
      publishersMap[r.pubId].increasedRevenue += increasedRevenue;
      publishersMap[r.pubId].cost += cost;
    }

    if (!networksMap[r.gamNetworkId]) {
      networksMap[r.gamNetworkId] = {
        id: r.gamNetworkId,
        name: _.find(homeDashboardData.gamNetworks, { id: r.gamNetworkId })
          .name,
        pubId: r.pubId, // for later filtering purposes
        notProtectedRevenue,
        protectedOriginalRevenue,
        increasedRevenue,
        cost,
      };
    } else {
      networksMap[r.gamNetworkId].notProtectedRevenue += notProtectedRevenue;
      networksMap[r.gamNetworkId].protectedOriginalRevenue +=
        protectedOriginalRevenue;
      networksMap[r.gamNetworkId].increasedRevenue += increasedRevenue;
      networksMap[r.gamNetworkId].cost += cost;
    }

    if (!yieldSetsMap[r.yieldSetId]) {
      yieldSetsMap[r.yieldSetId] = {
        id: r.yieldSetId,
        name: _.find(homeDashboardData.yieldSets, { id: r.yieldSetId }).name,
        gamNetworkId: r.gamNetworkId,
        notProtectedRevenue,
        protectedOriginalRevenue,
        increasedRevenue,
        cost,
      };
    } else {
      yieldSetsMap[r.yieldSetId].notProtectedRevenue += notProtectedRevenue;
      yieldSetsMap[r.yieldSetId].protectedOriginalRevenue +=
        protectedOriginalRevenue;
      yieldSetsMap[r.yieldSetId].increasedRevenue += increasedRevenue;
      yieldSetsMap[r.yieldSetId].cost += cost;
    }
  });

  let pTotalGoogleAdRevenue = 0;
  let pTotalNetIncreasedRevenue = 0;
  let publishers = _.map(publishersMap, (n) => {
    const {
      id,
      name,
      notProtectedRevenue,
      protectedOriginalRevenue,
      increasedRevenue,
      cost,
    } = n;
    const googleAdRevenue =
      notProtectedRevenue + protectedOriginalRevenue + increasedRevenue;
    const netIncreasedRevenue = increasedRevenue - cost;

    pTotalGoogleAdRevenue += googleAdRevenue;
    pTotalNetIncreasedRevenue += netIncreasedRevenue;

    return {
      id,
      name,
      googleAdRevenue: _.round(_normalizeRevenue(googleAdRevenue), 2),
      netIncreasedRevenue: _.round(_normalizeRevenue(netIncreasedRevenue), 2),
    };
  });
  pTotalGoogleAdRevenue = _normalizeRevenue(pTotalGoogleAdRevenue);
  pTotalNetIncreasedRevenue = _normalizeRevenue(pTotalNetIncreasedRevenue);
  publishers = _.map(publishers, (p) => {
    p.googleAdRevenuePercentage = _.round(
      (p.googleAdRevenue / pTotalGoogleAdRevenue) * 100,
      2
    );
    p.netIncreasedRevenuePercentage = _.round(
      (p.netIncreasedRevenue / pTotalNetIncreasedRevenue) * 100,
      2
    );
    return p;
  });

  let nTotalGoogleAdRevenue = 0;
  let nTotalNetIncreasedRevenue = 0;
  let networks = _.map(networksMap, (n) => {
    const {
      id,
      name,
      pubId,
      notProtectedRevenue,
      protectedOriginalRevenue,
      increasedRevenue,
      cost,
    } = n;
    const googleAdRevenue =
      notProtectedRevenue + protectedOriginalRevenue + increasedRevenue;
    const netIncreasedRevenue = increasedRevenue - cost;

    nTotalGoogleAdRevenue += googleAdRevenue;
    nTotalNetIncreasedRevenue += netIncreasedRevenue;

    return {
      id,
      name,
      pubId,
      googleAdRevenue: _.round(_normalizeRevenue(googleAdRevenue), 2),
      netIncreasedRevenue: _.round(_normalizeRevenue(netIncreasedRevenue), 2),
    };
  });
  nTotalGoogleAdRevenue = _normalizeRevenue(nTotalGoogleAdRevenue);
  nTotalNetIncreasedRevenue = _normalizeRevenue(nTotalNetIncreasedRevenue);
  networks = _.map(networks, (n) => {
    n.googleAdRevenuePercentage = _.round(
      (n.googleAdRevenue / nTotalGoogleAdRevenue) * 100,
      2
    );
    n.netIncreasedRevenuePercentage = _.round(
      (n.netIncreasedRevenue / nTotalNetIncreasedRevenue) * 100,
      2
    );
    return n;
  });

  let ysTotalGoogleAdRevenue = 0;
  let ysTotalNetIncreasedRevenue = 0;
  let yieldSets = _.map(yieldSetsMap, (n) => {
    const {
      id,
      name,
      gamNetworkId,
      notProtectedRevenue,
      protectedOriginalRevenue,
      increasedRevenue,
      cost,
    } = n;
    const googleAdRevenue =
      notProtectedRevenue + protectedOriginalRevenue + increasedRevenue;
    const netIncreasedRevenue = increasedRevenue - cost;

    ysTotalGoogleAdRevenue += googleAdRevenue;
    ysTotalNetIncreasedRevenue += netIncreasedRevenue;

    return {
      id,
      name,
      gamNetworkId,
      googleAdRevenue: _.round(_normalizeRevenue(googleAdRevenue), 2),
      netIncreasedRevenue: _.round(_normalizeRevenue(netIncreasedRevenue), 2),
    };
  });

  ysTotalGoogleAdRevenue = _normalizeRevenue(ysTotalGoogleAdRevenue);
  ysTotalNetIncreasedRevenue = _normalizeRevenue(ysTotalNetIncreasedRevenue);
  yieldSets = _.map(yieldSets, (n) => {
    n.googleAdRevenuePercentage = _.round(
      (n.googleAdRevenue / ysTotalGoogleAdRevenue) * 100,
      2
    );
    n.netIncreasedRevenuePercentage = _.round(
      (n.netIncreasedRevenue / ysTotalNetIncreasedRevenue) * 100,
      2
    );
    return n;
  });

  // publishers: [{ id, name, metric... }]
  // networks: [{ id, name, metric... }]
  // yieldSets: [{ id, name, metric... }]
  return {
    publishers: _.orderBy(publishers, ["googleAdRevenue"], ["desc"]),
    networks: _.orderBy(networks, ["googleAdRevenue"], ["desc"]),
    yieldSets: _.orderBy(yieldSets, ["googleAdRevenue"], ["desc"]),
  };
}

export function calculateSummaryData({
  homeDashboardData,
  selectedPubId,
  selectedNetworkId,
  selectedYieldSetIds,
  startDate,
  endDate,
  exchangeRates,
}) {
  // "totalRequests", "protectedRequests", "boostingRequests",
  // "notProtectedRevenue", "protectedOriginalRevenue", "originalRevenue",
  // "increasedRevenue", "cost"
  let reports = [];

  if (selectedPubId === -1) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return _isDateBetween({ date: r.date, startDate, endDate });
    });
  } else if (
    selectedPubId !== -1 &&
    selectedNetworkId === -1 &&
    selectedYieldSetIds === -1
  ) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        r.pubId === selectedPubId
      );
    });
  } else if (selectedNetworkId !== -1 && selectedYieldSetIds === -1) {
    reports = _.filter(homeDashboardData.gamNetworkPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        r.gamNetworkId === selectedNetworkId
      );
    });
  } else if (selectedYieldSetIds !== -1) {
    reports = _.filter(homeDashboardData.yieldSetPerformanceReport, (r) => {
      return (
        _isDateBetween({ date: r.date, startDate, endDate }) &&
        _.indexOf(selectedYieldSetIds, r.yieldSetId) !== -1
      );
    });
  }

  let data = {
    increasedRevenue: 0,
    cost: 0,
    notProtectedRevenue: 0,
    protectedOriginalRevenue: 0,
    originalRevenue: 0,

    totalRequests: 0,
    protectedRequests: 0,
    boostingRequests: 0,
  };

  _.forEach(reports, (r) => {
    // currency is different per month
    const month = r.date.substring(0, "2020-10".length);
    const currencyRate = exchangeRates[month];

    data.increasedRevenue += r.increasedRevenue * currencyRate;
    data.cost += r.cost * currencyRate;
    data.notProtectedRevenue += r.notProtectedRevenue * currencyRate;
    data.protectedOriginalRevenue += r.protectedOriginalRevenue * currencyRate;
    data.originalRevenue += r.originalRevenue * currencyRate;

    data.totalRequests += r.totalRequests;
    data.protectedRequests += r.protectedRequests;
    data.boostingRequests += r.boostingRequests;
  });

  data.protectedRate = _.round(
    (data.protectedRequests / data.totalRequests) * 100,
    2
  );
  data.defendedRate = _.round(
    (data.boostingRequests / data.protectedRequests) * 100,
    2
  );

  data.netIncreasedRevenue = data.increasedRevenue - data.cost;

  const perceivedLift =
    data.protectedOriginalRevenue > 0
      ? (data.netIncreasedRevenue / data.protectedOriginalRevenue) * 100
      : 0;

  const securedLift =
    data.originalRevenue > 0
      ? (data.netIncreasedRevenue / data.originalRevenue) * 100
      : 0;

  data.netIncreasedRevenue = _.round(
    _normalizeRevenue(data.netIncreasedRevenue),
    2
  );
  data.perceivedLift = _.round(perceivedLift, 2);
  data.securedLift = _.round(securedLift, 2);

  return data;
}

// Inventory Section + Algorithm Activity Section
export function calculateInventoryData({
  homeDashboardData,
  selectedPubId,
  selectedNetworkId,
  selectedYieldSetIds,
}) {
  // compatibleRequests, compatibleUnitCount, totalUnitCount,
  // onboardedUnitCount, runningUnitCount, onboardedRequests, runningRequests,
  // // newly added for v2
  // currentSegments, totalSegmentBatches, totalExperiments,
  // totalExperimentGroups, averageExperimentsPerDay,
  // averageExperimentGroupsPerDay

  // Network
  // For "Traffic Protected Score" : (100 * runningRequests / compatibleRequests [%])
  // YieldSet
  // For "Traffic Protected Score" : (100 * runningRequests / onboardedRequests [%])
  let reports = homeDashboardData.gamNetworkStatusReport;

  if (
    selectedPubId !== -1 &&
    selectedNetworkId === -1 &&
    selectedYieldSetIds === -1
  ) {
    reports = _.filter(homeDashboardData.gamNetworkStatusReport, (r) => {
      return r.pubId === selectedPubId;
    });
  } else if (selectedNetworkId !== -1 && selectedYieldSetIds === -1) {
    reports = _.filter(homeDashboardData.gamNetworkStatusReport, {
      gamNetworkId: selectedNetworkId,
    });
  } else if (selectedYieldSetIds !== -1) {
    reports = _.filter(homeDashboardData.yieldSetStatusReport, (r) => {
      return _.indexOf(selectedYieldSetIds, r.yieldSetId) !== -1;
    });
  }

  let data = {
    totalUnits: 0,
    compatibleUnits: 0,
    onboardedUnits: 0,
    runningUnits: 0,

    compatibleRequests: 0,
    onboardedRequests: 0,
    runningRequests: 0,

    currentSegments: 0,
    totalSegmentBatches: 0,
    totalExperiments: 0,
    totalExperimentGroups: 0,
    averageExperimentsPerDay: 0,
    averageExperimentGroupsPerDay: 0,
  };

  // compatibleRequests, compatibleUnitCount, totalUnitCount,
  // onboardedUnitCount, runningUnitCount, onboardedRequests, runningRequests,
  // // newly added for v2
  // currentSegments, totalSegmentBatches, totalExperiments,
  // totalExperimentGroups, averageExperimentsPerDay,
  // averageExperimentGroupsPerDay
  _.forEach(reports, (r) => {
    data.totalUnits += r.totalUnitCount;
    data.compatibleUnits += r.compatibleUnitCount;
    data.onboardedUnits += r.onboardedUnitCount;
    data.runningUnits += r.runningUnitCount;
    data.compatibleRequests += r.compatibleRequests;
    data.onboardedRequests += r.onboardedRequests;
    data.runningRequests += r.runningRequests;

    data.currentSegments += r.currentSegments;
    data.totalSegmentBatches += r.totalSegmentBatches;
    data.totalExperiments += r.totalExperiments;
    data.totalExperimentGroups += r.totalExperimentGroups;
    data.averageExperimentsPerDay += r.averageExperimentsPerDay;
    data.averageExperimentGroupsPerDay += r.averageExperimentGroupsPerDay;
  });

  data.compatibleUnitsRate =
    data.totalUnits > 0
      ? _.round((data.compatibleUnits / data.totalUnits) * 100, 2)
      : 0;
  data.onboardedUnitsRate =
    data.compatibleUnits > 0
      ? _.round((data.onboardedUnits / data.compatibleUnits) * 100, 2)
      : 0;
  // v2: protected rate
  data.runningUnitsRate =
    data.onboardedUnits > 0
      ? _.round((data.runningUnits / data.onboardedUnits) * 100, 2)
      : 0;

  data.trafficProtectedScoreNetwork =
    data.compatibleRequests > 0
      ? _.round((data.runningRequests / data.compatibleRequests) * 100, 2)
      : 0;

  data.trafficProtectedScoreYieldSet =
    data.onboardedRequests > 0
      ? _.round((data.runningRequests / data.onboardedRequests) * 100, 2)
      : 0;

  return data;
}

function _isDateBetween({ date, startDate, endDate }) {
  return moment(date).isBetween(startDate, endDate, undefined, "[]");
}

function _normalizeRevenue(revenue) {
  return revenue / 1000000;
}

function _transformExchangeRates(exchangeRates) {
  /*
  {
    USD: {
      "2020-09": 1
    },
    ...
  }
  */
  let currencyMap = {};
  _.forEach(exchangeRates, (e) => {
    currencyMap[e.currency] = _.reduce(
      e.rates,
      (result, r) => {
        const month = r[0];
        const rate = r[1];
        result[month] = rate;
        return result;
      },
      {}
    );
  });

  return currencyMap;
}

function _transformToJson(reports) {
  // transform reports to json format
  const { cols, records } = reports;

  return _.reduce(
    records,
    (result, row) => {
      let r = {};
      _.forEach(cols, (column, index) => {
        r[column] = row[index];
      });
      result.push(r);
      return result;
    },
    []
  );
}
