import gql from "graphql-tag";

export const GET_YIELD_SET = gql`
  query GET_YIELD_SET($pubId: Int) {
    yieldSets(first: 1000, assumePubId: $pubId) {
      edges {
        node {
          id
          yieldSetId
          name
          gamNetwork {
            id
            networkId
            name
            currency
          }
          adsenseAccount {
            id
            accountId
          }
          adxAccount {
            id
            adxAccountId
          }
        }
      }
    }
  }
`;

export const GET_AD_UNIT = gql`
  query GET_AD_UNIT($filter: gamAdUnitsFilter!, $pubId: Int) {
    items: gamAdUnits(filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          itemId: adUnitId
          name
          unitType
          extGamAdUnitId
          parentPath
          platformType
          adxFloorPrice
          adSenseFloorPrice
          yieldSet {
            id
            name
            yieldSetId
          }
          adSizes {
            sizeId
            width
            height
          }
        }
      }
    }
  }
`;

export const GET_PLACEMENTS = gql`
  query GET_PLACEMENTS($filter: gamPlacementsFilter!, $pubId: Int) {
    items: gamPlacements(filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          itemId: placementId
          name
          unitType
          extGamPlacementId
          platformType
          adxFloorPrice
          adSenseFloorPrice
          yieldSet {
            id
            name
            yieldSetId
          }
          adSizes {
            sizeId
            width
            height
          }
        }
      }
    }
  }
`;

export const GET_UNIT_CANDIDATES = gql`
  query GET_UNIT_CANDIDATES($filter: extGamAdUnitsFilter!, $pubId: Int) {
    extItems: extGamAdUnits(filter: $filter, assumePubId: $pubId) {
      extId: adUnitId
      name
      code
      parentPath
      hasChildren
      adSizes {
        sizeId
        width
        height
      }
    }
    items: gamAdUnits(first: 1000, assumePubId: $pubId) {
      edges {
        node {
          id
          itemId: adUnitId
          name
          code
          extId: extGamAdUnitId
        }
      }
    }
  }
`;

export const GET_PLACEMENT_CANDIDATES = gql`
  query GET_PLACEMENT_CANDIDATES(
    $filter: extGamPlacementsFilter!
    $pubId: Int
  ) {
    extItems: extGamPlacements(filter: $filter, assumePubId: $pubId) {
      extId: placementId
      name
      adSizes {
        sizeId
        width
        height
      }
    }
    items: gamPlacements(first: 1000, assumePubId: $pubId) {
      edges {
        node {
          id
          itemId: placementId
          name
          extId: extGamPlacementId
        }
      }
    }
  }
`;

export const CREATE_AD_UNIT = gql`
  mutation CREATE_AD_UNIT($input: onboardGamAdUnitsInput!, $pubId: Int) {
    create: onboardGamAdUnits(input: $input, assumePubId: $pubId) {
      gamAdUnits {
        id
        itemId: adUnitId
      }
    }
  }
`;

export const SAVE_AD_UNIT = gql`
  mutation SAVE_AD_UNIT($input: updateGamAdUnitInput!, $pubId: Int) {
    update: updateGamAdUnit(input: $input, assumePubId: $pubId) {
      gamAdUnit {
        id
      }
    }
  }
`;

export const CREATE_PLACEMENT = gql`
  mutation CREATE_PLACEMENT($input: onboardGamPlacementsInput!, $pubId: Int) {
    create: onboardGamPlacements(input: $input, assumePubId: $pubId) {
      gamPlacements {
        id
        itemId: placementId
      }
    }
  }
`;

export const SAVE_PLACEMENT = gql`
  mutation SAVE_PLACEMENT($input: updateGamPlacementInput!, $pubId: Int) {
    update: updateGamPlacement(input: $input, assumePubId: $pubId) {
      gamPlacement {
        id
      }
    }
  }
`;
