import React from "react";
import { Redirect } from "react-router-dom";
import { USER_ROLE } from "../../common/constants/common";
import {
  UserInfoContext,
  PublisherInfoContext,
} from "../../common/contexts/contexts";

function getDefaultPath(userInfo, publisherInfo) {
  const isResellerControllingPublisher =
    _.get(publisherInfo, "publisherId") > 0;

  if (isResellerControllingPublisher) {
    return "/summary/overview";
  }

  const role = userInfo.role;
  switch (role) {
    case USER_ROLE.RESELLER_ADMIN: {
      // return "/publishers";
      return "/home-dashboard";
    }

    case USER_ROLE.MANAGED_PUBLISHER_ADMIN: {
      // return "/summary/overview";
      return "/home-dashboard";
    }

    case USER_ROLE.DIRECT_PUBLISHER_ADMIN: {
      // return "/summary/overview";
      return "/home-dashboard";
    }
  }

  return routeConfig;
}

const RedirectWrapper = (props) => {
  const defaultPath = getDefaultPath(props.userInfo, props.publisherInfo);
  return <Redirect to={defaultPath} />;
};

const BaseOverview = (props) => {
  return (
    <UserInfoContext.Consumer>
      {({ userInfo }) => (
        <React.Fragment>
          {userInfo ? (
            <PublisherInfoContext.Consumer>
              {({ publisherInfo }) => {
                return (
                  <React.Fragment>
                    {publisherInfo && (
                      <RedirectWrapper
                        userInfo={userInfo}
                        publisherInfo={publisherInfo}
                      />
                    )}
                  </React.Fragment>
                );
              }}
            </PublisherInfoContext.Consumer>
          ) : (
            // "loading"
            ""
          )}
        </React.Fragment>
      )}
    </UserInfoContext.Consumer>
  );
};

export default BaseOverview;
