import React from "react";
import {
  Container,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Table,
  Modal
} from "react-bootstrap";
import moment from "moment";
import InvoiceDownloadButton from "./invoice-download-button";
import PaymentStatusIndicator from "./payment-status-indicator";
import _ from "lodash";

function translatePaymentStatus(status) {
  switch (status) {
    case "SUCCESS":
      return "Paid";
    case "FAILED":
      return "Payment failed";
    case "WAITING_FOR_TRANSACTION":
      return "Waiting for payment";
    case "EXPIRED":
      return "Payment expired";
  }
}

function translateRefundStatus(status) {
  switch (status) {
    case "REFUNDED":
      return "Refunded";
    case "FAILED":
      return "Refund failed";
    case "PENDING":
      return "Waiting for refund";
  }
}

function PaymentHistoryDetailModal(props) {
  const record = props.paymentRecord;

  return (
    <Modal size="lg" show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>
          <small className="text-muted">Invoice No.</small> {record.invoiceNo}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="text-dark">
          <Row>
            <Col md={6}>
              <dl>
                <dt>Invoice No.</dt>
                <dd>{record.invoiceNo}</dd>

                <dt>Issue Date</dt>
                <dd>
                  {record.issueDate
                    ? moment(record.issueDate).format("YYYY/MM/DD HH:mm:ss")
                    : "-"}
                </dd>

                <dt>Invoice Status</dt>
                <dd>{record.isInvoiceValid ? "Valid" : "Invalid"}</dd>

                <dt>Due Date</dt>
                <dd>
                  {record.dueDate
                    ? moment(record.dueDate).format("YYYY/MM/DD HH:mm:ss")
                    : "-"}
                </dd>
              </dl>
            </Col>
            <Col md={6}>
              <dl>
                <dt>
                  Amount <small>(After Tax)</small>
                </dt>
                <dd>
                  <small className="text-muted">{record.currency}</small>{" "}
                  {record.afterTaxAmount &&
                    record.afterTaxAmount.toLocaleString()}
                </dd>

                <dt>Payment Date</dt>
                <dd>
                  {record.paymentDate
                    ? moment(record.paymentDate).format("YYYY/MM/DD HH:mm:ss")
                    : "-"}
                </dd>

                <dt>Payment Status</dt>
                <dd>
                  <PaymentStatusIndicator paymentRecord={record} />{" "}
                  {translatePaymentStatus(record.paymentStatus)}
                </dd>

                <dt>Payment Method</dt>
                <dd>
                  {record.billingProfile && record.billingProfile.method ? (
                    <div>
                      {record.billingProfile.method === "CREDIT_CARD" ? (
                        <span>
                          <i className="fa fa-credit-card fa-fw" />{" "}
                          {`${record.billingProfile.profile.card6No.substr(
                            0,
                            4
                          )}-${record.billingProfile.profile.card6No.substr(
                            4,
                            2
                          )}**-****-${record.billingProfile.profile.card4No}`}
                        </span>
                      ) : (
                        <span>Wire Transfer</span>
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </dd>
              </dl>
            </Col>
          </Row>
          <Row>
            <Container>
              <label>
                <b>Refunds</b>
              </label>

              {record.refunds && _.size(record.refunds) ? (
                <Table size="sm">
                  <thead style={{ fontSize: "smaller" }}>
                    <tr>
                      <th />
                      <th>Refund Date</th>
                      <th>Description</th>
                      <th className="text-right">Amount</th>
                      <th className="text-center">PDF</th>
                    </tr>
                  </thead>
                  <tbody className="text-dark">
                    {record.refunds.map((refund, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-middle">
                            <div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {translateRefundStatus(refund.refundStatus)}
                                  </Tooltip>
                                }
                              >
                                <div>
                                  {refund.refundStatus === "REFUNDED" && (
                                    <i className="fa fa-check text-success" />
                                  )}
                                  {refund.refundStatus === "FAILED" && (
                                    <i className="fa fa-exclamation-triangle text-danger" />
                                  )}
                                  {refund.refundStatus === "PENDING" && "-"}
                                </div>
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td className="align-middle">
                            {refund.refundDate
                              ? moment(refund.refundDate).format("YYYY/MM/DD")
                              : "-"}
                          </td>
                          <td className="align-middle">
                            {refund.allowanceNo ? (
                              <div>
                                <small className="text-muted">
                                  {refund.allowanceNo}
                                </small>
                                <div>
                                  Allowance issued on{" "}
                                  {refund.allowanceIssueDate
                                    ? moment(refund.allowanceIssueDate).format(
                                        "YYYY/MM/DD"
                                      )
                                    : "-"}
                                </div>
                              </div>
                            ) : refund.refundStatus === "REFUNDED" ? (
                              <div>
                                {refund.billingProfile.method ===
                                "CREDIT_CARD" ? (
                                  <span>
                                    <i className="fa fa-credit-card fa-fw" />{" "}
                                    {`${refund.billingProfile.profile.card6No.substr(
                                      0,
                                      4
                                    )}-${refund.billingProfile.profile.card6No.substr(
                                      4,
                                      2
                                    )}**-****-${
                                      refund.billingProfile.profile.card4No
                                    }`}
                                  </span>
                                ) : (
                                  <span>Wire Transfer</span>
                                )}
                              </div>
                            ) : (
                              `Waiting for refund`
                            )}
                          </td>
                          <td className="text-right align-middle">
                            <small className="text-muted">
                              {record.currency}
                            </small>{" "}
                            {refund.amount && refund.amount.toLocaleString()}
                          </td>
                          <td className="align-middle text-center">
                            <InvoiceDownloadButton
                              type="ALLOWANCE"
                              uniqueNo={refund.allowanceNo}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div>-</div>
              )}
            </Container>
          </Row>
        </Container>

        {/* <pre>{JSON.stringify(props.paymentRecord.refunds, null, 4)}</pre> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

module.exports = PaymentHistoryDetailModal;
