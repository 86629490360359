import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import YbReqStatDiagram from "./yb-req-stat-diagram";
import NumberFormat from "../../_newapp/home-dashboard/components/number-format";
import Style from "common/styles/req-stat-tooltip.css";

const YbReqStatTooltip = (props) => {
  let { item, tooltipKey } = props;

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top-start"
      overlay={
        <Popover
          id={tooltipKey}
          className="shadow-lg"
          style={{
            maxWidth: "400px",
            backgroundColor: "#f7f7f7",
          }}
        >
          <Popover.Content>
            <div className={Style.statTooltipWrapper}>
              <div className={Style.subHead}>
                Daily Avg Req:{" "}
                <NumberFormat
                  value={item.dailyTotalAvg}
                  format="0,0a"
                ></NumberFormat>
              </div>

              <div className={Style.mainHead}>
                Compatible Req:{" "}
                <NumberFormat
                  value={item.dailyCompatibleAvgReq}
                  format="0,0a"
                ></NumberFormat>
              </div>

              <div className={Style.diagramWrapper}>
                <YbReqStatDiagram item={item} isGraphOnly></YbReqStatDiagram>
              </div>

              <div className={Style.stat}>
                <div className={Style.gptIndicator}></div>
                GPT:{" "}
                <NumberFormat
                  value={item.gptReq}
                  format="0,0a"
                ></NumberFormat>{" "}
                (
                <NumberFormat
                  value={item.gptReqPercentage}
                  format="0,0"
                  postfix="%"
                ></NumberFormat>
                )
              </div>

              <div className={Style.stat}>
                <div className={Style.ampIndicator}></div>
                AMP:{" "}
                <NumberFormat
                  value={item.ampReq}
                  format="0,0a"
                ></NumberFormat>{" "}
                (
                <NumberFormat
                  value={item.ampReqPercentage}
                  format="0,0"
                  postfix="%"
                ></NumberFormat>
                )
              </div>

              <div className={Style.stat}>
                <div className={Style.othersIndicator}></div>
                Others:{" "}
                <NumberFormat
                  value={item.othersReq}
                  format="0,0a"
                ></NumberFormat>{" "}
                (
                <NumberFormat
                  value={item.othersReqPercentage}
                  format="0,0"
                  postfix="%"
                ></NumberFormat>
                )
              </div>
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <div>{props.children}</div>
    </OverlayTrigger>
  );
};

module.exports = YbReqStatTooltip;
