import React from "react";
import { Button } from "react-bootstrap";

function PublisherContactInfo(props) {
  const resellerName = props.resellerName;
  const handleSubmit = props.handleSubmit;

  function handleFormSubmit() {
    console.log("submit");
    handleSubmit();
  }

  return (
    <React.Fragment>
      <div className="mt-4 mb-3">
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            className="ml-2"
            onClick={handleFormSubmit}
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            Confirm and register
          </Button>
        </div>
        <p className="text-secondary text-center">
          <small>
            Once you confirm, you are agreeing that <b>{resellerName}</b> will
            be able to manage your inventory.
          </small>
        </p>
      </div>
    </React.Fragment>
  );
}

export default PublisherContactInfo;
