import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import intowowLogo from "../../../common/images/intowow-logo-180x180.png";
import InviteSignup from "./invite-signup";
import RegisterByInvitation from "./register-by-invitation";
import { Card } from "react-bootstrap";

function Invite(props) {
  const onSignup = props.onSignup;
  useEffect(() => {
    document.body.style.backgroundColor = "#161A30";

    return () => {
      document.body.style.backgroundColor = null;
    };
  });

  return (
    <React.Fragment>
      <div
        style={{
          paddingTop: "80px",
          paddingBottom: "120px",
        }}
      >
        <div className="d-flex justify-content-center align-self-center">
          <div style={{ width: "80%" }}>
            <div className="d-flex justify-content-center pb-4">
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "30px",
                  border: "4px solid black",
                }}
              >
                <img src={intowowLogo} width="52px" />
              </div>
            </div>

            <Card
              style={{
                borderRadius: "8px",
                padding: "64px",
                background: "#F1F5F7",
              }}
            >
              <Card.Body>
                <Switch>
                  <Route exact path="/invite/signup" component={InviteSignup} />

                  <Route
                    exact
                    path="/invite/register"
                    render={(props) => (
                      <RegisterByInvitation {...props} onSignup={onSignup} />
                    )}
                  />

                  <Route
                    render={(props) => (
                      <div className="text-center">
                        <h2>
                          Sorry, we cannot find the page you are looking for.
                        </h2>
                        <hr />

                        <p>
                          Do you meant to login?{" "}
                          <a href="#/login">You can login here</a>
                        </p>
                      </div>
                    )}
                  />
                </Switch>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Invite;
