import React, { useRef } from "react";
import Popup from "reactjs-popup";

const BetterPopupWrapper = (props) => {
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();
  const toggleTooltip = () => ref.current.toggle();

  const {
    triggerElement,
    contentElement,
    triggerType,
    position,
    keepInside,
  } = props;

  return (
    <>
      {/* <button type="button" className="button" onClick={openTooltip}>
        open
      </button>
      <button type="button" className="button" onClick={closeTooltip}>
        close
      </button>

      <button type="button" className="button" onClick={toggleTooltip}>
        toggle
      </button> */}
      <Popup
        ref={ref}
        contentStyle={{
          // width: "auto", // control width in contentElement
          // zIndex: 9999999999,
          // padding: "8px 16px",
          // // margin: 0,
          backgroundColor: "#FFFFFF",
          border: "1px solid #d7d7d7",
          borderRadius: "5px",
          // sharp shadow with decreasing alpha
          boxShadow: `0 1px 1px rgba(0,0,0,0.25),
            0 2px 2px rgba(0,0,0,0.20),
            0 4px 4px rgba(0,0,0,0.15),
            0 8px 8px rgba(0,0,0,0.10),
            0 16px 16px rgba(0,0,0,0.05)`,
        }}
        arrowStyle={{
          stroke: "#d7d7d7",
        }}
        trigger={triggerElement}
        on={triggerType || "click"}
        position={position || "top"}
        keepTooltipInside={keepInside || true}
      >
        {contentElement}
      </Popup>
    </>
  );
};

export default BetterPopupWrapper;
