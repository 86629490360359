import React from "react";
import NumberFormat from "./number-format";
import ProgressBar from "./progress-bar";

class InventoryScoreGaugeChart extends React.PureComponent {
  render() {
    const progress = this.props.value / 100;
    // const barColor = "#FFEA82"
    //const trailColor =  "#eee"
    const trailColor = "#cbd5e0";

    const barColor = "#56ecf7";

    const options = {
      strokeWidth: 6,
      color: barColor,
      trailColor,
      trailWidth: 2,
      easing: "easeInOut",
      duration: 1400,
      // text: {
      //   value: progress * 100 + "%",
      //   alignToBottom: false,
      //   autoStyleContainer: true,
      //   style: {
      //     color: "#000",
      //     position: "absolute",
      //     left: "50%",
      //     // top: "50%",
      //     // // bottom: "10%",
      //     // padding: 0,
      //     // margin: 100,
      //     fontSize: "22px",
      //     transform: {
      //       value: "translate(-5%, -5%)",
      //     },
      //   },
      // },
      from: { color: barColor },
      to: { color: "#ED6A5A" },
    };

    return (
      <div style={{ width: "90px", marginTop: "6px" }}>
        <ProgressBar.SemiCircle
          progress={progress <= 1 ? progress : 1}
          // text={progress}
          // options={{ strokeWidth: 4 }}
          options={options}
          initialAnimate={true}
        />
        <div style={styles.dataText}>
          <NumberFormat value={this.props.value} format="0,0"></NumberFormat>
          <span className="font-normal">%</span>
        </div>
      </div>
    );
  }
}

const styles = {
  dataText: {
    textAlign: "center",
    marginTop: "-1.5rem",
    marginBottom: "-.5rem",
    fontSize: "1.25rem",
    fontWeight: 500,
  },
};

export default InventoryScoreGaugeChart;
