import React from "react";
import Select from "react-select";

class FilterCurrencySelector extends React.Component {
  constructor(props) {
    super(props);

    const options = _.map(props.currencies, (c) => {
      return { value: c, label: c };
    });

    const selectedValue = _.find(options, { value: props.selectedCurrency });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    this.props.handleCurrencyChanged(option.value);
  }

  render() {
    const { options, selectedValue } = this.state;
    return (
      <React.Fragment>
        <Select
          styles={customStyles}
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
        />
      </React.Fragment>
    );
  }
}

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 96,
  }),
};

const styles = {
  header: {
    padding: "12px 84px",
  },
  filterBtn: {
    border: "1px solid lightgray",
  },
};

export default FilterCurrencySelector;
