import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { Mutation } from "react-apollo";

import FormStyle from "common/styles/forms.css";
import YbLoading from "common/components/yb-loading";

import { ONBOARD_AD_UNITS } from "../query/mutations";

class OnboardFormWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };

    this.backToSummary = this.backToSummary.bind(this);
    this.onSubmitClicked = this.onSubmitClicked.bind(this);
  }

  onSubmitClicked(saveInventory) {
    const { yieldSetId, publisherId, selectedItems } = this.props;
    var params = {
      variables: {
        input: {
          yieldSetId,
          extUnitIds: _.map(selectedItems, "extId"),
        },
        pubId: publisherId,
      },
    };

    console.log("--------------------");
    console.log("submit params", params);

    saveInventory(params);
  }

  backToSummary() {
    this.props.history.goBack();
  }

  render() {
    const {
      yieldSetId,
      gamNetworkId,
      publisherId,
      selectedItems,
      shouldHideCancelBtn,
      wizard,
      isSubmitDisabled,
    } = this.props;

    var formClass = FormStyle.form;

    if (this.state.submitted) {
      formClass = `${FormStyle.submitted} ${FormStyle.form}`;
    }

    let isSubmitBtnClickable =
      selectedItems && selectedItems.length > 0 && !isSubmitDisabled;

    return (
      <Mutation mutation={ONBOARD_AD_UNITS}>
        {(saveInventory, { loading, error, data }) => {
          var submitBtn = null;
          var submitLabel = (
            <span>
              <i className="fa fa-arrow-right" /> {i18n`Onboard Ad Units`}
            </span>
          );
          let errorMessage = null;

          submitBtn = (
            <button
              className={FormStyle.submitBtn}
              // FormStyle.submitBtn has some weird styling, add marginTop to align button
              style={{
                marginTop: "10px",
              }}
              type="button"
              disabled={!isSubmitBtnClickable}
              onClick={(event) => {
                this.onSubmitClicked(saveInventory);
                event.preventDefault();
              }}
            >
              {submitLabel}
            </button>
          );

          if (loading) {
            submitBtn = (
              <span className={FormStyle.submitLoading}>
                <YbLoading />
              </span>
            );
          }

          if (error) {
            console.log(error);
            errorMessage = (
              <div className="alert alert-danger">
                Error Onboarding Ad Units:
                <div style={{ fontSize: "14px" }}>
                  {error.message && JSON.stringify(error.message, null, 4)}
                </div>
              </div>
            );
          }

          if (data) {
            // onboard success!
            this.props.callbackAfterOnboardSuccess();
            return "";
          }

          return (
            <div style={{ marginTop: "24px" }}>
              <div>{errorMessage}</div>

              <div>{submitBtn}</div>

              <div>
                {!loading && !shouldHideCancelBtn && (
                  <button
                    style={{ marginTop: "25px" }} // to fix weird alignment, wtf
                    className={FormStyle.cancelBtn}
                    onClick={this.backToSummary}
                  >
                    <i className="fa fa-times" /> Back
                  </button>
                )}
              </div>

              {!loading && wizard && wizard.renderPrevStepBtn()}
              {!loading && wizard && wizard.renderExitBtn()}
            </div>
          );
        }}
      </Mutation>
    );
  }
}

module.exports = withRouter(OnboardFormWrapper);
