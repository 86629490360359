import React, { useState } from "react";
import moment from "moment";
import { Button, Table, OverlayTrigger, Popover } from "react-bootstrap";
import PublisherDetailsModal from "./publisher-details-modal";

import {
  SUBSCRIPTION_STATUS,
  TRIAL_STATUS,
} from "../../common/constants/common";
import { PublisherInfoContext } from "../../common/contexts/contexts";
import PublisherInvitationLinkCopy from "./publisher-invitation-link-copy";

function PublishersList(props) {
  const [hoveredPublisherId, setHoveredPublisherId] = useState();

  const [isModalShow, setIsModalShow] = useState(false);
  const [detailsPublisherId, setDetailsPublisherId] = useState();

  const selfPublisherId = _.get(props.userInfo, "reseller.publisherId");
  const publishers = _.filter(props.publishers, (pub) => {
    return pub.publisherId !== selfPublisherId;
  });
  let registeredPublishers = _.filter(publishers, (pub) => pub.isRegistered);
  let notRegisteredPublishers = _.filter(
    publishers,
    (pub) => !pub.isRegistered
  );

  function handleDeletePublisher(pub) {
    props.handleDeletePublisher(pub);
  }

  function handlePublisherHovered(pub, isHovered) {
    if (isHovered) {
      setHoveredPublisherId(pub.publisherId);
    } else {
      setHoveredPublisherId(null);
    }
  }

  function handlePublisherDetailsClicked(event, pub) {
    event.preventDefault();
    event.stopPropagation();

    if (isModalShow) {
      return;
    } else {
      setDetailsPublisherId(pub.publisherId);
      setIsModalShow(true);
    }
  }

  function handleCloseModal() {
    setIsModalShow(false);
  }

  function refreshPublishers() {
    setIsModalShow(false);
    props.refetchPublishers();
  }

  return (
    <React.Fragment>
      <div>
        <label className="text-muted">
          <b>{registeredPublishers.length} REGISTERED</b>
        </label>
      </div>

      {isModalShow && (
        <PublisherDetailsModal
          publisherId={detailsPublisherId}
          isModalShow={isModalShow}
          handleCloseModal={handleCloseModal}
          refreshPublishers={refreshPublishers}
        />
      )}

      <div>
        {registeredPublishers && (
          // TODO: Different hover background color
          <PublisherInfoContext.Consumer>
            {({ handlePublisherChange }) => {
              return (
                <Table bordered>
                  <tbody>
                    {registeredPublishers.map((pub) => {
                      return (
                        <tr
                          key={pub.publisherId}
                          onMouseEnter={() => {
                            handlePublisherHovered(pub, true);
                          }}
                          onMouseLeave={() => {
                            handlePublisherHovered(pub, false);
                          }}
                        >
                          <td
                            className="pt-4 pl-5 pr-5 pb-4"
                            style={{
                              backgroundColor:
                                hoveredPublisherId === pub.publisherId &&
                                "#f7f7f7",
                            }}
                          >
                            <div className="d-flex">
                              <div>
                                <div>
                                  <React.Fragment>
                                    {pub.trialStatus === TRIAL_STATUS.TRIAL ? (
                                      <span
                                        style={{
                                          background: "#F1F447",
                                          color: "#695905",
                                          borderRadius: "12px",
                                          padding: "2px 10px",
                                          fontSize: "12px",
                                          fontWeight: "bold",
                                          marginRight: "8px",
                                        }}
                                      >
                                        TRIAL
                                      </span>
                                    ) : (
                                      <span>
                                        {pub.subscriptionStatus !==
                                          SUBSCRIPTION_STATUS.SUBSCRIBED && (
                                          <span
                                            style={{
                                              background: "#FFA397",
                                              color: "#5C1515",
                                              borderRadius: "12px",
                                              padding: "2px 10px",
                                              fontSize: "12px",
                                              fontWeight: "bold",
                                              marginRight: "8px",
                                            }}
                                          >
                                            TRIAL EXPIRED
                                          </span>
                                        )}
                                      </span>
                                    )}
                                  </React.Fragment>

                                  <React.Fragment>
                                    {pub.subscriptionStatus ===
                                    SUBSCRIPTION_STATUS.SUBSCRIBED ? (
                                      <span
                                        style={{
                                          background: "#84EC9B",
                                          color: "#265E1D",
                                          borderRadius: "12px",
                                          padding: "2px 10px",
                                          fontSize: "12px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        SUBSCRIBED
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          background: "#FFA397",
                                          color: "#5C1515",
                                          borderRadius: "12px",
                                          padding: "2px 10px",
                                          fontSize: "12px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        NOT SUBSCRIBED
                                      </span>
                                    )}
                                  </React.Fragment>
                                </div>
                                <div
                                  style={{
                                    fontSize: "22px",
                                    fontWeight: "bold",
                                    marginTop: "2px",
                                  }}
                                >
                                  {pub.name}
                                  <span
                                    className="text-muted ml-3"
                                    style={{ fontSize: "smaller" }}
                                  >
                                    {pub.isInFreeTrial && (
                                      <small>
                                        Free trial ends in{" "}
                                        {pub.trialInfo.remainingDays} day
                                        {pub.trialInfo.remainingDays > 1 && "s"}
                                      </small>
                                    )}
                                  </span>
                                </div>
                                <div className="text-dark">
                                  Current Plan:{" "}
                                  <b>
                                    {pub.subscriptionPlan
                                      ? pub.subscriptionPlan
                                      : "Not subscribed to any plans"}
                                  </b>
                                </div>
                                <Button
                                  variant="link"
                                  className="m-0 p-0 border-0"
                                  onClick={(e) => {
                                    return handlePublisherDetailsClicked(
                                      e,
                                      pub
                                    );
                                  }}
                                >
                                  Detail Settings
                                </Button>
                              </div>
                            </div>
                          </td>
                          <td
                            width="15%"
                            style={{
                              verticalAlign: "middle",
                              cursor: "pointer",
                              backgroundColor:
                                hoveredPublisherId === pub.publisherId &&
                                "#e7e8ea",
                            }}
                            onClick={() => {
                              const redirectPath = `#/publisher/${pub.publisherId}/summary/overview`;
                              handlePublisherChange(pub, redirectPath);
                            }}
                          >
                            {/* RIGHT */}
                            <div className="d-flex justify-content-end mr-4 text-muted">
                              <div
                                className="d-flex"
                                style={{
                                  color:
                                    hoveredPublisherId === pub.publisherId &&
                                    "#3f4bff",
                                }}
                              >
                                {hoveredPublisherId === pub.publisherId && (
                                  <div className="mt-auto mb-auto mr-2">
                                    <small className="mb-1">
                                      <i>
                                        <b>Go to publisher </b>
                                      </i>
                                    </small>
                                  </div>
                                )}

                                <div
                                  style={{
                                    marginRight:
                                      hoveredPublisherId === pub.publisherId &&
                                      "-16px",
                                  }}
                                >
                                  <i className="fa fa-2x fa-angle-right" />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              );
            }}
          </PublisherInfoContext.Consumer>
        )}
      </div>

      <div style={{ marginTop: "64px" }}>
        <label className="text-secondary">
          <b>{notRegisteredPublishers.length} NOT REGISTERED</b>
        </label>
      </div>

      <div>
        {notRegisteredPublishers && (
          <Table bordered hover>
            <tbody>
              {notRegisteredPublishers.map((pub) => {
                return (
                  <tr key={pub.publisherId}>
                    <td className="pt-4 pl-5 pr-5 pb-4">
                      <div className="d-flex">
                        <div>
                          <div
                            style={{
                              fontSize: "22px",
                              fontWeight: "bold",
                            }}
                          >
                            {pub.name}
                          </div>
                          <div className="text-muted">
                            Created at{" "}
                            {moment(pub.createdAt).format("YYYY/MM/DD")} (
                            {moment(pub.createdAt).fromNow()})
                          </div>
                          <div>
                            <Button
                              variant="link"
                              className="m-0 p-0 border-0"
                              onClick={(e) => {
                                return handlePublisherDetailsClicked(e, pub);
                              }}
                            >
                              Detail Settings
                            </Button>
                          </div>
                        </div>

                        {/* RIGHT */}
                        <div className="ml-auto">
                          <OverlayTrigger
                            rootClose
                            trigger="click"
                            overlay={
                              <Popover
                                id={`popover-invite-${pub.publisherId}`}
                                title={`Invitation Link`}
                                className="p-2"
                                style={{
                                  maxWidth: "640px",
                                  width: "640px",
                                }}
                              >
                                <PublisherInvitationLinkCopy
                                  invitationLink={pub.invitationLink}
                                />
                              </Popover>
                            }
                          >
                            <Button variant="secondary" className="m-4">
                              Get Invitation Link
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            rootClose
                            trigger="click"
                            overlay={
                              <Popover
                                id={`popover-delete-${pub.publisherId}`}
                                title={`Delete ${pub.name}?`}
                                className="p-2"
                                style={{
                                  maxWidth: "320px",
                                  width: "320px",
                                }}
                              >
                                <label>
                                  Once this publisher is deleted, the invitation
                                  link belonging to this publisher will be
                                  deactivated.
                                </label>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    variant="danger"
                                    onClick={() => handleDeletePublisher(pub)}
                                  >
                                    Yes, delete
                                  </Button>
                                </div>
                              </Popover>
                            }
                          >
                            <Button variant="danger">Delete</Button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </React.Fragment>
  );
}

export default PublishersList;
