import React, { useState, useEffect } from "react";
import {
  OverlayTrigger,
  ListGroup,
  Popover,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import { useQuery } from "react-apollo-hooks";
import { QUERY_PUBLISHERS } from "../../publishers/query/publishers";
import { PublisherInfoContext } from "../../common/contexts/contexts";

function PublisherSelectorOverlay(props) {
  let publishers = null;
  let { data, error, loading } = useQuery(QUERY_PUBLISHERS);
  const [filteredPublishers, setFilteredPublishers] = useState(publishers);

  useEffect(() => {
    setFilteredPublishers(publishers);
  }, [data]);

  if (data) {
    publishers = _.reduce(
      _.get(data, "publishers.edges"),
      (result, edge) => {
        const node = edge.node;
        // Do not show reseller
        if (node.publisherId !== props.resellerId) {
          if (node.registered === "REGISTERED") {
            result.push(node);
          }
        }
        return result;
      },
      []
    );
  }

  function replacePubIdInPath(hashpath, pubId) {
    const prefexPath = "#/publisher/";
    const path = hashpath;
    const start = path.substring(path.indexOf(prefexPath), prefexPath.length);
    const middle = pubId;
    const remains = path.substring(prefexPath.length);
    const end = remains.substring(remains.indexOf("/"));
    return start + middle + end;
  }

  function handlePublisherSelected(pub, handlePublisherChange) {
    const redirectPath = replacePubIdInPath(
      window.location.hash,
      pub.publisherId
    );
    handlePublisherChange(pub, redirectPath);
  }

  function handleFilterPublishers(event) {
    const keyword = _.get(event, "target.value");

    const pubs = _.reduce(
      publishers,
      (result, pub) => {
        if (pub.name.includes(keyword)) {
          result.push(pub);
        }

        return result;
      },
      []
    );

    setFilteredPublishers(pubs);
  }

  return (
    <div id="publisher-selector-overlay-wrapper">
      {filteredPublishers && (
        <React.Fragment>
          {error && <div>Cannot query publishers</div>}
          {loading && <div>loading</div>}
          <PublisherInfoContext.Consumer>
            {({ handlePublisherChange }) => {
              return (
                <React.Fragment>
                  <Form className="mt-2 mb-2">
                    <FormControl
                      type="text"
                      placeholder="Type to search for a publisher by name"
                      onChange={handleFilterPublishers}
                    />
                  </Form>

                  <div>
                    <small className="text-muted">
                      {filteredPublishers.length} PUBLISHERS
                    </small>
                  </div>
                  <ListGroup
                    style={{ maxHeight: "320px", overflowY: "scroll" }}
                  >
                    {filteredPublishers.map((pub) => {
                      const isCurrentPub =
                        props.currentPublisher.publisherId === pub.publisherId;
                      return (
                        <ListGroup.Item
                          key={pub.publisherId}
                          action
                          onClick={() => {
                            if (isCurrentPub) return;
                            handlePublisherSelected(pub, handlePublisherChange);
                          }}
                          className={isCurrentPub ? "font-weight-bold" : ""}
                          style={{ cursor: "pointer" }}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  style={{ width: "24px" }}
                                  className="text-left"
                                >
                                  {isCurrentPub && (
                                    <i className="fa fa-check" />
                                  )}
                                </td>
                                <td>{pub.name}</td>
                              </tr>
                            </tbody>
                          </table>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </React.Fragment>
              );
            }}
          </PublisherInfoContext.Consumer>
        </React.Fragment>
      )}
    </div>
  );
}

function PublisherSelectorInHeader(props) {
  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="bottom"
      overlay={
        <Popover
          id="publisher-selector-popover"
          title="Switch publisher"
          className="shadow-lg p-2"
          style={{
            maxWidth: "360px",
            width: "360px",
            backgroundColor: "#f7f7f7",
          }}
        >
          <PublisherSelectorOverlay
            currentPublisher={props.publisher}
            resellerId={props.reseller.publisherId}
          />
        </Popover>
      }
    >
      <Button
        variant="outline-secondary"
        type="button"
        className="border-0 m-0"
      >
        {props.children}
      </Button>
    </OverlayTrigger>
  );
}

export default PublisherSelectorInHeader;
