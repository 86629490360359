import React from "react";
import { Button, Form, Col } from "react-bootstrap";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import * as Yup from "yup";

const PublisherFormSchema = Yup.object().shape({
  publisherName: Yup.string().required(
    "Please fill in your publisher name (Usually it's the company name)"
  ),
});

function PublisherFormBasic(props) {
  const { defaultPublisher, handleNextStep, handleCancel } = props;

  function handlePublisherFormBasicSubmit(values) {
    // if validation passes
    console.log("publihser basic form submit", values);
    handleNextStep(values);
  }

  return (
    <React.Fragment>
      <div className="text-muted mb-4" style={{ letterSpacing: "0.04em" }}>
        <b>BASIC INFORMATION</b>
      </div>

      <Formik
        initialValues={defaultPublisher}
        validationSchema={PublisherFormSchema}
        onSubmit={handlePublisherFormBasicSubmit}
        render={(props) => {
          const { errors, touched, values } = props;

          return (
            <FormikForm onSubmit={props.handleSubmit}>
              <Form.Row className="mb-4">
                <Form.Group as={Col} lg="10" controlId="publisherName">
                  <Form.Label
                    className="text-dark"
                    style={{ fontSize: "18px", fontWeight: "bolder" }}
                  >
                    Publisher Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="publisherName"
                    onChange={props.handleChange}
                    value={values.publisherName}
                    isValid={touched.publisherName && !errors.publisherName}
                    isInvalid={errors.publisherName && touched.publisherName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.publisherName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <div className="d-flex justify-content-end mt-4">
                <Button variant="link" type="button" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="ml-2"
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  Next Step
                </Button>
              </div>
            </FormikForm>
          );
        }}
      />
    </React.Fragment>
  );
}

export default PublisherFormBasic;
