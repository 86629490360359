import _ from "lodash";
import moment from "moment";

export const DATE_RANGE_TYPE = {
  DEFAULT: "DEFAULT",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  LAST_3_MONTHS: "LAST_3_MONTHS",
};
export const DATE_RANGES = [
  DATE_RANGE_TYPE.THIS_MONTH,
  DATE_RANGE_TYPE.LAST_MONTH,
  DATE_RANGE_TYPE.LAST_3_MONTHS,
];
export const DATE_FORMAT = "YYYY-MM-DD";

export const MIN_DATE = moment()
  .subtract(120, "days")
  .startOf("month")
  .toDate();

export function getDateRangeByType(type) {
  const now = moment().startOf("day");
  let startDate, endDate;

  switch (type) {
    case DATE_RANGE_TYPE.DEFAULT: {
      // if curreny day is before the 7th, also show last month data
      if (now.date() <= 7) {
        const d = now.clone().subtract(1, "month");
        startDate = d.startOf("month");
      } else {
        startDate = now.clone().startOf("month");
      }
      endDate = now;
      break;
    }

    case DATE_RANGE_TYPE.THIS_MONTH: {
      startDate = now.clone().startOf("month");
      endDate = now;
      break;
    }

    case DATE_RANGE_TYPE.LAST_MONTH: {
      const d = now.clone().subtract(1, "month");
      startDate = d.startOf("month");
      endDate = d.clone().endOf("month");
      break;
    }

    case DATE_RANGE_TYPE.LAST_3_MONTHS: {
      const d = now.clone().subtract(3, "month");
      startDate = d.startOf("month");
      endDate = now;
      break;
    }

    default: {
      //
    }
  }

  return {
    startDate: startDate.format(DATE_FORMAT),
    endDate: endDate.format(DATE_FORMAT),
  };
}

export function isWeekend(date) {
  const wd = new Date(date).getDay();
  return wd === 0 || wd === 6;
}
