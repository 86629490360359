import React from 'react'

import CommonStyle from 'common/styles/common.css'

import Styles from '../styles/summary.css'

class YbHider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };
  }

  render() {
    var collectionRef = this.props.collectionRef;
    var lineHeight = this.props.lineHeight;
    var direct = this.props.direct;
    var lastHeight = this.state.height;
    var lastItem = null;
    var boundingClientRect = null;
    var style = {
      height: `${lastHeight}px`,
    };

    if (!collectionRef) {
      return <span></span>;
    }

    setTimeout(() => {
      if (direct) {
        lastItem = collectionRef;
      }
      else {
        lastItem = collectionRef.children[collectionRef.children.length-1];
      }

      boundingClientRect = lastItem.getBoundingClientRect();
      this.setState({
        height: Math.floor(boundingClientRect.height - lineHeight),
      });
    }, 350);

    return (
      <span className={Styles.hider} style={style}></span>
    );
  }
};

module.exports = YbHider;
