import React from "react";
import { MetricDescription } from "../constants/metric-descriptions";
import BetterPopupWrapper from "./better-popup-wrapper";

class InfoGraphDescriptionTooltip extends React.PureComponent {
  render() {
    const { metricKey, position, isLarge } = this.props;

    if (!metricKey) {
      return "";
    }

    const m = MetricDescription[metricKey];

    if (!m) {
      return "";
    }

    const { title, img, metrics } = m;

    const contentElement = () => {
      return (
        <>
          <div
            className="text-left"
            style={{
              maxWidth: img ? "800px" : "400px",
              padding: "8px 16px",
              fontSize: "0.85rem",
            }}
          >
            <div className="mb-2" style={{ fontSize: "1.1rem" }}>
              {title}:
            </div>
            <div className="d-flex align-items-center">
              {img && (
                <div style={{ width: "300px", marginRight: "16px" }}>
                  <img src={img} style={{ width: "100%" }}></img>
                </div>
              )}

              <div>
                {metrics.map((tm) => {
                  const {
                    title,
                    symbolShape,
                    symbolColor,
                    desc,
                    desc2,
                    desc3,
                  } = tm;
                  const Symbol = () => {
                    if (symbolShape === "square") {
                      return (
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: symbolColor,
                          }}
                        ></div>
                      );
                    }

                    if (symbolShape === "circle") {
                      return (
                        <div
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: symbolColor,
                            borderRadius: 99,
                          }}
                        ></div>
                      );
                    }

                    if (symbolShape === "line") {
                      return (
                        <div
                          style={{
                            width: "12px",
                            height: "2px",
                            backgroundColor: symbolColor,
                          }}
                        ></div>
                      );
                    }

                    return "";
                  };

                  return (
                    <div className="mb-2" key={title}>
                      <div className="d-flex align-items-center">
                        <Symbol></Symbol>
                        <div
                          className="font-weight-bold"
                          style={{ marginLeft: "8px" }}
                        >
                          {title}
                        </div>
                      </div>

                      <div style={{ marginLeft: "18px", lineHeight: "1.375" }}>
                        <div>{desc}</div>
                        {desc2 && (
                          <div>
                            <br></br>
                            {desc2}
                          </div>
                        )}
                        {desc3 && (
                          <div>
                            <br></br>
                            {desc3}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      );
    };

    return (
      <BetterPopupWrapper
        // ref={this.child}
        position={position || "top"}
        // position="left bottom"
        triggerType="hover"
        triggerElement={
          <span className="px-1" style={{ marginRight: "-8px" }}>
            <i
              className="fa fa-info-circle"
              style={{ color: "#a5a5a5" }}
              aria-hidden="true"
            ></i>
          </span>
        }
        contentElement={contentElement}
        keepInside={true}
      ></BetterPopupWrapper>
    );
  }
  // render() {
  //   const { descKey } = this.props;

  //   if (!descKey) {
  //     return "";
  //   }

  //   if (!MetricDescription[descKey]) return "";

  //   return (
  //     <React.Fragment>
  //       <OverlayTrigger
  //         trigger={["hover", "focus"]}
  //         placement="top-start"
  //         overlay={
  //           <Popover
  //             id={descKey}
  //             className="shadow-lg"
  //             style={{
  //               maxWidth: "900px",
  //               width: "828px",
  //               backgroundColor: "#f7f7f7",
  //             }}
  //           >
  //             <Popover.Content>
  //               <img
  //                 src={MetricDescription[descKey].img}
  //                 style={{
  //                   width: "800px",
  //                   height: MetricDescription[descKey].imgHeight,
  //                 }}
  //               ></img>
  //             </Popover.Content>
  //           </Popover>
  //         }
  //       >
  //         <span id={descKey} className="px-1" style={{ marginRight: "-8px" }}>
  //           <i
  //             className="fa fa-info-circle"
  //             style={{ color: "#a5a5a5" }}
  //             aria-hidden="true"
  //           ></i>
  //         </span>
  //       </OverlayTrigger>
  //     </React.Fragment>
  //   );
  // }
}

export default InfoGraphDescriptionTooltip;
