import React from "react";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";

import Home from "./home";

function getApolloClient(pubId) {
  let headers = {
    "content-type": "text/plain",
  };
  if (pubId) {
    headers["Access-Control-Request-Headers"] =
      "content-type, x-in2w-assume-pub";
    headers["x-in2w-assume-pub"] = pubId;
  }
  // console.log("getting apollo client", pubId, headers);
  const client = new ApolloClient({
    uri: process.env.INTOWOW_GRAPHQL_ENDPOINT + "/graphql",
    headers,
    credentials: "include",
    fetchOptions: {
      useGETForQueries: true,
    },
  });
  return client;
}

function HomeWrapper(props) {
  const { loginAsPubId } = props;

  const client = getApolloClient(loginAsPubId);

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <Home
          loginAsPubId={loginAsPubId}
          handleSwitchLoginAs={props.handleSwitchLoginAs}
        ></Home>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
}

export default HomeWrapper;
