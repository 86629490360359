import React from "react";
import { Button } from "react-bootstrap";
import PublisherInvitationLinkCopy from "./publisher-invitation-link-copy";

function PublisherFormInvite(props) {
  const { publisher, invitationLink, handleDone } = props;

  return (
    <React.Fragment>
      <p
        className="text-dark"
        style={{ fontSize: "18px", fontWeight: "bolder" }}
      >
        Invite <i>{publisher.publisherName}</i> with this special link:
      </p>
      <p>
        This link will redirect publisher to an invitation page that shows their
        publisher profile. The registration will be complete once they confirm
        the invitation and their free trial will start automatically.
      </p>

      <PublisherInvitationLinkCopy invitationLink={invitationLink} />

      <div className="d-flex justify-content-end" style={{ marginTop: "64px" }}>
        <Button
          variant="primary"
          type="button"
          className="ml-2"
          style={{ paddingLeft: "24px", paddingRight: "24px" }}
          onClick={handleDone}
        >
          Done
        </Button>
      </div>
    </React.Fragment>
  );
}

export default PublisherFormInvite;
