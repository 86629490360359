import React, { useState } from "react";
import { Card, Button, Table, Col, Form } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import _ from "lodash";

import CountrySelector from "../../../subscription-and-billing/components/country-selector";

const PublisherFormSchema = Yup.object().shape({
  geoCountryId: Yup.number().required("Please select your GAM country"),
});

const GamProfileForm = (props) => {
  const publisher = props.publisher;
  const defaultPublisher = {
    geoCountryId: publisher.geoCountryId,
  };
  const handleUpdatePublisher = props.handleUpdatePublisher;
  const handleCancel = props.handleCancel;

  function handleCountrySelect(geoCountryId, setValues, values) {
    setValues({ ...values, geoCountryId: geoCountryId });
  }

  return (
    <Formik
      initialValues={defaultPublisher}
      validationSchema={PublisherFormSchema}
      onSubmit={handleUpdatePublisher}
      render={(props) => {
        const { errors, touched, values } = props;

        return (
          <FormikForm onSubmit={props.handleSubmit}>
            {
              <React.Fragment>
                <Form.Row className="mb-4">
                  <Form.Group
                    as={Col}
                    lg="8"
                    controlId="validationFormikGoogleAccountCountry"
                  >
                    <Form.Label
                      className="text-dark"
                      style={{ fontSize: "18px", fontWeight: "bolder" }}
                    >
                      What is your invoicing country?
                    </Form.Label>
                    <Form.Control
                      style={{ display: "none" }}
                      type="number"
                      name="geoCountryId"
                      onChange={props.handleChange}
                      value={values.geoCountryId}
                      isValid={touched.geoCountryId && !errors.geoCountryId}
                      isInvalid={errors.geoCountryId && touched.geoCountryId}
                    />
                    <CountrySelector
                      geoCountryId={values.geoCountryId}
                      handleChange={(geoCountryId) =>
                        handleCountrySelect(
                          geoCountryId,
                          props.setValues,
                          values
                        )
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.geoCountryId}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </React.Fragment>
            }
            <div className="d-flex justify-content-start">
              <Button
                className="text-dark pl-4 pr-4"
                variant="light"
                size="sm"
                onClick={handleCancel}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                className="ml-2 pl-5 pr-5"
                size="sm"
                style={{ paddingLeft: "24px", paddingRight: "24px" }}
              >
                Save
              </Button>
            </div>
          </FormikForm>
        );
      }}
    />
  );
};

function PublisherGamProfile(props) {
  const publisher = props.publisher;
  const handleSubmit = props.handleSubmit;

  const [isEditing, setIsEditing] = useState(false);
  const [isGamDetailsShown, setIsGamDetailsShown] = useState(false);
  const [isCountrySettingHelpOpen, setIsCountrySettingHelpOpen] =
    useState(false);

  function handleShowGamDetails() {
    setIsGamDetailsShown(!isGamDetailsShown);
  }

  function handleToggleCountrySettingHelp() {
    setIsCountrySettingHelpOpen(!isCountrySettingHelpOpen);
  }

  function handleUpdatePublisher(values) {
    const pub = {
      geoCountryId: values.geoCountryId,
      publisherId: publisher.publisherId,
    };
    handleSubmit(pub).then(() => {
      setTimeout(() => {
        setIsEditing(false);
      }, 500);
    });
  }

  function handleIsEditing() {
    setIsEditing(!isEditing);
  }

  return (
    <React.Fragment>
      <Card className="mt-2 shadow-sm">
        <Card.Body className="p-4">
          <div className="text-muted mb-4" style={{ letterSpacing: "0.04em" }}>
            <b>INVOICE SETTING</b>
            <hr />
          </div>

          {isEditing ? (
            <GamProfileForm
              publisher={publisher}
              handleCancel={handleIsEditing}
              handleUpdatePublisher={handleUpdatePublisher}
            />
          ) : (
            <React.Fragment>
              <Table borderless>
                <tbody>
                  <tr>
                    <td className="pl-0 text-secondary">Invoice Country</td>
                    <td width="70%" className="font-weight-bold text-dark">
                      {publisher.geoCountryName}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </React.Fragment>
          )}

          {props.canShowEditButton && (
            <React.Fragment>
              {!isEditing && (
                <Button
                  className="text-dark"
                  variant="light"
                  size="sm"
                  style={{ backgroundColor: "#e6e6e6" }}
                  onClick={handleIsEditing}
                >
                  Change Invoice Setting
                </Button>
              )}
            </React.Fragment>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default PublisherGamProfile;
