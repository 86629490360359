import React from "react";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import YbPagedTableView from "common/components/yb-paged-table-view";
import YbLoadingView from "common/components/yb-loading-view";
import { ACCOUNT_CONFIG } from "common/query/publisher";

import { query, mutation } from "../query/user";

const count = 1000;

class List extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var accountConfig = null;
    var usersCount = 0;

    return (
      <Query
        query={ACCOUNT_CONFIG}
        variables={{ pubId: this.props.publisherId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return (
              <div className={`${CommonStyle.wrapper}`}>
                <div
                  className={`${CommonStyle.textDanger} ${CommonStyle.textView}`}
                >
                  {i18n`ERROR_LOADING_ACCOUNT_CONFIG_ELLIPSIS`}
                </div>
              </div>
            );
          }

          if (data && !accountConfig) {
            accountConfig = data.accountConfig.config;
          }

          return (
            <div className={CommonStyle.wrapper}>
              <Query
                query={query}
                variables={{ count: count, pubId: this.props.publisherId }}
              >
                {({ data, loading, fetchMore, error }) => {
                  if (loading) {
                    return <div />;
                  }

                  if (error) {
                    return <div>{error.message}</div>;
                  }

                  usersCount = data.users.edges.length;

                  const columns = [
                    {
                      name: i18n`EMAIL`,
                      key: "email",
                    },
                  ];

                  data.users = data.users || {
                    edges: [],
                    pageInfo: {},
                  };

                  const rows = data.users.edges.map(({ node }) => {
                    return {
                      id: node.id,
                      data: {
                        email: node.email,
                      },
                    };
                  });

                  return (
                    <YbPagedTableView
                      title={i18n`USERS`}
                      hasMore={data.users.pageInfo.hasNextPage}
                      count={count}
                      rows={rows}
                      columns={columns}
                      onUpdateCursor={(cursor) => {
                        return fetchMore({
                          variables: {
                            count: count,
                            cursor: cursor,
                            pubId: this.props.publisherId,
                          },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) {
                              return prev;
                            }

                            fetchMoreResult.users.edges = [
                              ...prev.users.edges,
                              ...fetchMoreResult.users.edges,
                            ];
                            return fetchMoreResult;
                          },
                        });
                      }}
                    >
                      <Mutation
                        mutation={mutation}
                        update={(cache, { data }) => {
                          const len = data.inviteUsers.users.length;
                          if (!len) {
                            return;
                          }

                          const prev = cache.readQuery({ query });
                          if (!prev.users.pageInfo.hasNextPage) {
                            prev.users.pageInfo.endCursor =
                              data.inviteUsers.users[len - 1].id;
                            const newUsers = data.inviteUsers.users.map(
                              (user) => ({
                                node: user,
                                __typename: "UserEdge",
                              })
                            );
                            prev.users.edges = [
                              ...prev.users.edges,
                              ...newUsers,
                            ];

                            cache.writeQuery({
                              query: query,
                              data: prev,
                            });
                          }
                        }}
                      >
                        {(invite, { data, loading, error }) => {
                          var props = {};
                          var allowUserCreation =
                            accountConfig.userManagementConfig.user
                              .userInvitation.enabled;

                          if (
                            accountConfig.userManagementConfig.user.userCap !==
                            -1
                          ) {
                            if (
                              usersCount >=
                              accountConfig.userManagementConfig.user.userCap
                            ) {
                              allowUserCreation = false;
                            }
                          }

                          if (!allowUserCreation) {
                            props.disabled = true;
                          }

                          return (
                            <form
                              onKeyPress={(event) => {
                                if (
                                  event.key === "Enter" &&
                                  event.target.type !== "submit"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              className="form-inline float-right"
                              onSubmit={(_) =>
                                invite({
                                  variables: {
                                    email: this.emailInput.value,
                                    pubId: this.props.publisherId,
                                  },
                                }).then((_) => (this.emailInput.value = ""))
                              }
                            >
                              <input
                                {...props}
                                className="form-control mx-2"
                                placeholder={i18n`EXAMPLE_AT_DOMAIN_DOT_COM_ELLIPSIS`}
                                required
                                type="email"
                                ref={(ref) => (this.emailInput = ref)}
                              />
                              {!props.disabled ? (
                                <button
                                  disabled={loading}
                                  key="add"
                                  type="submit"
                                  className={CommonStyle.buttonSuccess}
                                >
                                  <i
                                    className="fa fa-user-plus"
                                    aria-hidden="true"
                                  />
                                </button>
                              ) : (
                                <button
                                  disabled
                                  key="add"
                                  className={CommonStyle.buttonSuccess}
                                >
                                  <i
                                    className="fa fa-user-plus"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}
                            </form>
                          );
                        }}
                      </Mutation>
                    </YbPagedTableView>
                  );
                }}
              </Query>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default List;
