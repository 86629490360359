import React from "react";

// import CommonStyle from "common/styles/common.css";
import Constants from "common/constants/constants";
// import YbTooltip from "common/components/yb-tooltip";

import Styles from "../styles/summary.css";
import YbStatus from "../components/yb-status";
import MCMLabel from "../../_newapp/onboarding/components/mcm-label";

class YbInventoryItem extends React.Component {
  constructor(props) {
    super(props);
  }

  haveProblems(item) {
    if (!item.problems) {
      return false;
    }

    return !!item.problems.filter((problem) => {
      return problem !== "NORMAL";
    }).length;
  }

  toggleItem(item) {
    var disableEdit = this.props.disableEdit;
    var toggleItem = this.props.toggleItem;

    if (!this.haveProblems(item) && !disableEdit && toggleItem) {
      this.props.toggleItem(item);
    }
  }

  editItem(item, event) {
    this.props.editItem(item, event);
  }

  render() {
    var displayOnly = this.props.displayOnly;
    var accountConfig = this.props.accountConfig;
    var network = this.props.network;
    var yieldSet = this.props.yieldSet;
    var item = this.props.item;
    var itemIndex = this.props.itemIndex;
    var wrapperClass = this.props.wrapperClass;
    var editTooltip = this.props.editTooltip;
    var itemClass = Styles.inventoryItem;
    // var adSizes = <span></span>;
    var haveProblems = this.haveProblems(item);
    var currency =
      Constants.currencies[network.currency] || network.currency || "$";
    var showAdSenseFloorPrice = false;
    var showAdXFloorPrice = false;
    var disableEdit = this.props.disableEdit;

    if (!displayOnly) {
      showAdSenseFloorPrice =
        yieldSet.adsenseAccount &&
        accountConfig.gamNetwork.adsense.floorPriceSetting.enabled;
      showAdXFloorPrice =
        yieldSet.adxAccount &&
        accountConfig.gamNetwork.adx.floorPriceSetting.enabled;
    }

    if (item.selected) {
      itemClass += " " + Styles.selected;
    }

    if (haveProblems || disableEdit) {
      itemClass += " " + Styles.haveProblems;
    }

    // adSizes = (
    //   <div className={`row ${Styles.property}`}>
    //     <div className={`col-md-3 ${Styles.propertyName}`}>{i18n`SIZES`}:</div>
    //     <div className={`col-md-9 ${Styles.propertyValue}`}>
    //       {item.adSizes.map((size, index) => {
    //         var optimization = true;
    //         var sizeClass = '';

    //         if (!displayOnly) {
    //           optimization = yieldSet.optimizations.find(optSize => optSize.sizeId === size.sizeId);
    //         }

    //         if (haveProblems && !optimization && yieldSet.adsenseAccount) {
    //           sizeClass = CommonStyle.textDanger;
    //         }

    //         return (
    //           <div key={index} className={sizeClass}>
    //             {`${size.width} X ${size.height}`}
    //           </div>
    //         );
    //       })}
    //     </div>
    //   </div>
    // );

    return (
      <div className={wrapperClass}>
        <span className={Styles.connector}></span>
        <div className={itemClass} onClick={this.toggleItem.bind(this, item)}>
          <div className={`row ${Styles.titleWrapper}`}>
            <div className="col-md-10">
              <span className={Styles.caret}>
                <i className="fa fa-check"></i>
              </span>
              <span>{`${
                item.parentPath && item.parentPath !== "/"
                  ? item.parentPath
                  : ""
              }/${item.name}`}</span>
              <YbStatus
                displayOnly={displayOnly}
                item={item}
                index={itemIndex}
                total={yieldSet.adUnits ? yieldSet.adUnits.length : 0}
                showStatus={true}
                itemType={this.props.isPlacement ? "placement" : "adunit"}
              />
              <div>
                {item.siteNetworkCode && (
                  <MCMLabel
                    siteNetworkCodes={item.siteNetworkCode}
                    id={item.adUnitId}
                  ></MCMLabel>
                )}
              </div>
            </div>
            {/* {!displayOnly && (
              <div className={`col-md-2 ${CommonStyle.textAlignRight}`}>
                <span className={Styles.performance}></span>
                {!disableEdit ? (
                  <YbTooltip message={editTooltip} position="left">
                    <span
                      className={`${CommonStyle.button} ${Styles.editBtn}`}
                      onClick={this.editItem.bind(this, item)}
                    >
                      <i className="fa fa-pencil"></i>
                    </span>
                  </YbTooltip>
                ) : (
                  <span
                    className={`${CommonStyle.button} ${Styles.editBtn}`}
                    disabled
                  >
                    <i className="fa fa-pencil"></i>
                  </span>
                )}
              </div>
            )} */}
          </div>
          {/* {showAdSenseFloorPrice && (
            <div className={`row ${Styles.property}`}>
              <div className={`col-md-3 ${Styles.propertyName}`}>
                {i18n`ADSENSE_FLOOR_PRICE`}:
              </div>
              <div className={`col-md-9 ${Styles.propertyValue}`}>
                {currency}
                {parseFloat(item.adSenseFloorPrice).toFixed(2)}
              </div>
            </div>
          )} */}
          {showAdXFloorPrice && (
            <div className={`row ${Styles.property}`}>
              <div className={`col-md-3 ${Styles.propertyName}`}>
                {i18n`ADX_FLOOR_PRICE`}:
              </div>
              <div className={`col-md-9 ${Styles.propertyValue}`}>
                {currency}
                {parseFloat(item.adxFloorPrice).toFixed(2)}
              </div>
            </div>
          )}
          {/* {adSizes} */}
        </div>
      </div>
    );
  }
}

module.exports = YbInventoryItem;
