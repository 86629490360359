import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";

import YbLoadingView from "common/components/yb-loading-view";
import YbLoading from "common/components/yb-loading";
import YbTooltip from "common/components/yb-tooltip";
import YbHighlight from "common/components/yb-highlight";
import YbTreeSelector from "common/components/yb-tree-selector";

import YbAddAdSenseBtn from "../../integration/components/yb-add-adsense-btn";
import YbAddAdxBtn from "../../integration/components/yb-add-adx-btn";

import { GET_YIELDSETS } from "../../summary/query/summary";
import {
  CREATE_YIELD_SET,
  GET_AD_SENSE_ACCOUNTS,
  GET_ADX_ACCOUNTS,
} from "../../yield-sets/query/yield-sets";

import UserStyles from "../../user/styles/signup.css";

import Style from "../styles/wizard.css";

class YbWizardStep3 extends React.Component {
  constructor(props) {
    super(props);

    this.yieldSetCreated = this.yieldSetCreated.bind(this);
    this.yieldSetChanged = this.yieldSetChanged.bind(this);
    this.adxAccountChanged = this.adxAccountChanged.bind(this);
    this.adSenseAccountChanged = this.adSenseAccountChanged.bind(this);
    this.createYieldSet = this.createYieldSet.bind(this);
    this.nameChanged = this.nameChanged.bind(this);
    this.addAdSenseAccount = this.addAdSenseAccount.bind(this);
    this.addAdxAccount = this.addAdxAccount.bind(this);
    this.adxOnHide = this.adxOnHide.bind(this);
    this.adSenseOnHide = this.adSenseOnHide.bind(this);
    this.adxAdded = this.adxAdded.bind(this);
    this.adSenseAdded = this.adSenseAdded.bind(this);
    this.handleShowAdSenseChange = this.handleShowAdSenseChange.bind(this);
    this.handleShowAdxChange = this.handleShowAdxChange.bind(this);
    this.getNextStepBtn = this.getNextStepBtn.bind(this);

    this.state = {
      publisherId: props.publisherId,
    };
  }

  addAdSenseAccount() {
    this.props.updateCurrentData((stepData) => {
      stepData.addAdSense = true;
      stepData.showAdSenseComponent = true;
    });
  }

  adSenseOnHide() {
    this.props.updateCurrentData((stepData) => {
      stepData.addAdSense = false;
      stepData.showAdSenseComponent = false;
    });
  }

  addAdxAccount() {
    this.props.updateCurrentData((stepData) => {
      stepData.addAdx = true;
    });
  }

  adxOnHide() {
    this.props.updateCurrentData((stepData) => {
      stepData.addAdx = false;
    });
  }

  adxAdded(data) {
    this.props.updateCurrentData((stepData) => {
      stepData.reloadAdx = true;
      stepData.addAdx = false;
      stepData.adxAccount = data.createAdxAccount.adxAccount.adxAccountId;
    });
  }

  adSenseAdded(data) {
    this.props.updateCurrentData((stepData) => {
      stepData.reloadAdsense = true;
      stepData.addAdSense = false;
      stepData.adSenseAccount =
        data.createAdsenseAccount.adsenseAccount.accountId;
    });

    setTimeout(() => {
      this.props.updateCurrentData((stepData) => {
        stepData.showAdSenseComponent = false;
      });
    });
  }

  yieldSetChanged(yieldSet) {
    this.props.updateCurrentData((stepData) => {
      stepData.selectedYieldSet = yieldSet;
    });
  }

  nameChanged(event) {
    this.props.updateCurrentData((stepData) => {
      stepData.name = event.target.value;
    });
  }

  adxAccountChanged(event) {
    this.props.updateCurrentData((stepData) => {
      stepData.adxAccount = parseInt(event.target.value);
    });
  }

  adSenseAccountChanged(event) {
    this.props.updateCurrentData((stepData) => {
      stepData.adSenseAccount = parseInt(event.target.value);
    });
  }

  handleShowAdSenseChange() {
    this.props.updateCurrentData((stepData) => {
      stepData.showAdSense = !stepData.showAdSense;

      if (!stepData.showAdSense) {
        stepData.adSenseAccount = "";
      }
    });
  }

  handleShowAdxChange() {
    this.props.updateCurrentData((stepData) => {
      stepData.showAdx = !stepData.showAdx;

      if (!stepData.showAdx) {
        stepData.adxAccount = "";
      }
    });
  }

  yieldSetCreated(data) {
    this.props.updateCurrentData((stepData) => {
      stepData.refetch();
      stepData.createdYieldSetId = data.createYieldSet.yieldSet.yieldSetId;
      stepData.radio = "option2";
      stepData.yieldSets = null;
      stepData.isLoading = false;
    });
  }

  createYieldSet() {
    this.props.updateCurrentData((stepData) => {
      var mutationParams = {
        variables: {
          input: {
            name: stepData.name,
            networkId: stepData.selectedNetwork.networkId,
          },
          pubId: this.state.publisherId,
        },
      };

      if (stepData.adSenseAccount && stepData.adSenseAccount != -1) {
        mutationParams.variables.input.adSenseAccountId =
          stepData.adSenseAccount;
      }

      if (stepData.adxAccount && stepData.adxAccount != -1) {
        mutationParams.variables.input.adxAccountId = stepData.adxAccount;
      }

      stepData.createYieldSet(mutationParams);
      stepData.isLoading = true;
    });
  }

  renderAdSenseAccounts(props) {
    var stepData = this.props.stepData;
    var variables = {
      filter: {
        networkId: stepData.selectedNetwork.networkId,
      },
      pubId: this.state.publisherId,
    };

    return (
      <Query
        query={GET_AD_SENSE_ACCOUNTS}
        variables={variables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <YbLoading />;
          }

          if (error) {
            return (
              <span className="text-danger">
                <input className={FormStyle.hiddenInput} required />
                {i18n`ERROR_LOADING_ADSENSE_ACCOUNTS_ELLIPSIS`}
              </span>
            );
          }

          if (stepData.reloadAdsense) {
            stepData.reloadAdsense = false;
            refetch();
          }

          if (data) {
            stepData.adSenseAccounts = data.adsenseAccounts.edges.map(
              (edge) => edge.node
            );
          }

          return (
            <div className={CommonStyle.textAlignLeft}>
              <label>
                <input
                  type="checkbox"
                  checked={stepData.showAdSense}
                  onChange={this.handleShowAdSenseChange}
                />{" "}
                {i18n`AdSense account`}
              </label>
              {/* {stepData.showAdSense && (
                <div className={FormStyle.inputGroupRightButtonExtra}>
                  <select
                    value={stepData.adSenseAccount}
                    onChange={this.adSenseAccountChanged}
                  >
                    <option
                      value=""
                      disabled
                    >{i18n`SELECT_AN_ADSENSE_ACCOUNT_ELLIPSIS`}</option>
                    {data.adsenseAccounts.edges.map((account, index) => {
                      return (
                        <option key={index} value={account.node.accountId}>
                          {account.node.name} ({account.node.clientCode})
                        </option>
                      );
                    })}
                  </select>
                  <span className={FormStyle.rightButtonWrapper}>
                    <YbTooltip
                      message={i18n`ADD_ADSENSE_ACCOUNT`}
                      position="left"
                    >
                      <button
                        key="add"
                        type="button"
                        className={CommonStyle.buttonNoBorder}
                        onClick={this.addAdSenseAccount}
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </YbTooltip>
                  </span>
                  <span className={FormStyle.rightButtonExtraWrapper}>
                    <YbTooltip
                      big={true}
                      position="auto"
                      message={
                        <div>
                          <div className={`${CommonStyle.textAlignLeft}`}>
                            {i18n`After clicking on`}{" "}
                            <span className={Style.plusBtnImg} />{" "}
                            {i18n`Add AdSense Account:`}
                          </div>
                          <br />
                          <div className={`${CommonStyle.textAlignLeft}`}>
                            <YbHighlight
                              text={i18n`1) Complete the Google OAuth with your AdSense email account and password (Unlike AdX account which can be operated simply with Web property code as it is fully integrated into GAM, AdSense is relatively isolated from GAM platform and requires a Goole OAuth for Intowow to operate on behalf of you)`}
                              highlight={i18n`Web property code`}
                            />
                          </div>
                          <br />
                          <div className={CommonStyle.textAlignLeft}>
                            <YbHighlight
                              text={i18n`2) In New AdSense account dialog, enter the Display Name and Web property code(e.g. pub-XXXXXXXXXXXX) and click +Create`}
                              highlight={[
                                i18n`New AdSense account`,
                                i18n`Display Name`,
                                i18n`Web property code`,
                                i18n`Create`,
                              ]}
                            />
                          </div>
                          <br />
                          <div>
                            <span className={Style.adSenseAccountDialog} />
                          </div>
                        </div>
                      }
                    >
                      <span
                        className={`${CommonStyle.buttonLink} ${UserStyles.infoTooltip}`}
                      >
                        <i className="fa fa-info-circle" />
                      </span>
                    </YbTooltip>
                  </span>
                </div>
              )} */}
              <br />
              {this.renderAdxAccounts()}
            </div>
          );
        }}
      </Query>
    );
  }

  renderAdxAccounts(props) {
    var stepData = this.props.stepData;
    var variables = {
      filter: {
        networkId: stepData.selectedNetwork.networkId,
      },
      pubId: this.state.publisherId,
    };

    return (
      <Query
        query={GET_ADX_ACCOUNTS}
        variables={variables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <YbLoading />;
          }

          if (error) {
            return (
              <span className="text-danger">
                <input className={FormStyle.hiddenInput} required />
                {i18n`ERROR_LOADING_ADX_ACCOUNTS_ELLIPSIS`}
              </span>
            );
          }

          if (stepData.reloadAdx) {
            stepData.reloadAdx = false;
            refetch();
          }

          if (data) {
            stepData.adxAccounts = data.adxAccounts.edges.map(
              (edge) => edge.node
            );
          }

          return (
            <div>
              {/* <label>
                <input
                  type="checkbox"
                  checked={stepData.showAdx}
                  onChange={this.handleShowAdxChange}
                />{" "}
                {i18n`AdX account`}
              </label> */}
              {/* {stepData.showAdx && ( */}
              <div className={FormStyle.inputGroupRightButtonExtra}>
                <select
                  value={stepData.adxAccount}
                  onChange={this.adxAccountChanged}
                >
                  <option
                    value=""
                    disabled
                  >{i18n`Select an AdX account....`}</option>
                  {data.adxAccounts.edges.map((account, index) => {
                    return (
                      <option key={index} value={account.node.adxAccountId}>
                        {account.node.name} ({account.node.clientCode})
                      </option>
                    );
                  })}
                </select>
                <span className={FormStyle.rightButtonWrapper}>
                  <YbTooltip message={i18n`ADD_ADX_ACCOUNT`} position="left">
                    <button
                      key="add"
                      type="button"
                      className={CommonStyle.buttonNoBorder}
                      onClick={this.addAdxAccount}
                    >
                      <i className="fa fa-plus" />
                    </button>
                  </YbTooltip>
                </span>
                <span className={FormStyle.rightButtonExtraWrapper}>
                  <YbTooltip
                    big={true}
                    position="auto"
                    message={
                      <div>
                        <div className={`${CommonStyle.textAlignLeft}`}>
                          {i18n`After clicking on `}{" "}
                          <span className={Style.plusBtnImg} />{" "}
                          {i18n`Add AdX Account:`}
                        </div>
                        <br />
                        <div className={`${CommonStyle.textAlignLeft}`}>
                          <YbHighlight
                            text={i18n`In New AdX account dialog, enter the Display Name and Web property code(e.g. ca-pub-XXXXXXXXXXXX) and click +Create`}
                            highlight={[
                              i18n`New AdX account dialog`,
                              i18n`Display Name`,
                              i18n`Web property code`,
                              i18n`Create`,
                            ]}
                          />
                        </div>
                        <br />
                        <div>
                          <span className={Style.adxAccountDialog} />
                        </div>
                      </div>
                    }
                  >
                    <span
                      className={`${CommonStyle.buttonLink} ${UserStyles.infoTooltip}`}
                    >
                      <i className="fa fa-info-circle" />
                    </span>
                  </YbTooltip>
                </span>
              </div>
              {/* )} */}
            </div>
          );
        }}
      </Query>
    );
  }

  renderSelectedYieldSet() {
    var stepData = this.props.stepData;

    if (!stepData.selectedYieldSet) {
      return <span />;
    }

    return (
      <div>
        {/* {stepData.selectedYieldSet.adsenseAccount && (
          <div>
            <br />
            <div className={FormStyle.label}>{i18n`AdSense Account`}</div>
            <select
              value={stepData.selectedYieldSet.adsenseAccount.accountId}
              disabled
            >
              <option
                value={stepData.selectedYieldSet.adsenseAccount.accountId}
              >
                {stepData.selectedYieldSet.adsenseAccount.name}
              </option>
            </select>
          </div>
        )} */}
        {stepData.selectedYieldSet.adxAccount && (
          <div>
            <br />
            <div className={FormStyle.label}>{i18n`AdX Account`}</div>
            <select
              value={stepData.selectedYieldSet.adxAccount.adxAccountId}
              disabled
            >
              <option value={stepData.selectedYieldSet.adxAccount.adxAccountId}>
                {stepData.selectedYieldSet.adxAccount.name}
              </option>
            </select>
          </div>
        )}
      </div>
    );
  }

  getNextStepBtn(nextStepFunc) {
    var stepData = this.props.stepData;
    var accountConfig = this.props.accountConfig;
    var cappedAdUnits = accountConfig.gamAdUnit.gamAdUnitCap;

    if (stepData.isLoading) {
      return (
        <span className={FormStyle.submitLoading}>
          <YbLoading />
        </span>
      );
    }

    if (stepData.radio === "option1") {
      return (
        <button className={FormStyle.submitBtn} onClick={this.createYieldSet}>
          <span>
            <i className="fa fa-arrow-right" /> {i18n`Create yield set`}
          </span>
        </button>
      );
    }

    return (
      <button
        className={FormStyle.submitBtn}
        onClick={(event) => {
          nextStepFunc();
          event.preventDefault();
        }}
      >
        <span>
          <i className="fa fa-arrow-right" /> {i18n`NEXT_STEP`}
        </span>
      </button>
    );
  }

  render() {
    var props = {};
    var selectClass = "";
    var stepData = this.props.stepData;
    var showCreate = false;
    var variables = {
      filter: {
        networkId: stepData.selectedNetwork.networkId,
      },
      pubId: this.state.publisherId,
    };

    stepData.getNextStepBtn = this.getNextStepBtn;

    if (
      stepData.submitted &&
      !stepData.adSenseAccount &&
      !stepData.adxAccount
    ) {
      selectClass = FormStyle.invalid;
    }

    if (stepData.forwarding) {
      return <YbLoadingView />;
    }

    return (
      <Query
        query={GET_YIELDSETS}
        fetchPolicy={"network-only"}
        variables={variables}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return (
              <div
                className={`${CommonStyle.textView} ${CommonStyle.textDanger}`}
              >
                {i18n`ERROR_LOADING_YIELD_SETS_ELLIPSIS`}
              </div>
            );
          }

          if (data) {
            stepData.yieldSets = data.yieldSets.edges.map((edge) => edge.node);
            stepData.refetch = refetch;

            if (stepData.createdYieldSetId) {
              stepData.forwarding = true;
              setTimeout(() => {
                this.props.updateCurrentData((stepData) => {
                  stepData.selectedYieldSet = stepData.yieldSets.find(
                    (yieldSet) => {
                      return yieldSet.yieldSetId === stepData.createdYieldSetId;
                    }
                  );

                  if (stepData.selectedYieldSet) {
                    stepData.createdYieldSetId = null;
                    setTimeout(() => {
                      stepData.nextStepFunc();
                    });
                  }
                });
              });

              return <YbLoadingView />;
            } else if (
              !stepData.radio ||
              (!stepData.yieldSets.length && stepData.radio !== "option1")
            ) {
              if (!stepData.yieldSets.length) {
                stepData.radio = "option1";
              } else {
                stepData.radio = "option2";

                if (!stepData.selectedYieldSet) {
                  setTimeout(() => {
                    this.props.updateCurrentData((stepData) => {
                      stepData.selectedYieldSet = stepData.yieldSets[0];
                    });
                  });
                }
              }
            }
          }

          return (
            <div>
              <div>
                <p>
                  {i18n`A yield set is a set of ad units associated with your AdX account.`}
                </p>

                <div className="alert alert-secondary" role="alert">
                  <p className="text-left">
                    <small>
                      {i18n`All your onboarded ad units are grouped into a default yield set under your Network name.`}
                      <br></br>
                      {i18n`Yield set can also be used as a customized labeling feature to group your ad units. For example, if you have ad units spanning across multiple websites, you can group them by websites, with each yield set containing all ad units under the same website. This makes tracking Intowow performance of your inventory easier on the dashboard.`}
                      <br></br>
                      {i18n`Contact your Solution Engineer if you need further support.`}
                    </small>
                  </p>
                </div>
              </div>

              <br />
              {!!stepData.yieldSets.length && (
                <div className={CommonStyle.textAlignLeft}>
                  <label className={`${FormStyle.inputRadio}`}>
                    <input
                      type="radio"
                      value="option1"
                      name="radio"
                      onChange={this.props.handleOptionChange}
                      checked={stepData.radio === "option1"}
                    />{" "}
                    {i18n`Create new yield set`}
                  </label>
                  <br />
                  <label className={`${FormStyle.inputRadio}`}>
                    <input
                      type="radio"
                      value="option2"
                      name="radio"
                      onChange={this.props.handleOptionChange}
                      checked={stepData.radio === "option2"}
                    />{" "}
                    {i18n`Use an existing yield set`}
                  </label>
                </div>
              )}
              <br />
              {stepData.radio === "option1" && (
                <Mutation mutation={CREATE_YIELD_SET}>
                  {(createYieldSet, { loading, error, data }) => {
                    stepData.createYieldSet = createYieldSet;

                    if (loading) {
                      return <YbLoadingView />;
                    }

                    let errorMessage = "";
                    if (error) {
                      let err = "";
                      if (error.message) {
                        err = error.message.replace("GraphQL error: ", "");
                      } else {
                        err = error.message.match(/capped/) ? (
                          <span>
                            <span>{i18n`You exceeded the maximum number of permitted Yield Sets.`}</span>
                            <br />
                            <span>{i18n`Please use an existing Yield Set.`}</span>
                          </span>
                        ) : (
                          i18n`Error creating Yield Set...`
                        );
                      }

                      errorMessage = (
                        <div
                          style={{
                            backgroundColor: "#ffdddd",
                            margin: "10px",
                            padding: "8px 16px",
                          }}
                        >
                          <span className="text-danger">{err}</span>
                        </div>
                      );

                      setTimeout(() => {
                        this.props.updateCurrentData((stepData) => {
                          stepData.isLoading = false;
                        });
                      }, 0);
                    }

                    if (data) {
                      setTimeout(() => {
                        this.yieldSetCreated(data);
                      }, 0);
                    }

                    showCreate =
                      stepData.radio === "option1" &&
                      stepData.name &&
                      ((stepData.adSenseAccount &&
                        stepData.adSenseAccount !== -1) ||
                        (stepData.adxAccount && stepData.adxAccount !== -1));

                    if (showCreate !== stepData.showCreate) {
                      setTimeout(() => {
                        this.props.updateCurrentData((stepData) => {
                          stepData.showCreate = showCreate;
                        });
                      });
                    }

                    if (stepData.addAdSense) {
                      setTimeout(() => {
                        this.props.updateCurrentData((stepData) => {
                          stepData.addAdSense = false;
                        });
                      }, 0);
                    }

                    return (
                      <div>
                        <div className={CommonStyle.textAlignLeft}>
                          <b>{i18n`In the procedure of creating a Yield Set, Google ad account(AdX) will be setup for Intowow to monetize your inventory with each ad property. Before setting up your yield set, please get all these at hand:`}</b>
                        </div>
                        <br />
                        <div className={CommonStyle.textAlignLeft}>
                          <i
                            className={`fa fa-check ${CommonStyle.highlightedText}`}
                          />{" "}
                          {i18n`The Google ad accounts(AdX) have been linked to your GAM console`}
                        </div>
                        <div className={CommonStyle.textAlignLeft}>
                          <i
                            className={`fa fa-check ${CommonStyle.highlightedText}`}
                          />{" "}
                          <YbHighlight
                            text={i18n`The Display name and Web property code of your account (Admin > Linked accounts > Ad Exchange). If you have multiple AdX accounts, please select the one (usually the primary AdX account) primary for monetization.`}
                            highlight={[
                              i18n`Display name`,
                              i18n`Web property code`,
                              i18n`Admin`,
                              i18n`Linked accounts`,
                              i18n`Ad Exchange/AdSense`,
                            ]}
                          />
                        </div>
                        <div className={FormStyle.row}>
                          <div className={FormStyle.label}>
                            {i18n`NAME`}{" "}
                            <span className={FormStyle.requiredStar}>*</span>{" "}
                            <YbTooltip
                              big={true}
                              position="auto"
                              message={
                                <div className={`${CommonStyle.textAlignLeft}`}>
                                  <YbHighlight
                                    text={i18n`Enter a Name to your yield set. Intowow supports setting up multiple yield sets, the name of your yield set is an alias to help you easily recognize the group of ad units and AdX account (Example: nbc.com)`}
                                    highlight={i18n`Name`}
                                  />
                                </div>
                              }
                            >
                              <span
                                className={`${CommonStyle.buttonLink} ${UserStyles.infoTooltip}`}
                              >
                                <i className="fa fa-info-circle" />
                              </span>
                            </YbTooltip>
                          </div>
                          <div>
                            <input
                              {...props}
                              required
                              type="text"
                              value={stepData.name || ""}
                              onChange={this.nameChanged}
                              placeholder={`${i18n`EX_DOT`} ${
                                stepData.selectedNetwork.name
                              } - ${i18n`YIELD_SET`}`}
                            />
                          </div>
                        </div>
                        <div className={FormStyle.row}>
                          <div className={FormStyle.label}>
                            {i18n`Account`}{" "}
                            <span className={FormStyle.requiredStar}>*</span>{" "}
                            {/* <YbTooltip
                              big={true}
                              position="auto"
                              message={
                                <div className={`${CommonStyle.textAlignLeft}`}>
                                  {i18n`Selecting both AdSense & AdX account give the most benefits from optimization. You can still select only an AdSense or AdX Account.`}
                                </div>
                              }
                            >
                              <span
                                className={`${CommonStyle.buttonLink} ${UserStyles.infoTooltip}`}
                              >
                                <i className="fa fa-info-circle" />
                              </span>
                            </YbTooltip> */}
                          </div>
                          <div className={selectClass}>
                            {/* {this.renderAdSenseAccounts(props)} */}
                            {this.renderAdxAccounts(props)}
                          </div>
                        </div>
                        {!!loading && <YbLoading isText={true} />}
                        <br />
                        {errorMessage}
                        <br />
                        <br />
                      </div>
                    );
                  }}
                </Mutation>
              )}
              {stepData.radio === "option2" && (
                <div>
                  <div className={FormStyle.label}>
                    {i18n`Yield set`}{" "}
                    <span className={FormStyle.requiredStar}>*</span>
                  </div>
                  <YbTreeSelector
                    noSort={true}
                    noHeader={true}
                    short={true}
                    items={[stepData.yieldSets]}
                    value={[]}
                    selectedItems={stepData.selectedYieldSet}
                    onSelect={this.yieldSetChanged}
                    onRemove={this.yieldSetChanged}
                    id="yieldSetId"
                  />
                  {this.renderSelectedYieldSet()}
                </div>
              )}
              {stepData.showAdSenseComponent && (
                <YbAddAdSenseBtn
                  showAdSenseModal={stepData.addAdSense}
                  hideCallback={this.adSenseOnHide}
                  callback={this.adSenseAdded}
                  networkId={stepData.selectedNetwork.networkId}
                  publisherId={this.state.publisherId}
                />
              )}
              <YbAddAdxBtn
                showAdxModal={stepData.addAdx}
                hideCallback={this.adxOnHide}
                callback={this.adxAdded}
                networkId={stepData.selectedNetwork.networkId}
                publisherId={this.state.publisherId}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

module.exports = YbWizardStep3;
