import React from "react";
import { MetricDescription } from "../constants/metric-descriptions";
import { OverlayTrigger, Popover } from "react-bootstrap";

class InfoDescriptionTooltip extends React.PureComponent {
  render() {
    const { metricKey, place } = this.props;

    if (!metricKey) {
      return "";
    }

    const tooltipKey = "desc-tt-" + metricKey;
    const m = MetricDescription[metricKey];

    if (!m) {
      return "";
    }

    const { title, desc, desc2, desc3 } = m;

    return (
      <React.Fragment>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top-start"
          overlay={
            <Popover
              id={tooltipKey}
              className="shadow-lg"
              style={{
                maxWidth: "400px",
                backgroundColor: "#f7f7f7",
              }}
            >
              <Popover.Content>
                <div className="text-left">
                  <div className="font-weight-bold">{title}:</div>
                  <div>{desc}</div>
                  {desc2 && <div className="mt-2">{desc2}</div>}
                  {desc3 && <div className="mt-2">{desc3}</div>}
                </div>
              </Popover.Content>
            </Popover>
          }
        >
          <span
            id={tooltipKey}
            className="px-1"
            style={{ marginRight: "-8px" }}
          >
            <i
              className="fa fa-info-circle"
              style={{ color: "#a5a5a5" }}
              aria-hidden="true"
            ></i>
          </span>
        </OverlayTrigger>
      </React.Fragment>
    );
  }
}

export default InfoDescriptionTooltip;
