import React from "react";

class NewebpayForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.submitForm();
  }

  submitForm() {
    document.getElementById("newebpayform").submit();
  }

  render() {
    const { formdata } = this.props;
    return (
      <form
        id="newebpayform"
        name="form"
        action={formdata.action}
        method={formdata.method}
      >
        <input
          type="hidden"
          name="MerchantID"
          value={formdata.fields.MerchantID}
        />
        <input
          type="hidden"
          name="RespondType"
          value={formdata.fields.RespondType}
        />
        <input
          type="hidden"
          name="CheckValue"
          value={formdata.fields.CheckValue}
        />
        <input
          type="hidden"
          name="TimeStamp"
          value={formdata.fields.TimeStamp}
        />
        <input type="hidden" name="Version" value={formdata.fields.Version} />
        <input type="hidden" name="LangType" value={formdata.fields.LangType} />
        <input
          type="hidden"
          name="MerchantOrderNo"
          value={formdata.fields.MerchantOrderNo}
        />
        <input type="hidden" name="Amt" value={formdata.fields.Amt} />
        <input type="hidden" name="ItemDesc" value={formdata.fields.ItemDesc} />
        <input
          type="hidden"
          name="ReturnURL"
          value={formdata.fields.ReturnURL}
        />
        {/* <input
          type="hidden"
          name="NotifyURL"
          value={formdata.fields.NotifyURL}
        /> */}
        <input type="hidden" name="Email" value={formdata.fields.Email} />
        <input
          type="hidden"
          name="LoginType"
          value={formdata.fields.LoginType}
        />
        <input
          type="hidden"
          name="CREDITAGREEMENT"
          value={formdata.fields.CREDITAGREEMENT}
        />
        <input
          type="hidden"
          name="OrderComment"
          valu={formdata.fields.OrderComment}
        />
        <input
          type="hidden"
          name="TokenTerm"
          value={formdata.fields.TokenTerm}
        />
      </form>
    );
  }
}

module.exports = NewebpayForm;
