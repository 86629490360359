import React from "react";
import { Form, Button, Col, InputGroup } from "react-bootstrap";

import TaiwanInvoiceSetting from "./taiwan-invoice-setting";
import CountrySelector from "./country-selector";

class BillingInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      billingRecipientsString: "",
      isCountryOfInvoiceTaiwan: true,
      billingSetting: props.billingSetting,
      isSubscribe: props.isSubscribe,
      isCompanyNameRequired: true,
      shouldShowCompanyName: true
    };

    this.handleBillingInfoSubmit = this.handleBillingInfoSubmit.bind(this);
    this.handleContactFieldChange = this.handleContactFieldChange.bind(this);
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleRecipientsChange = this.handleRecipientsChange.bind(this);
    this.handleCountrySelect = this.handleCountrySelect.bind(this);
    this.handleInvoiceSettingChange = this.handleInvoiceSettingChange.bind(
      this
    );
  }

  handleCompanyNameChange(e) {
    this.setState({
      billingSetting: {
        ...this.state.billingSetting,
        companyName: e.target.value
      }
    });
  }

  handleContactFieldChange(field, e) {
    const value = e.target.value;

    this.setState({
      billingSetting: {
        ...this.state.billingSetting,
        billingContact: {
          ...this.state.billingSetting.billingContact,
          [field]: value
        }
      }
    });
  }

  handleRecipientsChange(e) {
    this.setState({ billingRecipientsString: e.target.value });
  }

  handleInvoiceSettingChange(newInvoiceSetting) {
    this.setState({
      isCompanyNameRequired:
        newInvoiceSetting.type === "TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE",
      shouldShowCompanyName:
        newInvoiceSetting.type === "TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE",
      billingSetting: {
        ...this.state.billingSetting,
        invoiceSetting: newInvoiceSetting,
        companyName: ""
      }
    });
  }

  handleCountrySelect(geoCountryId) {
    // if country selected is Taiwan
    if (geoCountryId === 45) {
      this.setState({
        isCountryOfInvoiceTaiwan: true,
        isCompanyNameRequired: true,
        shouldShowCompanyName: true,
        billingSetting: {
          ...this.state.billingSetting,
          geoCountryId: geoCountryId,
          invoiceSetting: {
            type: "TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE" // default value
          }
        }
      });
    } else {
      this.setState({
        isCountryOfInvoiceTaiwan: false,
        isCompanyNameRequired: false,
        shouldShowCompanyName: true,
        billingSetting: {
          ...this.state.billingSetting,
          geoCountryId: geoCountryId,
          invoiceSetting: false
        }
      });
    }
  }

  handleBillingInfoSubmit(e) {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      // pass validation
      let newBillingSetting = this.state.billingSetting;

      newBillingSetting.billingRecipients = [
        newBillingSetting.billingContact.email
      ];
      // transform Billing Recipients from string to array
      if (this.state.billingRecipientsString) {
        const additionalRecipients = this.state.billingRecipientsString.split(
          ","
        );
        newBillingSetting.billingRecipients = newBillingSetting.billingRecipients.concat(
          additionalRecipients
        );
      }

      this.props.handleSubmit(newBillingSetting);
    }
    this.setState({ validated: true });
  }

  render() {
    let billingSetting = this.state.billingSetting;
    return (
      <div
        style={{
          paddingTop: "16px",
          paddingBottom: "16px"
        }}
      >
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleBillingInfoSubmit}
        >
          <Form.Row>
            <Col lg="8">
              <Form.Group controlId="countryOfInvoice">
                <Form.Label>Country of Billing</Form.Label>

                <CountrySelector
                  geoCountryId={billingSetting.geoCountryId}
                  handleChange={geoCountryId =>
                    this.handleCountrySelect(geoCountryId)
                  }
                />
                <Form.Text className="text-muted">
                  The billing country that you are receiving the invoice.
                </Form.Text>
                <Form.Control
                  style={{ display: "none" }}
                  type="number"
                  value={billingSetting.geoCountryId}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select your billing country
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col lg="10">
              {this.state.isCountryOfInvoiceTaiwan && (
                <TaiwanInvoiceSetting
                  invoiceSetting={billingSetting.invoiceSetting}
                  handleChange={this.handleInvoiceSettingChange}
                />
              )}
            </Col>
          </Form.Row>

          <hr style={{ marginTop: "8px", marginBottom: "36px" }} />

          {this.state.shouldShowCompanyName && (
            <Form.Row>
              <Col lg="8">
                <Form.Group>
                  <Form.Label>
                    Company Name{" "}
                    {!this.state.isCompanyNameRequired && (
                      <small className="text-muted">(optional)</small>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={billingSetting.billingContact.companyName}
                    onChange={this.handleCompanyNameChange}
                    required={this.state.isCompanyNameRequired}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your company name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          )}

          <Form.Row>
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={billingSetting.billingContact.firstName}
                onChange={e => this.handleContactFieldChange("firstName", e)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please tell us your first name
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={billingSetting.billingContact.lastName}
                onChange={e => this.handleContactFieldChange("lastName", e)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please tell us your last name
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}"
              value={billingSetting.billingContact.email}
              onChange={e => this.handleContactFieldChange("email", e)}
              required
            />

            <Form.Control.Feedback type="invalid">
              Please enter a valid email address
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Label>
            Contact Phone Number{" "}
            <small className="text-muted">(optional)</small>
          </Form.Label>
          <Form.Row>
            <Form.Group as={Col} md="3" controlId="phoneCountryCode">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">+</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  pattern="^[0-9]+$"
                  value={billingSetting.billingContact.phoneCountryCode}
                  onChange={e =>
                    this.handleContactFieldChange("phoneCountryCode", e)
                  }
                  style={{
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px"
                  }}
                />
                <Form.Text>
                  <a href="https://countrycode.org/" target="_blank">
                    Country Code List
                  </a>
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid country code
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="phoneNumber" md="9">
              <Form.Control
                type="text"
                pattern=".*[0-9].*"
                value={billingSetting.billingContact.phoneNumber}
                onChange={e => this.handleContactFieldChange("phoneNumber", e)}
              />
              <Form.Text className="text-muted">
                We will use this phone number to contact you if there are any
                payment problems (ex: +886 227085799)
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                Please enter a valid phone number
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          {/* 
              <hr />

              <Form.Group controlId="emailRecipients">
                <Form.Label>Additional Invoice Email Recipients</Form.Label>
                <Form.Text className="text-muted">
                  Separate emails with ","
                </Form.Text>
                <Form.Text className="text-muted">
                  ex: a@example.com, b@example.com
                </Form.Text>
                <Form.Control
                  as="textarea"
                  rows="2"
                  value={this.state.billingRecipientsString}
                  onChange={this.handleRecipientsChange}
                />
                <Form.Text className="text-muted">
                  We will send the invoice to the email filled in above, along
                  with these additional recipients
                </Form.Text>
              </Form.Group> */}

          {/* <pre style={{ width: "500px" }}>
            {JSON.stringify(this.state.billingSetting, null, 2)}
          </pre> */}
          <br />
          <div className="text-center">
            <Button type="submit">
              {this.state.isSubscribe ? "Subscribe" : "Save Billing Info"}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

module.exports = BillingInfo;
