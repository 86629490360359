import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { useMutation } from "react-apollo-hooks";
import { Card, Table, Button, Form, Row, Col, Nav } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";

import { UPDATE_PUBLISHER } from "../../publishers/query/publishers";
import { UserInfoContext } from "../../common/contexts/contexts";

const sidebarLinks = [
  {
    title: "General",
    path: ""
  }
];

function ResellerSettings() {
  const [isEditing, setIsEditing] = useState(false);
  const updatePublisher = useMutation(UPDATE_PUBLISHER);

  function handleUpdatePublisher(pub, handleUpdateUserInfo) {
    let input = {};
    if (pub.name) {
      input.name = pub.name;
    }

    return updatePublisher({
      variables: {
        input,
        pubId: pub.publisherId
      }
    })
      .then(response => {
        if (response) {
          cogoToast.success(
            `Successfully updated ${pub.name || publisher.name}`
          );
          handleUpdateUserInfo();
        } else {
          throw `We are unable to update publisher info for ${publisher.name}`;
        }
      })
      .catch(err => {
        console.log("EROROOROROROR", err);
        cogoToast.error(`Something wrong! ${err.message}`, {
          hideAfter: 10
        });
      });
  }

  function handleIsEditing() {
    setIsEditing(true);
  }

  function handleCancel() {
    setIsEditing(false);
  }

  return (
    <React.Fragment>
      <div style={{ margin: "64px 120px" }}>
        <h1 className="display-4">Settings</h1>
        <hr />

        <Row>
          <Col lg="2">
            <Nav
              variant="pills"
              defaultActiveKey="/settings"
              className="flex-column"
              // onSelect={handleTabSelected}
            >
              {sidebarLinks.map((link, i) => {
                return (
                  <Nav.Link
                    key={i}
                    active={true}
                    // eventKey={link.path}
                    // active={isNavLinkActive(link.path)}
                  >
                    {link.title}
                  </Nav.Link>
                );
              })}
            </Nav>
          </Col>
          <Col lg="10">
            <UserInfoContext.Consumer>
              {({ userInfo, handleUpdateUserInfo }) => {
                return (
                  <React.Fragment>
                    {userInfo && (
                      <div>
                        <Card>
                          <Card.Body className="p-4">
                            <div
                              className="text-muted mb-4"
                              style={{ letterSpacing: "0.04em" }}
                            >
                              <b>BASIC INFORMATION</b>
                              <hr />
                            </div>
                            {isEditing ? (
                              <ResellerSettingsForm
                                publisher={userInfo.reseller}
                                handleUpdatePublisher={handleUpdatePublisher}
                                handleUpdateUserInfo={handleUpdateUserInfo}
                                handleCancel={handleCancel}
                              />
                            ) : (
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td className="pl-0 text-secondary">
                                      Reseller Name:
                                    </td>
                                    <td width="70%">
                                      <div className="text-dark font-weight-bold">
                                        {userInfo.reseller.name}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            )}

                            {!isEditing && (
                              <Button
                                className="text-dark"
                                variant="light"
                                size="sm"
                                style={{ backgroundColor: "#e6e6e6" }}
                                onClick={handleIsEditing}
                              >
                                Edit basic information
                              </Button>
                            )}
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </React.Fragment>
                );
              }}
            </UserInfoContext.Consumer>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const ResellerSettingsForm = props => {
  const publisher = props.publisher;
  const handleCancel = props.handleCancel;

  const defaultPublisher = {
    publisherName: publisher.name
  };

  const PublisherFormSchema = Yup.object().shape({
    publisherName: Yup.string().required(
      "Please fill in your reseller name (Usually it's the company name)"
    )
  });

  function handlePublisherFormBasicSubmit(values) {
    const pub = {
      name: values.publisherName,
      publisherId: publisher.publisherId
    };
    props.handleUpdatePublisher(pub, props.handleUpdateUserInfo);
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={defaultPublisher}
        validationSchema={PublisherFormSchema}
        onSubmit={handlePublisherFormBasicSubmit}
        render={props => {
          const { errors, touched, values } = props;

          return (
            <FormikForm onSubmit={props.handleSubmit}>
              <Table borderless>
                <tbody>
                  <tr>
                    <td className="pl-0 text-secondary">Reseller Name:</td>
                    <td width="70%">
                      <Form.Group controlId="publisherName">
                        <Form.Control
                          type="text"
                          name="publisherName"
                          onChange={props.handleChange}
                          value={values.publisherName}
                          isValid={
                            touched.publisherName && !errors.publisherName
                          }
                          isInvalid={
                            errors.publisherName && touched.publisherName
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.publisherName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div className="d-flex justify-content-start">
                <Button
                  className="text-dark pl-4 pr-4"
                  variant="light"
                  size="sm"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  variant="primary"
                  type="submit"
                  className="ml-2 pl-5 pr-5"
                  size="sm"
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  Save
                </Button>
              </div>
            </FormikForm>
          );
        }}
      />
    </React.Fragment>
  );
};

export default ResellerSettings;
