import React from "react";
import { Query } from "react-apollo";
import YbLoadingView from "common/components/yb-loading-view";
import { Card } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";

import PaymentHistoryTable from "../components/payment-history-table";

import { GET_INVOICES } from "../query/payment-history";

/**
 *  /subscription-and-billing/payment-history
 */
function PaymentHistory() {
  return (
    <Query query={GET_INVOICES} fetchPolicy={"network-only"}>
      {({ loading, error, data }) => {
        if (loading) {
          return <YbLoadingView />;
        }

        if (error) {
          console.log("GET_INVOICES", error);
          return "Could not get any payment records, please contact us if there are any questions.";
        }

        let records = _.get(data, "invoices.edges");
        if (records && records.length > 0) {
          records = records.map((record) => {
            return record.node;
          });

          let paymentRecords = transformToPaymentRecords(records);
          // Sort by issue date in descending order
          paymentRecords.sort((a, b) => {
            return new Date(b.issueDate) - new Date(a.issueDate);
          });

          return <PaymentHistoryTable paymentRecords={paymentRecords} />;
        } else {
          return (
            <Card bg="light">
              <Card.Body>
                <Card.Text className="align-middle-center text-center">
                  <br />
                  You have not made any payments to see payment history.
                  <br />
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
          );
        }
      }}
    </Query>
  );
}

function transformToPaymentRecords(records) {
  let paymentRecords = records.map((record) => {
    let paymentRecord = {
      invoiceId: record.invoiceId,
      invoiceNo: record.invoiceNo,
      issueDate: _.get(record, "paymentTermStartDate"),
      isInvoiceValid: _.get(record, "status") === "VALID",
      dueDate: _.get(record, "dueDate"),
      currency: _.get(record, "currency"),
      afterTaxAmount: _.get(record, "afterTaxAmount"),
      paymentDate: _.get(record, "payment.collectionDate"),
      paymentStatus: null,
      billingProfile: _.get(record, "payment.billingProfile"),
      refunds:
        record.refunds && transformRefunds(record.refunds, record.currency),
      description: "",
    };

    let status = _.get(record, "payment.collectionStatus");
    let overallPaymentStatus = _.get(record, "payment.status");

    if (!paymentRecord.isInvoiceValid) {
      paymentRecord.description = "Invalid Invoice";
    }

    // payment.collectionStatus: PAID, FAILED, PENDING
    // Need description when record is not paid or refunded
    if (status === "PAID") {
      paymentRecord.paymentStatus = "SUCCESS";
    }

    if (status === "PENDING") {
      paymentRecord.paymentStatus = "WAITING_FOR_TRANSACTION";
      paymentRecord.description = `Payment is due before ${moment(
        paymentRecord.dueDate
      ).format("YYYY/MM/DD")}`;
    }

    if (status === "FAILED") {
      paymentRecord.paymentStatus = "FAILED";
      paymentRecord.description = `Payment failed, please make sure payment is valid or contact us if there are any questions`;
    }

    if (overallPaymentStatus === "BAD_DEBT") {
      paymentRecord.paymentStatus = "EXPIRED";
      paymentRecord.description = `Payment expired, we have failed to collect payment during this term. Your account will now be suspended, please contact us to arrange payment and renewal of service.`;
    }

    return paymentRecord;
  });

  return paymentRecords;
}

function transformRefunds(refunds, invoiceCurrency) {
  return refunds.map((record) => {
    return {
      refundStatus: record.status,
      refundDate: record.refundDate,
      currency: invoiceCurrency,
      amount: record.amount,
      billingProfile: record.billingProfile,

      allowanceNo: _.get(record, "allowance.allowanceNo"),
      allowanceIssueDate: _.get(record, "allowance.extAllowanceIssueDate"),
    };
  });
}

module.exports = PaymentHistory;
