import React from "react";
import _ from "lodash";
import { Query } from "react-apollo";
import cogoToast from "cogo-toast";

import { withRouter } from "react-router-dom";
import YbLoading from "common/components/yb-loading";
import YbSearch from "common/components/yb-search";
import YbTooltip from "common/components/yb-tooltip";

import Style from "../styles/ad-units.css";
import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import ItemStyle from "common/styles/inventory-item-latest.css";
import TreeStyle from "common/styles/tree-selector-latest.css";

import { GET_UNIT_CANDIDATES } from "../query/queries";
import { formatCandidates } from "../helpers/ad-unit-candidate-formatter-latest";

import YbReqStatTooltip from "../../../ad-units/components/yb-req-stat-tooltip";
import YbReqStatDiagram from "../../../ad-units/components/yb-req-stat-diagram";

import OnboardFormWrapper from "./onboard-form-wrapper";
import OnboardItemComponent from "./onboard-item-component-latest";
import MCMLabel from "./mcm-label";
import ParentUnitLabel from "./parent-unit-label";

const ITEMS_PER_PAGE = 500;

const ONBOARD_LIMIT_UNIT_COUNT = 1000;
const ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE = `Sorry, we are unable to onboard more than ${ONBOARD_LIMIT_UNIT_COUNT} ad units at a time.`;

// the error passed back by graphql
const GRAPHQL_LIMIT_EXCEED_ERROR_MSG =
  "GraphQL error: Unit count limit exceeded when recursively search descendants. Limit: 1000";

function _toastErrorMessage(errorMessage) {
  cogoToast.error(errorMessage, {
    hideAfter: 3,
    heading: "Error Loading Ad Units",
  });
}

const CHILDREN_SELECTION_STATUS = {
  SOME_SELECTED: "SOME_SELECTED",
  ALL_SELECTED: "ALL_SELECTED",
  NONE_SELECTED: "NONE_SELECTED",
};

class AdUnitsOnboardHandler extends React.Component {
  constructor(props) {
    super(props);

    const { rootLayerCandidates } = props;
    this.state = {
      // for top selector
      layers: [rootLayerCandidates],

      // to remember the units in each layer that has been expanded
      drilledDownUnits: [],

      // for bottom selected items table
      selectedItems: [],

      //
      activeQuery: null,
    };

    // for layer header
    this.onAddUnits = this.onAddUnits.bind(this);
    this.onRemoveUnits = this.onRemoveUnits.bind(this);

    // for top selector
    this.onSelectUnit = this.onSelectUnit.bind(this);
    this.onSelectAllChildUnits = this.onSelectAllChildUnits.bind(this);
    this.onDrillDown = this.onDrillDown.bind(this);

    // for bottom selected items table
    this.onRemoveSelectedUnit = this.onRemoveSelectedUnit.bind(this);
    this.onRemoveAllSelectedUnits = this.onRemoveAllSelectedUnits.bind(this);

    this.onBackToSummaryPage = this.onBackToSummaryPage.bind(this);
  }

  onBackToSummaryPage() {
    const { publisherId } = this.props;
    let overviewRoute = "/summary/overview";
    if (publisherId > 0) {
      overviewRoute = `/publisher/${publisherId}/summary/overview`;
    }

    return this.props.history.push(overviewRoute);
  }

  _getUnitFullPath(unit) {
    return `${unit.parentPath === "/" ? "" : unit.parentPath}/${unit.name}`;
  }

  _findRootUnit(targetUnit) {
    let currentUnit = targetUnit;
    while (currentUnit.parentUnit) {
      currentUnit = currentUnit.parentUnit;
    }
    return currentUnit;
  }

  // targetUnit can be leaf or parent
  _recursivelyAddParentSelectedDirectCount(targetUnit) {
    let currentUnit = targetUnit;
    let fullDelta =
      currentUnit.selectedDirectCount ===
      currentUnit.selectableDirectChildUnitCount
        ? 1
        : 0;
    let partialDelta = 0;
    while (currentUnit.parentUnit && (fullDelta !== 0 || partialDelta !== 0)) {
      const pu = currentUnit.parentUnit;
      const oldSelectedDirectCount = pu.selectedDirectCount;
      const oldSelectedPartialCount = pu.selectedPartialDirectCount;

      pu.selectedDirectCount += fullDelta;
      pu.selectedPartialDirectCount += partialDelta;

      pu.childrenSelectionStatus = this._getUnitChildSelectionStatus(pu);

      // <1, 0> ==> simply we have a full child
      // <0, 1> ==> we have a partial child
      // <1, -1> ==> we have a child changing from partial to full
      if (fullDelta === 1) {
        // full after add 1
        if (oldSelectedDirectCount === pu.selectableDirectChildUnitCount - 1) {
          fullDelta = 1;

          // Previous, we report partial so we should subtract it.
          if (pu.selectableDirectChildUnitCount > 1) {
            partialDelta = -1;
          }
          // No need to subtract back
          else {
            partialDelta = 0;
          }
        }
        // becoming partial full
        else if (
          oldSelectedDirectCount === 0 &&
          oldSelectedPartialCount === 0
        ) {
          fullDelta = 0;
          partialDelta = 1;
        } else {
          fullDelta = 0;
          partialDelta = 0;
        }
      } else if (partialDelta === 1) {
        if (oldSelectedPartialCount === 0 && oldSelectedDirectCount === 0) {
          fullDelta = 0;
          partialDelta = 1;
        } else {
          fullDelta = partialDelta = 0;
        }
      } else {
        fullDelta = partialDelta = 0;
      }

      currentUnit = pu;
    }
  }

  // targetUnit can be leaf or parent
  _recursivelySubtractParentSelectedDirectCount(targetUnit) {
    let currentUnit = targetUnit;
    let fullDelta = -1;
    let partialDelta = 0;

    while (currentUnit.parentUnit && (fullDelta !== 0 || partialDelta !== 0)) {
      const pu = currentUnit.parentUnit;
      const oldSelectedDirectCount = pu.selectedDirectCount;
      const oldSelectedPartialCount = pu.selectedPartialDirectCount;

      pu.selectedDirectCount += fullDelta;
      pu.selectedPartialDirectCount += partialDelta;

      pu.childrenSelectionStatus = this._getUnitChildSelectionStatus(pu);

      // <-1, 0>
      // <-1, 1>
      // <0, -1>
      if (fullDelta === -1) {
        if (oldSelectedDirectCount === pu.selectableDirectChildUnitCount) {
          // From full to partial <-1, 1>
          if (pu.selectableDirectChildUnitCount > 1) {
            fullDelta = -1;
            partialDelta = 1;
          }
          // From full to empty <-1, 0>
          else {
            fullDelta = -1;
            partialDelta = 0;
          }
        }
        // From partial to empty
        else if (
          oldSelectedDirectCount === 1 &&
          oldSelectedPartialCount === 0 &&
          partialDelta === 0
        ) {
          fullDelta = 0;
          partialDelta = -1;
        } else {
          fullDelta = partialDelta = 0;
        }
      } else if (partialDelta === -1) {
        // Partial to empty
        if (oldSelectedPartialCount === 1 && oldSelectedDirectCount === 0) {
          fullDelta = 0;
          partialDelta = -1;
        }
        // Partial to partial => No change!
        else {
          fullDelta = partialDelta = 0;
        }
      } else {
        fullDelta = partialDelta = 0;
      }

      currentUnit = pu;
    }
  }

  _getUnitChildSelectionStatus(pu) {
    // All selected:
    // 1. All direct child units are selected
    // => selectedDirectCount > 0 && selectableDirectChildUnitCount === selectedDirectCount
    // 2. All child units are selected
    // => selectedDirectCount > 0 && numOnboardableChildren === selectedDirectCount

    // Some selected:
    // 1. Some direct child units selected
    // => selectedDirectCount > 0
    // 2. Some child units selected
    // => selectedPartialDirectCount > 0

    // None selected:
    // => selectedDirectCount === 0 && selectedPartialDirectCount === 0

    if (pu.selectedDirectCount > 0) {
      if (pu.selectableDirectChildUnitCount === pu.selectedDirectCount) {
        return CHILDREN_SELECTION_STATUS.ALL_SELECTED;
      }

      if (pu.numOnboardableChildren === pu.selectedDirectCount) {
        return CHILDREN_SELECTION_STATUS.ALL_SELECTED;
      }

      return CHILDREN_SELECTION_STATUS.SOME_SELECTED;
    }

    if (pu.selectedPartialDirectCount > 0) {
      return CHILDREN_SELECTION_STATUS.SOME_SELECTED;
    }

    return CHILDREN_SELECTION_STATUS.NONE_SELECTED;
  }

  // bottom selected items table
  onRemoveSelectedUnit(targetUnit) {
    this.onSelectUnit(targetUnit);
  }

  // bottom selected items table
  onRemoveAllSelectedUnits() {
    for (let ru of this.props.rootLayerCandidates) {
      this.onSelectAllChildUnits(ru, { isSelectAll: false });
    }

    setTimeout(() => {
      this.setState({
        selectedItems: [],
      });
    });
  }

  // parent and leaf unit both applicable
  // select or unselect
  onSelectUnit(targetUnit) {
    // console.log("onSelectUnit", targetUnit);
    let newSelectedItems = [];
    // unselect if unit is selected
    if (targetUnit.checkboxStatus === "SELECTED") {
      targetUnit.checkboxStatus = "NOT_SELECTED";

      this._recursivelySubtractParentSelectedDirectCount(targetUnit);

      newSelectedItems = _.without(this.state.selectedItems, targetUnit);
    } else {
      // select if unit is not selected
      targetUnit.checkboxStatus = "SELECTED";

      this._recursivelyAddParentSelectedDirectCount(targetUnit);

      newSelectedItems = [...this.state.selectedItems, targetUnit];
    }

    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  // for layer header
  onAddUnits(targetUnits) {
    for (let targetUnit of targetUnits) {
      targetUnit.checkboxStatus = "SELECTED";

      this._recursivelyAddParentSelectedDirectCount(targetUnit);
    }

    let newSelectedItems = _.union(this.state.selectedItems, targetUnits);
    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  // for layer header
  onRemoveUnits(targetUnits) {
    for (let targetUnit of targetUnits) {
      targetUnit.checkboxStatus = "NOT_SELECTED";

      this._recursivelySubtractParentSelectedDirectCount(targetUnit);
    }

    let newSelectedItems = _.difference(this.state.selectedItems, targetUnits);
    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  // Select all child units or Unselect all child units
  // isSelectAll: true -> Select all child units
  // isSelectAll: false -> Unselect all child units
  onSelectAllChildUnits(targetUnit, { isSelectAll }) {
    if (targetUnit.numOnboardableChildren === 0) return;
    let goSelected = true;

    // if (
    //   targetUnit.childrenSelectionStatus ===
    //   CHILDREN_SELECTION_STATUS.ALL_SELECTED
    // ) {
    //   goSelected = false;
    // } else {
    //   goSelected = true;
    // }
    goSelected = isSelectAll;

    // 1. find child units of targetUnit
    // 2. set all child units' childrenSelectionStatus to ALL_SELECTED
    // 3. filter compatible units inside child units to add to selectedItems

    // 1. find root unit
    // if targetUnit is in root layer: use name
    // if not in root layer: get root parent name from parentPath
    const rootUnit = this._findRootUnit(targetUnit);
    const fullPath = this._getUnitFullPath(targetUnit);

    const pendingOp = (queryError) => {
      if (queryError) {
        if (rootUnit.queryError) return;
        rootUnit.queryError = queryError;

        _toastErrorMessage(queryError.message);

        setTimeout(() => {
          this.setState({
            activeQuery: null,
          });
        });
        return;
      }

      let allChildUnits = _.filter(rootUnit.childUnits, (unit) => {
        return _.startsWith(unit.parentPath, fullPath);
      });

      let allCompatibleUnits = _.filter(allChildUnits, (unit) => {
        return unit.isCompatible && !unit.isOnboarded;
      });

      let newSelectedItems;
      // unselect all child units
      if (goSelected === false) {
        for (let u of allCompatibleUnits) {
          // To avoid over updating, we should only update the lowest selectable nodes
          if (
            u.checkboxStatus !== "NOT_SELECTED" &&
            u.selectableDirectChildUnitCount === 0
          ) {
            this._recursivelySubtractParentSelectedDirectCount(u);
          }
        }
        for (let i = 0; i < allCompatibleUnits.length; ++i) {
          allCompatibleUnits[i].checkboxStatus = "NOT_SELECTED";
        }

        newSelectedItems = _.difference(
          this.state.selectedItems,
          allCompatibleUnits
        );
      } else {
        // selected or partially selected
        for (let u of allCompatibleUnits) {
          // To avoid over updating, we should only update the lowest selectable nodes
          if (
            u.checkboxStatus !== "SELECTED" &&
            u.selectableDirectChildUnitCount === 0
          ) {
            this._recursivelyAddParentSelectedDirectCount(u);
          }
        }
        for (let i = 0; i < allCompatibleUnits.length; ++i) {
          allCompatibleUnits[i].checkboxStatus = "SELECTED";
        }

        newSelectedItems = _.union(
          this.state.selectedItems,
          allCompatibleUnits
        );
      }

      setTimeout(() => {
        this.setState({
          activeQuery: null,
          selectedItems: newSelectedItems,
        });
      });
    };

    // 2. check if rootUnit.childUnits have been cached
    // if has childUnits -> step3
    // if not -> query
    if (rootUnit.queryError) {
      _toastErrorMessage(rootUnit.queryError.message);
    } else if (rootUnit.childUnits) {
      pendingOp();
    } else {
      // if child units have not been queried yet
      this.setState({
        activeQuery: {
          targetUnit,
          pendingOp,
        },
      });
    }
  }

  // layerNum: start from 1 (root layer = 1)
  onDrillDown(targetUnit, layerNum) {
    // console.log("onDrillDown", layerNum, targetUnit);

    // 1. find root unit
    // 2. check if rootUnit.childUnits have been cached
    // 3. find next layer units

    // 1. find root unit
    // if targetUnit is in root layer: use name
    // if not in root layer: get root parent name from parentPath

    const rootUnit = this._findRootUnit(targetUnit);

    const currentLayers = this.state.layers;
    const pendingOp = (queryError) => {
      if (queryError) {
        if (rootUnit.queryError) return;
        rootUnit.queryError = queryError;

        _toastErrorMessage(queryError.message);

        setTimeout(() => {
          this.setState({
            activeQuery: null,
          });
        });
        return;
      }

      const nextLayerUnits = targetUnit.directChildUnits;

      // update layers
      let layerCount = 0;
      let newLayers = [];
      while (layerCount < layerNum) {
        newLayers.push(currentLayers[layerCount]);
        ++layerCount;
      }
      newLayers.push(nextLayerUnits);

      // update drilledDownUnits
      for (let ddu of this.state.drilledDownUnits) {
        ddu.isDrilledDown = false;
      }
      layerCount = 0;
      let newDrilledDownUnits = [];
      while (layerCount < layerNum - 1) {
        const oldDrilledDownUnit = this.state.drilledDownUnits[layerCount];
        oldDrilledDownUnit.isDrilledDown = true;
        newDrilledDownUnits.push(oldDrilledDownUnit);
        ++layerCount;
      }
      newDrilledDownUnits.push(targetUnit);
      targetUnit.isDrilledDown = true;

      setTimeout(() => {
        this.setState({
          activeQuery: null,
          layers: newLayers,
          drilledDownUnits: newDrilledDownUnits,
        });
      });
    };

    // 2. check if rootUnit.childUnits have been cached
    // if has childUnits -> step3
    // if not -> query

    if (rootUnit.queryError) {
      _toastErrorMessage(rootUnit.queryError.message);
    } else if (rootUnit.childUnits) {
      pendingOp();
    } else {
      // if child units have not been queried yet
      this.setState({
        activeQuery: {
          targetUnit,
          pendingOp,
        },
      });
    }

    setTimeout(() => {
      if (layerNum < 2) return;
      const wrapper = document.getElementById("layers-wrapper");
      wrapper.scrollLeft += wrapper.scrollWidth;
    });
  }

  render() {
    const { yieldSetId, gamNetworkId, publisherId, wizard } = this.props;

    const { layers, activeQuery, selectedItems } = this.state;

    let queryComponent = "";

    if (activeQuery) {
      const targetUnit = activeQuery.targetUnit;
      const pendingOp = activeQuery.pendingOp;
      const queryVariables = {
        filter: {
          parentAdUnitIds: [targetUnit.extId],
          gamNetworkId: gamNetworkId,
          recursive: true,
        },
        pubId: publisherId,
      };

      queryComponent = (
        <Query
          query={GET_UNIT_CANDIDATES}
          variables={queryVariables}
          fetchPolicy={"network-only"}
        >
          {({ loading, error, data }) => {
            if (loading) {
              // return <YbLoading />;
              // return (
              //   <div className={Style.loadingInsideOverlay}>
              //     <YbLoading></YbLoading>
              //   </div>
              // );
              return (
                <div
                  // className={Style.loadingInsideOverlay}
                  style={{
                    zIndex: 999999,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fff",
                    opacity: "0.8",
                    border: "1px solid gray",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "not-allowed",
                  }}
                  onClick={(event) => {
                    // console.log("overlay click");
                    event.stopPropagation();
                    return false;
                  }}
                >
                  <YbLoading></YbLoading>
                </div>
              );
            }

            if (error) {
              console.log(error);
              let queryError = {
                message:
                  "Failed to query ad units. Please contact our SE for support.",
              };
              if (error && error.message === GRAPHQL_LIMIT_EXCEED_ERROR_MSG) {
                queryError.message =
                  "Ad units limit exceeded. Please contact our SE for support.";
              }

              pendingOp(queryError);
              return "";
            }

            let candidates = formatCandidates(data.extItems, targetUnit);
            targetUnit.childUnits = candidates;

            pendingOp();

            return "";
          }}
        </Query>
      );
    }

    const isSubmitDisabled = selectedItems.length > ONBOARD_LIMIT_UNIT_COUNT;

    return (
      <React.Fragment>
        <div>
          <div className={Style.loadingOverlayWrapper}>
            {queryComponent}

            <TreeLayers
              layers={layers}
              onSelectUnit={this.onSelectUnit}
              onSelectAllChildUnits={this.onSelectAllChildUnits}
              onDrillDown={this.onDrillDown}
              onAddUnits={this.onAddUnits}
              onRemoveUnits={this.onRemoveUnits}
            ></TreeLayers>
          </div>

          <SelectedItemsTable
            selectedItems={selectedItems}
            isSubmitDisabled={isSubmitDisabled}
            onRemoveSelectedUnit={this.onRemoveSelectedUnit}
            onRemoveAllSelectedUnits={this.onRemoveAllSelectedUnits}
          ></SelectedItemsTable>

          <div>
            <OnboardFormWrapper
              yieldSetId={yieldSetId}
              gamNetworkId={gamNetworkId}
              publisherId={publisherId}
              selectedItems={selectedItems}
              isSubmitDisabled={isSubmitDisabled}
              shouldHideCancelBtn={wizard ? true : false} // wizard has its own prev step btn
              callbackAfterOnboardSuccess={
                wizard
                  ? () => wizard.nextStepFnForStep4(selectedItems)
                  : () => {
                      this.onBackToSummaryPage();
                    }
              }
              wizard={wizard}
            ></OnboardFormWrapper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function SelectedItemsTable(props) {
  let {
    selectedItems,
    isSubmitDisabled,

    // actions
    onRemoveSelectedUnit,
    onRemoveAllSelectedUnits,
  } = props;

  if (!selectedItems.length) {
    return <span />;
  }

  // Sort candidates!
  selectedItems = _.orderBy(selectedItems, ["dailyCompatibleAvgReq"], ["desc"]);

  const hasSelectedParentUnit = _.some(selectedItems, { hasChildren: true });

  return (
    <React.Fragment>
      <div style={{ marginTop: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className={FormStyle.label}>
            {i18n`SELECTED_AD_UNITS`} ({selectedItems.length})
            <span style={{ marginLeft: "12px" }}>
              <button
                className={CommonStyle.buttonLink}
                onClick={onRemoveAllSelectedUnits}
              >
                <i className="fa fa-times" /> {i18n`REMOVE_ALL`}
              </button>
            </span>
          </div>

          <div style={{ display: "flex", gap: "24px" }}>
            {hasSelectedParentUnit && (
              <div style={{ fontSize: "12px", color: "#595959" }}>
                <div style={{ fontWeight: "600" }}>Parent Unit</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ParentUnitLabel></ParentUnitLabel>
                </div>
              </div>
            )}
          </div>
        </div>

        {isSubmitDisabled && (
          <div className="alert alert-danger">
            {ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE}
          </div>
        )}

        <div className={Style.selectedAdUnits}>
          {selectedItems.map((item) => {
            return (
              <div className={ItemStyle.selectedRow} key={item.extId}>
                <div className={ItemStyle.selectedRowStart}>
                  <div style={{ marginRight: "18px" }}>
                    <button
                      className={CommonStyle.buttonLink}
                      onClick={() => onRemoveSelectedUnit(item)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </div>

                  <div>
                    <div>
                      {/* <div className={ItemStyle.unitExtId}>{item.extId}</div> */}
                      <div className={ItemStyle.unitName}>{item.fullPath}</div>

                      {item.hasChildren && <ParentUnitLabel></ParentUnitLabel>}
                    </div>

                    {item.siteNetworkCodes && (
                      <MCMLabel
                        id={item.extId}
                        siteNetworkCodes={item.siteNetworkCodes}
                      ></MCMLabel>
                    )}
                  </div>
                </div>

                <div className={ItemStyle.itemStat}>
                  <YbReqStatTooltip
                    item={item}
                    tooltipKey={item.extId + "-selected-tooltip"}
                  >
                    <YbReqStatDiagram item={item}></YbReqStatDiagram>
                  </YbReqStatTooltip>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

class TreeLayer extends React.Component {
  constructor(props) {
    super(props);

    let paginatedCandidates = _.chunk(this.props.candidates, ITEMS_PER_PAGE);
    let currentPageCount = 0;

    let compatibleUnits = _.filter(this.props.candidates, (item) => {
      return item.isCompatible && !item.isOnboarded;
    });

    this.state = {
      searchText: "",
      // filteredCandidates: this.props.candidates,
      filteredCandidates: paginatedCandidates[currentPageCount],
      paginatedCandidates,
      currentPageCount: ++currentPageCount,
      totalPageCount: paginatedCandidates.length,

      compatibleUnits,
    };

    this.onFilterCandidates = _.debounce(
      this.onFilterCandidates.bind(this),
      500
    );
    this.toggleSelectAllCompatibleUnits =
      this.toggleSelectAllCompatibleUnits.bind(this);
    this.searchTextChange = this.searchTextChange.bind(this);

    this.onShowMoreCandidates = this.onShowMoreCandidates.bind(this);
  }

  componentDidUpdate(prevProps) {
    // console.log(
    //   "componentDidUpdate",
    //   prevProps,
    //   this.props,
    //   prevProps.candidates === this.props.candidates
    // );

    if (prevProps.candidates !== this.props.candidates) {
      let paginatedCandidates = _.chunk(this.props.candidates, ITEMS_PER_PAGE);
      let currentPageCount = 0;

      this.setState({
        searchText: "",
        isSearching: false,
        // filteredCandidates: this.props.candidates,
        filteredCandidates: paginatedCandidates[currentPageCount],
        paginatedCandidates,
        currentPageCount: ++currentPageCount,
        totalPageCount: paginatedCandidates.length,
      });
    }
  }

  searchTextChange(value) {
    this.setState({
      searchText: value,
      isSearching: true,
    });

    this.onFilterCandidates(value);
  }

  onFilterCandidates(searchText) {
    // console.log("onfilter", searchText);
    let regexp = new RegExp(searchText, "i");
    let filteredCandidates = _.filter(this.props.candidates, (item) => {
      // still show item that is being drilled down
      return item.isDrilledDown || item.name.match(regexp);
    });

    if (searchText === "") {
      let currentPageCount = 0;

      this.setState({
        isSearching: false,
        // filteredCandidates: this.props.candidates,
        filteredCandidates: this.state.paginatedCandidates[currentPageCount],
        // paginatedCandidates,
        currentPageCount: ++currentPageCount,
        totalPageCount: this.state.paginatedCandidates.length,
      });
    } else {
      this.setState({
        isSearching: false,
        filteredCandidates,
        currentPageCount: 0,
        totalPageCount: 0,
      });
    }
  }

  onShowMoreCandidates() {
    let newCandidates =
      this.state.paginatedCandidates[this.state.currentPageCount];
    this.setState({
      filteredCandidates: [...this.state.filteredCandidates, ...newCandidates],
      currentPageCount: ++this.state.currentPageCount,
    });
  }

  toggleSelectAllCompatibleUnits() {
    // select from filtered list? Not now
    const { compatibleUnits } = this.state;
    if (compatibleUnits.length === 0) return;

    const { onAddUnits, onRemoveUnits } = this.props;

    if (this.props.isAllCompatibleSelected) {
      return onRemoveUnits(compatibleUnits);
    } else {
      return onAddUnits(compatibleUnits);
    }
  }

  render() {
    const {
      candidates,
      layerNum,
      isAllCompatibleSelected,

      onSelectUnit,
      onSelectAllChildUnits,
      onDrillDown,
    } = this.props;

    if (candidates.length > 0) {
      // Sort candidates!
      let filteredCandidates = _.orderBy(
        this.state.filteredCandidates,
        [
          "hasChildren",
          "isCompatible",
          "childrenOnboardStatus",
          "dailyCompatibleAvgReq",
          "isOnboarded",
        ],
        ["desc", "desc", "desc", "desc", "desc"]
      );

      const totalCandidates = _.flatten(this.state.paginatedCandidates).length;

      return (
        <React.Fragment>
          <TreeLayerHeader
            toggleSelectAllCompatibleUnits={this.toggleSelectAllCompatibleUnits}
            searchTextChange={this.searchTextChange}
            searchText={this.state.searchText}
            isAllCompatibleSelected={isAllCompatibleSelected}
            numCompatibleUnits={this.state.compatibleUnits.length}
          ></TreeLayerHeader>

          {/* <div>
              current page count: {this.state.currentPageCount}/
              {this.state.totalPageCount}
            </div>
            <div>
              candidates count: {filteredCandidates.length}/{totalCandidates}
            </div> */}

          {!this.state.isSearching && this.state.searchText && (
            <div
              style={{
                fontSize: "16px",
                color: "#333333",
                padding: "8px",
                borderTop: "1px solid darkgray",
              }}
            >
              <div>
                Search result matched <b>{filteredCandidates.length}</b> out of{" "}
                {totalCandidates} ad units
              </div>
            </div>
          )}

          {this.state.isSearching ? (
            <div
              style={{
                borderTop: "1px solid darkgray",
              }}
            >
              Searching <i>{this.state.searchText}</i>...
            </div>
          ) : (
            <div className={TreeStyle.itemList}>
              {filteredCandidates.map((candidate) => {
                return (
                  <OnboardItemComponent
                    key={candidate.extId}
                    item={candidate}
                    layerNum={layerNum}
                    onSelectUnit={onSelectUnit}
                    onSelectAllChildUnits={onSelectAllChildUnits}
                    onDrillDown={onDrillDown}
                  ></OnboardItemComponent>
                );
              })}

              {this.state.currentPageCount < this.state.totalPageCount && (
                <button
                  type="button"
                  onClick={this.onShowMoreCandidates}
                  className={ItemStyle.showMoreItemsBtn}
                >
                  Show more ad units
                  <span style={{ fontSize: "smaller", marginLeft: "8px" }}>
                    <i className="fa fa-arrow-down"></i>
                  </span>
                </button>
              )}
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return <NoActiveUnits></NoActiveUnits>;
    }
  }
}

class TreeLayerHeader extends React.PureComponent {
  render() {
    const {
      toggleSelectAllCompatibleUnits,
      searchTextChange,
      searchText,
      isAllCompatibleSelected,
      numCompatibleUnits,
    } = this.props;

    const isSelectAllDisabled = numCompatibleUnits === 0 || searchText;
    const tooltipMessage =
      numCompatibleUnits === 0
        ? "No compatible units to be selected"
        : "Select all compatible units";

    return (
      <div className={TreeStyle.columnHeader}>
        <span className={TreeStyle.checkAllWrapper}>
          {/* show tooltip when button not disabled */}
          {searchText ? (
            <button
              type="button"
              disabled={isSelectAllDisabled}
              className={CommonStyle.button}
              onClick={toggleSelectAllCompatibleUnits}
            >
              <i
                className={`fa ${
                  isAllCompatibleSelected ? "fa-check-square-o" : "fa-square-o"
                }`}
              ></i>
            </button>
          ) : (
            <YbTooltip message={tooltipMessage} position="top">
              <button
                type="button"
                disabled={isSelectAllDisabled}
                className={CommonStyle.button}
                onClick={toggleSelectAllCompatibleUnits}
              >
                <i
                  className={`fa ${
                    isAllCompatibleSelected
                      ? "fa-check-square-o"
                      : "fa-square-o"
                  }`}
                ></i>
              </button>
            </YbTooltip>
          )}
        </span>

        <span className={TreeStyle.seachInputWrapper}>
          <YbSearch
            value={searchText}
            type="text"
            placeholder={i18n`SEARCH_BY_NAME_ELLIPSIS`}
            onChange={searchTextChange}
          />
        </span>
      </div>
    );
  }
}

function NoActiveUnits(props) {
  return (
    <a
      className={`col-md-12 ${TreeStyle.tableColumn} ${CommonStyle.accesibleItem}`}
      disabled
    >
      <div
        className={`row ${TreeStyle.node} ${TreeStyle.leaf} ${TreeStyle.noItems}`}
      >
        <div className="col-md-12" style={{ textAlign: "center" }}>
          No active units
        </div>
      </div>
    </a>
  );
}

function TreeLayers(props) {
  const {
    layers,

    // actions
    onSelectUnit,
    onSelectAllChildUnits,
    onDrillDown,

    onAddUnits,
    onRemoveUnits,
  } = props;

  function _isAllCompatibleSelected(candidates) {
    let selectedUnitsCount = 0;
    let selectableUnitsCount = 0;

    for (let i = 0; i < candidates.length; ++i) {
      const item = candidates[i];
      if (item.isCompatible && !item.isOnboarded) {
        ++selectableUnitsCount;

        if (item.checkboxStatus === "SELECTED") {
          ++selectedUnitsCount;
        }
      }
    }

    return selectedUnitsCount === selectableUnitsCount;
  }

  return (
    <div className={`${CommonStyle.table} ${TreeStyle.treeSelector}`}>
      <div
        id="layers-wrapper"
        style={{
          overflowX: "scroll",
          overscrollBehaviorX: "contain",
          scrollBehavior: "smooth",
          whiteSpace: "nowrap",
          borderTop: "1px solid #9a9a9a",
          backgroundColor: "#f9f9f9",
        }}
      >
        {layers.map((layerItems, layerIndex) => {
          const isAllCompatibleSelected = _isAllCompatibleSelected(layerItems);

          return (
            <div
              key={layerIndex}
              style={{
                width: "500px",
                display: "inline-block",
                verticalAlign: "top",
                border: "0.5px solid lightgray",
                backgroundColor: "#fff",
                whiteSpace: "normal",
              }}
            >
              <TreeLayer
                candidates={layerItems}
                layerNum={layerIndex + 1}
                isAllCompatibleSelected={isAllCompatibleSelected}
                onSelectUnit={onSelectUnit}
                onSelectAllChildUnits={onSelectAllChildUnits}
                onDrillDown={onDrillDown}
                onAddUnits={onAddUnits}
                onRemoveUnits={onRemoveUnits}
              ></TreeLayer>
            </div>
          );
        })}
      </div>
    </div>
  );
}

module.exports = withRouter(AdUnitsOnboardHandler);
