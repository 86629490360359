import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import FilterInventoryPopover from "./filter-inventory-popover";

class FilterInventory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.onOpenPopover = this.onOpenPopover.bind(this);
  }

  onOpenPopover() {
    this.setState({ show: !this.state.show });
  }

  handleClosePopover() {
    this.setState({ show: false });
  }

  render() {
    const {
      isReseller,
      publishers,
      selectedPubId,
      networks,
      selectedNetworkId,
      selectedYieldSetIds,
      handleInventoryFilterChanged,
      yieldSets,
    } = this.props;

    let selectorText = "";

    if (selectedPubId === -1) {
      selectorText = <b>All publishers</b>;
    } else if (selectedNetworkId === -1) {
      if (selectedPubId !== -1) {
        const pName = _.find(publishers, { id: selectedPubId }).name;
        selectorText = (
          <span>
            Publisher: <b>{pName}</b>
          </span>
        );
      } else {
        selectorText = <b>All networks</b>;
      }
    } else if (selectedYieldSetIds !== -1 && selectedNetworkId !== -1) {
      const nName = _.find(networks, { id: selectedNetworkId }).name;
      let yss = _.filter(yieldSets, (y) => {
        return _.indexOf(selectedYieldSetIds, y.id) !== -1;
      });
      let yNames = _.map(yss, "name").join(", ");
      selectorText = (
        <span>
          Network: <b style={{ marginRight: "4px" }}>{nName}</b> Yield Sets(
          {yss.length}): <b>{yNames}</b>
        </span>
      );
    } else if (selectedYieldSetIds === -1 && selectedNetworkId !== -1) {
      const nName = _.find(networks, { id: selectedNetworkId }).name;
      selectorText = (
        <span>
          Network: <b>{nName}</b>
        </span>
      );
    }

    return (
      <React.Fragment>
        <OverlayTrigger
          rootClose={true} // close when clicked outside overlay
          show={this.state.show}
          onToggle={this.onOpenPopover}
          trigger="click"
          placement="bottom-start"
          overlay={
            <Popover
              id="inventory-selector-popover"
              // title="Select Network"
              className="shadow-lg p-2"
              style={{
                maxWidth: "600px",
                width: "600px",
                backgroundColor: "#f7f7f7",
              }}
            >
              <FilterInventoryPopover
                isReseller={isReseller}
                publishers={publishers}
                selectedPubId={selectedPubId}
                networks={networks}
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetIds={selectedYieldSetIds}
                handleInventoryFilterChanged={handleInventoryFilterChanged}
                yieldSets={yieldSets}
                handleClosePopover={this.handleClosePopover}
              ></FilterInventoryPopover>
            </Popover>
          }
        >
          <Button
            variant="light"
            style={styles.filterBtn}
            className="text-dark px-3 d-flex align-items-center"
          >
            <div className="text-truncate" style={styles.filterText}>
              {selectorText}
            </div>
            <span className="fa fa-angle-down ml-2"></span>
          </Button>
        </OverlayTrigger>
      </React.Fragment>
    );
  }
}

const styles = {
  header: {
    padding: "12px 84px",
  },
  filterBtn: {
    border: "1px solid lightgray",
  },
  filterText: {
    maxWidth: "520px",
  },
  currencySelector: {
    width: "120px",
  },
};

export default FilterInventory;
