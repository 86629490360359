import React, { Fragment, useState, useEffect } from "react";

import Select from "react-select";
import _ from "lodash";

// TODO:
// Query google spreadsheets api with a fixed url
// transform data into a lovecode array
// now you can select the damn thing
// ahahhahahahhahahahahhahahaha
function LoveCodeSelector(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLoveCode, setLoveCode] = useState(props.loveCode);
  const [data, setData] = useState({ selectOptions: [] });

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      const codes = await fetchLoveCodes();
      let selectOptions = codes.map(code => {
        return {
          value: code.loveCode,
          label: `${code.loveCode} - ${code.name}`
        };
      });

      setData({ selectOptions });
      setIsLoading(false);
    }

    fetch();
  }, []);

  function handleChange(selectedOption) {
    setLoveCode(selectedOption);
    props.handleSetLoveCode(selectedOption.value);
  }

  return (
    <Fragment>
      <Select
        value={selectedLoveCode}
        onChange={handleChange}
        options={data.selectOptions}
        isLoading={isLoading}
        isSearchable={true}
        placeholder="Select one to donate"
        // TODO: make border red if invalid
        // styles={{
        //   control: (provided, state) => {
        //     return {
        //       ...provided,
        //       borderColor: "red"
        //     };
        //   }
        // }}
      />
    </Fragment>
  );
}

function fetchLoveCodes() {
  const url = `https://spreadsheets.google.com/feeds/list/1pLX6coyCFIkC2gmtJQrcjq2_TZWzlGc_pOjuCimApIk/default/public/values?alt=json`;

  return fetch(url)
    .then(response => {
      return response.json();
    })
    .then(data => {
      let codes = _.get(data, "feed.entry");

      return codes.map(entry => {
        return {
          name: _.get(entry, "gsx$name.$t"),
          loveCode: _.get(entry, "gsx$lovecode.$t")
        };
      });
    })
    .catch(err => {
      console.log("Error fetching love codes from google sheets", err);
    });
}

module.exports = LoveCodeSelector;
