import gql from "graphql-tag";

export const GET_INVOICES = gql`
  query {
    invoices(first: 1000, filter: { status: [VALID, INVALID] }) {
      edges {
        node {
          invoiceId
          invoiceNo
          paymentTermStartDate
          dueDate
          status
          afterTaxAmount
          currency
          payment {
            collectionDate
            collectionStatus
            amount
            status
            billingProfile {
              method
              profile
            }
          }
          refunds {
            status
            amount
            billingProfile {
              method
              profile
            }
            refundDate
            invoice {
              invoiceId
            }
            allowance {
              allowanceId
              allowanceNo
              extAllowanceIssueDate
              extAllowanceStatus
              amount
              dueDate
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_INVOICE_DOWNLOAD_URL = gql`
  query($filter: invoiceDownloadUrlFilter!) {
    invoiceDownloadUrl(filter: $filter) {
      downloadUrl
    }
  }
`;

export const CHECK_FOR_VALID_INVOICE = gql`
  query {
    invoices(first: 1, filter: { status: [VALID] }) {
      edges {
        node {
          invoiceId
          invoiceNo
          paymentTermStartDate
          status
        }
      }
    }
  }
`;

export const GET_BILLING_REPORT_URL = gql`
  query {
    dashboard {
      billReportUrl
    }
  }
`;
