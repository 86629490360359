import React from "react";
import { Modal } from "react-bootstrap";
import PublisherForm from "./publisher-form";

function CreatePublisherModal(props) {
  const isModalShow = props.isModalShow;
  const handleCloseModal = props.handleCloseModal;
  const refreshPublishers = props.refreshPublishers;

  return (
    <Modal
      show={isModalShow}
      size="lg"
      onHide={_ => {
        return;
      }}
    >
      <Modal.Header>
        <Modal.Title>Create Publisher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PublisherForm
          handleCancel={handleCloseModal}
          refreshPublishers={refreshPublishers}
        />
      </Modal.Body>
    </Modal>
  );
}

export default CreatePublisherModal;
