import React, { Fragment } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

function PaymentFailedAlert(props) {
  const paymentStatus = props.paymentStatus;
  const nextPaymentDate = props.nextPaymentDate;
  const finalPaymentDate = props.finalPaymentDate;

  const isFinalWarning =
    props.nextPaymentDate && props.nextPaymentDate === props.finalPaymentDate;

  const paymentHistoryPath = "/subscription-and-billing/payment-history";

  return (
    <Fragment>
      {paymentStatus === "EXPIRED" && (
        <Alert variant="danger">
          <Alert.Heading>Payment Expired.</Alert.Heading>
          <p>
            We have failed to collect payment, please contact us if there are
            any questions.
          </p>
          <hr />
          <p className="mb-0">
            Head over to{" "}
            <Link to={paymentHistoryPath} style={{ color: "inherit" }}>
              <b>payment history</b>
            </Link>{" "}
            to see more detailed payment information.
          </p>
        </Alert>
      )}

      {paymentStatus === "FAILED" && (
        <div>
          {isFinalWarning ? (
            <Alert variant="danger">
              <Alert.Heading>
                Your account will be suspended soon if we are still unable to
                collect your payment.
              </Alert.Heading>
              <p>
                Please make sure your payment and contact information is
                correct, contact us if there are any questions.
              </p>
              <p>
                Head over to{" "}
                <Link to={paymentHistoryPath} style={{ color: "inherit" }}>
                  <b>payment history</b>
                </Link>{" "}
                to see more detailed payment information.
              </p>
              <hr />
              <p className="mb-0">
                We will collect payment one last time on{" "}
                <b>{moment(finalPaymentDate).format("YYYY/MM/DD")}</b>
              </p>
            </Alert>
          ) : (
            <Alert variant="warning">
              <Alert.Heading>
                We failed to collect payment with your current payment method.
              </Alert.Heading>
              <p>
                Keep Intowow running by making sure your payment and contact
                information is correct, contact us if there are any questions.
              </p>
              <p>
                Head over to{" "}
                <Link to={paymentHistoryPath} style={{ color: "inherit" }}>
                  <b>payment history</b>
                </Link>{" "}
                to see more detailed payment information.
              </p>
              <hr />
              <p className="mb-0">
                We will collect payment again on{" "}
                <b>{moment(nextPaymentDate).format("YYYY/MM/DD")}</b>
              </p>
            </Alert>
          )}
        </div>
      )}
    </Fragment>
  );
}

module.exports = PaymentFailedAlert;
