import React from 'react'

import CommonStyle from 'common/styles/common.css'
import Constants from 'common/constants/constants'
import YbTooltip from 'common/components/yb-tooltip'
import YbModal from 'common/components/yb-modal'

import YbSuggestions from '../components/yb-suggestions'

import Styles from 'common/styles/problems.css'

class YbProblems extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);

    this.state = {
      showModal: false,
    };
  }

  cleanProblems(problems) {
    if (!problems) {
      return [];
    }

    return problems.filter(problem => {
      return problem !== 'NORMAL';
    }).map((problem) => {
      if (!Constants.problems[problem]) {
        return problem;
      }

      return Constants.problems[problem];
    });
  }

  onClick(event) {
    this.setState({
      showModal: true,
    });

    event.stopPropagation();
  }

  onHideModal() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    var item = this.props.item;
    var itemType = this.props.itemType;
    var problems = this.cleanProblems(item.problems);
    var position = this.props.position;
    var isTooLong = problems.length > 2;
    var showModal = this.state.showModal;

    if (!problems.length) {
      item.haveProblems = false;
      return <span></span>;
    }

    item.haveProblems = true;

    return (
      <span>
        <span className={`${CommonStyle.buttonLink} ${Styles.problems}`} onClick={this.onClick}>
          {!isTooLong && problems.map((problem, index) => {
            var label = i18n(problem.name || problem) + ((index < problems.length - 1) ? ', ' : '');

            return (
              <span key={index}>{label}</span>
            );
          })}
          {isTooLong && (
            <span>({problems.length} {i18n`PROBLEMS`})</span>
          )}
        </span>
        <YbModal
          show={showModal}
          onHide={this.onHideModal.bind(this)}
          title={i18n`PROBLEMS`}
          noSuccessBtn={true}
          cancelText={i18n`CLOSE`}
          >
          <span className={CommonStyle.textView}>
            <div className={`${CommonStyle.textAlignLeft} ${Styles.problemsList}`}>
              {problems.map((problem, index) => {
                return (
                  <div key={index} className={Styles.problem}>
                    <div className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>
                      {i18n(problem.name || problem)}
                    </div>
                    <YbSuggestions problem={problem} itemType={itemType}/>
                  </div>
                );
              })}
            </div>
          </span>
        </YbModal>
      </span>
    );
  }
};

module.exports = YbProblems;
