import gql from "graphql-tag";

export const QUERY_PUBLISHERS = gql`
  query QUERY_PUBLISHERS {
    publishers(first: 1000) {
      edges {
        node {
          publisherId
          name
          registered
          accountType
          accountStatus {
            trialStatus {
              remainingDays
              billableStartDate
            }
          }
          invitationLinkQuery
          createdAt
        }
      }
    }
  }
`;

export const GET_PUBLISHER_DETAIL = gql`
  query GET_PUBLISHER_DETAIL($pubId: Int!) {
    publishers(assumePubId: $pubId) {
      edges {
        node {
          publisherId
          name
          type
          registered
          invitationLinkQuery
          websites
          googleAccountType

          geoCountry {
            countryId
            name
          }

          accountType

          accountStatus {
            trialStatus {
              remainingDays
              billableStartDate
            }
          }

          createdAt
        }
      }
    }
  }
`;

export const CREATE_UNREGISTERED_PUBLISHER = gql`
  mutation($input: createInvitationLinkInput!) {
    createInvitationLink(input: $input) {
      link
    }
  }
`;

export const DELETE_UNREGISTERED_PUBLISHER = gql`
  mutation($input: deleteUnregisteredPublisherInput!) {
    deleteUnregisteredPublisher(input: $input) {
      result
    }
  }
`;

export const PUBLISHER_SUBSCRIBE_PLAN = gql`
  mutation($input: createPublisherSubscriptionInput!) {
    createPublisherSubscription(input: $input) {
      result
    }
  }
`;

export const UPDATE_PUBLISHER = gql`
  mutation($input: updatePublisherInput!, $pubId: Int!) {
    updatePublisher(input: $input, assumePubId: $pubId) {
      publisher {
        publisherId
        name
        websites
        googleAccountType
        geoCountry {
          countryId
          name
        }
      }
    }
  }
`;
