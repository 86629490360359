import React from "react";
export const UserInfoContext = React.createContext({
  userInfo: {},
  handleUpdateUserInfo: () => {},
});

export const PublisherInfoContext = React.createContext({
  pubId: -1,
  publisherInfo: {},
  handlePublisherChange: () => {},
});

export const LoginAsContext = React.createContext({
  loginAsPubId: null,
  userInfo: {},
  handleLoginAsChange: () => {},
});
