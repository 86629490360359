import gql from "graphql-tag";

export const GET_GAM_ADX_ACCOUNTS = gql`
  query(
    $pcFilter: extPropertyCodesFilter!
    $adxFilter: adxAccountsFilter!
    $pubId: Int
  ) {
    extPropertyCodes(filter: $pcFilter, assumePubId: $pubId) {
      code
    }

    adxAccounts(first: 1000, filter: $adxFilter, assumePubId: $pubId) {
      edges {
        node {
          id
          clientCode
        }
      }
    }
  }
`;
