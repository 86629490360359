import React from "react";
import FadeInWrapper from "./fade-in-wrapper";

class OptimizedLiftMetric extends React.PureComponent {
  render() {
    const { lift } = this.props;

    return (
      <div
        className={`d-flex align-items-center`}
        style={{ color: "#ed8936" }} // orange
      >
        {lift >= 0 ? (
          <div className="pr-1">
            <i
              className="fa fa-arrow-circle-o-up"
              style={{ fontSize: "18px" }}
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          ""
        )}
        <FadeInWrapper key={lift}>
          <span style={{ fontWeight: "500" }}>
            {lift >= 0 ? "+" : "-"}
            {Math.abs(lift)}%
          </span>
        </FadeInWrapper>
      </div>
    );
  }
}

export default OptimizedLiftMetric;
