import gql from "graphql-tag";

export const typenames = {
  edge: "AdsenseAccountEdge",
  connection: "AdsenseAccountConnection",
  node: "AdsenseAccount"
};

export const OAuthConfig = {
  url: "https://accounts.google.com/o/oauth2/v2/auth",
  params: {
    scope: "openid email https://www.googleapis.com/auth/adsense",
    include_granted_scopes: true,
    redirect_uri: window.location.origin,
    response_type: "code",
    client_id: process.env.GOOGLE_SERVICE_CLIENT_ID,
    prompt: "consent",
    access_type: "offline"
  }
};

export const query = gql`
  query($first: Int, $after: ID, $networkId: Int!, $pubId: Int) {
    adsenseAccounts(
      first: $first
      after: $after
      filter: { networkId: $networkId }
      assumePubId: $pubId
    ) @connection(key: "adsenseAccounts", filter: ["filter"]) {
      edges {
        node {
          id
          accountId
          clientCode
          name
          problems
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const mutation = gql`
  mutation CREATE_ADSENSE_ACCOUNT(
    $networkId: Int!
    $authorizationCode: String!
    $clientCode: String!
    $name: String!
    $pubId: Int
  ) {
    createAdsenseAccount(
      input: {
        networkId: $networkId
        authorizationCode: $authorizationCode
        clientCode: $clientCode
        name: $name
      }
      assumePubId: $pubId
    ) {
      adsenseAccount {
        id
        accountId
        clientCode
        name
        problems
      }
    }
  }
`;

export const authorize = gql`
  mutation ADD_ADSENSE_ACCOUNT(
    $accountId: Int!
    $authorizationCode: String!
    $pubId: Int
  ) {
    authorizeAdsenseAccount(
      input: { accountId: $accountId, authorizationCode: $authorizationCode }
      assumePubId: $pubId
    ) {
      adsenseAccount {
        id
        accountId
        clientCode
        name
        problems
      }
    }
  }
`;

export const revoke = gql`
  mutation aaa($accountId: Int!) {
    deauthorizeAdsenseAccount(input: { accountId: $accountId }) {
      adsenseAccount {
        id
        accountId
        clientCode
        name
        problems
      }
    }
  }
`;
