import React from "react";
import InvitationInvalid from "../components/invitation-invalid";
import InvitationFromReseller from "../components/invitation-from-reseller";

import useValidateInvite from "../hooks/use-validate-invite";

function InviteSignup(props) {
  const queryString = props.location.search.substr(1);
  const { isLoading, validateData } = useValidateInvite(queryString);

  if (isLoading) {
    return <h1>loading...</h1>;
  }

  if (validateData && validateData.isValid) {
    return <InvitationFromReseller inviteData={validateData.data} />;
  }

  if (validateData && validateData.message === "Already registered") {
    return (
      <div>
        <h3 className="mb-4">
          Seems like this invitation link has already been signup and
          registered!
        </h3>
        <hr />

        <p>
          You should be able to login directly with the account that you signed
          up with. <a href="#/login">Login here</a>
        </p>

        <p>
          Please contact your reseller if you don't know the account to login
          with.
        </p>
      </div>
    );
  }

  if (validateData && !validateData.isValid) {
    return <InvitationInvalid />;
  } else {
    // TODO: something wrong with the validation
    // return <InvitationInvalid />;
    return "";
  }
}

export default InviteSignup;
