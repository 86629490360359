import gql from "graphql-tag";

export const GET_PUBLISHER_INFO = gql`
  query($pubId: Int!, $input: publishersFilter!) {
    publishers(filter: $input) {
      edges {
        node {
          publisherId
          name
          type
          registered
          geoCountry {
            countryId
            name
          }
          accountType
          billingSetting {
            activeBillingProfile {
              profileId
            }
          }
          accountStatus {
            trialStatus {
              billableStartDate
              remainingDays
            }
          }
        }
      }
    }
    accountConfig(assumePubId: $pubId) {
      config
    }
  }
`;
