import React from "react";
import { Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import YbTooltip from "common/components/yb-tooltip";
import YbLoading from "common/components/yb-loading";

import Styles from "../styles/summary.css";
import { VERIFY_GAM_NETWORK } from "../query/summary";

function VerifyNetworkButton(props) {
  const networkId = props.networkId;
  const publisherId = props.publisherId;

  return (
    <Mutation mutation={VERIFY_GAM_NETWORK}>
      {(verifyNetwork, { loading, error, data }) => {
        if (loading) {
          return <YbLoading darkBackground={true} />;
        }

        if (error) {
          return (
            <YbTooltip
              message="Failed to trigger verify network"
              position="left"
            >
              <span className={CommonStyle.button} disabled>
                <i className="fa fa-refresh text-danger" />
              </span>
            </YbTooltip>
          );
        }

        if (data) {
          props.refetch();
        }

        return (
          <YbTooltip message="Verify again" position="top">
            <span
              className={`${CommonStyle.button} ${Styles.networkBtn}`}
              onClick={event => {
                verifyNetwork({
                  variables: {
                    input: {
                      networkId: networkId
                    },
                    pubId: publisherId
                  }
                });
                event.stopPropagation();
              }}
            >
              <i className="fa fa-refresh" />
            </span>
          </YbTooltip>
        );
      }}
    </Mutation>
  );
}

export default VerifyNetworkButton;
