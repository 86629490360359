import React from "react";
import { Alert, Button, Row, Col, Card } from "react-bootstrap";
import { useQuery } from "react-apollo-hooks";

import { GET_BILLING_SETTING } from "../query/billing-details";
import YbLoading from "../../../common/components/yb-loading";
import PaymentInfoView from "../../../subscription-and-billing/components/payment-info-view";
import BillingInfoView from "../../../subscription-and-billing/components/billing-info-view";

function BillingDetails(props) {
  let { data, error, loading, refetch } = useQuery(GET_BILLING_SETTING);

  function handleFillInBillingInfo() {
    const path = "/subscription-and-billing/billing-setting";
    props.history.push(path);
  }

  const hasBillingInfo = _.get(
    data,
    "billingSetting.activeBillingProfile.profileId"
  );

  return (
    <div>
      {loading && <YbLoading />}

      {error && <div>Something wrong when querying billing setting</div>}

      {hasBillingInfo && (
        <Row>
          <Col>
            <Card>
              <Card.Header>
                Payment Method
                {/* <Button
                            variant="link"
                            size="sm"
                            type="button"
                            className="float-right"
                            onClick={handleUpdatePaymentInfo}
                          >
                            Update
                          </Button> */}
              </Card.Header>
              <Card.Body>
                <PaymentInfoView billingSetting={data.billingSetting || {}} />
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Header>
                Billing Information
                {/* <Button
                            variant="link"
                            size="sm"
                            type="button"
                            className="float-right"
                            onClick={handleUpdateBillingInfo}
                          >
                            Update
                          </Button> */}
              </Card.Header>
              <Card.Body>
                <BillingInfoView billingSetting={data.billingSetting || {}} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {(!data.billingSetting || !hasBillingInfo) && (
        <Alert variant="info" className="text-center p-4">
          <Alert.Heading className="pb-3">
            Fill in billing information so that optimization doesn't stop after
            publisher's free trial
          </Alert.Heading>
          <p>
            Once we make sure your billing information is valid, you will be
            able to add subscription plans to each registered publisher.
          </p>
          <div className="d-flex justify-content-center pt-3">
            <Button onClick={handleFillInBillingInfo} variant="primary">
              Fill In Billing Information
            </Button>
          </div>
        </Alert>
      )}
    </div>
  );
}

export default BillingDetails;
