import React from "react";
import { Modal, Button } from "react-bootstrap";

import RootLayerWrapper from "./root-layer-wrapper-v2";
import BatchOnboardWrapper from "./batch-onboard-wrapper";

import TreeMethodImage from "../images/tree-view-selection-method.jpg";
import BatchMethodImage from "../images/batch-onboarding-method.jpg";

const METHOD_TYPE = {
  TREE: {
    key: "TREE",
    title: "Tree View Selection",
    image: TreeMethodImage,
    description:
      "I would like to view the full scope of my inventory in Tree View.",
  },
  BATCH: {
    key: "BATCH",
    title: "Batch Onboarding",
    image: BatchMethodImage,
    description:
      "Easier to onboard if you have a list of GAM ad unit ids ready.",
  },
};

class OnboardMethodWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      method: null,
      shouldShowModal: false,
    };

    this.handleMethodSelect = this.handleMethodSelect.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
  }

  handleMethodSelect(method) {
    this.setState({ method, shouldShowModal: false });
  }

  onHideModal() {
    this.setState({ shouldShowModal: false });
  }

  render() {
    const { method, shouldShowModal } = this.state;
    const {
      yieldSetId,
      gamNetworkId,
      publisherId,

      wizard, // wizard action buttons
    } = this.props;

    const otherMethod =
      method && method.key === METHOD_TYPE.TREE.key
        ? METHOD_TYPE.BATCH
        : METHOD_TYPE.TREE;

    return (
      <React.Fragment>
        {method ? (
          <div>
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <div
                  style={{
                    textTransform: "uppercase",
                    color: "#4a5568",
                    letterSpacing: "0.025em",
                    fontSize: "1.025rem",
                    fontWeight: "600",
                  }}
                >
                  {method.title}
                </div>

                <button
                  className="btn btn-link"
                  onClick={() => this.setState({ shouldShowModal: true })}
                >
                  Discard and change to <b>{otherMethod.title}</b>
                </button>
                <SwitchModal
                  show={shouldShowModal}
                  onHideModal={this.onHideModal}
                  onConfirm={() => this.handleMethodSelect(otherMethod)}
                ></SwitchModal>
              </div>
              <div className="card-body">
                <MethodPage
                  method={method}
                  yieldSetId={yieldSetId}
                  gamNetworkId={gamNetworkId}
                  publisherId={publisherId}
                  wizard={wizard}
                ></MethodPage>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="card">
              <div className="card-header">
                <span style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                  Choose how you would like to onboard:
                </span>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-around">
                  <MethodCard
                    method={METHOD_TYPE.TREE}
                    handleMethodSelect={this.handleMethodSelect}
                  ></MethodCard>
                  <MethodCard
                    method={METHOD_TYPE.BATCH}
                    handleMethodSelect={this.handleMethodSelect}
                  ></MethodCard>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

class MethodPage extends React.PureComponent {
  render() {
    const { method, yieldSetId, gamNetworkId, publisherId, wizard } =
      this.props;

    if (method.key === METHOD_TYPE.TREE.key) {
      return (
        <RootLayerWrapper
          yieldSetId={yieldSetId}
          gamNetworkId={gamNetworkId}
          publisherId={publisherId}
          wizard={wizard}
        ></RootLayerWrapper>
      );
    } else {
      return (
        <BatchOnboardWrapper
          yieldSetId={yieldSetId}
          gamNetworkId={gamNetworkId}
          publisherId={publisherId}
          wizard={wizard}
        ></BatchOnboardWrapper>
      );
    }
  }
}

class MethodCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  render() {
    const { isHovered } = this.state;
    const { method } = this.props;
    return (
      <div
        className={`card ${isHovered ? "shadow-sm border-primary" : ""}`}
        style={{ cursor: "pointer" }}
        onMouseOver={() => {
          this.setState({ isHovered: true });
        }}
        onMouseOut={() => {
          this.setState({ isHovered: false });
        }}
        onClick={() => {
          this.props.handleMethodSelect(method);
        }}
      >
        <div className="card-body">
          <h5
            style={{
              textTransform: "uppercase",
              color: isHovered ? "#3182ce" : "#4a5568",
              letterSpacing: "0.025em",
              fontSize: "1.025rem",
              fontWeight: "600",
            }}
          >
            {method.title}
          </h5>
          <p
            className="card-text"
            style={{ color: "#718096", fontSize: "1rem" }}
          >
            {method.description}
          </p>

          <div className="d-flex justify-content-center">
            <img src={method.image} />
          </div>
        </div>
      </div>
    );
  }
}

function SwitchModal(props) {
  const { show, onHideModal, onConfirm } = props;
  return (
    <Modal show={show} onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Careful!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The ad units you selected so far will be discarded.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHideModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Discard and change onboarding method
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

module.exports = OnboardMethodWrapper;
