import React from "react";
import ReactDOM from "react-dom";
// import { ApolloProvider } from "react-apollo";
// import ApolloClient from "apollo-boost";
// import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";

import MainApp from "./main-app";

import "react-bootstrap";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";

// for react date range library
import "../../node_modules/react-date-range/dist/styles.css";
import "../../node_modules/react-date-range/dist/theme/default.css";

function App() {
  // const client = new ApolloClient({
  //   uri: process.env.INTOWOW_GRAPHQL_ENDPOINT + "/graphql",
  //   headers: { "content-type": "text/plain" },
  //   credentials: "include",
  //   fetchOptions: {
  //     useGETForQueries: true,
  //   },
  // });

  return (
    // <ApolloProvider client={client}>
    //   <ApolloHooksProvider client={client}>
    <MainApp />
    //   </ApolloHooksProvider>
    // </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("newroot"));
