import React from "react";
import _ from "lodash";
import { Query } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import YbLoadingView from "common/components/yb-loading-view";

// import RootLayerWrapper from "../components/root-layer-wrapper-v2";
import OnboardMethodWrapper from "../components/onboard-method-wrapper";
import { GET_YIELD_SET } from "../query/queries";

class OnboardingAdUnits extends React.Component {
  render() {
    const yieldSetId = parseInt(_.get(this.props, "match.params.yieldSetId"));
    // If not Reseller, publisherId will be undefined
    const publisherId = _.get(this.props, "match.params.publisherId");

    const queryVariables = {
      filter: {
        yieldSetIds: [yieldSetId],
      },
      pubId: publisherId,
    };

    return (
      <Query
        query={GET_YIELD_SET}
        variables={queryVariables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            console.log(error);
            return (
              <div style={{ color: "red" }}>
                Error Loading Yield Set Information
                <div style={{ fontSize: "14px" }}>
                  {error.message && JSON.stringify(error.message, null, 4)}
                </div>
              </div>
            );
          }

          if (data) {
            const ys = _.get(data, "yieldSets.edges[0].node");
            const gamNetworkId = _.get(ys, "gamNetwork.networkId");

            if (!ys) {
              return `Sorry Yield Set ${yieldSetId} does not exist.`;
            }

            return (
              <div className={CommonStyle.wrapper}>
                <div className={CommonStyle.pageTitle}>
                  New Ad Unit for <span title={yieldSetId}>{ys.name}</span>
                </div>

                <div style={{ padding: "15px" }}>
                  {/* <RootLayerWrapper
                    yieldSetId={yieldSetId}
                    gamNetworkId={gamNetworkId}
                    publisherId={publisherId}
                  ></RootLayerWrapper> */}
                  <OnboardMethodWrapper
                    yieldSetId={yieldSetId}
                    gamNetworkId={gamNetworkId}
                    publisherId={publisherId}
                  ></OnboardMethodWrapper>
                </div>
              </div>
            );
          }
        }}
      </Query>
    );
  }
}

module.exports = OnboardingAdUnits;
