import React from "react";

class BillingInfoView extends React.Component {
  constructor(props) {
    super(props);

    const billingSetting = this.props.billingSetting;
    const billingContact = billingSetting.billingContact || {};
    const billingRecipients = billingSetting.billingRecipients || [];
    const geoCountry = billingSetting.geoCountry || {};
    const invoiceSetting = billingSetting.invoiceSetting || {};
    const isTaiwanInvoice = geoCountry.countryId === 45;
    const companyName = billingSetting.companyName;

    let fullname = "";
    let fullPhoneNumber = "";
    let humanReadableInvoiceSetting = "";

    if (billingContact) {
      fullname = `${billingContact.firstName} ${billingContact.lastName}`;
    }
    if (billingContact && billingContact.phoneNumber) {
      fullPhoneNumber = `+${billingContact.phoneCountryCode} ${
        billingContact.phoneNumber
      }`;
    }

    // transform goddamn invoice setting back to human readable format
    if (isTaiwanInvoice && invoiceSetting) {
      humanReadableInvoiceSetting = this.transformInvoiceSettingFromBackendApiWantedFormatToHumanReadableFormat(
        invoiceSetting
      );
    }

    this.state = {
      billingSetting,
      billingContact,
      billingRecipients,
      geoCountry,
      invoiceSetting,
      companyName,
      fullname,
      fullPhoneNumber,
      humanReadableInvoiceSetting,
      isTaiwanInvoice
    };
  }

  //           <option value="TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE">
  //             Triplicate Uniform Electronic Invoice
  //           </option>
  //           <option value="DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__CITIZEN_DIGITAL_CERTIFICATE">
  //             Duplicate Uniform Electronic Invoice - Citizen Digital Certificate
  //           </option>
  //           <option value="DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__MOBILE_BARCODE_CARRIER">
  //             Duplicate Uniform Electronic Invoice - Mobile Barcode Carrier
  //           </option>
  //           <option value="DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__EZPAY_CARRIER">
  //             Duplicate Uniform Electronic Invoice - EZPay Carrier
  //           </option>
  //           <option value="DONATE_INVOICE">Donate Invoice</option>
  //         </Form.Control>

  transformInvoiceSettingFromBackendApiWantedFormatToHumanReadableFormat(
    backendApiWantedFormatInvoiceSetting
  ) {
    let bawfis = backendApiWantedFormatInvoiceSetting;

    if (bawfis.category === "B2B") {
      return {
        type: "TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE",
        typeLabel: "Triplicate Uniform Electronic Invoice",
        data: {
          key: "taxCode",
          label: "Tax Code",
          value: bawfis.ubn
        }
      };
    }

    if (bawfis.category === "B2C") {
      if (bawfis.loveCode && bawfis.loveCode !== "") {
        return {
          type: "DONATE_INVOICE",
          typeLabel: "Donate Invoice",
          data: {
            key: "loveCode",
            label: "Love Code",
            value: bawfis.loveCode
          }
        };
      }

      if (bawfis.carrierType === "0") {
        return {
          type: "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__MOBILE_BARCODE_CARRIER",
          typeLabel:
            "Duplicate Uniform Electronic Invoice - Mobile Barcode Carrier",
          data: {
            key: "mobileBarcodeCarrier",
            label: "Mobile Barcode Carrier",
            value: bawfis.carrierNum
          }
        };
      }

      if (bawfis.carrierType === "1") {
        return {
          type:
            "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__CITIZEN_DIGITAL_CERTIFICATE",
          typeLabel:
            "Duplicate Uniform Electronic Invoice - Citizen Digital Certificate",
          data: {
            key: "citizenDigitalCertificate",
            label: "Citizen Digital Certificate Number",
            value: bawfis.carrierNum
          }
        };
      }

      if (bawfis.carrierType === "2") {
        return {
          type: "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__EZPAY_CARRIER",
          typeLabel: "Duplicate Uniform Electronic Invoice - EZPay Carrier",
          data: {
            key: "",
            label: "",
            value: ""
          }
        };
      }
    }
  }

  render() {
    return (
      <div>
        <div>
          <div
            style={{
              color: "#414a52"
            }}
          >
            <div
              style={{
                fontSize: "larger"
              }}
            >
              {this.state.companyName}
            </div>
            <div>
              <b>{this.state.fullname}</b>
            </div>
            <div>{this.state.billingContact.email}</div>
            <div>{this.state.fullPhoneNumber}</div>
          </div>
        </div>
        <hr />
        <div>
          <div
            style={{
              color: "#414a52"
            }}
          >
            <span>{i18n(this.state.geoCountry.name)}</span> &nbsp;
            <small>(Country of billing)</small>
            {this.state.isTaiwanInvoice && (
              <div>
                {this.state.humanReadableInvoiceSetting && (
                  <div>
                    {this.state.humanReadableInvoiceSetting.typeLabel}
                    <br />
                    {this.state.humanReadableInvoiceSetting.data.label}{" "}
                    {this.state.humanReadableInvoiceSetting.data.value}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

module.exports = BillingInfoView;
