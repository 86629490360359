import React from "react";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import YbDivider from "common/components/yb-divider";
import YbTooltip from "common/components/yb-tooltip";
import Constants from "common/constants/constants";

import Styles from "../styles/support.css";

class SupportCenter extends React.Component {
  constructor() {
    super();

    this.state = {
      copied: false,
    };
  }

  backToHome() {
    this.props.history.push("/");
  }

  copyEmail2Clipboard(email, event) {
    const el = document.createElement("textarea");
    el.value = email;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }

    this.setState({
      copied: true,
    });

    event.stopPropagation();
    event.preventDefault();
  }

  render() {
    var copied = this.state.copied;

    return (
      <div className={`${CommonStyle.wrapper} ${Styles.supportCenterWrapper}`}>
        <div className={CommonStyle.pageTitle}>{i18n`SUPPORT_CENTER`}</div>
        <br />
        <br />
        <div className="row">
          <div className={`col-md-3`}>
            <a href={Constants.GETTING_STARTED} target="_blank">
              <div className={Styles.supportCard}>
                <span className={Styles.gettingStartedImage} />
                <span className={Styles.supportTitle}>Intowow</span>
                <br />
                <span
                  className={Styles.supportTitle}
                >{i18n`Setup Tutorial`}</span>
              </div>
            </a>
          </div>
          <div className={`col-md-3`}>
            <a href={Constants.FAQ_URL} target="_blank">
              <div className={Styles.supportCard}>
                <span className={Styles.faqLogo} />
                <span className={Styles.supportTitle}>{i18n`FAQ`}</span>
              </div>
            </a>
          </div>
          <div className={`col-md-3`}>
            <a href={Constants.JIRA_HELPDESK_URL} target="_blank">
              <div className={Styles.supportCard}>
                <span className={Styles.jiraServiceDeskLogo} />
                <br />
                <span
                  className={Styles.supportTitle}
                >{i18n`YIELDBOOSTER_HELPDESK`}</span>
                <br />
                <br />
                <div>
                  <span className={FormStyle.requiredStar}>*</span>{" "}
                  {i18n`REQUIRES_JIRA_ACCOUNT`}
                </div>
              </div>
            </a>
          </div>
          <div className={`col-md-3`}>
            <a href={`mailto:${Constants.SUPPORT_EMAIL}`}>
              <div className={Styles.supportCard}>
                <span className={Styles.emailImage} />
                <span className={Styles.supportTitle}>{i18n`CONTACT_US`}</span>
                <div>
                  <span className={CommonStyle.buttonLink}>
                    {Constants.SUPPORT_EMAIL}
                  </span>
                </div>
                <div>
                  <YbTooltip
                    message={i18n`COPY_EMAIL_TO_CLIPBOARD`}
                    position="bottom"
                  >
                    <span
                      className={CommonStyle.button}
                      onClick={this.copyEmail2Clipboard.bind(
                        this,
                        Constants.SUPPORT_EMAIL
                      )}
                    >
                      <i
                        className={`fa ${copied ? "fa-check" : "fa-clipboard"}`}
                      />
                    </span>
                  </YbTooltip>
                </div>
              </div>
            </a>
          </div>
        </div>
        <br />
        <br />
        <YbDivider />
        <br />
        <br />
        <div className={CommonStyle.textAlignCenter}>
          <span
            className={CommonStyle.roundButton}
            onClick={this.backToHome.bind(this)}
          >
            <i className="fa fa-home" /> {i18n`BACK_TO_HOME`}
          </span>
        </div>
      </div>
    );
  }
}

export default SupportCenter;
