import React from "react";

import { Form, InputGroup } from "react-bootstrap";
import LovecodeSelector from "../components/lovecode-selector";

class TaiwanInvoiceSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceSetting: {
        ...this.props.invoiceSetting,
        type: this.props.type
          ? this.props.type
          : "TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE",

        taxCode: "",
        citizenDigitalCertificate: "",
        mobileBarcodeCarrier: "",
        loveCode: ""
      },
      handleChange: this.props.handleChange
    };

    this.handleReceiptTypeChange = this.handleReceiptTypeChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  handleReceiptTypeChange(e) {
    const value = e.target.value;

    const updatedInvoiceSetting = {
      type: value
    };

    this.setState({
      invoiceSetting: updatedInvoiceSetting
    });

    this.state.handleChange(updatedInvoiceSetting);
  }

  handleFieldChange(field, e) {
    const value = e.target.value;

    const updatedInvoiceSetting = {
      ...this.state.invoiceSetting,
      [field]: value
    };

    this.setState({
      invoiceSetting: updatedInvoiceSetting
    });

    this.state.handleChange(updatedInvoiceSetting);
  }

  handleSetLoveCode(code) {
    const updatedInvoiceSetting = {
      ...this.state.invoiceSetting,
      loveCode: code
    };
    this.setState({
      invoiceSetting: updatedInvoiceSetting
    });

    this.state.handleChange(updatedInvoiceSetting);
  }

  render() {
    const invoiceSetting = this.state.invoiceSetting || {};
    return (
      <div>
        <br />
        <Form.Group id="taiwanInvoiceType">
          <Form.Label>Taiwan Receipt Settings</Form.Label>
          <Form.Control
            as="select"
            value={invoiceSetting.type}
            onChange={this.handleReceiptTypeChange}
          >
            <option value="TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE">
              Triplicate Uniform Electronic Invoice
            </option>
            <option value="DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__CITIZEN_DIGITAL_CERTIFICATE">
              Duplicate Uniform Electronic Invoice - Citizen Digital Certificate
            </option>
            <option value="DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__MOBILE_BARCODE_CARRIER">
              Duplicate Uniform Electronic Invoice - Mobile Barcode Carrier
            </option>
            <option value="DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__EZPAY_CARRIER">
              Duplicate Uniform Electronic Invoice - EZPay Carrier
            </option>
            <option value="DONATE_INVOICE">Donate Invoice</option>
          </Form.Control>
        </Form.Group>

        {invoiceSetting.type === "TRIPLICATE_UNIFORM_ELECTRONIC_INVOICE" && (
          <Form.Group id="taxCode">
            <Form.Label>Tax Code</Form.Label>
            <Form.Control
              type="text"
              minLength="8"
              maxLength="8"
              pattern="^[0-9]+$"
              value={invoiceSetting.taxCode}
              onChange={e => this.handleFieldChange("taxCode", e)}
              required
            />
            <Form.Text className="text-muted">
              Should contain exactly 8 digits.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Please fill in your Tax Code. Must contain exactly 8 digits.
            </Form.Control.Feedback>
          </Form.Group>
        )}

        {invoiceSetting.type ===
          "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__CITIZEN_DIGITAL_CERTIFICATE" && (
          <Form.Group id="taxCode">
            <Form.Label>Citizen Digital Certificate Number</Form.Label>
            <Form.Control
              type="text"
              minLength="16"
              maxLength="16"
              pattern="^(?=(?:\D*\d){14}\D*$)(?:[^A-Z]*[A-Z]){2}[^A-Z]*$"
              value={invoiceSetting.citizenDigitalCertificate}
              onChange={e =>
                this.handleFieldChange("citizenDigitalCertificate", e)
              }
              required
            />
            <Form.Text className="text-muted">
              Should contain exactly 2 uppercase letters and 14 digits. (
              <a
                href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC253W/"
                target="_blank"
              >
                detail website
              </a>
              )
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Please fill in your Citizen Digital Certificate Number.{" "}
              <b>Must be exactly 2 uppercase letters and 14 digits</b>
            </Form.Control.Feedback>
          </Form.Group>
        )}

        {invoiceSetting.type ===
          "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__MOBILE_BARCODE_CARRIER" && (
          <Form.Group id="taxCode">
            <Form.Label>Mobile Barcode Carrier</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="barcodePrepend">/</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                style={{ width: "150px" }} // remove this and this input box becomes tiny
                minLength="7"
                maxLength="7"
                pattern="^[A-Z0-9\+-.]*$"
                value={invoiceSetting.mobileBarcodeCarrier}
                onChange={e =>
                  this.handleFieldChange("mobileBarcodeCarrier", e)
                }
                required
              />
              <Form.Text className="text-muted">
                Should contain only 7 either uppercase letters, digits or
                characters "+", "-", ".". (
                <a
                  href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC500W/"
                  target="_blank"
                >
                  detail website
                </a>
                )
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                Please fill in your Mobile Barcode Carrier.{" "}
                <b>
                  Must contain only 7 either uppercase letters, digits or
                  characters "+", "-", ".".
                </b>
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        )}

        {invoiceSetting.type ===
          "DUPLICATE_UNIFORM_ELECTRONIC_INVOICE__EZPAY_CARRIER" && (
          <Form.Text>
            I acknowledge that their domestic invoice will be kept in EZPay
            carrier and Intowow will notice and send the invoice to you once the
            invoice won the prize.
          </Form.Text>
        )}

        {invoiceSetting.type === "DONATE_INVOICE" && (
          <Form.Group id="loveCode">
            <Form.Label>Love Code</Form.Label>
            <LovecodeSelector
              loveCode={invoiceSetting.loveCode}
              handleSetLoveCode={code => this.handleSetLoveCode(code)}
            />
            <Form.Control
              style={{ display: "none" }}
              type="text"
              value={invoiceSetting.loveCode}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please select a love code to donate.
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </div>
    );
  }
}

module.exports = TaiwanInvoiceSetting;
