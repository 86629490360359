import qs from 'querystring'
import React from 'react'

import YbModal from 'common/components/yb-modal'

class OAuth extends React.Component {
  constructor () {
    super()
    this.oauthFlow = {}
    this.state = {
      showModal: false
    };
  }

  openWindow (url, target) {
    return (this.props.openWindow || window.open)(url, target)
  }

  authorize () {
    const { url, params } = this.props

    const key = new Date().getTime() + ''
    const query = {
      ...params,
      state: qs.stringify({ intowow_oauth: key })
    }

    const href = `${url}?${qs.stringify(query)}`

    this.startOAuth(key, href, ({ query: { code, error, scope } }) => {
      if (error) {
        return
      }

      if (this.props.callback) {
        this.props.callback(code);
      }

      this.__code = code
      this.setState({ showModal: true });
    })
  }

  reset() {
    this.setState({ showModal: false });
    delete this.__code;
  }

  startOAuth (key, href, callback) {
    let flow = this.oauthFlow[key]
    if (!flow || flow.window.closed) {
      flow = {
        window: this.openWindow(href, key),
        listener: (event) => {
          if (event.detail.intowow_oauth !== key) {
            return;
          }

          window.removeEventListener('oauth', flow.listener, false);
          delete this.oauthFlow[key];
          callback(event.detail)
        }
      }

      window.addEventListener('oauth', flow.listener, false)
      this.oauthFlow[key] = flow
    }

    flow.window.focus()
  }

  render() {
    const { modal, authorizeNow } = this.props;
    const { showModal } = this.state;

    if (authorizeNow) {
      setTimeout(() => {
        this.authorize();
      });
    }

    return (
      <div>
        <div className='children-wrapper' onClick={this.authorize.bind(this)}>
          {this.props.children}
        </div>
        {modal && (
          <YbModal
            onHide={_ => this.reset()}
            show={showModal}
            {...modal}
            onSubmit={obj => modal.onSubmit({
              ...obj,
              code: this.__code
            }).then(_ => this.reset())} />
        )}
      </div>
    )
  }
}

export default OAuth
