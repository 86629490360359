import gql from "graphql-tag";

export const GET_YIELD_SET = gql`
  query GET_YIELD_SET($pubId: Int) {
    yieldSets(first: 1000, assumePubId: $pubId) {
      edges {
        node {
          id
          yieldSetId
          name
          gamNetwork {
            id
            networkId
            name
            currency
          }
          adsenseAccount {
            id
            accountId
          }
          adxAccount {
            id
            adxAccountId
          }
        }
      }
    }
  }
`;

export const GET_AD_UNIT = gql`
  query GET_AD_UNIT($filter: gamAdUnitsFilter!, $pubId: Int) {
    items: gamAdUnits(filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          itemId: adUnitId
          name
          unitType
          extGamAdUnitId
          parentPath
          platformType
          adxFloorPrice
          adSenseFloorPrice
          yieldSet {
            id
            name
            yieldSetId
          }
          adSizes {
            sizeId
            width
            height
          }
        }
      }
    }
  }
`;

export const GET_UNIT_CANDIDATES = gql`
  query GET_UNIT_CANDIDATES($filter: extGamAdUnitsFilter!, $pubId: Int) {
    extItems: extGamAdUnits(filter: $filter, assumePubId: $pubId) {
      extId: adUnitId
      name
      code
      parentPath
      hasChildren
      adSizes {
        sizeId
        width
        height
      }

      isCompatible
      portfolio
    }
    items: gamAdUnits(first: 1000, assumePubId: $pubId) {
      edges {
        node {
          id
          itemId: adUnitId
          name
          code
          extId: extGamAdUnitId
        }
      }
    }
  }
`;

export const CREATE_AD_UNIT = gql`
  mutation CREATE_AD_UNIT($input: onboardGamAdUnitsInput!, $pubId: Int) {
    create: onboardGamAdUnits(input: $input, assumePubId: $pubId) {
      gamAdUnits {
        id
        itemId: adUnitId
      }
    }
  }
`;

export const SAVE_AD_UNIT = gql`
  mutation SAVE_AD_UNIT($input: updateGamAdUnitInput!, $pubId: Int) {
    update: updateGamAdUnit(input: $input, assumePubId: $pubId) {
      gamAdUnit {
        id
      }
    }
  }
`;
