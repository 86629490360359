import gql from "graphql-tag";

export const GET_YIELD_SET_AND_ONBOARDED_UNITS = gql`
  query GET_YIELD_SET_AND_ONBOARDED_UNITS(
    $filter: yieldSetsFilter!
    $pubId: Int
  ) {
    yieldSets(filter: $filter, first: 1, assumePubId: $pubId) {
      edges {
        node {
          yieldSetId
          name
          gamNetwork {
            networkId
          }
        }
      }
    }

    units: gamAdUnits(first: 100000000, assumePubId: $pubId) {
      edges {
        node {
          id
          itemId: adUnitId
          name
          code
          extId: extGamAdUnitId
        }
      }
    }
  }
`;

export const GET_YIELD_SET = gql`
  query GET_YIELD_SET($filter: yieldSetsFilter!, $pubId: Int) {
    yieldSets(filter: $filter, first: 1, assumePubId: $pubId) {
      edges {
        node {
          yieldSetId
          name
          gamNetwork {
            networkId
          }
        }
      }
    }
  }
`;

export const GET_ONBOARDED_AD_UNITS = gql`
  query GET_ONBOARDED_AD_UNITS($pubId: Int) {
    units: gamAdUnits(first: 100000000, assumePubId: $pubId) {
      edges {
        node {
          id
          itemId: adUnitId
          name
          code
          extId: extGamAdUnitId
        }
      }
    }
  }
`;

export const GET_UNIT_CANDIDATES = gql`
  query GET_UNIT_CANDIDATES($filter: extGamAdUnitsV2Filter!, $pubId: Int) {
    extItems: extGamAdUnitsV2(filter: $filter, assumePubId: $pubId) {
      extId: adUnitId
      name
      code
      parentPath
      hasChildren

      isCompatible
      portfolio
      siteNetworkCodes

      isOnboarded
      numOnboardableChildren
      numOnboardedChildren
    }
  }
`;
