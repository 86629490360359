import gql from "graphql-tag";

export const GET_ALL_SUBSCRIPTION_NEEDED_INFO = gql`
  query {
    publisherAccountStatus {
      publisher {
        publisherId
        name
        firstName
        lastName
        contactEmail
        accountType

        billingSetting {
          billingContact {
            firstName
            lastName
            email
            phoneNumber
            phoneCountryCode
          }
          billingRecipients
          activeBillingProfile {
            profileId
            method
            profile
          }
          geoCountry {
            countryId
            name
          }
          companyName
          currency
          invoiceSetting
        }
      }
      status {
        trialStatus {
          remainingDays
          billableStartDate
        }
      }
    }
    payments(filter: { collectionStatus: [FAILED, PENDING] }) {
      edges {
        node {
          paymentId
          status
          collectionStatus
          nextCollectionDate
          finalCollectionDate
          invoice {
            dueDate
            paymentTermStartDate
          }
        }
      }
    }
  }
`;

export const GET_NEWEBPAY_FORM = gql`
  query getNewebpayForm($filter: newebPayProfileFilter!) {
    newebPayProfile(filter: $filter) {
      form
    }
  }
`;

export const GET_BILLING_PROFILE = gql`
  query getBillingProfile($filter: billingProfilesFilter!) {
    billingProfiles(filter: $filter) {
      edges {
        node {
          profileId
          method
          profile
        }
      }
    }
  }
`;

export const GET_ALL_BILLING_PROFILES = gql`
  query {
    billingProfiles {
      edges {
        node {
          id
          profileId
          method
          profile
        }
      }
    }
  }
`;

export const GET_BILLING_SETTING_ACTIVE_BILLING_PROFILE_ONLY = gql`
  query {
    billingSetting {
      activeBillingProfile {
        profileId
        method
        profile
      }
    }
  }
`;

export const GET_BILLING_SETTING = gql`
  query {
    billingSetting {
      billingContact {
        firstName
        lastName
        email
        phoneNumber
        phoneCountryCode
      }
      billingRecipients
      activeBillingProfile {
        profileId
        method
        profile
      }
      geoCountry {
        countryId
        name
      }
      companyName
      currency
      invoiceSetting
    }
  }
`;

export const SET_BILLING_SETTING = gql`
  mutation($input: setBillingSettingInput!) {
    setBillingSetting(input: $input) {
      billingSetting {
        billingContact {
          firstName
          lastName
          email
          phoneNumber
          phoneCountryCode
        }
        billingRecipients
        activeBillingProfile {
          profileId
          method
          profile
        }
        geoCountry {
          countryId
          name
        }
        companyName
        currency
        invoiceSetting
      }
    }
  }
`;

export const SUBSCRIBE_PLAN = gql`
  mutation($input: subscribePlanInput!) {
    subscribePlan(input: $input) {
      result
    }
  }
`;
