import React from "react";
import useValidateInvite from "../hooks/use-validate-invite";
import RegisterConfirmation from "../components/register-confirmation";

function RegisterByInvitation(props) {
  const queryString = props.location.search.substr(1);

  const { isLoading, validateData } = useValidateInvite(queryString);

  if (isLoading) {
    return <h1>loading...</h1>;
  }

  if (validateData && validateData.isValid) {
    return (
      <RegisterConfirmation
        inviteData={validateData.data}
        onSignup={props.onSignup}
      />
    );
  } else if (validateData && !validateData.isValid) {
    return (
      <RegisterConfirmation
        inviteData={validateData.data}
        onSignup={props.onSignup}
      />
    );
  } else {
    return "";
  }
}

export default RegisterByInvitation;
