import React from "react";
import moment from "moment";
import { Button } from "react-bootstrap";

import { DateRange } from "react-date-range";
import {
  DATE_RANGES,
  MIN_DATE,
  getDateRangeByType,
} from "../helpers/date-helper";

class FilterDateRangePopover extends React.Component {
  constructor(props) {
    super(props);

    const { startDate, endDate } = this.props;

    this.state = {
      startDate,
      endDate,
    };

    this.onDateRangeChange = this.onDateRangeChange.bind(this);
    this.handleSelectDateRange = this.handleSelectDateRange.bind(this);
    this.handleApplyDateRangeFilter =
      this.handleApplyDateRangeFilter.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onDateRangeChange(ranges) {
    const { startDate, endDate } = ranges.dateRange;

    this.setState({
      startDate,
      endDate,
    });
  }

  handleSelectDateRange(dateRangeType) {
    const { startDate, endDate } = getDateRangeByType(dateRangeType);

    this.setState({
      startDate,
      endDate,
    });
  }

  handleApplyDateRangeFilter() {
    const { startDate, endDate } = this.state;
    this.props.handleDateRangeFilterChanged({ startDate, endDate });
    this.props.handleClosePopover();
  }

  handleCancel() {
    // close and nothing happened
    this.props.handleClosePopover();
  }

  render() {
    const { startDate, endDate } = this.state;

    return (
      <React.Fragment>
        <div className="d-flex">
          <div>
            {DATE_RANGES.map((dateRangeType) => {
              const dateRangeTitle = _.capitalize(_.lowerCase(dateRangeType));
              return (
                <div key={dateRangeType}>
                  <button
                    type="button"
                    onClick={() => this.handleSelectDateRange(dateRangeType)}
                    className="inline-flex border rounded cursor-pointer px-3 py-1 text-sm mr-3 mb-2 bg-white text-gray-700 hover:border-blue-400"
                  >
                    {dateRangeTitle}
                  </button>
                </div>
              );
            })}
          </div>

          <div>
            <DateRange
              // editableDateInputs={true}
              onChange={this.onDateRangeChange}
              moveRangeOnFirstSelection={false}
              months={2}
              dateDisplayFormat={"yyyy/MM/dd"}
              direction="horizontal"
              scroll={{ enabled: true }}
              maxDate={moment().toDate()}
              minDate={MIN_DATE}
              ranges={[
                {
                  startDate: moment(startDate).toDate(),
                  endDate: moment(endDate).toDate(),
                  key: "dateRange",
                },
              ]}
            />
          </div>
        </div>

        <div className="pt-3 mt-3 border-top d-flex justify-content-end">
          <Button variant="link" className="px-4" onClick={this.handleCancel}>
            Cancel
          </Button>

          <Button
            variant="primary"
            className="px-4"
            onClick={this.handleApplyDateRangeFilter}
          >
            Apply
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  header: {
    padding: "12px 84px",
  },
  filterBtn: {
    border: "1px solid lightgray",
  },
  currencySelector: {
    width: "120px",
  },
};

export default FilterDateRangePopover;
