import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container, Row, Col, Nav } from "react-bootstrap";
import BillingDetails from "./billing-details";
import BillingReport from "../../../subscription-and-billing/states/billing-report";
import PaymentHistory from "../../../subscription-and-billing/states/payment-history";

// const sidebarLinks = {
//   "/subscription-and-billing/overview": {
//     title: "Overview"
//     // component: SubscriptionAndBillingOverview
//   },
//   "/subscription-and-billing/billing-report": {
//     title: "Billing Report"
//   },
//   "/subscription-and-billing/payment-history": {
//     title: "Payment History"
//   }
// };

const sidebarLinks = [
  // {
  //   title: "Subscriptions",
  //   path: "/billing/subscriptions"
  // },
  {
    title: "Billing Details",
    path: "/billing/details"
  },
  {
    title: "Billing Report",
    path: "/billing/report"
  },
  {
    title: "Payment History",
    path: "/billing/history"
  }
];

function Billing(props) {
  function handleTabSelected(path) {
    if (path === props.location.pathname) {
      return;
    }
    props.history.push(path);
  }

  function isNavLinkActive(path) {
    return props.location.pathname === path;
  }

  return (
    <React.Fragment>
      <div style={{ margin: "64px 120px" }}>
        <Row>
          <Col lg="2">
            <div>
              <Nav
                variant="pills"
                defaultActiveKey="/billing/details"
                className="flex-column"
                onSelect={handleTabSelected}
              >
                {sidebarLinks.map((link, i) => {
                  return (
                    <Nav.Link
                      key={i}
                      eventKey={link.path}
                      active={isNavLinkActive(link.path)}
                    >
                      {link.title}
                    </Nav.Link>
                  );
                })}
              </Nav>
            </div>
          </Col>
          <Col lg="10">
            {/* <small className="text-muted">{`SUBSCRIPTION & BILLING`}</small> */}
            <h1 className="display-4">Billing</h1>
            <hr />

            <Switch>
              <Route
                exact
                path="/billing"
                render={() => {
                  return <Redirect to="/billing/details" push={false} />;
                }}
              />
              <Route path="/billing/details" component={BillingDetails} />
              <Route path="/billing/report" component={BillingReport} />
              <Route path="/billing/history" component={PaymentHistory} />
            </Switch>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default Billing;
