import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function translatePaymentStatus(status) {
  switch (status) {
    case "SUCCESS":
      return "Paid";
    case "FAILED":
      return "Payment failed";
    case "WAITING_FOR_TRANSACTION":
      return "Waiting for payment";
    case "EXPIRED":
      return "Payment expired";
  }
}

function PaymentStatusIndicator(props) {
  const record = props.paymentRecord;
  const translatedPaymentStatus = translatePaymentStatus(record.paymentStatus);

  const iconJsx = (
    <span>
      {!record.isInvoiceValid ? (
        <i className="fa fa-times text-muted" />
      ) : (
        <span>
          {record.paymentStatus === "SUCCESS" && (
            <i className="fa fa-check text-success" />
          )}
          {record.paymentStatus === "FAILED" && (
            <i className="fa fa-exclamation-triangle text-danger" />
          )}
          {record.paymentStatus === "WAITING_FOR_TRANSACTION" && (
            <i className="fa fa-clock-o text-muted" />
          )}
          {record.paymentStatus === "EXPIRED" && (
            <i className="fa fa-ban text-danger" />
          )}
        </span>
      )}
    </span>
  );

  return (
    <React.Fragment>
      {props.showTooltip ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {record.isInvoiceValid
                ? translatedPaymentStatus
                : "Invalid Invoice"}
            </Tooltip>
          }
        >
          {iconJsx}
        </OverlayTrigger>
      ) : (
        iconJsx
      )}
    </React.Fragment>
  );
}

module.exports = PaymentStatusIndicator;
