import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import PaymentHistoryDetailModal from "./payment-history-detail-modal";
import PaymentStatusIndicator from "./payment-status-indicator";
import InvoiceDownloadButton from "./invoice-download-button";

function PaymentHistoryTable(props) {
  const [activeModalIndicator, setModalIndicator] = useState("");

  function handleModalClose() {
    setModalIndicator("");
  }

  function handleDetailModalShow(id) {
    setModalIndicator(id);
  }

  return (
    <React.Fragment>
      <Table responsive size="sm" className="border-bottom">
        <thead className="thead-light">
          <tr>
            <th />
            <th>Issue Date</th>
            <th>Payment Date</th>
            <th>Invoice Description</th>
            <th className="text-right">
              Amount <small>(After Tax)</small>
            </th>
            <th className="text-center">Details</th>
            <th className="text-center">PDF</th>
          </tr>
        </thead>
        <tbody className="text-dark">
          {props.paymentRecords.map((record, index) => {
            return (
              <tr
                // key={record.invoiceId} // Invoice Id is NOT unique! Invoice No is also NOT unique!
                key={index}
                className={
                  record.paymentStatus === "FAILED" ||
                  record.paymentStatus === "EXPIRED"
                    ? "text-danger"
                    : ""
                }
              >
                <td className="align-middle text-center">
                  <PaymentStatusIndicator paymentRecord={record} showTooltip />
                </td>

                <td className="align-middle">
                  {record.issueDate
                    ? moment(record.issueDate).format("YYYY/MM/DD")
                    : "-"}
                </td>

                <td className="align-middle">
                  {record.paymentDate
                    ? moment(record.paymentDate).format("YYYY/MM/DD")
                    : "-"}
                </td>

                <td className="align-middle">
                  <div>
                    <small className="text-muted">{record.invoiceNo}</small>
                  </div>
                  {record.paymentStatus === "SUCCESS" && (
                    <div>
                      {record.billingProfile.method ? (
                        <div>
                          Paid via{" "}
                          {record.billingProfile.method === "CREDIT_CARD" ? (
                            <span>
                              <i className="fa fa-credit-card fa-fw" /> ending
                              in {record.billingProfile.profile.card4No}
                            </span>
                          ) : (
                            <span>Wire Transfer</span>
                          )}
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                  )}
                  {record.description}
                </td>
                <td className="text-right align-middle">
                  <small className="text-muted">{record.currency}</small>
                  &nbsp;
                  <span>
                    {record.afterTaxAmount &&
                      record.afterTaxAmount.toLocaleString()}
                  </span>
                </td>
                <td className="text-center align-middle">
                  <div>
                    <Button
                      type="button"
                      variant="link"
                      onClick={() => handleDetailModalShow(record.invoiceId)}
                    >
                      View
                    </Button>

                    {activeModalIndicator === record.invoiceId && (
                      <PaymentHistoryDetailModal
                        paymentRecord={record}
                        show={activeModalIndicator === record.invoiceId}
                        onHide={handleModalClose}
                      />
                    )}
                  </div>
                </td>
                <td className="text-center align-middle">
                  <InvoiceDownloadButton
                    type="INVOICE"
                    uniqueNo={record.invoiceNo}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

module.exports = PaymentHistoryTable;
