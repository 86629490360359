import React from "react";

import RootLayerWrapper from "../../_newapp/onboarding/components/root-layer-wrapper-v2";
import OnboardMethodWrapper from "../../_newapp/onboarding/components/onboard-method-wrapper";

class YbWizardStep4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publisherId: props.publisherId,

      selectedItems: [],
    };
  }

  render() {
    var stepData = this.props.stepData;
    var selectedYieldSet = stepData.selectedYieldSet;

    stepData.getNextStepBtn = this.getNextStepBtn;

    if (!selectedYieldSet) {
      return <span />;
    }

    const yieldSetId = selectedYieldSet.yieldSetId;
    const gamNetworkId = selectedYieldSet.gamNetwork.networkId;
    const publisherId = this.state.publisherId;

    return (
      // textAlign left to override Wizard's overall style
      <div style={{ textAlign: "left" }}>
        {/* <RootLayerWrapper
          yieldSetId={yieldSetId}
          gamNetworkId={gamNetworkId}
          publisherId={publisherId}
          wizard={{
            renderPrevStepBtn: this.props.renderPrevStepBtn,
            renderExitBtn: this.props.renderExitBtn,
            nextStepFnForStep4: this.props.nextStepFnForStep4,
          }}
        ></RootLayerWrapper> */}

        <OnboardMethodWrapper
          yieldSetId={yieldSetId}
          gamNetworkId={gamNetworkId}
          publisherId={publisherId}
          wizard={{
            renderPrevStepBtn: this.props.renderPrevStepBtn,
            renderExitBtn: this.props.renderExitBtn,
            nextStepFnForStep4: this.props.nextStepFnForStep4,
          }}
        ></OnboardMethodWrapper>
      </div>
    );
  }
}

module.exports = YbWizardStep4;
