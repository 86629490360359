import React from "react";
import { Redirect } from "react-router-dom";
import { Query, Mutation } from "react-apollo";

import FormStyle from "common/styles/forms.css";
import CommonStyle from "common/styles/common.css";
import YbLoading from "common/components/yb-loading";
import YbTooltip from "common/components/yb-tooltip";
import YbLoadingView from "common/components/yb-loading-view";
import Constants from "common/constants/constants";
// import YbMultipleSelectTable from "common/components/yb-multiple-select-table";
import YbTreeSelector from "common/components/yb-tree-selector";
import { ACCOUNT_CONFIG } from "common/query/publisher";

import Style from "../styles/ad-units";
// import YbSizes from "../components/yb-sizes";
import {
  GET_YIELD_SET,
  GET_AD_UNIT,
  GET_PLACEMENTS,
  GET_UNIT_CANDIDATES,
  GET_PLACEMENT_CANDIDATES,
  CREATE_AD_UNIT,
  CREATE_PLACEMENT,
  SAVE_AD_UNIT,
  SAVE_PLACEMENT,
} from "../query/ad-units";

/**
 *  /ad-units/create/:yieldSetId
 *  /ad-units/update/:adUnitId
 *  /placements/create/:yieldSetId
 *  /placements/update/:placementId
 *  /inventory/create/:yieldSetId
 */
// const overviewRoute = "/summary/overview";
const minFloorPrice = 0.0;
const maxFloorPrice = 99999999.99;

class AdUnitForm extends React.Component {
  constructor(props) {
    super(props);
    this.unitTypeChanged = this.unitTypeChanged.bind(this);
    this.platformTypeChanged = this.platformTypeChanged.bind(this);
    this.adUnitChanged = this.adUnitChanged.bind(this);
    this.removeAdUnits = this.removeAdUnits.bind(this);
    this.submitClick = this.submitClick.bind(this);
    this.backToSummary = this.backToSummary.bind(this);
    this.reloadExternalSource = this.reloadExternalSource.bind(this);

    this.unitTypes = this.constantToArray(Constants.unitTypes);
    this.platformTypes = this.constantToArray(Constants.platformTypes);

    const pubId = _.get(this.props, "match.params.publisherId");

    let overviewRoute = "/summary/overview";
    if (pubId > 0) {
      overviewRoute = `/publisher/${pubId}/summary/overview`;
    }

    this.state = {
      itemType: this.props.match.path.match(/placements/)
        ? "placement"
        : "adUnit",
      yieldSet: "",
      savedAdUnit: "",
      item: null,
      candidateIndex: "",
      candidates: [],
      treeSelection: [],
      unitType: "ABOVE_THE_FOLD",
      platformType: "BOTH_PC_AND_MOBILE",
      submitted: false,
      adxFloorPrice: minFloorPrice.toFixed(2),
      adSenseFloorPrice: minFloorPrice.toFixed(2),
      reloadExternalSource: false,
      publisherId: _.parseInt(pubId),
      overviewRoute: overviewRoute,
    };
  }

  handleOptionChange(event) {
    this.setState({
      itemType: event.target.value,
      item: null,
      candidateIndex: "",
      treeSelection: [],
      unitType: "ABOVE_THE_FOLD",
      platformType: "BOTH_PC_AND_MOBILE",
      adxFloorPrice: minFloorPrice.toFixed(2),
      adSenseFloorPrice: minFloorPrice.toFixed(2),
    });
  }

  constantToArray(constant) {
    return Object.keys(constant).map((keyName) => {
      return {
        id: keyName,
        name: constant[keyName],
      };
    });
  }

  backToSummary() {
    this.props.history.goBack();
  }

  filterAlreadyUsed(data) {
    var alreadyUsed = data.items.edges.map((item) => {
      return item.node.extId;
    });

    var result = data.extItems.filter((item) => {
      return alreadyUsed.indexOf(item.extId) === -1;
    });

    return result.sort((itemA, itemB) => itemA.name.localeCompare(itemB.name));
  }

  renderTitle(isEdit) {
    var inventoryItem =
      this.state.itemType === "adUnit" ? i18n`AD_UNIT` : i18n`PLACEMENT`;
    var content = (
      <span>
        {i18n`NEW`} {inventoryItem} {i18n`FOR`} {this.state.yieldSet.name}
      </span>
    );

    if (isEdit) {
      content = (
        <span>
          {i18n`UPDATE`} {inventoryItem} {i18n`IN`} {this.state.yieldSet.name}
        </span>
      );
    }

    return <div className={CommonStyle.pageTitle}>{content}</div>;
  }

  unitTypeChanged(event) {
    this.setState({
      unitType: event.target.value,
    });
  }

  platformTypeChanged(event) {
    this.setState({
      platformType: event.target.value,
    });
  }

  adxFloorPriceChanged(event) {
    this.setState({
      adxFloorPrice: event.target.value,
    });
  }

  adSenseFloorPriceChanged(event) {
    this.setState({
      adSenseFloorPrice: event.target.value,
    });
  }

  onBlur(isAdx, event) {
    var prop = isAdx ? "adxFloorPrice" : "adSenseFloorPrice";
    var state = {};

    state[prop] = parseFloat(event.target.value);

    if (state[prop] < minFloorPrice || !state[prop]) {
      state[prop] = minFloorPrice;
    }

    if (state[prop] > maxFloorPrice) {
      state[prop] = maxFloorPrice;
    }

    state[prop] = state[prop].toFixed(2);
    this.setState(state);
  }

  submitClick() {
    if (!this.state.submitted) {
      this.setState({
        submitted: true,
      });
    }
  }

  placementChanged(event) {
    var index = parseInt(event.target.value);

    this.setState({
      candidateIndex: index,
      item: this.state.candidates[index],
    });
  }

  renderPlacementCandidates(isEdit) {
    var candidates = this.state.candidates;
    var required = <span />;
    var submitted = this.state.submitted;
    var item = this.state.item;
    var invalid = false;

    if (isEdit) {
      return (
        <span>
          <input type="text" disabled value={this.state.item.name} />
        </span>
      );
    }

    invalid = !this.state.item || !this.state.item.length;

    if (invalid) {
      required = <input className={FormStyle.hiddenInput} required />;
    }

    return (
      <div>
        <YbTreeSelector
          items={[candidates]}
          value={this.state.treeSelection}
          selectedItems={this.state.item}
          onSelect={this.adUnitChanged}
          onRemove={this.removeAdUnits}
          id="extId"
          invalid={submitted && invalid}
        >
          {({ renderItems, drillTo }) => {
            return <span />;
          }}
        </YbTreeSelector>
        {required}
      </div>
    );
  }

  adUnitChanged(item) {
    var items = this.state.item;

    if (!Array.isArray(item)) {
      item = [item];
    }

    if (Array.isArray(items)) {
      items.push(...item);
    } else if (items) {
      items = [items, ...item];
    } else {
      items = item;
    }

    this.setState({
      item: items,
    });
  }

  removeAdUnits(itemsToRemove) {
    var items = this.state.item;

    if (!Array.isArray(itemsToRemove)) {
      itemsToRemove = [itemsToRemove];
    }

    if (Array.isArray(items)) {
      itemsToRemove.forEach((itemToRemove) => {
        items.splice(items.indexOf(itemToRemove), 1);
      });
    } else {
      items = [];
    }

    this.setState({
      item: items,
    });
  }

  renderAdUnitCandidates(isEdit) {
    var candidates = this.state.candidates;
    var required = <span />;
    var submitted = this.state.submitted;
    var item = this.state.item;
    var invalid = false;

    if (isEdit) {
      return (
        <span>
          <input
            type="text"
            disabled
            value={`${this.state.item.parentPath}/${this.state.item.name}`}
          />
        </span>
      );
    }

    invalid = !this.state.item || !this.state.item.length;

    if (invalid) {
      required = <input className={FormStyle.hiddenInput} required />;
    }

    return (
      <div>
        <YbTreeSelector
          items={[candidates]}
          value={this.state.treeSelection}
          selectedItems={this.state.item}
          onSelect={this.adUnitChanged}
          onRemove={this.removeAdUnits}
          id="extId"
          invalid={submitted && invalid}
        >
          {({ renderItems, drillTo }) => {
            var variables = {
              filter: {
                // parentPath: `${
                //   drillTo.parentPath && drillTo.parentPath !== "/"
                //     ? drillTo.parentPath
                //     : ""
                // }/${drillTo.name}`,
                // Important! use id instead of parentPath to search child units to improve performance
                parentAdUnitIds: [drillTo.extId],
                gamNetworkId: this.state.yieldSet.gamNetwork.networkId,
                reload: this.state.reloadExternalSource,
              },
              pubId: this.state.publisherId,
            };

            return (
              <Query
                query={GET_UNIT_CANDIDATES}
                variables={variables}
                fetchPolicy={"network-only"}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <div className={CommonStyle.textAlignCenter}>
                        <YbLoading />
                      </div>
                    );
                  }

                  if (error) {
                    return (
                      <span className={CommonStyle.textDanger}>
                        Error Loading Ad Units...
                      </span>
                    );
                  }

                  var items = this.filterAlreadyUsed(data);

                  return renderItems(items);
                }}
              </Query>
            );
          }}
        </YbTreeSelector>
        {required}
      </div>
    );
  }

  renderAdxFloorPrice(accountConfig) {
    var yieldSet = this.state.yieldSet;
    var currency = yieldSet.gamNetwork.currency;
    currency = Constants.currencies[currency] || currency || "$";

    if (
      !yieldSet.adxAccount ||
      !accountConfig.gamNetwork.adx.floorPriceSetting.enabled
    ) {
      return <span />;
    }

    return (
      <div className={FormStyle.row}>
        <div className={FormStyle.label}>{i18n`ADX_FLOOR_PRICE`}</div>
        <div className={FormStyle.currencyWrapper}>
          <span className={FormStyle.currency}>{currency}</span>
          <input
            required
            type="number"
            value={this.state.adxFloorPrice}
            onChange={this.adxFloorPriceChanged.bind(this)}
            onBlur={this.onBlur.bind(this, true)}
            step="0.01"
            min={minFloorPrice}
            max={maxFloorPrice}
          />
        </div>
      </div>
    );
  }

  renderAdSenseFloorPrice(accountConfig) {
    var yieldSet = this.state.yieldSet;
    var currency = yieldSet.gamNetwork.currency;
    currency = Constants.currencies[currency] || currency || "$";

    if (
      !yieldSet.adsenseAccount ||
      !accountConfig.gamNetwork.adsense.floorPriceSetting.enabled
    ) {
      return <span />;
    }

    return (
      <div className={FormStyle.row}>
        <div className={FormStyle.label}>{i18n`ADSENSE_FLOOR_PRICE`}</div>
        <div className={FormStyle.currencyWrapper}>
          <span className={FormStyle.currency}>{currency}</span>
          <input
            required
            type="number"
            value={this.state.adSenseFloorPrice}
            onChange={this.adSenseFloorPriceChanged.bind(this)}
            onBlur={this.onBlur.bind(this, false)}
            step="0.01"
            min={minFloorPrice}
            max={maxFloorPrice}
          />
        </div>
      </div>
    );
  }

  renderInventoryItem() {
    return (
      <div>
        <label className={`radio-inline ${FormStyle.inputRadio}`}>
          <input
            type="radio"
            value="adUnit"
            name="itemType"
            onChange={this.handleOptionChange.bind(this)}
            checked={this.state.itemType === "adUnit"}
          />{" "}
          {i18n`AD_UNIT`}
        </label>
        <label className={`radio-inline ${FormStyle.inputRadio}`}>
          <input
            type="radio"
            value="placement"
            name="itemType"
            onChange={this.handleOptionChange.bind(this)}
            checked={this.state.itemType === "placement"}
          />{" "}
          {i18n`PLACEMENT`}
        </label>
      </div>
    );
  }

  reloadExternalSource(event) {
    this.setState({
      reloadExternalSource: true,
      item: null,
      treeSelection: [],
    });

    event.preventDefault();
  }

  renderFormContent(mutation, isEdit, accountConfig) {
    var formClass = FormStyle.form;
    var isAdUnit = this.state.itemType === "adUnit";

    if (this.state.submitted) {
      formClass = `${FormStyle.submitted} ${FormStyle.form}`;
    }

    return (
      <Mutation mutation={mutation}>
        {(saveInventory, { loading, error, data }) => {
          var submitBtn = null;
          var inventoryType = (
            <div className={FormStyle.row}>
              <div className={FormStyle.label}>
                {i18n`INVENTORY_TYPE`}{" "}
                <span className={FormStyle.requiredStar}>*</span>
              </div>
              {this.renderInventoryItem()}
            </div>
          );
          var submitLabel = (
            <span>
              <i className="fa fa-plus" /> {i18n`CREATE`}
            </span>
          );

          if (isEdit) {
            submitLabel = (
              <span>
                <i className="fa fa-save" /> {i18n`SAVE`}
              </span>
            );
            inventoryType = <span />;
          }

          if (
            !accountConfig.gamAdUnit.enabled ||
            !accountConfig.gamPlacement.enabled
          ) {
            inventoryType = <span />;
          }

          submitBtn = (
            <button
              className={FormStyle.submitBtn}
              type="submit"
              onClick={this.submitClick}
            >
              {submitLabel}
            </button>
          );

          if (loading) {
            submitBtn = (
              <span className={FormStyle.submitLoading}>
                <YbLoading />
              </span>
            );
          }

          if (error) {
            submitBtn = (
              <span className={FormStyle.submitLoading}>
                <span className="text-danger">
                  {isEdit ? (
                    <span>
                      {isAdUnit
                        ? i18n`ERROR_SAVING_AD_UNIT_ELLIPSIS`
                        : i18n`ERROR_SAVING_PLACEMENT_ELLIPSIS`}
                    </span>
                  ) : (
                    <span>
                      {isAdUnit
                        ? i18n`ERROR_CREATING_AD_UNIT_ELLIPSIS`
                        : i18n`ERROR_CREATING_PLACEMENT_ELLIPSIS`}
                    </span>
                  )}
                </span>
              </span>
            );
          }

          if (data) {
            return <Redirect to={this.state.overviewRoute} />;
          }

          return (
            <div>
              <form
                onKeyPress={(event) => {
                  if (event.key === "Enter" && event.target.type !== "submit") {
                    event.preventDefault();
                  }
                }}
                onSubmit={(event) => {
                  var adxFloorPrice = parseFloat(this.state.adxFloorPrice);
                  var adSenseFloorPrice = parseFloat(
                    this.state.adSenseFloorPrice
                  );
                  var item = this.state.item;
                  var items = item;
                  var yieldSet = this.state.yieldSet;
                  var platformType = this.state.platformType;
                  var unitType = this.state.unitType;
                  var input = {};
                  var params = {
                    variables: {
                      input: input,
                      pubId: this.state.publisherId,
                    },
                  };

                  if (isEdit) {
                    if (isAdUnit) {
                      input.adUnitId = parseInt(
                        this.props.match.params.adUnitId
                      );
                    } else {
                      input.placementId = parseInt(
                        this.props.match.params.placementId
                      );
                    }

                    (input.adxFloorPrice = adxFloorPrice),
                      (input.adSenseFloorPrice = adSenseFloorPrice),
                      (input.platformType = platformType),
                      (input.unitType = unitType),
                      // this could be removed if the api do this automatically
                      (input.adSizeIds = item.adSizes.map(
                        (size) => size.sizeId
                      ));
                  } else {
                    input.yieldSetId = yieldSet.yieldSetId;

                    if (!Array.isArray(items)) {
                      items = [items];
                    }

                    if (isAdUnit) {
                      input.gamAdUnits = items.map((item) => ({
                        extGamAdUnitId: item.extId,
                        adUnitCode: item.code,
                        name: item.name,
                        parentPath: item.parentPath,
                        adSizeIds: item.adSizes.map((size) => size.sizeId),
                        adxFloorPrice: adxFloorPrice,
                        adSenseFloorPrice: adSenseFloorPrice,
                      }));
                    } else {
                      input.gamPlacements = items.map((item) => ({
                        extGamPlacementId: item.extId,
                        name: item.name,
                        adSizeIds: item.adSizes.map((size) => size.sizeId),
                        adxFloorPrice: adxFloorPrice,
                        adSenseFloorPrice: adSenseFloorPrice,
                      }));
                    }
                  }

                  saveInventory(params);
                  event.preventDefault();
                }}
                className={formClass}
              >
                {inventoryType}
                <div className={FormStyle.row}>
                  <div className={FormStyle.label}>
                    {isAdUnit ? i18n`AD_UNITS` : i18n`PLACEMENTS`}{" "}
                    <span className={FormStyle.requiredStar}>*</span>
                    {!isEdit && (
                      <YbTooltip
                        message={i18n`SYNCHRONIZE_YOUR_INVENTORY_WITH_GOOGLE_AD_MANAGER`}
                      >
                        <button
                          className={CommonStyle.button}
                          onClick={this.reloadExternalSource}
                        >
                          <i className="fa fa-refresh" />
                        </button>
                      </YbTooltip>
                    )}
                  </div>
                  {isAdUnit
                    ? this.renderAdUnitCandidates(isEdit)
                    : this.renderPlacementCandidates(isEdit)}
                </div>
                {this.renderSelectedItems(isEdit)}
                {this.renderAdxFloorPrice(accountConfig)}
                {/* {this.renderAdSenseFloorPrice(accountConfig)} */}
                {/* <YbSizes
                  item={this.state.item}
                  title={isAdUnit ? i18n`AD_UNIT_SIZES` : i18n`PLACEMENT_SIZES`}
                /> */}
                <div>{submitBtn}</div>
              </form>
              {!loading && (
                <button
                  className={FormStyle.cancelBtn}
                  onClick={this.backToSummary}
                >
                  <i className="fa fa-times" /> {i18n`CANCEL`}
                </button>
              )}
            </div>
          );
        }}
      </Mutation>
    );
  }

  onRemoveClick(item, event) {
    this.removeAdUnits(item);
    event.preventDefault();
  }

  onRemoveAll(event) {
    this.setState({
      item: [],
    });
    event.preventDefault();
  }

  renderSelectedItems(isEdit) {
    var isAdUnit = this.state.itemType === "adUnit";
    var selectedItems = this.state.item || [];

    if (isEdit) {
      return <span />;
    }

    if (!Array.isArray(selectedItems)) {
      selectedItems = [selectedItems];
    }

    selectedItems = selectedItems.filter((item) => item);

    if (!selectedItems.length) {
      return <span />;
    }

    return (
      <div className={FormStyle.row}>
        <div className={FormStyle.label}>
          {isAdUnit ? i18n`SELECTED_AD_UNITS` : i18n`SELECTED_PLACEMENTS`} (
          {selectedItems.length})
          <button
            className={CommonStyle.buttonLink}
            onClick={this.onRemoveAll.bind(this)}
          >
            <i className="fa fa-times" /> {i18n`REMOVE_ALL`}
          </button>
        </div>
        <div className={Style.selectedAdUnits}>
          {selectedItems
            .map((item) => {
              item._fullName = `${
                item.parentPath && item.parentPath !== "/"
                  ? item.parentPath
                  : ""
              }/${item.name.replace("/", '"#"')}`;
              return item;
            })
            .sort((itemA, itemB) => {
              var textA = itemA._fullName.toUpperCase();
              var textB = itemB._fullName.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .map((item, index) => {
              var fullName = item._fullName
                .split("/")
                .filter((item, index) => index);

              return (
                <div className="row" key={index}>
                  <div className="col-md-1">
                    <div key={index}>
                      <button
                        className={CommonStyle.buttonLink}
                        onClick={this.onRemoveClick.bind(this, item)}
                      >
                        <i className="fa fa-times" />
                      </button>
                    </div>
                  </div>
                  {fullName.map((item, index) => (
                    <div className={`col ${Style.nameWrapper}`} key={index}>
                      <span>/{item.replace('"#"', "/")}</span>
                    </div>
                  ))}
                  <div className="col" />
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  renderForm(isEdit, accountConfig) {
    var isAdUnit = this.state.itemType === "adUnit";
    var mutation = isAdUnit ? CREATE_AD_UNIT : CREATE_PLACEMENT;
    var variables = {
      filter: {
        gamNetworkId: this.state.yieldSet.gamNetwork.networkId,
      },
      pubId: this.state.publisherId,
    };
    var candidateQuery = GET_UNIT_CANDIDATES;

    if (isEdit) {
      mutation = isAdUnit ? SAVE_AD_UNIT : SAVE_PLACEMENT;
    } else {
      variables.filter.reload = this.state.reloadExternalSource;
    }

    if (!isAdUnit) {
      candidateQuery = GET_PLACEMENT_CANDIDATES;
    } else {
      // variables.filter.parentPath = "/";
      // Important! use "root" instead of "/" to improve performance
      variables.filter.parentAdUnitIds = ["root"];
    }

    return (
      <div>
        {this.renderTitle(isEdit)}
        <Query
          query={candidateQuery}
          variables={variables}
          fetchPolicy={"network-only"}
        >
          {({ loading, error, data }) => {
            var candidate = null;
            var savedAdUnit = null;
            var candidates = this.state.candidates;

            if (loading) {
              return <YbLoadingView />;
            }

            if (error) {
              return (
                <div className={`text-danger ${CommonStyle.textView}`}>
                  <input className={FormStyle.hiddenInput} required />
                  {isAdUnit
                    ? i18n`ERROR_LOADING_AD_UNIT_CANDIDATES_ELLIPSIS`
                    : i18n`ERROR_LOADING_PLACEMENT_CANDIDATES_ELLIPSIS`}
                </div>
              );
            }

            if (this.state.reloadExternalSource) {
              setTimeout(() => {
                this.setState({
                  reloadExternalSource: false,
                });
              });
            }

            while (candidates.length) {
              candidates.pop();
            }

            this.filterAlreadyUsed(data).forEach((item) => {
              candidates.push(item);
            });

            if (isEdit) {
              savedAdUnit = this.state.item;
              candidate = data.extItems.find((adUnit) => {
                return adUnit.adUnitId === savedAdUnit.extGamAdUnitId;
              });
            }

            return this.renderFormContent(mutation, isEdit, accountConfig);
          }}
        </Query>
      </div>
    );
  }

  renderYieldSet(yieldSetId, isEdit, accountConfig) {
    return (
      <Query
        query={GET_YIELD_SET}
        variables={{ pubId: this.state.publisherId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }
          if (error) {
            return <Redirect to={this.state.overviewRoute} />;
          }

          this.state.yieldSet = data.yieldSets.edges.find((yieldSet) => {
            return yieldSet.node.yieldSetId === yieldSetId;
          });

          if (!this.state.yieldSet) {
            return <Redirect to={this.state.overviewRoute} />;
          }

          this.state.yieldSet = this.state.yieldSet.node;

          return this.renderForm(isEdit, accountConfig);
        }}
      </Query>
    );
  }

  setItem(item) {
    this.state.yieldSet = item.yieldSet;
    this.state.item = item;
    this.state.unitType = item.unitType;
    this.state.platformType = item.platformType;
    this.state.adxFloorPrice = parseFloat(item.adxFloorPrice).toFixed(2);
    this.state.adSenseFloorPrice = parseFloat(item.adSenseFloorPrice).toFixed(
      2
    );
  }

  renderConfig(accountConfig) {
    var adUnitId = parseInt(this.props.match.params.adUnitId);
    var placementId = parseInt(this.props.match.params.placementId);
    var yieldSetId = parseInt(this.props.match.params.yieldSetId);
    var itemId = placementId || adUnitId;
    var isEdit = !!itemId;
    var itemQuery = GET_AD_UNIT;
    var variables = {
      filter: {},
      pubId: this.state.publisherId,
    };

    if (isEdit) {
      if (adUnitId) {
        variables.filter.adUnitIds = [itemId];
      } else {
        itemQuery = GET_PLACEMENTS;
        variables.filter.placementIds = [itemId];
      }

      return (
        <div className={CommonStyle.wrapper}>
          <Query
            query={itemQuery}
            variables={variables}
            fetchPolicy={"network-only"}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <YbLoadingView />;
              }

              if (error) {
                return <Redirect to={this.state.overviewRoute} />;
              }

              if (!this.state.item) {
                var item = data.items.edges.find((item) => {
                  return item.node.itemId === itemId;
                });

                if (!item) {
                  return <Redirect to={this.state.overviewRoute} />;
                }

                this.setItem(item.node);
              }

              return (
                <span>
                  {this.renderYieldSet(
                    this.state.yieldSet.yieldSetId,
                    isEdit,
                    accountConfig
                  )}
                </span>
              );
            }}
          </Query>
        </div>
      );
    } else if (yieldSetId) {
      return (
        <div className={CommonStyle.wrapper}>
          {this.renderYieldSet(yieldSetId, isEdit, accountConfig)}
        </div>
      );
    }

    return <Redirect to={this.state.overviewRoute} />;
  }

  render() {
    var accountConfig = null;

    return (
      <Query
        query={ACCOUNT_CONFIG}
        variables={{ pubId: this.state.publisherId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return (
              <div className={`${CommonStyle.wrapper}`}>
                <div
                  className={`${CommonStyle.textDanger} ${CommonStyle.textView}`}
                >
                  {i18n`ERROR_LOADING_ACCOUNT_CONFIG_ELLIPSIS`}
                </div>
              </div>
            );
          }

          if (data && !accountConfig) {
            accountConfig = data.accountConfig.config.resourceConfig;
          }

          if (!accountConfig.gamAdUnit.enabled) {
            this.state.itemType = "placement";
          }

          return this.renderConfig(accountConfig);
        }}
      </Query>
    );
  }
}

module.exports = AdUnitForm;
