import React from "react";
import moment from "moment";
import { UserInfoContext } from "../../common/contexts/contexts";
import CommonStyle from "../../../common/styles/common.css";

import YbLoading from "common/components/yb-loading";
import FilterHeader from "../components/filter-header";
import SummarySection from "../components/summary-section";
import InventorySection from "../components/inventory-section";
import TrendSection from "../components/trend-section";

import { DATE_RANGE_TYPE, getDateRangeByType } from "../helpers/date-helper";
import {
  transformHomeDashboardData,
  calculateInventoryData,
  calculateSummaryData,
  calculateDistributionData,
  calculateTrendData,
} from "../helpers/reports-helper";
import AlgorithmSection from "../components/algorithm-section";

function _convertDateToYMD(date) {
  return moment(date).format("YYYY-MM-DD");
}

// V2
class HomeDashboardView extends React.Component {
  constructor(props) {
    super(props);

    const { startDate, endDate } = getDateRangeByType(DATE_RANGE_TYPE.DEFAULT);

    const { resultData } = props;
    const homeDashboardData = transformHomeDashboardData(resultData);

    const { publishers, gamNetworks } = homeDashboardData;

    // Preselect the only publisher, otherwise "All"
    const selectedPubId =
      publishers.length === 1 ? _.get(publishers, [0, "id"], -1) : -1;

    // For Reseller
    let filteredNetworks = gamNetworks;
    if (selectedPubId !== -1) {
      filteredNetworks = _.filter(gamNetworks, { pubId: selectedPubId });
    }

    // Preselect the only network, otherwise "All"
    const selectedNetworkId =
      gamNetworks.length === 1 ? _.get(gamNetworks, [0, "id"], -1) : -1;

    // const selectedPubId = -1; // Default: All publishers
    // const selectedNetworkId = -1; // Default: All networks
    const selectedYieldSetIds = -1; // All YieldSets

    const selectedCurrency = homeDashboardData.defaultReportingCurrency;
    const currencies = _.keys(homeDashboardData.exchangeRates);
    const exchangeRates = homeDashboardData.exchangeRates[selectedCurrency];

    this.state = {
      // filter
      selectedPubId,
      filteredNetworks,
      currencies,

      selectedNetworkId,
      selectedYieldSetIds,
      selectedCurrency,

      startDate,
      endDate,

      // data
      homeDashboardData,
      exchangeRates,

      publishers,

      inventoryData: calculateInventoryData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
      }),
      summaryData: calculateSummaryData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
    };

    this.handlePublisherChanged = this.handlePublisherChanged.bind(this);
    this.handleInventoryFilterChanged =
      this.handleInventoryFilterChanged.bind(this);
    this.handleDateRangeFilterChanged =
      this.handleDateRangeFilterChanged.bind(this);
    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
  }

  handlePublisherChanged(pubId) {
    console.log("Publisher changed: ", pubId);
    this.setState({ selectedPubId: pubId });
  }

  handleInventoryFilterChanged({
    selectedPubId,
    selectedNetworkId,
    selectedYieldSetIds,
  }) {
    console.log(
      "Inventory Filter changed: ",
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds
    );
    this.setState({
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
      inventoryData: calculateInventoryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
      }),
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleDateRangeFilterChanged({ startDate, endDate }) {
    // Important! Convert a date object (with timezone) to just YYYY-MM-DD format
    var sd = _convertDateToYMD(startDate);
    var ed = _convertDateToYMD(endDate);

    console.log("Date Range Filter changed: ", sd, ed);
    this.setState({
      startDate: sd,
      endDate: ed,
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetIds: this.state.selectedYieldSetIds,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetIds: this.state.selectedYieldSetIds,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetIds: this.state.selectedYieldSetIds,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleCurrencyChanged(currency) {
    console.log("Currency changed: ", currency);
    const {
      homeDashboardData,
      startDate,
      endDate,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
    } = this.state;
    const exchangeRates = homeDashboardData.exchangeRates[currency];
    this.setState({
      selectedCurrency: currency,
      exchangeRates,
      startDate,
      endDate,
      summaryData: calculateSummaryData({
        homeDashboardData: homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetIds,
        startDate,
        endDate,
        exchangeRates,
      }),
    });
  }

  render() {
    const {
      isLoading,
      errMsg,
      homeDashboardData,

      selectedPubId,
      publishers,
      filteredNetworks,
      selectedNetworkId,
      selectedYieldSetIds,
      inventoryData,
      summaryData,
      startDate,
      endDate,
      distributionData,
      trendData,
      currencies,
      selectedCurrency,
    } = this.state;

    if (isLoading) {
      return (
        <div className={CommonStyle.textAlignCenter}>
          <YbLoading></YbLoading>
        </div>
      );
    }

    if (errMsg) {
      return <div>{errMsg}</div>;
    }

    if (!homeDashboardData) {
      return "No Data";
    }

    return (
      <UserInfoContext.Consumer>
        {({ userInfo }) => {
          const isReseller = userInfo.reseller;

          return (
            <React.Fragment>
              <div style={styles.fixedWidth}>
                <div
                  className={CommonStyle.pageTitle}
                  style={{ marginLeft: "-16px", marginTop: "8px" }}
                >
                  Home Dashboard
                </div>
              </div>

              <div className="sticky-top">
                <FilterHeader
                  isReseller={isReseller}
                  selectedPubId={selectedPubId}
                  publishers={publishers}
                  networks={filteredNetworks}
                  selectedNetworkId={selectedNetworkId}
                  selectedYieldSetIds={selectedYieldSetIds}
                  yieldSets={homeDashboardData.yieldSets}
                  handleInventoryFilterChanged={
                    this.handleInventoryFilterChanged
                  }
                  currencies={currencies}
                  selectedCurrency={selectedCurrency}
                  handleCurrencyChanged={this.handleCurrencyChanged}
                  startDate={startDate}
                  endDate={endDate}
                  handleDateRangeFilterChanged={
                    this.handleDateRangeFilterChanged
                  }
                ></FilterHeader>
              </div>

              {/* <div style={{ margin: "24px 84px" }}> */}
              <div style={styles.fixedWidth}>
                <InventorySection
                  updatedTime={homeDashboardData.updatedTime}
                  inventoryData={inventoryData}
                  selectedYieldSetIds={selectedYieldSetIds}
                ></InventorySection>

                <AlgorithmSection
                  inventoryData={inventoryData}
                  selectedYieldSetIds={selectedYieldSetIds}
                ></AlgorithmSection>

                <br></br>

                <SummarySection
                  selectedPubId={selectedPubId}
                  selectedNetworkId={selectedNetworkId}
                  selectedYieldSetIds={selectedYieldSetIds}
                  startDate={startDate}
                  endDate={endDate}
                  summaryData={summaryData}
                  distributionData={distributionData}
                  selectedCurrency={selectedCurrency}
                ></SummarySection>

                <TrendSection
                  selectedPubId={selectedPubId}
                  selectedNetworkId={selectedNetworkId}
                  selectedYieldSetIds={selectedYieldSetIds}
                  startDate={startDate}
                  endDate={endDate}
                  reports={trendData}
                  summaryData={summaryData}
                  selectedCurrency={selectedCurrency}
                ></TrendSection>
              </div>
            </React.Fragment>
          );
        }}
      </UserInfoContext.Consumer>
    );
  }
}

const styles = {
  fixedWidth: {
    padding: "8px 24px",
    maxWidth: "1280px",
    margin: "auto",
  },
};

export default HomeDashboardView;
