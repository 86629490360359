import React, { Fragment } from "react";
import { Card, Row, Col } from "react-bootstrap";

function CurrentPaymentInfo(props) {
  const paymentInfo = props.currentPaymentInfo;

  return (
    <Fragment>
      <Row>
        <Col sm="4">
          <Card className="text-center">
            {/* <Card.Header>Current Payment Term</Card.Header> */}
            <Card.Body>
              <div>
                <span className="text-secondary">Current Payment Amount</span>
              </div>
              <div>
                <small>TWD</small> <span style={{ fontSize: "20px" }}>928</span>
              </div>
              <div>
                {/* <small className="text-muted">2019/05/01 - 2019/05/31</small> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="4">
          <Card className="text-center">
            <Card.Body>
              <div>
                <span className="text-secondary">Payment Due</span>
              </div>
              <div>
                <span style={{ fontSize: "20px" }}>2019/06/06</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="4">
          <Card className="text-center">
            <Card.Body>
              <div>
                <span className="text-secondary">Next Invoice Issue Date</span>
              </div>
              <div>
                <span style={{ fontSize: "20px" }}>2019/06/06</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col sm="8">
          <Card>
            <Card.Body>
              <div>Current Payment Due Date: 2019/06/06</div>
              <div>Next Invoice Issue Date: 2019/06/06</div>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </Fragment>
  );
}

module.exports = CurrentPaymentInfo;
