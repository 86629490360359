import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Alert, Card } from "react-bootstrap";
import intowowLogo from "../../../common/images/intowow-logo-180x180.png";

const COOKIE_NAME = "loginAsPubId";
function PermissionDeniedPage(props) {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);
  useEffect(() => {
    document.body.style.backgroundColor = "#011542";
    return () => {
      document.body.style.backgroundColor = null;
    };
  });

  function refresh() {
    removeCookie(COOKIE_NAME);
    window.location.reload();
  }

  return (
    <React.Fragment>
      <div
        style={{
          paddingTop: "80px",
          paddingBottom: "120px",
        }}
      >
        <div className="d-flex justify-content-center align-self-center">
          <div style={{ width: "60%" }}>
            <div className="d-flex justify-content-center pb-4">
              <div
                style={{
                  // background: "linear-gradient(to bottom, #f83600, #fe8c00)",
                  // background: "linear-gradient(to top, #2B32B2, #1488CC)",
                  backgroundColor: "#fff",
                  borderRadius: "30px",
                  border: "4px solid black",
                }}
              >
                <img src={intowowLogo} width="52px" />
              </div>
            </div>

            <Card
              className="text-center shadow-lg"
              style={{
                borderRadius: "8px",
                padding: "24px",
                background: "#fbfeff",
              }}
            >
              <Card.Body>
                <h1 className="mt-2 mb-4">Permission Denied</h1>

                <div>
                  <Alert variant="danger" className="p-4">
                    <i className="fa fa-exclamation-circle fa-fw" />
                    Click{" "}
                    <a href="" onClick={refresh}>
                      here
                    </a>{" "}
                    to refresh.
                    <br></br>
                    Or contact Intowow to make sure you have the right
                    permissions.
                  </Alert>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PermissionDeniedPage;
