// adunits conveniently sorted alphabeltically in desc
const CHILDREN_ONBOARD_STATUS = {
  ALL_NOT_COMPATIBLE: "ALL_NOT_COMPATIBLE", // show these last
  ALL_ONBOARDED: "ALL_ONBOARDED",
  NONE_ONBOARDED: "NONE_ONBOARDED",
  SOME_ONBOARDED: "SOME_ONBOARDED", // show these first
};
const CHILDREN_SELECTION_STATUS = {
  SOME_SELECTED: "SOME_SELECTED",
  ALL_SELECTED: "ALL_SELECTED",
  NONE_SELECTED: "NONE_SELECTED",
};
const CHECKBOX_STATUS = {
  ONBOARDED: "ONBOARDED",
  NOT_COMPATIBLE: "NOT_COMPATIBLE",
  SELECTED: "SELECTED",
  NOT_SELECTED: "NOT_SELECTED",
};

export function formatCandidates(candidates, rootUnit) {
  if (!candidates || candidates.length === 0) {
    return [];
  }

  const unitsMapByFullPath = _.keyBy(candidates, (unit) => {
    return `${unit.parentPath === "/" ? "" : unit.parentPath}/${unit.name}`;
  });

  if (rootUnit) {
    unitsMapByFullPath[`/${rootUnit.name}`] = rootUnit;
  }

  for (const c in candidates) {
    const item = candidates[c];

    item.fullPath = `${item.parentPath === "/" ? "" : item.parentPath}/${
      item.name
    }`;

    // to calculate child unit selection status
    item.parentUnit = unitsMapByFullPath[item.parentPath];
    item.directChildUnits = [];
    item.selectedDirectCount = 0;
    item.selectedPartialDirectCount = 0;
    item.selectableDirectChildUnitCount = 0;

    // default checkbox status
    if (item.isOnboarded) {
      item.checkboxStatus = CHECKBOX_STATUS.ONBOARDED;
    } else if (!item.isCompatible) {
      item.checkboxStatus = CHECKBOX_STATUS.NOT_COMPATIBLE;
    } else {
      item.checkboxStatus = CHECKBOX_STATUS.NOT_SELECTED;
    }

    // child unit onboarding status
    let childrenOnboardStatus = CHILDREN_ONBOARD_STATUS.NONE_ONBOARDED;
    if (item.numOnboardableChildren === 0 && item.numOnboardedChildren === 0) {
      childrenOnboardStatus = CHILDREN_ONBOARD_STATUS.ALL_NOT_COMPATIBLE;
    }

    if (item.numOnboardableChildren === 0 && item.numOnboardedChildren > 0) {
      childrenOnboardStatus = CHILDREN_ONBOARD_STATUS.ALL_ONBOARDED;
    }

    if (item.numOnboardableChildren > 0 && item.numOnboardedChildren > 0) {
      childrenOnboardStatus = CHILDREN_ONBOARD_STATUS.SOME_ONBOARDED;
    }
    item.childrenOnboardStatus = childrenOnboardStatus;

    // child unit selection status
    // will be changed when the unit's child units have been selected
    item.childrenSelectionStatus = CHILDREN_SELECTION_STATUS.NONE_SELECTED;

    // Portfolio
    const {
      gptReqPercentage,
      ampReqPercentage,
      dailyCompatibleAvgReq,
      othersReqPercentage,
      dailyTotalAvg,
      gptReq,
      ampReq,
      othersReq,
    } = _formatPortfolio(item);

    item.gptReqPercentage = gptReqPercentage;
    item.ampReqPercentage = ampReqPercentage;
    item.dailyCompatibleAvgReq = dailyCompatibleAvgReq;

    // used in tooltip
    item.othersReqPercentage = othersReqPercentage;
    item.dailyTotalAvg = dailyTotalAvg;
    item.gptReq = gptReq;
    item.ampReq = ampReq;
    item.othersReq = othersReq;
  }

  for (let c of candidates) {
    if (c.parentUnit) {
      c.parentUnit.directChildUnits.push(c);
    }
  }

  for (let c of candidates) {
    for (let dc of c.directChildUnits) {
      // is parent
      // or
      // if leaf
      // isCompatible
      // is not onboarded

      // no longer matters if its parent or leaf
      // isCompatible + !isOnboarded
      // let isSelectableParent = dc.hasChildren && dc.numOnboardableChildren > 0;
      // let isSelectableLeaf =
      //   !dc.hasChildren && dc.isCompatible && !dc.isOnboarded;
      let isSelectableUnit = dc.isCompatible && !dc.isOnboarded;

      // if (isSelectableParent || isSelectableLeaf) {
      if (dc.numOnboardableChildren > 0 || isSelectableUnit) {
        ++c.selectableDirectChildUnitCount;
      }
    }
  }

  if (rootUnit) {
    for (let dc of rootUnit.directChildUnits) {
      // let isSelectableParent = dc.hasChildren && dc.numOnboardableChildren > 0;
      // let isSelectableLeaf =
      //   !dc.hasChildren && dc.isCompatible && !dc.isOnboarded;
      let isSelectableUnit = dc.isCompatible && !dc.isOnboarded;

      // if (isSelectableParent || isSelectableLeaf) {
      if (dc.numOnboardableChildren > 0 || isSelectableUnit) {
        ++rootUnit.selectableDirectChildUnitCount;
      }
    }
  }

  // Sort candidates!
  candidates = _.orderBy(
    candidates,
    [
      "hasChildren",
      "isCompatible",
      "childrenOnboardStatus",
      "dailyCompatibleAvgReq",
      "isOnboarded",
    ],
    ["desc", "desc", "desc", "desc", "asc"]
  );

  return candidates;
}

// const REQ_TYPE = {
//   0: "Unknown",
//   1: "GPT",
//   2: "AMP",
//   3: "GPT Lite",
//   4: "APP",
//   5: "Video",
// };
function _formatPortfolio(item) {
  // Important! portfolio schema is fixed!
  let reqStatPortfolio = item.portfolio[0];
  let gptReq = 0;
  let ampReq = 0;
  let othersReq = 0;
  let dailyCompatibleAvgReq = 0;
  let dailyTotalAvg = 0;

  for (let i = 0; i < reqStatPortfolio.length; ++i) {
    let reqPortfolio = reqStatPortfolio[i];
    let reqType = reqPortfolio[0];
    let isCompatible = reqPortfolio[1];
    let dailyReq = reqPortfolio[2];

    dailyTotalAvg += dailyReq;
    if (isCompatible) {
      dailyCompatibleAvgReq += dailyReq;
    }

    if (reqType === 1) {
      gptReq += dailyReq;
    } else if (reqType === 2) {
      ampReq += dailyReq;
    } else {
      othersReq += dailyReq;
    }
  }
  let gptReqPercentage =
    dailyCompatibleAvgReq > 0 ? (gptReq / dailyCompatibleAvgReq) * 100 : 0;
  let ampReqPercentage =
    dailyCompatibleAvgReq > 0 ? (ampReq / dailyCompatibleAvgReq) * 100 : 0;
  let othersReqPercentage =
    dailyCompatibleAvgReq > 0 ? (othersReq / dailyCompatibleAvgReq) * 100 : 0;

  // if (!item.isCompatible) {
  //   gptReqPercentage = 0;
  //   ampReqPercentage = 0;
  //   dailyCompatibleAvgReq = 0;
  // }

  return {
    gptReqPercentage,
    ampReqPercentage,
    dailyCompatibleAvgReq,
    othersReqPercentage,
    dailyTotalAvg,
    gptReq,
    ampReq,
    othersReq,
  };
}
