import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";

import YbLoadingView from "common/components/yb-loading-view";
import YbLoading from "common/components/yb-loading";
import YbHighlight from "common/components/yb-highlight";
import YbModal from "common/components/yb-modal";
import YbTreeSelector from "common/components/yb-tree-selector";

import YbAddNetworkBtn from "../../summary/components/yb-add-network-btn";
import YbNetworkConfigHint from "../../summary/components/yb-network-config-hint";

import Styles from "../styles/wizard.css";

import { GET_NETWORKS } from "../../summary/query/summary";

class YbWizardStep1 extends React.Component {
  constructor(props) {
    super(props);

    this.networkChanged = this.networkChanged.bind(this);
    this.networkCreated = this.networkCreated.bind(this);
    this.openHint = this.openHint.bind(this);
    this.onHintClose = this.onHintClose.bind(this);
    this.handleVerifiedChange = this.handleVerifiedChange.bind(this);
    this.permissionDeniedCallback = this.permissionDeniedCallback.bind(this);
    this.onPermisisonDeniedClose = this.onPermisisonDeniedClose.bind(this);

    this.state = {
      publisherId: props.publisherId,
    };
  }

  permissionDeniedCallback(network) {
    this.props.updateCurrentData((stepData) => {
      stepData.verifiedGAMSettings = false;
      stepData.showPermissionDeniedAlertNetwork = network;
    });
  }

  onPermisisonDeniedClose() {
    this.props.updateCurrentData((stepData) => {
      stepData.showPermissionDeniedAlertNetwork = false;
    });
  }

  networkChanged(network) {
    this.props.updateCurrentData((stepData) => {
      stepData.selectedNetwork = network;
    });
  }

  openHint(info1) {
    this.props.updateCurrentData((stepData) => {
      if (info1) {
        stepData.showHintModal1 = true;
      } else {
        stepData.showHintModal2 = true;
      }
    });
  }

  handleVerifiedChange() {
    this.props.updateCurrentData((stepData) => {
      stepData.verifiedGAMSettings = !stepData.verifiedGAMSettings;
      stepData.showPermissionDeniedAlertNetwork = null;
    });
  }

  onHintClose() {
    this.props.updateCurrentData((stepData) => {
      stepData.showHintModal1 = false;
      stepData.showHintModal2 = false;
    });
  }

  networkCreated(data) {
    this.props.updateCurrentData((stepData) => {
      if (data) {
        stepData.refetch();
        stepData.createdNetworkId = data.createGamNetwork.gamNetwork.networkId;
        stepData.radio = "option2";
        stepData.networks = null;
        stepData.verifiedGAMSettings = false;
      }
    });
  }

  render() {
    var stepData = this.props.stepData;
    var variables = {
      filter: {
        gamAccountId: stepData.selectedAccount.accountId,
      },
      pubId: this.state.publisherId,
    };

    return (
      <Query
        query={GET_NETWORKS}
        fetchPolicy={"network-only"}
        variables={variables}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return (
              <div
                className={`${CommonStyle.textView} ${CommonStyle.textDanger}`}
              >
                {i18n`ERROR_LOADING_NETWORKS_ELLIPSIS`}
              </div>
            );
          }

          if (data) {
            stepData.networks = data.gamNetworks.edges.map((edge) => edge.node);
            stepData.networks = _.filter(stepData.networks, (network) => {
              return (
                network.status === "RUNNING" || network.status === "PAUSED"
              );
            });

            stepData.refetch = refetch;

            if (stepData.createdNetworkId) {
              setTimeout(() => {
                this.props.updateCurrentData((stepData) => {
                  stepData.selectedNetwork = stepData.networks.find(
                    (network) => {
                      return network.networkId === stepData.createdNetworkId;
                    }
                  );

                  if (stepData.selectedNetwork) {
                    stepData.createdNetworkId = null;
                  }
                });
              });
            } else if (
              !stepData.radio ||
              (!stepData.networks.length && stepData.radio !== "option1")
            ) {
              if (!stepData.networks.length) {
                stepData.radio = "option1";
                stepData.verifiedGAMSettings = false;
              } else {
                stepData.radio = "option2";

                if (!stepData.selectedNetwork) {
                  setTimeout(() => {
                    this.props.updateCurrentData((stepData) => {
                      stepData.selectedNetwork = stepData.networks[0];
                    });
                  });
                }
              }
            }
          }

          return (
            <div>
              {!!stepData.networks.length && (
                <div className={CommonStyle.textAlignLeft}>
                  <label className={`${FormStyle.inputRadio}`}>
                    <input
                      type="radio"
                      value="option1"
                      name="radio"
                      onChange={this.props.handleOptionChange}
                      checked={stepData.radio === "option1"}
                    />{" "}
                    {i18n`Add new network`}
                  </label>
                  <br />
                  <label className={`${FormStyle.inputRadio}`}>
                    <input
                      type="radio"
                      value="option2"
                      name="radio"
                      onChange={this.props.handleOptionChange}
                      checked={stepData.radio === "option2"}
                    />{" "}
                    {i18n`Use an existing network`}
                  </label>
                </div>
              )}
              {stepData.radio === "option1" && (
                <div>
                  <br />
                  <div>
                    <div
                      className={`alert ${
                        stepData.showPermissionDeniedAlertNetwork
                          ? "alert-danger"
                          : "alert-secondary"
                      }`}
                      role="alert"
                    >
                      {stepData.showPermissionDeniedAlertNetwork && (
                        <div className={CommonStyle.textDanger}>
                          <strong>
                            {i18n`API access was not turned on for #NETWORK_NAME#.`.replace(
                              /\#NETWORK_NAME\#/,
                              stepData.showPermissionDeniedAlertNetwork.name
                            )}
                          </strong>
                          <br />
                          <br />
                        </div>
                      )}
                      <i className="fa fa-info-circle" />{" "}
                      <YbHighlight
                        text={i18n`Before adding a network, please confirm API access setting is turned on in your GAM console. API access feature is required to automatically optimize your inventory.`}
                        highlight={i18n`API access`}
                      />
                      <div>
                        <br />
                        <button
                          className={CommonStyle.button}
                          onClick={this.openHint.bind(this, true)}
                        >
                          <span>
                            <i className="fa fa-info-circle" />{" "}
                            {i18n`How to Enable API access`}
                          </span>
                        </button>
                      </div>
                      <br />
                      <label>
                        <strong>
                          <input
                            type="checkbox"
                            checked={stepData.verifiedGAMSettings}
                            onChange={this.handleVerifiedChange}
                          />{" "}
                          <YbHighlight
                            text={i18n`I have turned on API access on my GAM Console`}
                            highlight={[i18n`API access`]}
                          />{" "}
                          <span className={FormStyle.requiredStar}>*</span>
                        </strong>
                      </label>
                    </div>
                    <br />
                    {stepData.verifiedGAMSettings && (
                      <div>
                        <div>{i18n`From your Google Ad Manager account, Add a Network using the button below`}</div>
                        <br />
                        <YbAddNetworkBtn
                          btnClass={`${CommonStyle.buttonPrimary} ${CommonStyle.buttonBig}`}
                          account={stepData.selectedAccount}
                          networks={stepData.networks}
                          callback={this.networkCreated}
                          permissionDeniedCallback={
                            this.permissionDeniedCallback
                          }
                          publisherId={this.state.publisherId}
                        />
                      </div>
                    )}
                    <YbNetworkConfigHint
                      show={!!stepData.showHintModal1}
                      hint={2}
                      callback={this.onHintClose}
                    />
                  </div>
                </div>
              )}
              {stepData.radio === "option2" && (
                <div className={FormStyle.row}>
                  <div className={FormStyle.label}>
                    {i18n`Network`}{" "}
                    <span className={FormStyle.requiredStar}>*</span>
                  </div>
                  <YbTreeSelector
                    noSort={true}
                    noHeader={true}
                    short={true}
                    items={[stepData.networks]}
                    value={[]}
                    selectedItems={stepData.selectedNetwork}
                    onSelect={this.networkChanged}
                    onRemove={this.networkChanged}
                    id="networkId"
                  />
                  <br />
                  {/* <div>
                    <div className="alert alert-secondary" role="alert">
                      <i className="fa fa-info-circle" />{" "}
                      <YbHighlight
                        text={i18n`Before moving forward to the next step, please confirm both the Key-values and API access setting are turned on in your GAM console. The Key-values report is required to enhance Intowow algorithm.`}
                        highlight={i18n`Key-values`}
                      />
                      <br />
                      <br />
                      <div>
                        <button
                          className={CommonStyle.button}
                          onClick={this.openHint.bind(this, false)}
                        >
                          <span>
                            <i className="fa fa-info-circle" />{" "}
                            {i18n`How to Enable Key-values`}
                          </span>
                        </button>
                      </div>
                      <br />
                      <label>
                        <strong>
                          <input
                            type="checkbox"
                            checked={stepData.verifiedGAMSettings}
                            onChange={this.handleVerifiedChange}
                          />{" "}
                          <YbHighlight
                            text={i18n`I turned on Key-values on my GAM Console`}
                            highlight={[i18n`Key-values`, i18n`API access`]}
                          />{" "}
                          <span className={FormStyle.requiredStar}>*</span>
                        </strong>
                      </label>
                    </div>
                    <br />
                    <br />
                    <YbNetworkConfigHint
                      show={!!stepData.showHintModal2}
                      hint={1}
                      callback={this.onHintClose}
                    />
                  </div> */}
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

module.exports = YbWizardStep1;
