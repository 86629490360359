import React from "react";
import ItemStyle from "common/styles/inventory-item-latest.css";

function ParentUnitLabel() {
  return (
    <div className={ItemStyle.parentLabel} title="Parent Unit">
      P
    </div>
  );
}

module.exports = ParentUnitLabel;
