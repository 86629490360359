import React from 'react'

import Styles from 'common/styles/common.css'
import YbDivider from 'common/components/yb-divider'

class NoMatch extends React.Component {
  constructor(props) {
    super(props);
  }

  backToHome () {
    this.props.history.replace(this.props.link)
  }

  render() {
    return (
      <div>
        <div className={Styles.notFoundWrapper}>
          <div className={Styles.mainTitle}>
            {i18n`ERROR_404_PAGE_NOT_FOUND_EQ`}
          </div>
          <YbDivider></YbDivider>
          <span className={Styles.roundButton} onClick={this.backToHome.bind(this)}>
            <i className="fa fa-home"></i> {i18n`BACK_TO_HOME`}
          </span>
        </div>
      </div>
    );
  }
}

module.exports = NoMatch;
