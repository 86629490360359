import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

class YbInfoTooltip extends React.PureComponent {
  render() {
    const { metricKey, place } = this.props;

    if (!metricKey) {
      return "";
    }

    const tooltipKey = "yb-tt-" + metricKey;

    return (
      <React.Fragment>
        <OverlayTrigger
          trigger={["click"]}
          placement={place || "top-start"}
          overlay={
            <Popover
              id={tooltipKey}
              className="shadow-lg"
              style={{
                maxWidth: "632px",
                maxHeight: "300px",
                overflow: "auto",
                backgroundColor: "#f7f7f7",
              }}
            >
              <Popover.Content>{this.props.children}</Popover.Content>
            </Popover>
          }
        >
          <span
            id={tooltipKey}
            className="px-1"
            style={{ marginRight: "-8px", cursor: "pointer" }}
          >
            <i
              className="fa fa-info-circle"
              style={{ color: "#a5a5a5" }}
              aria-hidden="true"
            ></i>
          </span>
        </OverlayTrigger>
      </React.Fragment>
    );
  }
}

export default YbInfoTooltip;
