import React from 'react'
import qs from 'querystring'

class OAuthCallback extends React.Component {
  constructor () {
    super()
    this.state = {}
  }

  componentDidMount () {
    let { search } = window.location
    if (search[0] === '?') {
      search = search.substr(1)
    }

    const query = qs.parse(search)
    const { opener } = window
    if (opener) {
      opener.focus()
      opener.dispatchEvent(new CustomEvent('oauth', {
        detail: {
          query,
          intowow_oauth: this.props.oauthState.intowow_oauth
        }
      }))
      window.close()
    } else {
      this.props.history.replace('/')
    }
  }

  render() {
    return <div></div>
  }
};

export default OAuthCallback
