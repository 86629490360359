import React from "react";
import { Query, withApollo, Mutation } from "react-apollo";

import CommonStyles from "common/styles/common.css";
import YbPagedTableView from "common/components/yb-paged-table-view";

import { CacheHelper } from "../services/cache-helper";
import OAuth from "../components/oauth";
import { query as gamQuery, mutation } from "../query/gam-account";

class GoogleAccountBind extends React.Component {
  constructor(props) {
    super(props);
    const { client } = props;
    this.createHelper(client);
    this.state = {};
  }

  createHelper(client) {
    this.helper = new CacheHelper(client, { query: gamQuery }, "gamAccounts", {
      connection: "GamAccountConnection",
      edge: "GamAccountEdge"
    });
  }

  render() {
    return (
      <div className={CommonStyles.wrapper}>
        <div className="row">
          <div className="col">
            <Query
              variables={{
                first: this.props.count,
                pubId: this.props.publisherId
              }}
              query={gamQuery}
            >
              {({ loading, error, data, fetchMore }) => {
                if (error) {
                  return <h1>{error.message}</h1>;
                }

                return (
                  <YbPagedTableView
                    id="gam-account-table"
                    {...this.createGamConfig(data.gamAccounts)}
                    onUpdateCursor={cursor =>
                      fetchMore({
                        variables: {
                          first: this.props.count,
                          after: cursor,
                          pubId: this.props.publisherId
                        },
                        updateQuery: this.helper.afterFetchMore.bind(
                          this.helper
                        )
                      })
                    }
                  >
                    <Mutation mutation={mutation}>
                      {(addGamAccount, { data, loading, called, error }) => (
                        <div>
                          <OAuth
                            {...this.oauthConfig()}
                            modal={{
                              error: error,
                              title: i18n`NEW_GAM_ACCOUNT`,
                              fields: [
                                {
                                  key: "name",
                                  title: i18n`NAME`,
                                  placeholder: i18n`EXAMPLE_AT_DOMAIN_DOT_COM_ELLIPSIS`
                                }
                              ],
                              disabled: loading,
                              onSubmit: ({ name, code }) =>
                                addGamAccount({
                                  variables: {
                                    name,
                                    code,
                                    pubId: this.props.publisherId
                                  }
                                }).then(({ data }) =>
                                  this.helper.afterMutation({
                                    query: gamQuery,
                                    data: data.createGamAccount.gamAccount
                                  })
                                )
                            }}
                          >
                            <button
                              key="add"
                              type="button"
                              className={CommonStyles.buttonSuccess}
                            >
                              <i className="fa fa-plus" /> {i18n`GAM_ACCOUNT`}
                            </button>
                          </OAuth>
                        </div>
                      )}
                    </Mutation>
                  </YbPagedTableView>
                );
              }}
            </Query>
          </div>
        </div>
      </div>
    );
  }

  oauthConfig() {
    return {
      url: "https://accounts.google.com/o/oauth2/v2/auth",
      params: {
        scope: "https://www.googleapis.com/auth/dfp",
        include_granted_scopes: false,
        redirect_uri: window.location.origin,
        response_type: "code",
        client_id: process.env.GOOGLE_SERVICE_CLIENT_ID,
        prompt: "consent",
        access_type: "offline"
      }
    };
  }

  createGamConfig(accounts = { pageInfo: {}, edges: [] }) {
    return {
      count: this.props.count,
      hasMore: accounts.pageInfo.hasNextPage,
      intowowTag: "gam",
      title: i18n`GAM_ACCOUNTS`,
      columns: [
        {
          name: i18n`NAME`,
          key: "name"
        },
        {
          name: i18n`ACTIONS`,
          key: "actions"
        }
      ],
      rows: accounts.edges.map(
        ({ node: { id, accountId, name, problems } }) => ({
          id,
          data: {
            name,
            actions: []
          }
        })
      )
    };
  }
}

export default withApollo(GoogleAccountBind);
