import React from "react";

import ItemStyle from "common/styles/inventory-item.css";

import YbReqStatTooltip from "./yb-req-stat-tooltip";
import YbReqStatDiagram from "./yb-req-stat-diagram";

const YbReqStatWrapper = ({ item }) => {
  // only show if portfolio has data
  const hasPortfolioData =
    item.portfolio &&
    item.portfolio.length > 0 &&
    item.portfolio[0] &&
    item.portfolio[0].length > 0;

  // workaround
  // if hasData but also not compatible
  const workaroundStyle =
    hasPortfolioData && !item.isCompatible ? { marginTop: "8px" } : {};

  return (
    <div className={ItemStyle.itemStat} style={workaroundStyle}>
      {hasPortfolioData && (
        <YbReqStatTooltip item={item} tooltipKey={item.extId + "-req-tooltip"}>
          <YbReqStatDiagram item={item}></YbReqStatDiagram>
        </YbReqStatTooltip>
      )}
    </div>
  );
};

module.exports = YbReqStatWrapper;
