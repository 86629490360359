import React from 'react'

import CommonStyle from 'common/styles/common.css'

import Styles from '../styles/summary.css'
import YbProblems from '../components/yb-problems'

class YbStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var item = this.props.item;
    var index = this.props.index;
    var total = this.props.total;
    var itemType = this.props.itemType;
    var showStatus = this.props.showStatus;
    var displayOnly = this.props.displayOnly;
    var status = <span></span>;
    var position = 'bottom';

    if (displayOnly) {
      item.status = 'PAUSED';
    }

    if (showStatus) {
      switch(item.status) {
        case 'PROCESSING':
          status = <span className={CommonStyle.textWarning}>{i18n`PROCESSING_ELLIPSIS`}</span>;
          break;
        case 'RUNNING':
          status = <span className={CommonStyle.textSuccess}>{i18n`RUNNING_EM`}</span>;
          break;
        case 'PAUSED':
          status = <span className={CommonStyle.textDanger}>{i18n`PAUSED`}</span>;
          break;
        case 'ARCHIVED':
          status = <span className={CommonStyle.textMuted}>{i18n`ARCHIVED`}</span>;
          break;
      }
    }

    if (index > total/2) {
      position = 'top';
    }

    return (
      <span>
        {showStatus && (
          <span className={Styles.statusWrapper}>
            {status}
          </span>
        )}
        <YbProblems item={item} position={position} itemType={itemType}/>
      </span>
    );
  }
};

module.exports = YbStatus;
