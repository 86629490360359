import React from "react";
import { Card, Alert } from "react-bootstrap";
import cogoToast from "cogo-toast";

import {
  GOOGLE_ACCOUNT_TYPE,
  GAM_LOGO_TYPE,
  API_ENDPOINT,
} from "../../common/constants/common";

import PublisherBasicInfo from "./publisher-basic-info";
import PublisherGamProfile from "./publisher-gam-profile-v2";
import PublisherContactInfo from "./publisher-contact-info";

function RegisterConfirmation(props) {
  const data = props.inviteData;
  const reseller = data.reseller;
  let { publisher } = data;

  async function handleSubmit() {
    const params = {
      rId: parseInt(reseller.resellerId),
      pId: parseInt(publisher.publisherId),
      h: data.hash,
    };

    try {
      const response = await confirmRegistration(params);
      console.log("confirm validation", response);
      if (response.ok) {
        // Set user as registered in main router
        props.onSignup();
      } else {
        throw new Error("Failed to confirm register.");
      }
    } catch (err) {
      console.log(err);
      if (err.message === "Failed to fetch") {
        cogoToast.error(
          `Your session is expired, please go back and sign in again with your google account.`
        );
      } else {
        cogoToast.error(`Something's wrong with our server: ${err.message}`);
      }
    }
  }

  return (
    <React.Fragment>
      <h1 className="text-center mb-4">
        Welcome <b>{publisher.name}</b>
      </h1>
      <h3 className="text-justify mb-4">
        Reseller <b style={{ color: "#0348b7" }}>{reseller.name}</b> has filled
        in your publisher profile below, please double check if the info is
        correct then confirm the registration to get started!
      </h3>

      <div>
        <div>
          <PublisherBasicInfo publisher={publisher} />
          <PublisherGamProfile publisher={publisher} />
        </div>

        <div className="mt-2 mb-2">
          <Alert variant="warning" className="mb-0 p-4 font-weight-bold">
            <i className="fa fa-exclamation-circle fa-fw" /> Please contact your
            reseller if the above info is incorrect and make sure your reseller
            corrects the info before you continue the registration.
          </Alert>
        </div>

        <div>
          <PublisherContactInfo
            handleSubmit={handleSubmit}
            resellerName={reseller.name}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

async function confirmRegistration(params) {
  const url = `${API_ENDPOINT}/pubRegister/v1/confirmRegister`;
  const options = {
    method: "post",
    credentials: "include",
    body: JSON.stringify(params),
  };

  const response = await fetch(url, options);
  return response;
}

export default RegisterConfirmation;
