import React from 'react'

import CommonStyle from 'common/styles/common.css'
import Constants from 'common/constants/constants'
import YbSubLink from 'common/components/yb-sub-link'

import Styles from 'common/styles/problems.css'

class YbSuggestions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var problem = this.props.problem;
    var itemType = this.props.itemType;
    var problems = Object.keys(Constants.problems).reduce((result, key) => {result[key] = Constants.problems[key].name; return result;}, {});

    return (
      <div className={Styles.problemSuggestion}>
        {(() => {
          switch(problem.name) {
            case problems.UNAUTHORIZED:
              if (itemType === 'gamAccount') {
                return (
                  <div>
                    <p>
                      <YbSubLink
                        message={i18n`THE_GAM_ACCOUNT_COULD_BE_UNAUTHORIZED_FOR_SEVERAL_REASONS_DOT`}
                        link={i18n`REASONS`}
                        to="https://developers.google.com/identity/protocols/OAuth2#expiration"
                        />
                    </p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                    <p>{i18n`1_DOT_ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_GAM_API_WITH_THE_UNAUTHORIZED_GAM_ACCOUNT`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                    <p>{i18n`PLEASE_MAKE_SURE_THE_ACCOUNT_IS_GRANTED_WITH_PERMISSIONS_FOR_YIELDBOOSTER_AND_REAUTHORIZE`}</p>
                  </div>
                );
              }

              return (
                <div>
                  <p>
                    <YbSubLink
                      message={i18n`THE_ADSENSE_ACCOUNT_COULD_BE_UNAUTHORIZED_FOR_SEVERAL_REASONS_DOT`}
                      link={i18n`REASONS`}
                      to="https://developers.google.com/identity/protocols/OAuth2#expiration"
                      />
                  </p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`1_DOT_ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_ADSENSE_API_WITH_THE_UNAUTHORIZED_ADSENSE_ACCOUNT`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_MAKE_SURE_THE_ACCOUNT_IS_GRANTED_WITH_PERMISSIONS_FOR_YIELDBOOSTER_AND_REAUTHORIZE`}</p>
                </div>
              );
            case problems.UNAUTHORIZED_NETWORK:
              return (
                <div>
                  <p>{i18n`THE_NETWORK_HAS_BEEN_ONBOARD_COMMA_HOWEVER_THE_GAM_ACCOUNT_IS_REMOVED_FROM_SUCH_NETWORK_DOT`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`1_DOT_ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_THE_NETWORK_THROUGH_GAM_API`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`GRANT_THE_PERMISSION_OF_THE_NETWORK_TO_THE_ONBOARD_GAM_ACCOUNT_AND_CONTACT_INTOWOW_SUPPORT_TEAM`}</p>
                </div>
              );
            case problems.NOT_ENOUGH_PERMISSION:
              return (
                <div>
                  <p>{i18n`THE_ACCOUNT_IS_SHORT_OF_THE_PERMISSION_FOR_YIELDBOOSTER_TO_PERFORM_OPTIMIZATION`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`1_DOT_ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_OPERATE_THE_NETWORK_FOR_OPTIMIZATION_THROUGH_GAM_API`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_CHECK_FOLLOWING_2_SOLUTIONS_AND_CONTACT_INTOWOW_SUPPORT_TEAM_COLON`}</p>
                  <p>{i18n`1_DOT_GRANT_THE_PERMISSION_OF_THE_NETWORK_TO_THE_ONBOARD_GAM_ACCOUNT`}</p>
                  <p>{i18n`2_DOT_IF_YOU_MANAGED_DIFFERNET_TEAM_AND_NETWORK_ON_AD_MANAGER_COMMA_MAKE_SURE_THE_GAM_ACCOUNT_IS_ADDED_TO_CORRESPONDING_TEAM_AS_A_TEAM_MEMBER`}</p>
                </div>
              );
            case problems.KEY_VALUES_NOT_REPORTABLE:
              return (
                <div>
                  <p>{i18n`THE_REQUIRED_KEYVALUES_ARE_NOT_REPORTED_IN_THE_REPORT_RETRIEVED_FROM_GAM_API_FOR_YIELDBOOSTER_TO_PERFORM_OPTIMIZATION`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`1_DOT_ACCESS_IS_UNRESTRICTED_COMMA_YIELDBOOSTER_CANNOT_RETRIEVE_THE_REQUIRED_KEYVALUES_THROUGH_GAM_API`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_ENABLE_THE_REPORTABLE_FEATURE_AND_CONTACT_INTOWOW_SUPPORT_TEAM_COLON`}</p>
                  <p>{i18n`INVENTORY__KEYVALUES__SELECT_INTOWOW_KEYSIN2W_KEY_COMMA_IN2W_KEY2__REPORT_ON_VALUESON`}</p>
                </div>
              );
            case problems.NO_YIELD_SET:
              return (
                <div>
                  <p>{i18n`NO_YIELD_SET_IS_ONBOARD_TO_THIS_GAM_NETWORK_ON_YIELDBOOSTER_DOT`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`1_DOT_NO_YIELD_SET_IS_ASSOCIATED_TO_THE_NETWORK`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_CREATE_A_YIELD_SET_DOT`}</p>
                </div>
              );
            case problems.INVALID:
              if (itemType === 'adunit') {
                return (
                  <div>
                    <p>{i18n`THE_AD_UNIT_HAS_BEEN_ONBOARD_ONCE_DOT_WHEREAS_THE_UNIT_IS_ARCHIVEDINACTIVATED`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                    <p>{i18n`1_DOT_ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_THE_AD_UNIT_THROUGH_GAM_API`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                    <p>{i18n`PLEASE_ACTIVATE_OR_UNARCHIVE_THE_UNIT_ON_GAM`}</p>
                  </div>
                );
              }

              if (itemType === 'placement') {
                return (
                  <div>
                    <p>{i18n`THE_PLACEMENT_HAS_BEEN_ONBOARD_ONCE_DOT_WHEREAS_THE_PLACEMENT_IS_ARCHIVEDINACTIVATED`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                    <p>{i18n`1_DOT_ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_THE_PLACEMENT_THROUGH_GAM_API`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                    <p>{i18n`PLEASE_ACTIVATE_OR_UNARCHIVE_THE_PLACEMENT_ON_GAM`}</p>
                  </div>
                );
              }

              if (itemType === 'optimization') {
                return (
                  <div>
                    <p>{i18n`THE_ADSENSE_AD_UNIT_HAS_BEEN_ONBOARD_ONCE_DOT_WHEREAS_THE_PLACEMENT_IS_ARCHIVEDINACTIVATED`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                    <p>{i18n`ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_THE_ADSENSE_AD_UNIT_THROUGH_GAM_API`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                    <p>{i18n`PLEASE_ACTIVATE_OR_UNARCHIVE_THE_ADSENSE_AD_UNIT_ON_ADSENSE_PLATFORM`}</p>
                  </div>
                );
              }

              return (
                <div>
                  <p>{i18n`THE_ADX_ACCOUNT_IS_INVALID_SINCE_THE_CLIENT_CODE_DOES_NOT_MATCHED_THE_ADX_ACCOUNT_LINKED_ON_PUBLISHERS_GAM_CONSOLE`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`1_DOT_ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_ANY_GAM_API_WITH_THE_CLIENT_CODE`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_CONTACT_INTOWOW_SUPPORT_TEAM_TO_CORRECT_THE_ADX_CLIENT_CODE`}</p>
                </div>
              );
            case problems.ACCESS_DENIED:
              return (
                <div>
                  <p>{i18n`PUBLISHER_HAS_ONBOARD_THE_BENCHMARK_ORDER_ONCE_DOT_HOWEVER_THE_ACCESS_COULD_BE_DENIED_FOR_SEVERAL_REASONS_COLON`}</p>
                  <p>{i18n`1_DOT_THE_GAM_ACCOUNT_IS_REMOVED_FROM_THE_TRAFFICKER_LIST`}</p>
                  <p>{i18n`2_DOT_THE_ACCOUNT_IS_OPERATED_WITH_A_ROLE_OF_GREATER_SCOPE_OF_PERMISSIONE_DOTG_DOT_ADMIN_WHEREAS_THE_ROLE_IS_CHANGED_TO_A_LIMITED_ONE_WHICH_REQUIRES_THE_ACCOUNT_TO_BE_ASSIGNED_AS_TRAFFICKER_OF_THE_ORDER_DOT`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`1_DOT_ACCESS_IS_RESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_THE_BENCHMARK_ORDER_THROUGH_GAM_API`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_SET_THE_ONBOARD_GAM_ACCOUNT_TO_BE_THE_TRAFFICKEREITHER_PRIMARY_OR_SECONDARY_OF_THE_BENCHMARK_ORDER`}</p>
                </div>
              );
            case problems.MISSING_OPTIMIZATION_ADSENSE_ADUNIT:
              if (itemType === 'adunit') {
                return (
                  <div>
                    <p>{i18n`ONE_OR_SEVERAL_SIZE_OF_THE_GAM_UNIT_DOES_NOT_MATCH_TO_AN_ADSENSE_AD_UNIT_THAT_SUPPORTS_SUCH_SIZE`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                    <p>{i18n`1_DOT_ONE_OR_SEVERAL_SIZE_OF_THE_GAM_AD_UNIT_DOES_NOT_MATCH_AN_ADSENSE_AD_UNIT_THAT_SUPPORTS_SUCH_SIZE`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                    <p>{i18n`PLEASE_ADD_AN_ADSENSE_AD_UNIT_SUPPORTING_THE_CORRESPONDING_SIZE`}</p>
                  </div>
                );
              }

              if (itemType === 'placement') {
                return (
                  <div>
                    <p>{i18n`ONE_OR_SEVERAL_SIZE_OF_THE_GAM_PLACEMENT_DOES_NOT_MATCH_AN_ADSENSE_AD_UNIT_THAT_SUPPORTS_SUCH_SIZE`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                    <p>{i18n`1_DOT_ONE_OR_SEVERAL_SIZE_OF_THE_GAM_PLACEMENT_DOES_NOT_MATCH_AN_ADSENSE_AD_UNIT_THAT_SUPPORTS_SUCH_SIZE`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                    <p>{i18n`PLEASE_ADD_AN_ADSENSE_AD_UNIT_SUPPORTING_THE_CORRESPONDING_SIZE`}</p>
                  </div>
                );
              }

              return <span>{i18n`WE_DONT_HAVE_INFORMATION_ON_HOW_TO_SOLVE_THIS_PROBLEM_YET_DOT`}</span>
            case problems.MISSING_GAM_BENCHMARK_ORDER:
              if (itemType === 'adunit') {
                return (
                  <div>
                    <p>{i18n`NONE_OF_THE_ONBOARD_BENCHMARK_ORDER_HAS_AT_LEAST_1_LINE_ITEM_THAT_TARGETS_THE_AD_UNIT_EFFECTIVELYTHE_SETTING_ON_GAM_CONSOLE_COULD_BE_TARGETING_THE_PLACEMENT_THAT_INCLUDES_SUCH_AD_UNIT`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                    <p>{i18n`1_DOT_NONE_OF_THE_ONBOARD_BENCHMARK_ORDER_HAS_AT_LEAST_1_LINE_ITEM_THAT_TARGETS_THE_AD_UNIT_EFFECTIVELYTHE_SETTING_ON_GAM_CONSOLE_COULD_BE_TARGETING_THE_PLACEMENT_THAT_INCLUDES_SUCH_AD_UNIT`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                    <p>{i18n`PLEASE_CHECK_FOLLOWING_2_SOLUTIONS_COLON`}</p>
                    <p>{i18n`1_DOT_ONBOARD_THE_ORDER_HAVING_AT_LEAST_1_LINE_ITEM_TARGETING_THE_AD_UNIT_EFFECTIVELY_TO_YIELDBOOSTER_WEBPAGE`}</p>
                    <p>{i18n`2_DOT_INCLUDE_THE_AD_UNIT_EFFECTIVELY_AMONG_THE_TARGETING_LIST_OF_CERTAIN_LINE_ITEM_OF_THE_BENCHMARK_ORDER_ON_GAM_CONSOLE`}</p>
                  </div>
                );
              }

              if (itemType === 'placement') {
                return (
                  <div>
                    <p>{i18n`NONE_OF_THE_ONBOARD_BENCHMARK_ORDER_HAS_AT_LEAST_1_LINE_ITEM_THAT_TARGETS_THE_PLACEMENT_EFFECTIVELYTHE_SETTING_ON_GAM_CONSOLE_COULD_BE_TARGETING_THE_AD_UNITS_THAT_ARE_INCLUDED_IN_THE_PLACEMENT`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                    <p>{i18n`1_DOT_NONE_OF_THE_ONBOARD_BENCHMARK_ORDER_HAS_AT_LEAST_1_LINE_ITEM_THAT_TARGETS_THE_PLACEMENT_EFFECTIVELYTHE_SETTING_ON_GAM_CONSOLE_COULD_BE_TARGETING_THE_AD_UNITS_THAT_ARE_INCLUDED_IN_THE_PLACEMENT`}</p>
                    <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                    <p>{i18n`PLEASE_CHECK_FOLLOWING_2_SOLUTIONS_COLON`}</p>
                    <p>{i18n`1_DOT_ONBOARD_THE_ORDER_HAVING_AT_LEAST_1_LINE_ITEM_TARGETING_THE_PLACEMENT_EFFECTIVELY_TO_YIELDBOOSTER_WEBPAGE`}</p>
                    <p>{i18n`2_DOT_INCLUDE_THE_PLACEMENT_EFFECTIVELY_AMONG_THE_TARGETING_LIST_OF_CERTAIN_LINE_ITEM_OF_THE_BENCHMARK_ORDER_ON_GAM_CONSOLE`}</p>
                  </div>
                );
              }

              return <span>{i18n`WE_DONT_HAVE_INFORMATION_ON_HOW_TO_SOLVE_THIS_PROBLEM_YET_DOT`}</span>
            case problems.UNLINKED_ADSENSE_ACCOUNT:
              return (
                <div>
                  <p>{i18n`THE_ONBOARD_ADSENSE_ACCOUNT_IS_NOT_LINKED_TO_THIS_GAM_NETWORK`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`THE_ADSENSE_ACCOUNT_IS_AUTHORIZED_COMMA_WHEREAS_THE_ACCOUNT_DOES_NOT_LINK_TO_THE_NETWORK`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_CHECK_FOLLOWING_2_SOLUTIONS_COLON`}</p>
                  <p>{i18n`1_DOT_LINK_THE_MATCHED_ADSENSE_ACCOUNTADMIN__GLOBAL_SETTINGS__NETWORK_SETTINGS__LINKED_ACCOUNTS__CLICK_LINK_AN_ADSENSE_ACCOUNT`}</p>
                  <p>{i18n`2_DOT_REVOKEREMOVE_THE_WRONG_ADSENSE_ACCOUNT_AND_ONBOARD_THE_LINKED_ADSENSE_ACCOUNTPLEASE_CONTACT_INTOWOW_SUPPORT_TEAM`}</p>
                </div>
              );
              break;
            case problems.UNLINKED_ADX_ACCOUNT:
              return (
                <div>
                  <p>{i18n`THE_ONBOARD_ADX_ACCOUNT_IS_NOT_LINKED_TO_THIS_GAM_NETWORK`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`THE_ADX_ACCOUNT_IS_VALID_COMMA_WHEREAS_THE_ACCOUNT_DOES_NOT_LINK_TO_THE_NETWORK`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_CHECK_FOLLOWING_2_SOLUTIONS_COLON`}</p>
                  <p>{i18n`1_DOT_LINK_THE_MATCHED_ADX_ACCOUNTADMIN__GLOBAL_SETTINGS__NETWORK_SETTINGS__LINKED_ACCOUNTS__CLICK_LINK_AN_AD_EXCHANGE_ACCOUNT`}</p>
                  <p>{i18n`2_DOT_REMOVE_THE_WRONG_ADX_ACCOUNT_AND_ONBOARD_THE_LINKED_ADX_ACCOUNTPLEASE_CONTACT_INTOWOW_SUPPORT_TEAM`}</p>
                </div>
              );
              break;
            case problems.CAPPED:
              return (
                <div>
                  <p>{i18n`YIELDBOOSTER_OPTIMIZATION_IS_CAPPED_FOR_THIS_NETWORK_DOT`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`TRIAL_PERIOD_IS_EXPIRED_DOT`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_CONTACT_SALES_FOR_FURTHER_OPTIMIZATION_DOT`}</p>
                </div>
              );
              break;
            case problems.NO_AVAILABLE_ACCESSIBLE_ORDERS:
              return (
                <div>
                  <p>{i18n`NO_BENCHMARK_ORDER_IS_ASSOCIATED_TO_THIS_NETWORK_DOT`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`CRITERIA`}</span>
                  <p>{i18n`1_DOT_ACCESS_IS_UNRESTRICTED_COMMA_YIELDBOOSTER_CANNOT_ACCESS_ANY_ORDER_THROUGH_GAM_API`}</p>
                  <p>{i18n`2_DOT_NO_ORDER_HAS_BEEN_ASSIGNED_TO_THIS_NETWORK_AS_BENCHMARK_ORDER`}</p>
                  <span className={`${CommonStyle.textDanger} ${Styles.problemTitle}`}>{i18n`SOLUTION`}</span>
                  <p>{i18n`PLEASE_CHECK_FOLLOWING_2_SOLUTIONS_COLON`}</p>
                  <p>{i18n`1_DOT_ASSIGN_THE_ADVERTISER_COMMA_INTOWOW_COMMA_AS_THE_SECONDARY_TRAFFICKER_TO_THE_ORDER_MANAGING_GOOGLE_DEMAND`}</p>
                  <p>{i18n`2_DOT_ASSIGN_ORDERS_IN_NETWORK_CONFIGURATION_PAGE`}</p>
                </div>
              );
              break;
            default:
              return <span>{i18n`WE_DONT_HAVE_INFORMATION_ON_HOW_TO_SOLVE_THIS_PROBLEM_YET_DOT`}</span>
          }
        })()}
      </div>
    );
  }
};

module.exports = YbSuggestions;
