import React from 'react'

import CommonStyle from 'common/styles/common.css'

import YbModal from 'common/components/yb-modal'
import YbHighlight from 'common/components/yb-highlight'

import Styles from '../styles/summary.css'

class YbNetworkConfigHint extends React.Component {
  constructor(props) {
    super(props);
  }

  onHideModal() {
    var callback = this.props.callback;

    if (callback) {
      callback();
    }
  }

  render() {
    var show = this.props.show;
    var hint = this.props.hint;

    return (
      <YbModal
        show={show}
        noSuccessBtn={true}
        bigModal={true}
        onHide={this.onHideModal.bind(this)}
        title={hint === 1 ? i18n`How to Enable Key-values reporting` : i18n`How to Enable API access`}
        cancelText={i18n`Close`}
        >
        <div className={`${CommonStyle.textView}`}>
          {hint === 1 ? (
            <div className={CommonStyle.textAlignLeft}>
              <div>{i18n`a. Sign in to Google Ad Manager as an Admin user`}</div>
              <div>
                <YbHighlight
                  text={i18n`b. Click Inventory > Key-values`}
                  highlight={[i18n`Inventory`, i18n`Key-values`]}
                  />
              </div>
              <div><span className={Styles.keyValues1}></span></div>
              <div>
                <YbHighlight
                  text={i18n`c. Click in2w_key`}
                  highlight={[i18n`in2w_key`]}
                  />
              </div>
              <div>
                <YbHighlight
                  text={i18n`d. Under Report on values, click edit > Yes > Save`}
                  highlight={[i18n`Report on values`, i18n`edit`, i18n`Yes`, i18n`Save`]}
                  />
              </div>
              <div><span className={Styles.keyValues2}></span></div>
              <div>
                <YbHighlight
                  text={i18n`e. Repeat b and c for in2w_key2`}
                  highlight={[i18n`in2w_key2`]}
                  />
              </div>
            </div>
          ) : (
            <div className={CommonStyle.textAlignLeft}>
              <div>{i18n`a. Sign in to Google Ad Manager as an Admin user`}</div>
              <div>
                <YbHighlight
                  text={i18n`b. Click Admin > Global settings`}
                  highlight={[i18n`Admin`, i18n`Global settings`]}
                  />
              </div>
              <div>
                <YbHighlight
                  text={i18n`c. Under Network settings, if the API access is restricted,`}
                  highlight={[i18n`Network settings`, i18n`API access`]}
                  />
                <YbHighlight
                  text={i18n`click API access switch`}
                  highlight={i18n`API access`}
                  />
              </div>
              <div><span className={Styles.apiAccess1}></span></div>
              <div>
                <YbHighlight
                  text={i18n`d. Under API Terms and Conditions, click ACCEPT`}
                  highlight={[i18n`API Terms and Conditions`, i18n`ACCEPT`]}
                  />
              </div>
              <div><span className={Styles.apiAccess2}></span></div>
              <div><span className={Styles.apiAccess3}></span></div>
            </div>
          )}
        </div>
      </YbModal>
    );
  }
};

module.exports = YbNetworkConfigHint;
