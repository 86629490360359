import React from "react";
import BillingReportQuicksight from "../components/billing-report-quicksight";

/**
 *  /subscription-and-billing/billing-report
 */
function BillingReport() {
  return <BillingReportQuicksight />;
}

module.exports = BillingReport;
