import React from "react";
import _ from "lodash";
import { Query } from "react-apollo";

import { withRouter } from "react-router-dom";
import Style from "../styles/ad-units.css";
import CommonStyle from "common/styles/common.css";
import YbLoading from "common/components/yb-loading";
import FormStyle from "common/styles/forms.css";

import { GET_UNIT_CANDIDATES } from "../query/queries";
import BatchOnboardItemComponent from "./batch-onboard-item-component";
import OnboardFormWrapper from "./onboard-form-wrapper";
import { formatCandidates } from "../helpers/ad-unit-candidate-formatter-latest";
import { transformTextToArray } from "../helpers/batch-onboard-transformer";
import ParentUnitLabel from "./parent-unit-label";

const ONBOARD_LIMIT_UNIT_COUNT = 1000;
const ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE = `Sorry, we are unable to onboard more than ${ONBOARD_LIMIT_UNIT_COUNT} ad units at a time.`;

class BatchOnboardWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adUnitIdsText: "",
      adUnitIds: [],

      isEditable: true,
      validateClicked: false,

      hasError: false,
      validateErrMsg: "",

      finalAdUnitIds: [],
    };

    this.onTextAreaChange = this.onTextAreaChange.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSelectUnit = this.handleSelectUnit.bind(this);
    this.handleSelectUnitIds = this.handleSelectUnitIds.bind(this);
    this.onBackToSummaryPage = this.onBackToSummaryPage.bind(this);
  }

  onBackToSummaryPage() {
    const { publisherId } = this.props;
    let overviewRoute = "/summary/overview";
    if (publisherId > 0) {
      overviewRoute = `/publisher/${publisherId}/summary/overview`;
    }

    return this.props.history.push(overviewRoute);
  }

  onTextAreaChange(event) {
    // convert to array here
    // Important! max limit 1000
    const text = event.target.value;
    const unitIds = transformTextToArray(text);

    if (unitIds.length > ONBOARD_LIMIT_UNIT_COUNT) {
      return this.setState({
        hasError: true,
        validateErrMsg: ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE,
      });
    }

    this.setState({
      hasError: false,
      validateErrMsg: "",
      adUnitIdsText: text,
      adUnitIds: unitIds,
    });
  }

  handleValidate() {
    this.setState({
      isEditable: false,
      validateClicked: true,
    });
  }

  handleEdit() {
    this.setState({
      isEditable: true,
      validateClicked: false,
      hasError: false,
      validateErrMsg: "",
      finalAdUnitIds: [],
    });
  }

  handleSelectUnit(unit) {
    let newUnitIds = [...this.state.finalAdUnitIds];
    if (_.indexOf(this.state.finalAdUnitIds, unit.extId) !== -1) {
      _.pull(newUnitIds, unit.extId);
    } else {
      newUnitIds.push(unit.extId);
    }
    this.setState({
      finalAdUnitIds: newUnitIds,
    });
  }

  handleSelectUnitIds(unitIds) {
    this.setState({
      finalAdUnitIds: [...this.state.finalAdUnitIds, ...unitIds],
    });
  }

  render() {
    const {
      yieldSetId,
      gamNetworkId,
      publisherId,

      wizard, // wizard action buttons
    } = this.props;
    const {
      adUnitIdsText,
      adUnitIds,
      isEditable,
      validateClicked,
      hasError,
      validateErrMsg,
      finalAdUnitIds,
    } = this.state;

    let queryVariables = {};
    if (validateClicked) {
      queryVariables = {
        filter: {
          adUnitIds: adUnitIds,
          gamNetworkId: gamNetworkId,
        },
        pubId: publisherId,
      };
    }

    return (
      <React.Fragment>
        <div>
          <div
            className={FormStyle.label}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              {i18n`Ad Units`} <span className={FormStyle.requiredStar}>*</span>{" "}
            </div>
          </div>

          <div>
            <div className="form-group">
              <label
                htmlFor="unitIds"
                style={{ fontWeight: "600", fontSize: "1.05rem" }}
              >
                1. Insert GAM Ad Unit Ids (Use comma, whitespace or line-break
                to separate ids)
              </label>
              <div style={{ color: "#718096", fontSize: "0.9rem" }}>
                ex. 283423,193421,593312
              </div>

              <textarea
                className="form-control"
                id="unitIds"
                rows="3"
                onChange={this.onTextAreaChange}
                value={adUnitIdsText}
                disabled={!isEditable}
              ></textarea>
              <div
                style={{
                  color: "#718096",
                  fontSize: "0.85rem",
                }}
              >
                <b>{adUnitIds.length}</b> ad units inserted{" "}
                <i>(maximum {ONBOARD_LIMIT_UNIT_COUNT} at a time)</i>
              </div>

              {hasError && <div style={{ color: "red" }}>{validateErrMsg}</div>}

              <div style={{ marginTop: "16px" }}>
                <button
                  className={
                    isEditable ? CommonStyle.buttonPrimary : CommonStyle.button
                  }
                  disabled={adUnitIds.length === 0 || hasError}
                  onClick={isEditable ? this.handleValidate : this.handleEdit}
                >
                  <span style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    {isEditable
                      ? "Validate and review"
                      : "Edit inserted GAM Ad Unit Ids"}
                  </span>
                </button>
              </div>

              {!isEditable && validateClicked && (
                <Query
                  query={GET_UNIT_CANDIDATES}
                  variables={queryVariables}
                  fetchPolicy={"network-only"}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <YbLoading />;
                    }

                    if (error) {
                      console.log(error);
                      return (
                        <div style={{ color: "red" }}>
                          Error Loading Ad Units...
                          <div style={{ fontSize: "14px" }}>
                            {error.message &&
                              JSON.stringify(error.message, null, 4)}
                          </div>
                        </div>
                      );
                    }

                    if (data) {
                      if (data.extItems.length > 0) {
                        const candidates = formatCandidates(
                          _.cloneDeep(data.extItems)
                        );

                        return (
                          <div style={{ marginTop: "32px" }}>
                            <hr></hr>

                            <div style={{ marginTop: "32px" }}>
                              <BatchOnboardPart2
                                adUnitIds={adUnitIds}
                                candidates={candidates}
                                handleSelectUnit={this.handleSelectUnit}
                                handleSelectUnitIds={this.handleSelectUnitIds}
                              ></BatchOnboardPart2>
                            </div>

                            <div>
                              <OnboardFormWrapper
                                yieldSetId={yieldSetId}
                                gamNetworkId={gamNetworkId}
                                publisherId={publisherId}
                                selectedItems={_.map(finalAdUnitIds, (id) => {
                                  return { extId: id };
                                })}
                                isSubmitDisabled={finalAdUnitIds.length === 0}
                                shouldHideCancelBtn={wizard ? true : false} // wizard has its own prev step btn
                                callbackAfterOnboardSuccess={
                                  wizard
                                    ? () =>
                                        wizard.nextStepFnForStep4(selectedItems)
                                    : () => {
                                        this.onBackToSummaryPage();
                                      }
                                }
                                wizard={wizard}
                              ></OnboardFormWrapper>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="text-danger"
                            style={{ padding: "8px 0px" }}
                          >
                            Could not find any valid ad units to onboard.
                          </div>
                        );
                      }
                    }

                    return "";
                  }}
                </Query>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class BatchOnboardPart2 extends React.PureComponent {
  constructor(props) {
    super(props);

    const tabsMap = {
      validAdUnits: {
        key: "validAdUnits",
        title: "Valid Ad Units",
        units: [],
      },
      onboardedAdUnits: {
        key: "onboardedAdUnits",
        title: "Onboarded Ad Units",
        units: [],
      },
      invalidAdUnits: {
        key: "invalidAdUnits",
        title: "Invalid Ad Units",
        units: [],
      },
    };
    const { adUnitIds, candidates } = this.props;

    _.forEach(candidates, (item) => {
      // Already onboarded units
      if (item.isOnboarded) {
        return tabsMap.onboardedAdUnits.units.push(item);
      }

      // Invalid units: Not Compatible / Not Found
      // now along parent units

      if (!item.isCompatible) {
        item.invalidLabel = "Not Compatible";
        return tabsMap.invalidAdUnits.units.push(item);
      }

      // Valid units
      item.checkboxStatus = "SELECTED"; // default select all valid units
      // this.props.handleSelectUnitIds(item);
      tabsMap.validAdUnits.units.push(item);
    });

    // Not found!
    const unitsNotFound = _.difference(adUnitIds, _.map(candidates, "extId"));
    _.forEach(unitsNotFound, (unitId) => {
      const item = {
        extId: unitId,
        invalidLabel: "Not Found",
      };
      tabsMap.invalidAdUnits.units.push(item);
    });

    if (tabsMap.validAdUnits.units.length > 0) {
      this.props.handleSelectUnitIds(
        _.map(tabsMap.validAdUnits.units, "extId")
      );
    }

    this.state = {
      currentTabKey: tabsMap.validAdUnits.key,
      tabsMap,
    };

    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  handleTabSelect(tab) {
    this.setState({ currentTabKey: tab.key });
  }

  render() {
    const { currentTabKey, tabsMap } = this.state;

    const hasSelectedParentUnit = _.some(this.props.candidates, {
      hasChildren: true,
    });

    return (
      <div>
        <div className="form-group">
          <label
            htmlFor="unitIds"
            style={{ fontWeight: "600", fontSize: "1.05rem" }}
          >
            2. Review GAM Ad Unit Candidates
          </label>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ul className="nav nav-tabs">
            {_.keys(tabsMap).map((tabKey) => {
              const tab = tabsMap[tabKey];
              const isActive = currentTabKey === tab.key;

              const tabStyle = {
                cursor: "pointer",
                fontSize: "1rem",
                color: isActive ? "#1a202c" : "#a0aec0",
              };

              return (
                <li className="nav-item" key={tabKey}>
                  <div
                    className={`nav-link ${isActive && "active"}`}
                    style={tabStyle}
                    onClick={() => this.handleTabSelect(tab)}
                  >
                    {tab.title} ({tab.units.length})
                  </div>
                </li>
              );
            })}
          </ul>

          <div style={{ display: "flex", gap: "24px" }}>
            {hasSelectedParentUnit && (
              <div style={{ fontSize: "12px", color: "#595959" }}>
                <div style={{ fontWeight: "600" }}>Parent Unit</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ParentUnitLabel></ParentUnitLabel>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <UnitList
            tab={tabsMap[currentTabKey]}
            handleSelectUnit={this.props.handleSelectUnit}
          ></UnitList>
        </div>
      </div>
    );
  }
}

class UnitList extends React.Component {
  render() {
    const { tab, handleSelectUnit } = this.props;
    // Sort candidates!
    const units = _.orderBy(tab.units, ["dailyCompatibleAvgReq"], ["desc"]);
    return (
      <div className={Style.selectedAdUnits}>
        {units.map((item) => {
          return (
            <BatchOnboardItemComponent
              key={item.extId}
              item={item}
              // onSelectUnit={handleSelectUnit}
              onSelectUnit={(item) => {
                item.checkboxStatus =
                  item.checkboxStatus === "NOT_SELECTED"
                    ? "SELECTED"
                    : "NOT_SELECTED";
                return handleSelectUnit(item);
              }}
            ></BatchOnboardItemComponent>
          );
        })}
      </div>
    );
  }
}

module.exports = withRouter(BatchOnboardWrapper);
