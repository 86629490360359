import React from "react";
import { Query } from "react-apollo";
import CommonStyle from "../../../common/styles/common.css";

import { QUERY_HOME_DASHBOARD } from "../query/home-dashboard";

import YbLoading from "common/components/yb-loading";
import HomeDashboardWrapper from "./home-dashboard-wrapper";

class HomeDashboard extends React.Component {
  render() {
    return (
      <div style={{ minHeight: "100vh" }}>
        <Query query={QUERY_HOME_DASHBOARD} fetchPolicy={"network-only"}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return (
                <div className={CommonStyle.textAlignCenter}>
                  <YbLoading></YbLoading>
                </div>
              );
            }

            if (error) {
              return (
                <div
                  style={{
                    textAlign: "center",
                    padding: "56px",
                    minHeight: "100vh",
                  }}
                >
                  <p>It seems we have no data for Home Dashboard yet.</p>
                </div>
              );
            }

            const reportsUrl = _.get(data, "homeDashboard.signedUrl");
            if (data && reportsUrl) {
              return (
                <HomeDashboardWrapper
                  reportsUrl={reportsUrl}
                ></HomeDashboardWrapper>
              );
            }

            return (
              <div
                style={{
                  textAlign: "center",
                  padding: "56px",
                  minHeight: "100vh",
                }}
              >
                Dashboard not available
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default HomeDashboard;
