import React from 'react'

class EmbeddedDashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var containerDiv = document.getElementById('dashboardContainer');
    var params = {
        url: this.props.dashboard,
        container: containerDiv,
        height: '800px',
        scrolling: 'yes',
    };
    var dashboard = QuickSightEmbedding.embedDashboard(params);
  }

  render() {
    return (
      <div id="dashboardContainer"></div>
    );
  }
};

module.exports = EmbeddedDashboard;
