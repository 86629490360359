import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import cogoToast from "cogo-toast";
import { Card, Table, Button, Alert } from "react-bootstrap";
import { SUBSCRIPTION_STATUS } from "../../common/constants/common";
import { UserInfoContext } from "../../common/contexts/contexts";
import { useMutation } from "react-apollo-hooks";
import { PUBLISHER_SUBSCRIBE_PLAN } from "../query/publishers";

function PublisherSubscriptionPlans(props) {
  const publisher = props.publisher;
  const refreshPublisherDetails = props.refreshPublisherDetails;
  const hasSubscriptionPlans =
    publisher.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBED;

  const subscribe = useMutation(PUBLISHER_SUBSCRIBE_PLAN);

  let trialMessage = "";
  if (publisher.trialInfo) {
    if (publisher.isInFreeTrial) {
      trialMessage = `In free trial with ${publisher.trialInfo.remainingDays} days remaining`;
    } else {
      if (_.get(publisher, "trialInfo.billableStartDate")) {
        trialMessage = `Free trial ended on ${moment(
          publisher.trialInfo.billableStartDate
        ).format("YYYY/MM/DD")} (${moment(
          publisher.trialInfo.billableStartDate
        ).fromNow()})`;
      } else {
        trialMessage = "Free trial ended";
      }
    }
  }

  const [isEditing, setIsEditing] = useState(false);

  function handleCancelEditing() {
    setIsEditing(false);
  }

  function handleAddPlansClicked() {
    setIsEditing(true);
  }

  function isBillablePublisher(userInfo) {
    return (
      _.get(userInfo, "reseller.isBillablePublisher") ||
      _.get(userInfo, "publisher.isBillablePublisher")
    );
  }

  function handleFillInBillingInfo() {
    const path = "/subscription-and-billing/billing-setting";
    props.history.push(path);
  }

  function handleSubscribe() {
    subscribe({
      variables: {
        input: {
          subscriber: {
            publisherId: publisher.publisherId,
            plan: "THE_PLAN_IS_NO_PLAN",
          },
        },
      },
    })
      .then((response) => {
        const result = _.get(
          response,
          "data.createPublisherSubscription.result"
        );

        if (result === "OK") {
          cogoToast.success(`Successfully subscribed ${publisher.name}`);
          setTimeout(() => {
            refreshPublisherDetails();
            setIsEditing(false);
          }, 500);
        } else {
          throw `We are unable to subscribe ${publisher.name}`;
        }
      })
      .catch((err) => {
        cogoToast.error(
          `Something wrong happened to our server when subscribing publisher ${publisher.name}: ${err.message}`,
          {
            hideAfter: 15,
          }
        );
      });
  }

  return (
    <Card className="shadow-sm">
      <Card.Body className="p-4">
        <div className="text-muted mb-4" style={{ letterSpacing: "0.04em" }}>
          <b>SUBSCRIPTION PLANS</b>
          <hr />
        </div>

        <UserInfoContext.Consumer>
          {({ userInfo }) => {
            return (
              <React.Fragment>
                {userInfo && (
                  <div>
                    <div>
                      <div
                        className="text-dark"
                        style={{ marginBottom: "16px" }}
                      >
                        {isEditing ? (
                          <div className="mb-4">
                            <p className="font-weight-bold">
                              Subscribe to a plan to get started:
                            </p>
                            <Card>
                              <Card.Body>
                                <div className="d-flex">
                                  <div style={{ fontSize: "18px" }}>
                                    <div className="text-dark font-weight-bold">
                                      Intowow
                                    </div>
                                    <small className="text-muted">
                                      For publishers to get started with
                                      optimization.
                                    </small>
                                  </div>

                                  <div className="ml-auto align-self-center">
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      className="pl-3 pr-3"
                                      onClick={handleSubscribe}
                                    >
                                      Subscribe
                                    </Button>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>

                            {publisher.isInFreeTrial && (
                              <p className="mt-3 text-info">
                                <i className="fa fa-info-circle fa-fw" /> Don't
                                worry, once subscribed the actual billing starts{" "}
                                <b>after</b> your free trial has ended.
                              </p>
                            )}
                          </div>
                        ) : (
                          <div>
                            <Table borderless>
                              <tbody>
                                <tr>
                                  <td className="pl-0 text-secondary">
                                    Current Plan:
                                  </td>
                                  <td width="70%">
                                    <div className="font-weight-bold">
                                      <span className="text-dark">
                                        {hasSubscriptionPlans
                                          ? "Intowow"
                                          : "Not subscribed to any plans"}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-0 text-secondary">
                                    Free Trial:
                                  </td>
                                  <td width="70%">{trialMessage}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </div>

                      <div className="d-flex justify-content-start">
                        {isBillablePublisher(userInfo) ? (
                          <React.Fragment>
                            {isEditing ? (
                              <Button
                                variant="secondary"
                                size="sm"
                                className="pl-4 pr-4"
                                onClick={handleCancelEditing}
                              >
                                Cancel
                              </Button>
                            ) : (
                              <React.Fragment>
                                {hasSubscriptionPlans ? (
                                  ""
                                ) : (
                                  <Button
                                    variant="info"
                                    size="sm"
                                    onClick={handleAddPlansClicked}
                                  >
                                    {hasSubscriptionPlans ? "Update" : "Add"}{" "}
                                    Subscription Plan
                                  </Button>
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ) : (
                          <Alert variant="warning">
                            <div>
                              <p className="font-weight-bold mb-2 mt-1">
                                <i className="fa fa-exclamation-circle" />{" "}
                                Sorry, seems like we don’t have your billing
                                information.
                              </p>
                              <p className="mb-3">
                                We need to make sure your payment method is
                                valid before we can continue with any
                                subscriptions.
                              </p>
                              <Button
                                variant="primary"
                                size="sm"
                                className="mb-1"
                                onClick={handleFillInBillingInfo}
                              >
                                Fill out Billing Information
                              </Button>
                            </div>
                          </Alert>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          }}
        </UserInfoContext.Consumer>
      </Card.Body>
    </Card>
  );
}

export default withRouter(PublisherSubscriptionPlans);
