import React from "react";
import YbTooltip from "common/components/yb-tooltip";

class MetricTooltip extends React.PureComponent {
  render() {
    const { tooltipMessage, place } = this.props;
    return (
      <React.Fragment>
        <span style={styles.dashedUnderline}>
          <YbTooltip message={tooltipMessage} position={place || "top"}>
            {this.props.children}
          </YbTooltip>
        </span>
      </React.Fragment>
    );
  }
}

const styles = {
  dashedUnderline: {
    borderBottom: "1px dashed lightgray",
  },
};

export default MetricTooltip;
