import React from 'react'

import CommonStyle from 'common/styles/common.css'

import Styles from '../styles/summary.css'

class YbStickyComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderedComponent: null,
      paddingTop: this.props.paddingTop,
    };
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  componentDidMount() {
    this.state.renderedComponent = this.refs.container;
    this.state.originalTop = this.refs.container.getBoundingClientRect().y + window.scrollY;
  }

  onScroll(event) {
    if (this.refs.container) {
      this.forceUpdate();
    }
  }

  render() {
    var elementTop = 0;
    var scrollY = window.scrollY;
    var renderedComponent = this.state.renderedComponent;
    var boundingClientRect = null;
    var paddingTop = this.state.paddingTop;
    var originalTop = this.state.originalTop;
    var spacerStyle = {
      display: 'block',
    };

    if (renderedComponent) {
      boundingClientRect = renderedComponent.getBoundingClientRect();
      spacerStyle.height = boundingClientRect.height + 'px';
      spacerStyle.width = boundingClientRect.width + 'px';
      if (scrollY + paddingTop > originalTop) {
        elementTop = scrollY - boundingClientRect.y + paddingTop;
        return (
          <span>
            <span style={{
              display: 'block',
              height: boundingClientRect.height + 'px',
              width: boundingClientRect.width + 'px',
            }}></span>
            <span
              ref="container"
              className={CommonStyle.stickyWrapper}
              style={{
                position: 'fixed',
                top: (paddingTop) + 'px',
                width: boundingClientRect.width + 'px',
              }}>
              {this.props.children}
            </span>
          </span>
        );
      }
      else {
        this.state.originalTop = boundingClientRect.y + scrollY;
      }
    }

    return (
      <span ref="container" className={CommonStyle.stickyWrapper}>
        {this.props.children}
      </span>
    );
  }
};

module.exports = YbStickyComponent;
