export function transformTextToArray(text) {
  let string = _transformFn(text);
  let items = _.split(string, ",");
  items = _.uniq(_.compact(items));
  return items;
}

function _transformFn(content) {
  const charArr = content.split("");
  let validContent = [];
  for (let c of charArr) {
    const lastC = _.last(validContent);
    switch (c) {
      case "\r": {
        continue;
      }

      case "\n":
      case " ": {
        // Ignore if we see continuous seperator
        if (lastC === undefined || lastC === ",") {
          continue;
        }

        c = ",";
        validContent.push(c);
        break;
      }

      default: {
        if (c >= "0" && c <= "9") {
          validContent.push(c);
        } else if (lastC && lastC != ",") {
          validContent.push(",");
        }
        break;
      }
    }
  }

  return validContent.join("");
}
