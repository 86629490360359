import React, { useState } from "react";
import { Alert } from "react-bootstrap";

function ThirdPartyCookieChecker() {
  const [isEnabled, setIsEnabled] = useState(true);

  var receiveMessage = function (evt) {
    if (evt.data === "MM:3PCunsupported") {
      setIsEnabled(false);
    } else if (evt.data === "MM:3PCsupported") {
      setIsEnabled(true);
    }
  };
  window.addEventListener("message", receiveMessage, false);

  return (
    <div>
      <iframe
        src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
        style={{ display: "none" }}
      />
      {/* third party cookies are {isEnabled ? "enabled" : "not enabled"} */}
      {!isEnabled && (
        <Alert variant="danger" className="p-4">
          <i className="fa fa-exclamation-circle fa-fw" />
          Please enable third party cookies in your browser in order to login.
          <br></br>
          <a
            href="https://support.google.com/chrome/answer/95647"
            target="_blank"
            className="text-sm"
          >
            (How to enable third party cookies in Chrome)
          </a>
        </Alert>
      )}
    </div>
  );
}

export default ThirdPartyCookieChecker;
