import React, { useState, useEffect } from "react";
import { Modal, Card, Button, Form, Col } from "react-bootstrap";
import _ from "lodash";

function PublisherFormStepItem(props) {
  const step = props.stepConfig;

  return (
    <div className="p-1 flex-fill">
      <div
        style={{
          height: "6px",
          width: "100%",
          backgroundColor: step.isFinished
            ? "green"
            : step.isCurrent
            ? "gray"
            : "lightgray"
        }}
      />
      <small
        style={{
          color: step.isFinished
            ? "green"
            : step.isCurrent
            ? "black"
            : "lightgray"
        }}
      >
        {step.title}
      </small>
    </div>
  );
}

export default PublisherFormStepItem;
